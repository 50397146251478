<template>
  <app-wrapper>
    <plain-layout class="bg-white dark:bg-black !h-full">
      <div class="w-full flex flex-col space-y-4 h-full">
        <!-- Top section -->
        <app-image-loader
          :photoUrl="'/images/purple-bg.svg'"
          class="w-full flex flex-col space-y-3 px-4 !overflow-y-visible relative"
          style="
            padding-top: calc(env(safe-area-inset-top) + 16px) !important;
            height: calc(env(safe-area-inset-top) + 160px) !important;
          "
        >
          <div class="w-full flex flex-row justify-between items-center">
            <app-image-loader
              :photoUrl="'/images/avatar-1.png'"
              :customClass="'!h-[40px] !w-[40px] rounded-full'"
              @click="showSideBar.value = true"
            />

            <div class="flex flex-row space-x-3 items-center">
              <app-icon
                :name="'story-circle-purple'"
                :customClass="'!h-[38px] cursor-pointer'"
                @click="
                  setStoryContent(ContentType.Explore, {
                    id: 0,
                    type: 'collection',
                  })
                "
              />
              <app-icon
                :name="'shopping-basket-circle-purple'"
                :customClass="'!h-[38px] cursor-pointer'"
                @click="Logic.Common.GoToRoute('/basket')"
              />
            </div>
          </div>

          <!-- Cashback balance -->
          <div
            class="w-full flex flex-col min-h-screen absolute left-0 space-y-4 mdlg:!border-l-[1px] mdlg:!border-r-[1px] mdlg:!border-grey-100 mdlg:dark:!border-grey-700 md:!border-l-[1px] md:!border-grey-100 md:dark:!border-grey-700"
            :style="`top: calc(38% + (env(safe-area-inset-top) - 8px)) !important;`"
          >
            <div class="w-full flex flex-col px-4">
              <div
                class="w-full flex flex-col bg-white rounded-[10px] px-3 py-3 space-y-2 z-50 drop-shadow-md dark:bg-primary-main"
              >
                <div
                  class="w-full flex flex-row items-center justify-between pb-3 border-b-[2px] border-grey-50 dark:border-primary-700"
                >
                  <div class="flex flex-col space-y-1">
                    <app-normal-text
                      class="!text-left !text-grey-main dark:!text-gray-50"
                    >
                      Cashback Balance
                    </app-normal-text>
                    <span class="flex flex-row items-center space-x-1">
                      <app-header-text class="!font-bold text-left">
                        {{
                          Logic.Common.convertToMoney(
                            UserWallet?.cash_point_balance,
                            true,
                            "ngn"
                          )
                        }}
                      </app-header-text>
                      <app-icon :name="'hide-balance'" :customClass="'h-[13px]'" />
                    </span>
                  </div>

                  <div
                    class="px-4 py-2 rounded-[30px] flex flex-row items-center justify-center space-x-2 bg-primary-800 cursor-pointer"
                    @click="Logic.Common.GoToRoute(`/savings/topup`)"
                  >
                    <app-icon :name="'plus-white'" :customClass="'h-[19px]'" />
                    <app-normal-text class="!text-white"> Top up </app-normal-text>
                  </div>
                </div>

                <!-- Weekly spend limit -->
                <div class="w-full flex flex-col space-y-2 pt-2">
                  <div class="w-full flex flex-row justify-between items-center">
                    <app-normal-text class="!text-black !text-[11px] dark:!text-white">
                      Weekly spend limit ({{ weeklySpendLimit }}%)
                    </app-normal-text>

                    <app-normal-text
                      class="!text-primary-300 cursor-pointer dark:!text-primary-100"
                      @click="Logic.Common.GoToRoute('/others/membership')"
                    >
                      Upgrade
                    </app-normal-text>
                  </div>
                  <div
                    class="w-full h-[8px] rounded-[10px] bg-secondary-50 dark:bg-secondary-100 relative"
                  >
                    <div
                      class="absolute top-0 left-0 h-full rounded-[10px] bg-[#FFC44D] dark:bg-secondary-500"
                      :style="`width: ${weeklySpendLimit}%;`"
                    ></div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Cashback savings -->
            <div
              class="w-full flex no-scrollbar flex-row space-x-3 flex-nowrap overflow-x-auto scrollbar-hide pl-4 pt-2"
              id="cashback-savings-container"
            >
              <div class="flex flex-row pr-4 space-x-3">
                <div
                  v-for="(item, index) in cashbackSavings"
                  :key="index"
                  class="w-[240px] flex flex-col space-y-3 bg-grey-50 dark:bg-gray-800 rounded-[6px] px-3 py-3"
                >
                  <div class="w-full flex flex-row items-center justify-between">
                    <div class="flex flex-col space-y-1">
                      <app-normal-text
                        class="!text-left !text-[10px] uppercase !font-semibold"
                      >
                        {{ item.title }}
                      </app-normal-text>
                      <app-header-text class="!text-sm !text-[#240046] !text-left">
                        {{ Logic.Common.convertToMoney(item.amount, true, "ngn") }}
                      </app-header-text>
                    </div>

                    <div
                      class="px-3 py-1 bg-white dark:bg-primary-700 rounded-[30px] items-center justify-between cursor-pointer"
                      @click="
                        monthIsClaimed(item.key) || !item.is_active
                          ? null
                          : claimedMonthPoint(item.key)
                      "
                    >
                      <app-normal-text
                        class="!text-primary-400 !text-[11px] dark:!text-primary-100"
                      >
                        {{
                          item.redeemed
                            ? "Redeemed"
                            : item.is_active
                            ? "Redeem now"
                            : "Not available"
                        }}
                      </app-normal-text>
                    </div>
                  </div>

                  <div class="w-full flex flex-row space-x-1">
                    <app-icon :name="'calendar-purple'" :customClass="'h-[14px]'" />
                    <app-normal-text
                      class="!text-[11px] !text-[#5A189A] dark:!text-primary-200"
                    >
                      Cashback available on:
                      <span class="!font-bold pl-[2px]">{{ item.available_on }}</span>
                    </app-normal-text>
                  </div>
                </div>
              </div>
            </div>

            <!-- Transactions -->

            <div
              class="w-full flex flex-col space-y-4 bg-white dark:bg-black rounded-[10px] px-4 pb-4 pt-2"
            >
              <div class="w-full flex flex-row items-center justify-between">
                <app-normal-text class="!font-semibold !text-sm text-left">
                  Recent transactions
                </app-normal-text>

                <app-normal-text
                  class="!text-[#9D4EDD] underline cursor-pointer"
                  @click="Logic.Common.GoToRoute('/savings/transactions')"
                >
                  View all
                </app-normal-text>
              </div>

              <template v-if="loadingTransactions">
                <div class="w-full flex flex-col justify-center items-center h-[200px]">
                  <app-loading-state class="text-primary-500" />
                </div>
              </template>
              <template v-else>
                <div
                  class="w-full flex flex-col justify-center items-center h-[200px]"
                  v-if="transactions.length == 0"
                >
                  <app-empty-state
                    :title="'No transactions yet'"
                    :sub-title="'Your transactions will appear here'"
                  />
                </div>

                <div class="w-full flex flex-col space-y-5" v-else>
                  <app-transaction
                    v-for="(item, index) in transactions"
                    :key="index"
                    :data="item"
                    class="cursor-pointer"
                    @click="
                      item.transaction_type == 'wallet'
                        ? Logic.Common.GoToRoute(`/savings/transactions/${item.id}`)
                        : Logic.Common.GoToRoute(`/savings/points/${item.uuid}`)
                    "
                  />
                </div>
              </template>
            </div>

            <!-- Spacer -->
            <div class="h-[120px]"></div>
          </div>
        </app-image-loader>
      </div>
    </plain-layout>
  </app-wrapper>
</template>

<script lang="ts">
import {
  capitalize,
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from "vue";
import { useMeta } from "vue-meta";
import { onIonViewWillEnter } from "@ionic/vue";
import { Logic } from "@shpt/logic";
import {
  AppImageLoader,
  AppIcon,
  AppNormalText,
  AppHeaderText,
  AppTransaction,
  AppEmptyState,
  AppLoadingState,
} from "@shpt/ui-components";
import { showSideBar } from "@shpt/ui-components/src/composable";
import {
  ContentType,
  PointTransaction,
  Transaction,
} from "@shpt/ui-components/src/gql/graphql";
import { setStoryContent } from "@shpt/ui-components/src/composable/story";
import AppWrapper from "@/components/AppWrapper.vue";

export default defineComponent({
  components: {
    AppImageLoader,
    AppIcon,
    AppNormalText,
    AppHeaderText,
    AppTransaction,
    AppEmptyState,
    AppLoadingState,
    AppWrapper,
  },
  name: "SavingsPage",
  middlewares: {
    fetchRules: [
      {
        domain: "Wallet",
        property: "UserWallet",
        method: "GetUserWallet",
        params: [],
        requireAuth: true,
      },
      {
        domain: "User",
        property: "ExploreContent",
        method: "GetUserContent",
        params: [ContentType.Explore, 1, 12],
        requireAuth: true,
      },
    ],
    tracking_data: {
      lable: "Savings Page",
      stage_type: "neutral",
      end_stage: "",
    },
  },
  layout: "Dashboard",
  setup() {
    useMeta({
      title: "Savings",
    });

    const AuthUser = ref(Logic.Auth.AuthUser);
    const UserWallet = ref(Logic.Wallet.UserWallet);
    const EarningStats = ref(Logic.Auth.EarningStats);

    const ClientWalletTransactions = ref(Logic.Wallet.ClientWalletTransactions);
    const ManyPointTransaction = ref(Logic.Wallet.ManyPointTransaction);

    const loadingTransactions = ref(false);

    const weeklySpendLimit = computed(() => {
      return (
        ((AuthUser.value?.receipt_week_spend || 0) /
          parseFloat(
            AuthUser.value?.profile?.subscription_plan.receipt_weekly_spend_limit || "0"
          ) <=
        1
          ? (AuthUser.value?.receipt_week_spend || 0) /
            parseFloat(
              AuthUser.value?.profile?.subscription_plan.receipt_weekly_spend_limit || "0"
            )
          : 1) * 100
      ).toFixed(2);
    });

    const monthIsClaimed = (month: string) => {
      const claimedMonths: string[] = JSON.parse(
        AuthUser.value?.profile?.claimed_earning_periods || "[]"
      );

      return claimedMonths.includes(month);
    };

    const claimedMonthPoint = (month: string) => {
      Logic.User.ClaimPointGained(month);
    };

    const cashbackSavings = reactive<
      {
        key: string;
        title: string;
        amount: number;
        available_on: string;
        redeemed: boolean;
        is_active: boolean;
      }[]
    >([]);

    const transactions = reactive<
      {
        id: string;
        uuid: string;
        description: string;
        type: string;
        time: string;
        amount: number;
        category: string;
        real_date: string;
        transaction_type: "wallet" | "point";
      }[]
    >([]);

    const setTransactions = () => {
      transactions.length = 0;

      const allTransactions: (Transaction | PointTransaction | any)[] = [
        ...(ClientWalletTransactions.value?.data || []),
        ...(ManyPointTransaction.value?.data || []),
      ];

      allTransactions.forEach((transaction) => {
        transactions.push({
          id: transaction.id,
          uuid: transaction.uuid,
          description: transaction.description || "",
          type: transaction.dr_or_cr,
          time: Logic.Common.fomartDate(transaction.created_at, "MMM D, YYYY | h:mmA"),
          amount: transaction.amount,
          category: capitalize(transaction.chargeable_type?.split("_").join(" ") || ""),
          real_date: transaction.created_at,
          transaction_type: transaction.__typename == "Transaction" ? "wallet" : "point",
        });
      });

      transactions.sort((a, b) => {
        return new Date(b.real_date).getTime() - new Date(a.real_date).getTime();
      });
    };

    const fetchTransactions = (silent = false) => {
      if (!silent || transactions.length > 0) {
        loadingTransactions.value = true;
      }
      const allFetchActions: any[] = [];
      allFetchActions.push(
        Logic.Wallet.GetTransactionsByWalletId(
          "client",
          parseInt(UserWallet.value?.id || "0") || 0,
          1,
          10,
          "CREATED_AT",
          "DESC"
        )
      );
      allFetchActions.push(
        Logic.Wallet.GetManyPointTransaction(
          parseInt(UserWallet.value?.id || "0") || 0,
          1,
          10,
          "CREATED_AT",
          "DESC"
        )
      );
      Promise.all(allFetchActions).then(() => {
        //
        loadingTransactions.value = false;
        setTransactions();
      });
    };

    const setCashbackSavings = () => {
      cashbackSavings.length = 0;
      for (const key in EarningStats.value) {
        if (Object.prototype.hasOwnProperty.call(EarningStats.value, key)) {
          const earning = EarningStats.value[key];

          cashbackSavings.push({
            key: key,
            title: key.toUpperCase(),
            amount: earning.claimed,
            available_on: earning.redemption_date,
            redeemed: monthIsClaimed(key.toString()),
            is_active: earning.is_active,
          });
        }
      }

      setTimeout(() => {
        const cashbackContainer = document.getElementById("cashback-savings-container");

        if (cashbackContainer) {
          cashbackContainer.scrollTo({
            left: 5000,
          });
        }
      }, 1000);
    };

    watch([ClientWalletTransactions, ManyPointTransaction], () => {
      setTransactions();
    });

    onIonViewWillEnter(() => {
      setCashbackSavings();
      fetchTransactions(true);

      Logic.Game.CheckGameStatus();
    });

    onMounted(() => {
      Logic.Wallet.watchProperty("UserWallet", UserWallet);
      Logic.Auth.watchProperty("AuthUser", AuthUser);
      Logic.Auth.watchProperty("EarningStats", EarningStats);
      Logic.Wallet.watchProperty("ClientWalletTransactions", ClientWalletTransactions);
      Logic.Wallet.watchProperty("ManyPointTransaction", ManyPointTransaction);
      setCashbackSavings();
      fetchTransactions();

      if (Logic.Common.currentBuildType() == "web") {
        Logic.Game.CheckGameStatus();
      }
    });

    return {
      Logic,
      AuthUser,
      cashbackSavings,
      transactions,
      showSideBar,
      UserWallet,
      weeklySpendLimit,
      ContentType,
      loadingTransactions,
      monthIsClaimed,
      claimedMonthPoint,
      setStoryContent,
    };
  },
});
</script>
