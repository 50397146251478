import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-row" }
const _hoisted_2 = { class: "pr-1" }
const _hoisted_3 = { class: "pr-1" }
const _hoisted_4 = { class: "w-full flex-col flex pt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_AppTextField = _resolveComponent("AppTextField")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_app_form_wrapper = _resolveComponent("app-form-wrapper")!
  const _component_auth_layout = _resolveComponent("auth-layout")!

  return (_openBlock(), _createBlock(_component_auth_layout, {
    onGoBack: _cache[3] || (_cache[3] = ($event: any) => (_ctx.Logic.Common.goBack())),
    "go-back": true
  }, {
    "header-text": _withCtx(() => _cache[4] || (_cache[4] = [
      _createTextVNode("Create Password")
    ])),
    default: _withCtx(() => [
      _createVNode(_component_app_form_wrapper, {
        ref: "formComp",
        parentRefs: _ctx.parentRefs,
        class: "w-full flex flex-col space-y-4 pt-4"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_app_normal_text, { class: "!text-[#664D7E]" }, {
              default: _withCtx(() => _cache[5] || (_cache[5] = [
                _createTextVNode(" Create a password to complete your account setup ")
              ])),
              _: 1
            })
          ]),
          _createVNode(_component_AppTextField, {
            "focus-border": 'border-primary-400',
            type: 'password',
            "has-title": true,
            "custom-class": '!bg-grey-50 ',
            placeholder: 'Input Value',
            name: 'Password',
            ref: "password",
            rules: [_ctx.FormValidations.RequiredRule],
            modelValue: _ctx.formData.password,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.password) = $event))
          }, {
            title: _withCtx(() => _cache[6] || (_cache[6] = [
              _createTextVNode("Password ")
            ])),
            "inner-prefix": _withCtx(() => [
              _createElementVNode("span", _hoisted_2, [
                _createVNode(_component_app_icon, {
                  name: 'padlock',
                  customClass: 'h-[18px]'
                })
              ])
            ]),
            _: 1
          }, 8, ["rules", "modelValue"]),
          _createVNode(_component_AppTextField, {
            "focus-border": 'border-primary-400',
            type: 'password',
            "has-title": true,
            "custom-class": '!bg-grey-50 ',
            name: 'Confirm password',
            ref: "confirm_password",
            rules: [
          _ctx.FormValidations.RequiredRule,
          _ctx.FormValidations.customValidator(
            _ctx.formData.confirm_password == _ctx.formData.password,
            'Password do not match'
          ),
        ],
            placeholder: 'Input Value',
            modelValue: _ctx.formData.confirm_password,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formData.confirm_password) = $event))
          }, {
            title: _withCtx(() => _cache[7] || (_cache[7] = [
              _createTextVNode("Re-type Password ")
            ])),
            "inner-prefix": _withCtx(() => [
              _createElementVNode("span", _hoisted_3, [
                _createVNode(_component_app_icon, {
                  name: 'padlock',
                  customClass: 'h-[18px]'
                })
              ])
            ]),
            _: 1
          }, 8, ["rules", "modelValue"]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_AppButton, {
              padding: 'py-3',
              "custom-class": 'rounded-[40px]',
              "bg-color": 'bg-primary-main',
              "text-color": 'text-white',
              onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.updatePassword()), ["prevent"])),
              loading: _ctx.loaderSetup.loading
            }, {
              default: _withCtx(() => _cache[8] || (_cache[8] = [
                _createTextVNode(" Continue ")
              ])),
              _: 1
            }, 8, ["loading"])
          ])
        ]),
        _: 1
      }, 8, ["parentRefs"])
    ]),
    _: 1
  }))
}