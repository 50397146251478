import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col space-y-4 pt-4" }
const _hoisted_2 = { class: "w-full flex flex-row px-4" }
const _hoisted_3 = { class: "w-full flex flex-col" }
const _hoisted_4 = {
  key: 0,
  class: "w-full flex flex-col space-y-3 px-4"
}
const _hoisted_5 = { class: "!font-semibold" }
const _hoisted_6 = {
  key: 1,
  class: "w-full flex flex-col items-center justify-center h-[350px]"
}
const _hoisted_7 = {
  key: 2,
  class: "w-full flex flex-col"
}
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "w-[165px] mdlg:!w-[240px] mdlg:!h-[200px] bg-[#F4EDFB] rounded-[10px] px-4 py-4 items-center justify-center flex flex-col space-y-2" }
const _hoisted_10 = { class: "w-full flex flex-col space-y-1 items-center justify-center" }
const _hoisted_11 = { class: "flex flex-row space-x-1 items-center" }
const _hoisted_12 = { class: "w-full flex flex-col space-y-3 px-4" }
const _hoisted_13 = { class: "w-full flex flex-col space-y-2" }
const _hoisted_14 = { class: "w-full flex flex-row items-center justify-between px-4" }
const _hoisted_15 = { class: "!w-[150px] flex flex-col items-center justify-end" }
const _hoisted_16 = { class: "w-full flex flex-col" }
const _hoisted_17 = { class: "w-[165px] mdlg:!w-[240px] h-fit" }
const _hoisted_18 = { class: "w-full flex flex-col space-y-2" }
const _hoisted_19 = { class: "w-full flex no-scrollbar flex-row space-x-3 flex-nowrap pl-4 overflow-x-auto scrollbar-hide" }
const _hoisted_20 = { class: "flex flex-row space-x-2 py-1 pr-4" }
const _hoisted_21 = ["onClick"]
const _hoisted_22 = { class: "absolute top-0 left-0 h-full w-full bg-black !bg-opacity-30 !rounded-[10px] flex flex-col justify-between py-3 px-3" }
const _hoisted_23 = { class: "flex flex-row justify-end" }
const _hoisted_24 = {
  class: /*@__PURE__*/_normalizeClass(`px-2 py-[2px] rounded-[4px] bg-primary-main  flex flex-row items-center justify-center`)
}
const _hoisted_25 = { class: "flex flex-row space-x-1 justify-end flex-grow items-center" }
const _hoisted_26 = { class: "font-semibold pl-[1px]" }
const _hoisted_27 = { class: "w-full flex flex-col space-y-1" }
const _hoisted_28 = { class: "w-full flex flex-col space-y-1" }
const _hoisted_29 = {
  key: 0,
  class: "w-full flex flex-row space-x-1 items-center flex-nowrap"
}
const _hoisted_30 = {
  key: 0,
  class: "flex flex-col space-y-3 h-full justify-end"
}
const _hoisted_31 = { class: "w-full flex flex-col space-y-2" }
const _hoisted_32 = {
  key: 0,
  class: "w-full flex flex-col"
}
const _hoisted_33 = ["onClick"]
const _hoisted_34 = { class: "w-[165px] mdlg:!w-[240px] bg-[#F4EDFB] rounded-[10px] px-4 py-4 items-center justify-center flex flex-col space-y-2" }
const _hoisted_35 = { class: "w-full flex flex-col space-y-1 items-center justify-center" }
const _hoisted_36 = { class: "flex flex-row space-x-1 items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_loading = _resolveComponent("app-loading")!
  const _component_app_text_field = _resolveComponent("app-text-field")!
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_empty_state = _resolveComponent("app-empty-state")!
  const _component_app_shoplist_explore = _resolveComponent("app-shoplist-explore")!
  const _component_app_image_loader = _resolveComponent("app-image-loader")!
  const _component_app_virtual_scroller = _resolveComponent("app-virtual-scroller")!
  const _component_app_banner_ad = _resolveComponent("app-banner-ad")!
  const _component_app_header_text = _resolveComponent("app-header-text")!
  const _component_app_select = _resolveComponent("app-select")!
  const _component_app_media_box = _resolveComponent("app-media-box")!
  const _component_dashboard_layout = _resolveComponent("dashboard-layout")!
  const _component_app_wrapper = _resolveComponent("app-wrapper")!

  return (_openBlock(), _createBlock(_component_app_wrapper, null, {
    default: _withCtx(() => [
      _createVNode(_component_dashboard_layout, {
        topBarType: "plain",
        title: "Shop",
        pageKey: 'shoplist'
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_app_text_field, {
                  class: "w-full",
                  placeholder: 'Search for products',
                  inputStyle: '',
                  onFocused: _cache[0] || (_cache[0] = ($event: any) => (_ctx.searchIsFocused = true)),
                  onBlurred: _cache[1] || (_cache[1] = ($event: any) => (_ctx.searchIsFocused = false)),
                  modelValue: _ctx.searchValue,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.searchValue) = $event))
                }, {
                  "inner-prefix": _withCtx(() => [
                    _createElementVNode("span", null, [
                      _createVNode(_component_app_icon, {
                        name: 'search-list',
                        customClass: 'h-[18px]'
                      })
                    ])
                  ]),
                  "inner-suffix": _withCtx(() => [
                    (_ctx.searchValue.length > 2 && _ctx.searchResultsLoading)
                      ? (_openBlock(), _createBlock(_component_app_loading, {
                          key: 0,
                          class: "!text-primary-400 dark:!text-white"
                        }))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ])
            ]),
            (_ctx.searchValue.length > 2)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  (_ctx.ShoplistSearchResults.data.length)
                    ? (_openBlock(), _createBlock(_component_app_normal_text, {
                        key: 0,
                        class: "w-full !text-left"
                      }, {
                        default: _withCtx(() => [
                          _cache[4] || (_cache[4] = _createTextVNode(" Search results for ")),
                          _createElementVNode("span", _hoisted_5, "\"" + _toDisplayString(_ctx.searchValue.value) + "\"", 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  (!_ctx.ShoplistSearchResults.data.length && !_ctx.searchResultsLoading)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                        _createVNode(_component_app_empty_state, {
                          title: 'No products found',
                          subTitle: `No products found for '${_ctx.searchValue.value}'`
                        }, null, 8, ["subTitle"])
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        _createVNode(_component_app_virtual_scroller, {
                          data: _ctx.ShoplistSearchResults.data,
                          pagination: _ctx.ShoplistSearchResults.paginatorInfo,
                          fetchMore: _ctx.fetchMoreShoplistSearchResults,
                          "container-class": ` grid grid-cols-2 gap-4 !space-y-0`
                        }, {
                          "item-content": _withCtx(({ index, item }) => [
                            (_ctx.itemIsMediaBox(item))
                              ? (_openBlock(), _createBlock(_component_app_shoplist_explore, {
                                  key: index,
                                  item: item,
                                  onClick: ($event: any) => (_ctx.handleShoplsistClick(item))
                                }, null, 8, ["item", "onClick"]))
                              : (_openBlock(), _createElementBlock("div", {
                                  class: "w-[165px] mdlg:!w-[240px] h-fit cursor-pointer",
                                  key: index,
                                  onClick: ($event: any) => (
                        _ctx.Logic.Common.GoToRoute(`/brand?uuid=${item.data.uuid}`)
                      )
                                }, [
                                  _createElementVNode("div", _hoisted_9, [
                                    _createElementVNode("div", null, [
                                      _createVNode(_component_app_image_loader, {
                                        "photo-url": item.data?.photo_url,
                                        class: _normalizeClass('!w-[100px] !h-[100px] !rounded-full')
                                      }, null, 8, ["photo-url"])
                                    ]),
                                    _createElementVNode("div", _hoisted_10, [
                                      _createVNode(_component_app_normal_text, { customClass: '!text-sm !font-semibold' }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(item.data?.business_name), 1)
                                        ]),
                                        _: 2
                                      }, 1024),
                                      _createElementVNode("div", _hoisted_11, [
                                        _createVNode(_component_app_normal_text, { customClass: '!text-gray-500 !text-[12px]' }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(" @" + _toDisplayString(item.data?.business_tag), 1)
                                          ]),
                                          _: 2
                                        }, 1024),
                                        _createVNode(_component_app_icon, {
                                          name: 'verified',
                                          class: "!h-[14px]"
                                        })
                                      ])
                                    ])
                                  ])
                                ], 8, _hoisted_8))
                          ]),
                          "skeleton-loaders": _withCtx(() => _cache[5] || (_cache[5] = [
                            _createElementVNode("div", { class: "col-span-1 flex flex-col space-y-2 h-[160px] mdlg:!h-[200px] md:!h-[200px] rounded-[10px] skeleton" }, null, -1),
                            _createElementVNode("div", { class: "col-span-1 flex flex-col space-y-2 h-[160px] mdlg:!h-[200px] md:!h-[200px] rounded-[10px] skeleton" }, null, -1)
                          ])),
                          _: 1
                        }, 8, ["data", "pagination", "fetchMore"])
                      ]))
                ]))
              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createElementVNode("div", _hoisted_12, [
                    _createVNode(_component_app_banner_ad, { data: _ctx.bannerAds }, null, 8, ["data"])
                  ]),
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("div", _hoisted_14, [
                      _createVNode(_component_app_header_text, { customClass: '!text-left !text-sm !font-semibold' }, {
                        default: _withCtx(() => _cache[6] || (_cache[6] = [
                          _createTextVNode(" Products ")
                        ])),
                        _: 1
                      }),
                      _createElementVNode("div", _hoisted_15, [
                        _createVNode(_component_app_select, {
                          hasTitle: false,
                          options: _ctx.categoryOptions,
                          paddings: 'px-3 py-1',
                          modelValue: _ctx.selectedCategory,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedCategory) = $event)),
                          "custom-class": "!text-[12px] !rounded-[4px]"
                        }, null, 8, ["options", "modelValue"])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_16, [
                      _createVNode(_component_app_virtual_scroller, {
                        "container-class": 'w-full !flex no-scrollbar !flex-row space-x-3 !space-y-0 flex-nowrap !pl-4 overflow-x-auto scrollbar-hide pt-2 pr-2',
                        data: _ctx.shoplistProducts.data,
                        pagination: _ctx.shoplistProducts.paginatorInfo,
                        fetchMore: _ctx.fetchMoreShoplistProducts,
                        fetchNew: _ctx.fetchNewShoplistProducts,
                        direction: 'horizontal',
                        ref: "productVirtualScrollerRef"
                      }, {
                        "item-content": _withCtx(({ index, item }) => [
                          _createElementVNode("div", _hoisted_17, [
                            (_openBlock(), _createBlock(_component_app_shoplist_explore, {
                              class: "w-[165px] mdlg:!w-[240px]",
                              key: index,
                              onClick: ($event: any) => (_ctx.handleShoplsistClick(item)),
                              item: item,
                              mediaBoxStyle: '!h-[140px] mdlg:!h-[200px] md:!h-[200px]',
                              theme: _ctx.Logic.Common.currentAppTheme()
                            }, null, 8, ["onClick", "item", "theme"]))
                          ])
                        ]),
                        "skeleton-loaders": _withCtx(() => _cache[7] || (_cache[7] = [
                          _createElementVNode("div", { class: "w-[165px] mdlg:!w-[240px]" }, [
                            _createElementVNode("div", { class: "!h-[170px] mdlg:!h-[230px] w-[165px] mdlg:!w-[240px] skeleton !rounded-[10px]" })
                          ], -1),
                          _createElementVNode("div", { class: "w-[165px] mdlg:!w-[240px]" }, [
                            _createElementVNode("div", { class: "!h-[170px] mdlg:!h-[230px] w-[165px] mdlg:!w-[240px] skeleton !rounded-[10px]" })
                          ], -1)
                        ])),
                        _: 1
                      }, 8, ["data", "pagination", "fetchMore", "fetchNew"])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_18, [
                    _createVNode(_component_app_header_text, { customClass: '!text-left px-4 !text-sm !font-semibold' }, {
                      default: _withCtx(() => _cache[8] || (_cache[8] = [
                        _createTextVNode(" Featured Shoplists ")
                      ])),
                      _: 1
                    }),
                    _createElementVNode("div", _hoisted_19, [
                      _createElementVNode("div", _hoisted_20, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sponsoredShoplists, (item, index) => {
                          return (_openBlock(), _createElementBlock("div", {
                            class: "w-[340px] xs:!w-[270px] flex flex-col space-y-1 cursor-pointer",
                            key: index,
                            onClick: ($event: any) => (
                    _ctx.setStoryContent(_ctx.ContentType.SponsoredShoplist, {
                      id: parseInt(item.id),
                      type: item.type,
                    })
                  )
                          }, [
                            _createVNode(_component_app_media_box, {
                              data: item.data,
                              class: "h-[270px] xs:!h-[230px] !rounded-[10px] relative",
                              isVideo: !item.data.is_grid
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_22, [
                                  _createElementVNode("div", _hoisted_23, [
                                    _createElementVNode("span", _hoisted_24, [
                                      _createElementVNode("div", _hoisted_25, [
                                        _createVNode(_component_app_icon, {
                                          name: 'cashback-icon',
                                          customClass: 'h-[15px]'
                                        }),
                                        _createVNode(_component_app_normal_text, { customClass: '!whitespace-nowrap !text-[11px] mdlg:!text-[12px] font-semibold !text-white' }, {
                                          default: _withCtx(() => [
                                            _cache[9] || (_cache[9] = _createTextVNode(" Earn ")),
                                            _createElementVNode("span", _hoisted_26, _toDisplayString(_ctx.numberToAbbrev(item.data.points || 0)) + " Pts", 1)
                                          ]),
                                          _: 2
                                        }, 1024)
                                      ])
                                    ])
                                  ]),
                                  _createElementVNode("div", _hoisted_27, [
                                    _createVNode(_component_app_normal_text, { class: "!text-left !text-white uppercase" }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.Logic.Common.fomartDate(
                              item.data.expires_at || "",
                              "MMM Do"
                            )), 1)
                                      ]),
                                      _: 2
                                    }, 1024),
                                    _createElementVNode("div", _hoisted_28, [
                                      _createVNode(_component_app_header_text, { class: "!text-white !text-left !font-semibold" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(item.data.title), 1)
                                        ]),
                                        _: 2
                                      }, 1024),
                                      (item.data.tags.length)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.data.tags, (tag, index) => {
                                              return (_openBlock(), _createElementBlock("span", {
                                                class: "px-2 py-[1px] bg-transparent rounded-[20px] border-[1px] border-white",
                                                key: index
                                              }, [
                                                _createVNode(_component_app_normal_text, { class: "!text-white !text-[10px]" }, {
                                                  default: _withCtx(() => [
                                                    _createTextVNode(_toDisplayString(tag), 1)
                                                  ]),
                                                  _: 2
                                                }, 1024)
                                              ]))
                                            }), 128))
                                          ]))
                                        : _createCommentVNode("", true)
                                    ])
                                  ])
                                ])
                              ]),
                              _: 2
                            }, 1032, ["data", "isVideo"])
                          ], 8, _hoisted_21))
                        }), 128)),
                        (_ctx.showSponsoredShoplistIsLoading)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_30, _cache[10] || (_cache[10] = [
                              _createElementVNode("div", { class: "!h-[230px] w-[270px] skeleton !rounded-[10px]" }, null, -1)
                            ])))
                          : _createCommentVNode("", true)
                      ]),
                      _cache[11] || (_cache[11] = _createElementVNode("div", { id: "visibility-handle-sponsored-shoplist" }, null, -1))
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_31, [
                    _createVNode(_component_app_header_text, { customClass: '!text-left px-4 !text-sm !font-semibold' }, {
                      default: _withCtx(() => _cache[12] || (_cache[12] = [
                        _createTextVNode(" Top brands ")
                      ])),
                      _: 1
                    }),
                    (_ctx.ManyBusinesses)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
                          _createVNode(_component_app_virtual_scroller, {
                            "container-class": 'w-full !flex no-scrollbar !flex-row space-x-3 !space-y-0 flex-nowrap !pl-4 overflow-x-auto scrollbar-hide pt-2 pr-2',
                            data: _ctx.ManyBusinesses.data,
                            pagination: _ctx.ManyBusinesses.paginatorInfo,
                            fetchMore: _ctx.fetchMoreBusinesses,
                            direction: 'horizontal'
                          }, {
                            "item-content": _withCtx(({ index, item }) => [
                              (_openBlock(), _createElementBlock("div", {
                                class: "w-[165px] mdlg:!w-[240px] h-fit cursor-pointer",
                                key: index,
                                onClick: ($event: any) => (_ctx.Logic.Common.GoToRoute(`/brand?uuid=${item.uuid}`))
                              }, [
                                _createElementVNode("div", _hoisted_34, [
                                  _createElementVNode("div", null, [
                                    _createVNode(_component_app_image_loader, {
                                      "photo-url": item.photo_url,
                                      class: _normalizeClass('!w-[100px] !h-[100px] !rounded-full')
                                    }, null, 8, ["photo-url"])
                                  ]),
                                  _createElementVNode("div", _hoisted_35, [
                                    _createVNode(_component_app_normal_text, { customClass: '!text-sm !font-semibold !line-clamp-1' }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(item.business_name), 1)
                                      ]),
                                      _: 2
                                    }, 1024),
                                    _createElementVNode("div", _hoisted_36, [
                                      _createVNode(_component_app_normal_text, { customClass: '!text-gray-500 !text-[12px]' }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(" @" + _toDisplayString(item.business_tag), 1)
                                        ]),
                                        _: 2
                                      }, 1024),
                                      _createVNode(_component_app_icon, {
                                        name: 'verified',
                                        class: "!h-[14px]"
                                      })
                                    ])
                                  ])
                                ])
                              ], 8, _hoisted_33))
                            ]),
                            "skeleton-loaders": _withCtx(() => _cache[13] || (_cache[13] = [
                              _createElementVNode("div", { class: "w-[165px] mdlg:!w-[240px]" }, [
                                _createElementVNode("div", { class: "!h-[180px] mdlg:!h-[180px] w-[165px] mdlg:!w-[240px] skeleton !rounded-[10px]" })
                              ], -1),
                              _createElementVNode("div", { class: "w-[165px] mdlg:!w-[240px]" }, [
                                _createElementVNode("div", { class: "!h-[180px] mdlg:!h-[180px] w-[165px] mdlg:!w-[240px] skeleton !rounded-[10px]" })
                              ], -1)
                            ])),
                            _: 1
                          }, 8, ["data", "pagination", "fetchMore"])
                        ]))
                      : _createCommentVNode("", true)
                  ])
                ], 64)),
            _cache[14] || (_cache[14] = _createElementVNode("div", { class: "h-[120px]" }, null, -1))
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}