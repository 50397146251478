import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col space-y-3 pt-2 items-center justify-center relative" }
const _hoisted_2 = { class: "w-full flex flex-row space-x-2 items-center" }
const _hoisted_3 = {
  key: 0,
  class: "w-full flex flex-col items-center justify-center h-[300px]"
}
const _hoisted_4 = {
  class: /*@__PURE__*/_normalizeClass(`animate-spin mr-3 h-5 w-5 text-primary-400`),
  xmlns: "http://www.w3.org/2000/svg",
  fill: "none",
  viewBox: "0 0 24 24"
}
const _hoisted_5 = {
  key: 1,
  class: "w-full grid grid-cols-2 gap-3"
}
const _hoisted_6 = {
  key: 1,
  class: "w-full col-span-2 flex flex-col h-[300px] justify-center items-center"
}
const _hoisted_7 = { class: "w-full flex flex-row py-4 bg-white dark:bg-black sticky bottom-0 mdlg:!pb-0 md:!pb-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_AppTextField = _resolveComponent("AppTextField")!
  const _component_app_product = _resolveComponent("app-product")!
  const _component_app_empty_state = _resolveComponent("app-empty-state")!
  const _component_app_button = _resolveComponent("app-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_AppTextField, {
        placeholder: `Search products`,
        modelValue: _ctx.searchQuery,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchQuery) = $event))
      }, {
        "inner-prefix": _withCtx(() => [
          _createVNode(_component_AppIcon, {
            "custom-class": '',
            name: 'search'
          })
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    (_ctx.isLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_openBlock(), _createElementBlock("svg", _hoisted_4, _cache[2] || (_cache[2] = [
            _createElementVNode("circle", {
              class: "opacity-25",
              cx: "12",
              cy: "12",
              r: "10",
              stroke: "currentColor",
              "stroke-width": "4"
            }, null, -1),
            _createElementVNode("path", {
              class: "opacity-75",
              fill: "currentColor",
              d: "M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            }, null, -1)
          ])))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_5, [
          (_ctx.products.length > 0)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.products, (product, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: index,
                  class: "col-span-1 flex flex-col"
                }, [
                  _createVNode(_component_app_product, {
                    onClick: ($event: any) => (_ctx.toggleAddedProducts(product)),
                    data: product,
                    "show-commission": _ctx.selectedSwitch == 'affiliate_products',
                    class: _normalizeClass(` px-3 py-2 rounded-[6px] ${
              _ctx.selectedProducts.filter((eachItem) => eachItem.id == product.id)
                .length > 0
                ? '!border-primary-300 !border-[2px] '
                : '!border-grey-100 !border-[1px] '
            }`)
                  }, null, 8, ["onClick", "data", "show-commission", "class"])
                ]))
              }), 128))
            : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createVNode(_component_app_empty_state, {
                  title: 'No Products Here',
                  subTitle: 'All available products will show up here'
                })
              ]))
        ])),
    _cache[3] || (_cache[3] = _createElementVNode("div", { class: "h-[50px]" }, null, -1)),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_app_button, {
        class: "w-full !py-3",
        onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.selectProducts()), ["prevent"]))
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Add Product" + _toDisplayString(_ctx.selectedProducts.length > 1 ? "s" : "") + " (" + _toDisplayString(_ctx.selectedProducts.length) + ") ", 1)
        ]),
        _: 1
      })
    ])
  ]))
}