import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col space-y-3 px-4" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "flex flex-row space-x-2 items-center" }
const _hoisted_4 = { class: "w-[37px]" }
const _hoisted_5 = { class: "w-[36px] h-[36px] rounded-full flex flex-row items-center justify-center bg-white dark:bg-slate-300" }
const _hoisted_6 = { class: "pr-1" }
const _hoisted_7 = { class: "w-full flex flex-row px-3 py-3 bg-grey-50 dark:bg-gray-900 rounded-[8px] justify-between items-center" }
const _hoisted_8 = { class: "flex flex-row space-x-2 items-center" }
const _hoisted_9 = { class: "flex flex-row space-x-3 items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_subpage_layout = _resolveComponent("subpage-layout")!

  return (_openBlock(), _createBlock(_component_subpage_layout, { title: 'Account Settings' }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.actions, (item, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "w-full flex flex-row px-3 py-3 bg-grey-50 dark:bg-gray-900 rounded-[8px] justify-between items-center cursor-pointer",
            onClick: ($event: any) => (_ctx.handleSettings(item))
          }, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_app_icon, {
                    name: item.icon,
                    class: _normalizeClass(item.icon_size)
                  }, null, 8, ["name", "class"])
                ])
              ]),
              _createVNode(_component_app_normal_text, { class: "!text-left !font-semibold" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.title), 1)
                ]),
                _: 2
              }, 1024)
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_app_icon, {
                name: 'chevron-right-grey',
                class: "!h-[13px]"
              })
            ])
          ], 8, _hoisted_2))
        }), 128)),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createVNode(_component_app_normal_text, { class: "!text-left !font-semibold" }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode(" Choose Theme ")
              ])),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setAppTheme('light'))),
              class: _normalizeClass(`w-[40px] h-[40px] rounded-full border-[2px] !bg-white cursor-pointer ${
              _ctx.Logic.Common.currentAppTheme() == 'light'
                ? 'border-primary-500'
                : 'border-gray-700 dark:border-gray-300'
            }`)
            }, null, 2),
            _createElementVNode("div", {
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setAppTheme('dark'))),
              class: _normalizeClass(`w-[40px] h-[40px] rounded-full border-[2px] !bg-black  cursor-pointer ${
              _ctx.Logic.Common.currentAppTheme() == 'dark'
                ? 'border-primary-500'
                : 'border-gray-700 dark:border-gray-300'
            }`)
            }, null, 2)
          ])
        ])
      ])
    ]),
    _: 1
  }))
}