<template>
  <auth-layout
    @button-clicked="Logic.Common.GoToRoute('/auth/signup')"
    @go-back="Logic.Common.goBack()"
    :go-back="true"
    :right-button="true"
    class="!overflow-y-auto h-full"
  >
    <template v-slot:button-text>Sign up</template>
    <template v-slot:header-text>Welcome back</template>

    <app-form-wrapper
      :parentRefs="parentRefs"
      ref="formComp"
      class="w-full flex flex-col space-y-4 pt-4"
    >
      <div class="w-full flex flex-row">
        <app-normal-text class="!text-[#664D7E] dark:!text-primary-100">
          Complete the details below to login
        </app-normal-text>
      </div>

      <AppTextField
        :focus-border="'border-primary-400'"
        :has-title="true"
        :custom-class="'!bg-grey-50 dark:!bg-grey-900'"
        :placeholder="'Input Value'"
        v-model="signInForm.email"
        name="Email or Phone number"
        ref="emailRef"
        :rules="[FormValidations.RequiredRule]"
      >
        <template v-slot:title> Email or Phone number </template>
        <template v-slot:inner-prefix>
          <span class="pr-1">
            <app-icon :name="'envelope'" :customClass="'h-[14px]'" />
          </span>
        </template>
      </AppTextField>

      <AppTextField
        :focus-border="'border-primary-400'"
        :type="'password'"
        :has-title="true"
        :custom-class="'!bg-grey-50 dark:!bg-grey-900'"
        :placeholder="'Input Value'"
        ref="passwordRef"
        name="Password"
        v-model="signInForm.password"
        :rules="[FormValidations.RequiredRule]"
      >
        <template v-slot:title> Password </template>
        <template v-slot:inner-prefix>
          <span class="pr-1">
            <app-icon :name="'padlock'" :customClass="'h-[18px]'" />
          </span>
        </template>
      </AppTextField>

      <div class="w-full flex-col flex pt-2 z-50">
        <AppButton
          :padding="'py-3'"
          :custom-class="'rounded-[40px]'"
          :bg-color="'bg-primary-400'"
          :text-color="'text-white'"
          :loading="loaderSetup.loading"
          @click.prevent="login()"
        >
          Login
        </AppButton>
      </div>

      <div class="flex items-center justify-center flex-row space-x-1">
        <AppNormalText class="dark:text-white">Forgot Password?</AppNormalText>
        <div>
          <AppNormalText
            @click="Logic.Common.GoToRoute('/auth/forgot-password')"
            :custom-class="'underline'"
            :color="'text-primary-main dark:!text-primary-100'"
            class="cursor-pointer"
            >Reset
          </AppNormalText>
        </div>
      </div>
    </app-form-wrapper>

    <!-- SSO -->
    <SSO :fromAction="'signIn'" :isRelative="true" />
  </auth-layout>
</template>

<script lang="ts">
import { defineComponent, reactive, ref } from "vue";
import { Logic } from "../../../index";
import SSO from "../../../components/AppCommon/SSO.vue";
import {
  FormValidations,
  isBusinessFocused,
  isNumber,
} from "../../../composable";
import { loaderSetup } from "../../../composable/common";
import { AppFormWrapper, AppTextField } from "../../../components";
import AppButton from "../../../components/AppButton";
import { AppNormalText } from "../../../components/AppTypography";
import AppIcon from "../../../components/AppIcon";
import AuthLayout from "../../../components/AppLayouts/auth.vue";

export default defineComponent({
  components: {
    AppTextField,
    AppButton,
    AppNormalText,
    AppFormWrapper,
    AuthLayout,
    AppIcon,
    SSO,
  },
  name: "LoginPage",
  setup() {
    const formComp = ref();

    const signInForm = reactive({
      email: "",
      password: "",
    });

    const login = () => {
      const state = formComp.value.validate();

      if (state) {
        Logic.Auth.SignInForm = {
          email: isNumber(signInForm.email)
            ? Logic.Form.getPhoneNumber("+234", signInForm.email)
            : signInForm.email,
          password: signInForm.password,
        };

        Logic.Auth.SignIn(true)?.then(async (data) => {
          if (data?.SignIn && !data?.SignIn.user.email_verified_at) {
            Logic.Auth.ResendVerifyEmailForm = {
              user_uuid: data?.SignIn.user.uuid || "",
            };
            await Logic.Auth.ResendVerifyEmail();
            Logic.Common.showModal({
              show: true,
              type: "verify_email",
              title: "Verify Your Email",
              preventClose: true,
              closeAction: () => {
                //
              },
            });
          } else if (data?.SignIn) {
            Logic.Common.hideLoader();

            if (!Logic.Auth.AuthUser?.phone_verified_at) {
              Logic.Common.GoToRoute("/auth/verify-phone");
              return;
            }

            if (Logic.Common.currentAccountType() == "merchant") {
              if (Logic.Auth.GetCurrentBusiness()?.phone_number) {
                window.location.href = "/";
              } else {
                window.location.href = "/onboarding";
              }
            } else {
              if (
                !data?.SignIn.user.profile?.gender ||
                !data?.SignIn.user.phone_verified_at
              ) {
                Logic.Common.GoToRoute("/auth/personalize");
              } else {
                Logic.Common.GoToRoute("/");
              }
            }
          }
        });
      }
    };

    return {
      FormValidations,
      Logic,
      signInForm,
      formComp,
      loaderSetup,
      login,
    };
  },
  data() {
    return {
      parentRefs: null,
    };
  },
  mounted() {
    const parentRefs: any = this.$refs;
    this.parentRefs = parentRefs;
  },
});
</script>
