<template>
  <div
    class="w-full flex flex-col space-y-3 pt-2 items-center justify-center relative"
  >
    <!-- Loading -->
    <div
      class="w-full flex flex-col items-center justify-center h-[100px]"
      v-if="isLoading"
    >
      <svg
        :class="`animate-spin mr-3 h-5 w-5 text-primary-400`"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          class="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          stroke-width="4"
        ></circle>
        <path
          class="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    </div>

    <!-- Shoplists -->
    <template v-if="!isLoading">
      <div
        v-if="!showAddNewShoplist"
        @click="showAddNewShoplist = true"
        class="w-full px-3 py-3 border-[2px] border-gray-200 border-dashed rounded-[10px] flex flex-row justify-center items-center cursor-pointer"
      >
        <app-normal-text class="text-center text-gray-700">
          + New Shoplist
        </app-normal-text>
      </div>

      <div
        class="w-full flex flex-row items-start space-x-1 justify-between"
        v-else
      >
        <app-text-field
          :hasTitle="false"
          v-model="newShoplistName"
          custom-class="w-full !px-3 !py-2  !rounded-[5px]"
          placeholder="Enter Shoplist Name"
          :rules="[FormValidations.RequiredRule]"
          :name="'Name'"
        >
        </app-text-field>
        <app-button
          :loading="addShoplistIsLoading"
          class="px-3 py-2 !rounded-[5px]"
          @click="addNewShoplist"
        >
          Add
        </app-button>
      </div>
    </template>

    <div class="w-full grid grid-cols-2 gap-3" v-if="!isLoading">
      <template v-if="(ManyShoplists?.data?.length || 0) > 0 && ManyShoplists">
        <!-- Create Shoplist -->

        <template v-if="showShoplists">
          <div
            v-for="(item, index) in ManyShoplists.data"
            :key="index"
            class="col-span-2 flex flex-row items-center justify-between px-3 py-3 pr-0 border-[2px] border-gray-200 rounded-[10px] cursor-pointer"
            @click="addOrRemoveProductFromShoplist(item.id, item.name)"
          >
            <app-normal-text class="text-left">
              {{ item.name }}
            </app-normal-text>

            <div>
              <app-checkbox
                :standard="true"
                v-model="selectedShoplists[item.id]"
              />
            </div>
          </div>
        </template>
        <template v-else>
          <div
            v-for="(item, index) in ManyShoplists.data"
            :key="index"
            class="col-span-2 flex flex-row items-center justify-between px-3 py-3 pr-0 border-[2px] border-gray-200 rounded-[10px] cursor-pointer"
            @click="addOrRemoveProductFromShoplist(item.id, item.name)"
          >
            <app-normal-text class="text-left">
              {{ item.name }}
            </app-normal-text>

            <div>
              <app-checkbox
                :standard="true"
                v-model="selectedShoplists[item.id]"
              />
            </div>
          </div>
        </template>
      </template>
    </div>

    <!-- Spacer -->
    <div class="h-[50px]"></div>
  </div>
</template>
<script lang="ts">
import { Logic, FormValidations } from "../../composable";
import { defineComponent, onMounted, reactive, ref, toRef, watch } from "vue";
import AppIcon from "../AppIcon";
import { AppCheckbox, AppTextField } from "../AppForm";
import { AppNormalText } from "../AppTypography";
import AppButton from "../AppButton";
import {
  ManageCollectionAction,
  UpdateShoplistCollectionType,
} from "@shpt/logic/src/gql/graphql";

export default defineComponent({
  components: {
    AppIcon,
    AppTextField,
    AppNormalText,
    AppCheckbox,
    AppButton,
  },
  props: {
    data: {
      type: Object as () => {
        product_id: string;
      },
    },
    action: {
      type: Function,
      default: () => {},
    },
  },
  setup(props) {
    const ManyShoplists = ref(Logic.Shoplist.ManyShoplistCollections);

    const showAddNewShoplist = ref(false);
    const addShoplistIsLoading = ref(false);
    const showShoplists = ref(true);
    const newShoplistName = ref("");

    const selectedShoplists = ref<{ [key: string]: boolean }>({});

    const isLoading = ref(false);

    const getShoplists = (silent: boolean = false) => {
      if (!silent) {
        isLoading.value = true;
      }
      Logic.Shoplist.GetShoplistCollections(
        1,
        30,
        "CREATED_AT",
        "DESC",
        `{
                          column: PROFILE_ID
                          operator: EQ
                          value: ${Logic.Auth.AuthUser?.profile?.id}
                        }`,
        false
      ).then((data: any) => {
        if (!silent) {
          isLoading.value = false;
        }

        if (data) {
          ManyShoplists.value = data;
        }
      });
    };

    const setSelectedShoplists = () => {
      ManyShoplists.value?.data?.forEach((item) => {
        const product = item.products.find(
          (product) => product.id === props.data?.product_id
        );
        selectedShoplists.value[item.id] = product ? true : false;
      });
    };

    const selectShoplist = (data: any) => {
      if (props.action) {
        props.action(data);
      }
    };

    const addNewShoplist = () => {
      if (newShoplistName.value.length) {
        Logic.Shoplist.CreateShoplistCollectionForm = {
          name: newShoplistName.value,
          type: UpdateShoplistCollectionType.User,
          products: [props.data?.product_id || "0"],
        };

        if (addShoplistIsLoading.value) return;

        addShoplistIsLoading.value = true;
        Logic.Shoplist.CreateShoplistCollection(() => {})
          ?.then((data: any) => {
            if (data) {
              getShoplists(false);
              setSelectedShoplists();
              showAddNewShoplist.value = false;
              newShoplistName.value = "";
            }
            addShoplistIsLoading.value = false;
          })
          .catch((error: any) => {
            addShoplistIsLoading.value = false;
          });
      }
    };

    const addOrRemoveProductFromShoplist = (
      collection_id: string,
      collection_name: string
    ) => {
      if (selectedShoplists.value[collection_id]) {
        Logic.Shoplist.AddRemoveCollectionProductForm = {
          collection_id: parseInt(collection_id),
          products: [parseInt(props.data?.product_id || "0")],
          action: ManageCollectionAction.Remove,
        };
        selectedShoplists.value[collection_id] = false;

        Logic.Common.showAlert({
          show: true,
          message: `Product removed from ${collection_name}`,
          type: "success",
        });
      } else {
        Logic.Shoplist.AddRemoveCollectionProductForm = {
          collection_id: parseInt(collection_id),
          products: [parseInt(props.data?.product_id || "0")],
          action: ManageCollectionAction.Add,
        };
        selectedShoplists.value[collection_id] = true;

        Logic.Common.showAlert({
          show: true,
          message: `Product added to ${collection_name}`,
          type: "success",
        });
      }

      showShoplists.value = false;
      setTimeout(() => {
        showShoplists.value = true;
      }, 200);

      Logic.Shoplist.AddRemoveCollectionProduct()?.then((data: any) => {
        if (data) {
          getShoplists(true);
          setSelectedShoplists();
        }
      });
    };

    watch(ManyShoplists, () => {
      setSelectedShoplists();
    });

    onMounted(() => {
      getShoplists();
      setSelectedShoplists();
      Logic.Shoplist.watchProperty("ManyShoplistCollections", ManyShoplists);
    });

    return {
      isLoading,
      ManyShoplists,
      selectedShoplists,
      showAddNewShoplist,
      newShoplistName,
      FormValidations,
      addShoplistIsLoading,
      showShoplists,
      selectShoplist,
      addNewShoplist,
      addOrRemoveProductFromShoplist,
    };
  },
});
</script>
