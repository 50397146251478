<template>
  <div class="w-full flex flex-col bg-black h-auto">
    <div class="w-full flex flex-col relative h-auto">
      <!-- Top Section -->
      <div
        class="w-full flex flex-col space-y-2 absolute top-0 left-0 z-[9999999] px-3 py-3 space-x-3 items-center"
      >
        <div
          class="w-full flex flex-row items-center justify-between"
          v-if="type == 'video' && videoSettings.paused"
        >
          <div class="flex flex-row items-center space-x-2">
            <div
              class="px-3 py-1 bg-black bg-opacity-50 rounded-[5px] min-w-[90px] flex items-center justify-center"
            >
              <app-normal-text class="!text-white !text-[12px]">
                {{ formatTime(videoClipSetup.start) }} -
                {{ formatTime(videoClipSetup.end) }}
              </app-normal-text>
            </div>
            <div
              class="w-[40px] h-[40px] bg-black bg-opacity-50 rounded-full flex items-center justify-center cursor-pointer"
              @click="videoPlayerRef.toggleMuteUnmute()"
            >
              <app-icon
                :name="videoSettings.muted ? 'mute-white' : 'unmute-white'"
                class="!h-[14px]"
              />
            </div>
          </div>

          <div class="flex flex-row items-center justify-end space-x-2">
            <div
              class="w-[40px] h-[40px] bg-black bg-opacity-50 rounded-full flex items-center justify-center cursor-pointer"
              @click="videoPlayerRef.togglePlayPause()"
            >
              <app-icon
                :name="videoSettings.paused ? 'play-white' : 'pause-white'"
                class="!h-[17px]"
              />
            </div>
          </div>
        </div>

        <div class="w-full flex flex-row items-center justify-between space-x-2">
          <div
            class="w-full rounded-[6px] border-[2px] border-white h-[50px] flex flex-row relative"
            ref="wrapper"
            v-if="type == 'video' && videoSettings.paused"
          >
            <!-- Add this new element for the moving line -->
            <div
              class="absolute top-0 bottom-0 w-[2px] bg-white z-10 transition-transform duration-300 ease-linear"
              :style="{ transform: `translateX(${progressLinePosition}px)` }"
            ></div>

            <div
              ref="seekable"
              class="absolute h-full left-0 top-0 bg-transparent border-t-[2px] z-20 border-b-[2px] rounded-[5px] border-secondary-500 cursor-pointer"
              :style="{
                left: `${clipSetup.left}px`,
                width: `${clipSetup.width}px`,
              }"
              @mousedown="startMove"
              @touchstart="startMove"
            >
              <!-- Left Handle -->
              <div
                class="absolute left-0 w-[20px] h-full bg-secondary-main cursor-ew-resize"
                @mousedown.stop="(event) => startResize(event, 'left')"
                @touchstart.stop="(event) => startResize(event, 'left')"
              ></div>

              <!-- Right Handle -->
              <div
                class="absolute right-0 w-[20px] h-full bg-secondary-main cursor-ew-resize rounded-r-[2px]"
                @mousedown.stop="(event) => startResize(event, 'right')"
                @touchstart.stop="(event) => startResize(event, 'right')"
              ></div>
            </div>

            <app-image-loader
              :photoUrl="item"
              v-for="(item, index) in videoFrames"
              :key="index"
              :class="`h-full ${index == 0 ? 'rounded-l-[6px]' : ''} ${
                index == videoFrames.length - 1 ? 'rounded-r-[6px]' : ''
              }`"
              :style="`width: ${100 / 6}%;`"
            />
          </div>
        </div>
      </div>

      <!-- Video Section -->
      <div
        v-if="type == 'video'"
        class="w-full flex flex-col items-center justify-center"
        style="
          height: calc(
            100vh - env(safe-area-inset-bottom) - env(safe-area-inset-top) - 120px
          );
        "
      >
        <div class="!w-full h-full flex flex-col">
          <app-video-player
            :videoUrl="clippedVideoUrl"
            v-if="type == 'video' && clippedVideoUrl"
            v-model:is-paused="videoSettings.paused"
            v-model:is-muted="videoSettings.muted"
            :is-full-screen="false"
            ref="videoPlayerRef"
          />
        </div>
      </div>

      <!-- Image Section -->
      <div
        v-if="type == 'image'"
        @click="showSettings ? (showSettings = false) : (showSettings = true)"
        class="w-full flex flex-col bg-black items-center justify-center overflow-y-hidden cursor-pointer"
        style="
          height: calc(
            100vh - env(safe-area-inset-bottom) - env(safe-area-inset-top) - 120px
          );
        "
      >
        <img :src="file_url" class="w-full h-auto" v-if="!imageCroppable" />

        <app-image-cropper
          :image_url="file_url"
          v-else
          v-model="newPromotion.basic.banner.crop_image_blob"
        />
      </div>

      <!-- Content Section -->
      <div
        class="w-full flex flex-row justify-between items-end px-3 py-3 min-h-[100px] absolute bottom-0 left-0"
        :style="'background: linear-gradient(to top, rgba(0, 0, 0, 0.7), transparent);'"
      >
        <!-- For Website cta -->
        <template v-if="withCta">
          <div class="w-full flex flex-col">
            <app-button
              class="!bg-white w-full py-3 !rounded-[7px] !text-black !font-semibold"
            >
              {{ newPromotion.basic.cta }}
            </app-button>
          </div>
        </template>

        <!-- For polls -->
        <template v-if="withPolls">
          <div class="w-full flex flex-col space-y-2 items-center justify-center">
            <app-normal-text
              class="w-full text-center font-semibold text-xl !text-white uppercase pb-1 px-3"
            >
              {{ newPromotion.basic.polls?.question }}
            </app-normal-text>

            <!-- Binary Options -->
            <div
              v-if="
                newPromotion.basic.polls?.answer_type === 'binary' ||
                newPromotion.basic.polls.options.length == 2
              "
              class="w-full grid grid-cols-2 gap-2"
            >
              <template
                v-for="(option, index) in newPromotion.basic.polls?.options"
                :key="index"
              >
                <template
                  v-if="
                    newPromotion.basic.polls.options.filter(
                      (eachitem) => eachitem.image_url
                    ).length == 0
                  "
                >
                  <button
                    class="focus:outline-none col-span-1 py-3 bg-white rounded-[6px] flex justify-center items-center"
                  >
                    <span class="text-black !font-semibold !text-xs">
                      {{ option.value }}
                    </span>
                  </button>
                </template>
                <div v-else class="col-span-1 rounded-[6px] bg-white flex flex-col">
                  <app-image-loader
                    :photoUrl="option.image_url || ''"
                    class="h-[120px] rounded-t-[6px] w-full"
                  />
                  <div class="py-1 flex flex-row items-center justify-center">
                    <span class="text-black !font-semibold !text-[11px]">
                      {{ option.value }}
                    </span>
                  </div>
                </div>
              </template>
            </div>

            <!-- Multiple Options -->
            <div
              v-if="
                newPromotion.basic.polls?.answer_type === 'options' &&
                newPromotion.basic.polls.options.length > 2
              "
              class="w-full grid grid-cols-2 gap-3"
            >
              <template
                v-for="(option, index) in newPromotion.basic.polls?.options"
                :key="index"
              >
                <button
                  v-if="
                    newPromotion.basic.polls?.option_type === 'plain' &&
                    newPromotion.basic.polls.options.filter(
                      (eachitem) => eachitem.image_url
                    ).length == 0
                  "
                  class="focus:outline-none col-span-2 py-3 bg-white rounded-[6px] flex justify-center items-center"
                >
                  <span class="text-black !font-semibold !text-xs">
                    {{ option.value }}
                  </span>
                </button>
                <div v-else class="col-span-1 rounded-[6px] bg-white flex flex-col">
                  <app-image-loader
                    :photoUrl="option.image_url || ''"
                    class="h-[120px] rounded-t-[6px] w-full"
                  />
                  <div class="py-1 flex flex-row items-center justify-center">
                    <span class="text-black !font-semibold !text-[11px]">
                      {{ option.value }}
                    </span>
                  </div>
                </div>
              </template>
            </div>

            <div class="w-full flex flex-row items-center justify-center pt-2">
              <span class="text-center text-gray-300 !text-[11px]">
                Vote to see result
              </span>
            </div>
          </div>
        </template>
      </div>
    </div>

    <!-- Bottom Section -->
    <transition name="slide-out-bottom">
      <slot name="bottom-section" />
    </transition>

    <!-- Loader -->
    <fixed-container
      customClass="w-full flex flex-col h-screen !bg-black items-center justify-center !z-[99999] top-0 left-0 space-y-3 "
      class="z-[9999999]"
      v-if="gettingReady"
    >
      <svg
        :class="`animate-spin mr-3 h-5 w-5 text-primary-400 -mt-10`"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          class="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          stroke-width="4"
        ></circle>
        <path
          class="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>

      <app-normal-text class="!text-center !text-white">
        Loading media editor...
      </app-normal-text>
    </fixed-container>
  </div>
</template>

<script lang="ts">
import { showSettings } from "../../composable/common";
import { newPromotion, newPromotionMedia } from "../../composable/promote";
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { fetchFile } from "@ffmpeg/util";
import { Logic } from "../../index";
import {
  AppVideoPlayer,
  AppIcon,
  AppNormalText,
  AppImageLoader,
  AppButton,
  AppImageCropper,
} from "../../components";
import { defineComponent, reactive, ref, watch } from "vue";
import FixedContainer from "./FixedContainer.vue";

export default defineComponent({
  components: {
    AppVideoPlayer,
    AppIcon,
    AppNormalText,
    AppImageLoader,
    AppButton,
    AppImageCropper,
    FixedContainer,
  },
  name: "MediaEditorComponent",
  props: {
    type: {
      type: String as () => "image" | "video",
      default: "image",
    },
    file: {
      type: Object as () => Blob,
    },
    file_url: {
      type: String,
      default: "",
    },
    withCta: {
      type: Boolean,
      default: false,
    },
    withPolls: {
      type: Boolean,
      default: false,
    },
    imageCroppable: {
      type: Boolean,
      default: false,
    },
    hasEdit: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:baseImage", "update:baseVideo"],
  setup(props, context) {
    const videoPlayerRef = ref();

    const gettingReady = ref(true);

    const ffmpeg = new FFmpeg();
    const videoFile = ref<Blob | null>(null);
    const videoFrames = reactive<string[]>([]);
    const videoBlobFrames = reactive<Blob[]>([]);

    const maxSlipDuration = ref(60);
    const videoClipSetup = reactive({
      start: 0,
      end: maxSlipDuration.value,
      total_width: 0,
    });
    const clippedVideoUrl = ref<string | null>(null);
    const clipedVideo = ref<Blob>();
    const lockChanges = ref(true);
    const progressLinePosition = ref(0);

    // Clip Control
    const wrapper = ref<HTMLDivElement | null>(null);
    const seekable = ref<HTMLDivElement | null>(null);

    const clipSetup = reactive({
      left: 0,
      width: 100,
      min: 50,
      max: 100,
    });

    let isDragging = false;
    let isResizing = false;
    let startX = 0;
    let initialLeft = 0;
    let initialWidth = 0;

    const formatTime = (seconds: number) => {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = Math.floor(seconds % 60);

      // Ensure two digits for seconds (e.g., 01, 09, 10)
      const paddedSeconds = remainingSeconds.toString().padStart(2, "0");

      return `${minutes}:${paddedSeconds}`;
    };

    const videoSettings = reactive({
      paused: true,
      muted: false,
      duration: 0,
    });

    const setVideoFile = async () => {
      if (props.file) {
        videoFile.value = props.file;

        if (!ffmpeg.loaded) {
          await ffmpeg.load();
        }

        // Write the video file to the in-memory file system
        await ffmpeg.writeFile("input.mp4", await fetchFile(videoFile.value));

        // Get the video metadata, including duration
        const videoElement = document.createElement("video");
        videoElement.preload = "metadata";

        videoElement.onloadedmetadata = async () => {
          videoSettings.duration = videoElement.duration;

          // Revoke the object URL to free up memory
          URL.revokeObjectURL(videoElement.src);

          // Remove the video element from the DOM
          videoElement.remove();

          await clipVideo();
          await extractVidoeFrameImages();
        };

        videoElement.src = URL.createObjectURL(videoFile.value);
      }
    };

    const extractVidoeFrameImages = async () => {
      videoFrames.length = 0;
      videoBlobFrames.length = 0;

      if (ffmpeg.loaded) {
        const totalDuration = videoSettings.duration;

        // Calculate the interval between frames to extract exactly 6 images
        const interval = totalDuration / 6;

        // Extract frames at intervals (e.g., every 5 second)
        for (let i = 0; i < 6; i++) {
          const timestamp = i * interval;
          await ffmpeg.exec([
            "-ss",
            `${timestamp}`,
            "-i",
            "input.mp4",
            "-frames:v",
            "1",
            `output_${i}.png`,
          ]);
          const data = await ffmpeg.readFile(`output_${i}.png`);
          const frameBlob = new Blob([data], { type: "image/png" });
          const frameUrl = URL.createObjectURL(frameBlob);
          videoFrames.push(frameUrl);
          videoBlobFrames.push(frameBlob);
        }
      }

      context.emit("update:baseImage", videoBlobFrames[0]);
    };

    const clipVideo = async () => {
      // Ensure clipEnd is at most the video's duration and at least 5 seconds
      const maxClipEnd = Math.min(
        videoSettings.duration,
        videoClipSetup.start + maxSlipDuration.value
      );

      videoClipSetup.end = Math.min(videoClipSetup.end, maxClipEnd);

      if (videoClipSetup.end - videoClipSetup.start < 5) {
        videoClipSetup.end = videoClipSetup.start + 5;
      }

      if (videoClipSetup.start < 0) {
        videoClipSetup.start = 0;
      }

      // Clip the video
      await ffmpeg.exec([
        "-ss",
        `${videoClipSetup.start}`,
        "-i",
        "input.mp4",
        "-to",
        `${videoClipSetup.end - videoClipSetup.start}`,
        "-c",
        "copy",
        "output.mp4",
      ]);

      // Read the clipped video file
      const data = await ffmpeg.readFile("output.mp4");
      const videoBlob = new Blob([data], { type: "video/mp4" });
      clipedVideo.value = videoBlob;
      clippedVideoUrl.value = URL.createObjectURL(videoBlob);

      context.emit("update:baseVideo", videoBlob);

      // Set clip size
      setClipSize();

      setTimeout(() => {
        lockChanges.value = false;
      }, 1000);
    };

    const move = (event: MouseEvent | TouchEvent) => {
      if (!isDragging || !wrapper.value || !seekable.value) return;

      const clientX =
        event instanceof MouseEvent ? event.clientX : event.touches[0].clientX;
      const dx = clientX - startX;
      let newLeft = initialLeft + dx;

      // Prevent moving out of bounds
      if (newLeft < 0) newLeft = 0;
      if (newLeft + clipSetup.width > wrapper.value.clientWidth) {
        newLeft = wrapper.value.clientWidth - clipSetup.width;
      }

      clipSetup.left = newLeft;
    };

    const stopMove = () => {
      isDragging = false;
      document.removeEventListener("mousemove", move);
      document.removeEventListener("touchmove", move);
      document.removeEventListener("mouseup", stopMove);
      document.removeEventListener("touchend", stopMove);
    };

    const startMove = (event: MouseEvent | TouchEvent) => {
      if (!wrapper.value || !seekable.value) return;

      isDragging = true;
      startX = event instanceof MouseEvent ? event.clientX : event.touches[0].clientX;
      initialLeft = clipSetup.left;

      document.addEventListener("mousemove", move);
      document.addEventListener("touchmove", move);
      document.addEventListener("mouseup", stopMove);
      document.addEventListener("touchend", stopMove);
    };

    const startResize = (event: MouseEvent | TouchEvent, direction: "left" | "right") => {
      if (!wrapper.value || !seekable.value) return;

      isResizing = true;
      startX = event instanceof MouseEvent ? event.clientX : event.touches[0].clientX;
      initialLeft = clipSetup.left;
      initialWidth = clipSetup.width;

      document.addEventListener("mousemove", (e) => resize(e, direction));
      document.addEventListener("touchmove", (e) => resize(e, direction));
      document.addEventListener("mouseup", stopResize);
      document.addEventListener("touchend", stopResize);
    };

    const resize = (event: MouseEvent | TouchEvent, direction: "left" | "right") => {
      if (!isResizing || !wrapper.value || !seekable.value) return;

      const clientX =
        event instanceof MouseEvent ? event.clientX : event.touches[0].clientX;
      const dx = clientX - startX;

      if (direction === "left") {
        let newLeft = initialLeft + dx;
        let newWidth = initialWidth - dx;

        // Prevent resizing out of bounds
        if (newLeft < 0) {
          newLeft = 0;
          newWidth = initialLeft + initialWidth;
        }
        if (newWidth < clipSetup.min) newWidth = clipSetup.min; // Apply minimum width
        if (newWidth > clipSetup.max) newWidth = clipSetup.max; // Apply maximum width

        clipSetup.left = newLeft;
        clipSetup.width = newWidth;
      } else if (direction === "right") {
        let newWidth = initialWidth + dx;

        // Prevent resizing out of bounds
        if (clipSetup.left + newWidth > wrapper.value.clientWidth) {
          newWidth = wrapper.value.clientWidth - clipSetup.left;
        }
        if (newWidth < clipSetup.min) newWidth = clipSetup.min; // Apply minimum width
        if (newWidth > clipSetup.max) newWidth = clipSetup.max; // Apply maximum width

        clipSetup.width = newWidth;
      }
    };

    const stopResize = () => {
      isResizing = false;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      document.removeEventListener("mousemove", resize);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      document.removeEventListener("touchmove", resize);
      document.removeEventListener("mouseup", stopResize);
      document.removeEventListener("touchend", stopResize);
    };

    const setClipSize = () => {
      const wrapperRect = wrapper.value?.getBoundingClientRect();

      if (wrapperRect) {
        const wrapperOriginalWidth = wrapperRect.width - 4;
        videoClipSetup.total_width = wrapperOriginalWidth;
        clipSetup.max = wrapperOriginalWidth;
        clipSetup.min = 50;

        const finalWidth =
          (wrapperOriginalWidth * (videoClipSetup.end - videoClipSetup.start)) /
          videoSettings.duration;
        clipSetup.width =
          finalWidth < wrapperOriginalWidth ? finalWidth : wrapperOriginalWidth;
      }
    };

    const pixelsToSeconds = (pixels: number, pixelRange: number, timeRange: number) => {
      // Calculate the ratio of seconds per pixel
      const pixelToTimeRatio = timeRange / pixelRange;

      // Convert pixels to seconds
      const seconds = pixels * pixelToTimeRatio;

      return seconds;
    };

    watch(clipSetup, () => {
      if (!lockChanges.value) {
        // Let's set the correct video clip setup
        videoClipSetup.start = pixelsToSeconds(
          clipSetup.left,
          videoClipSetup.total_width,
          videoSettings.duration
        );
        videoClipSetup.end = pixelsToSeconds(
          videoClipSetup.total_width -
            (videoClipSetup.total_width - (clipSetup.left + clipSetup.width)),
          videoClipSetup.total_width,
          videoSettings.duration
        );
      }
    });

    watch(videoClipSetup, () => {
      Logic.Common.debounce(async () => {
        await clipVideo();
      }, 500);
    });

    const runOnIonViewDidEnter = async () => {
      if (props.type == "video") {
        gettingReady.value = true;
        await setVideoFile();
        showSettings.value = true;
        gettingReady.value = false;
        context.emit("update:baseVideo", props.file);
      } else {
        gettingReady.value = false;
        showSettings.value = true;
        context.emit("update:baseImage", props.file);
      }
    };

    const runOnIonViewDidLeave = () => {
      lockChanges.value = true;
    };

    const closeBottom = () => {
      showSettings.value = false;
    };

    // Add this new watch effect
    watch(
      () => videoPlayerRef.value?.currentTime,
      (newTime) => {
        if (newTime && wrapper.value) {
          const progress = newTime / videoSettings.duration;
          progressLinePosition.value = progress * wrapper.value.clientWidth;
        }
      }
    );

    return {
      Logic,
      videoSettings,
      videoPlayerRef,
      videoFrames,
      clippedVideoUrl,
      videoClipSetup,
      wrapper,
      seekable,
      clipSetup,
      showSettings,
      newPromotion,
      gettingReady,
      progressLinePosition,
      newPromotionMedia,
      startMove,
      startResize,
      formatTime,
      runOnIonViewDidEnter,
      runOnIonViewDidLeave,
      closeBottom,
    };
  },
});
</script>
<style scoped>
body {
  background: black !important;
}
</style>
