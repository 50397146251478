<template>
  <div class="w-full flex flex-col space-y-2 pb-4 md:!pb-0 mdlg:!pb-0">
    <app-normal-text class="w-full text-left text-gray-700">
      Select shoplist media type
    </app-normal-text>
    <div class="w-full grid grid-cols-2 gap-3">
      <div
        class="col-span-2 border-[1px] border-grey-200 rounded-[10px] h-[140px] mdlg:!h-[180px] cursor-pointer"
        @click="handleInstagramSelected"
      >
        <div
          class="w-full flex flex-col space-y-2 justify-center items-center h-full py-3 px-3"
        >
          <div
            class="w-[40px] h-[40px] md:!h-[50px] md:!w-[50px] bg-grey-50 rounded-[5px] flex flex-row items-center justify-center"
          >
            <app-icon
              :name="'instagram'"
              :class="'h-[15px] mdlg:!h-[20px] md:!h-[20px] '"
            />
          </div>

          <app-normal-text class="!text-center">
            Import video from Instagram
          </app-normal-text>
        </div>
      </div>
      <app-file-attachment
        :isWrapper="true"
        class="col-span-1 border-[1px] border-grey-200 rounded-[10px] h-[140px] mdlg:!h-[180px] cursor-pointer"
        :accept="'video/mp4'"
        @update:modelValue="handleVideoSelected"
        @update:localFileUrl="handleVideoURLReady"
      >
        <div
          class="w-full flex flex-col space-y-2 justify-center items-center h-full py-3 px-3"
        >
          <div
            class="w-[40px] h-[40px] bg-grey-50 rounded-[5px] flex flex-row items-center justify-center"
          >
            <app-icon :name="'video'" :customClass="'!h-[15px]'" />
          </div>

          <app-normal-text> Upload Video </app-normal-text>
        </div>
      </app-file-attachment>
      <app-file-attachment
        :isWrapper="true"
        class="col-span-1 border-[1px] border-grey-200 rounded-[10px] h-[140px] mdlg:!h-[180px] cursor-pointer"
        :accept="'image/jpeg,image/png,image/gif'"
        @update:modelValue="handleImageSelected"
        @update:localFileUrl="handleImageURLReady"
      >
        <div class="w-full flex flex-col space-y-2 justify-center items-center py-3 px-3">
          <div
            class="w-[40px] h-[40px] bg-grey-50 rounded-[5px] flex flex-row items-center justify-center"
          >
            <app-icon :name="'image'" :customClass="'!h-[17px]'" />
          </div>

          <app-normal-text> Updload Image </app-normal-text>
        </div>
      </app-file-attachment>
    </div>
  </div>
</template>
<script lang="ts">
import { InstagramMedia } from "../../gql/graphql";
import { defineComponent, reactive } from "vue";
import { Logic } from "../../composable";
import { AppFileAttachment } from "../AppForm";
import AppIcon from "../AppIcon";
import { AppNormalText } from "../AppTypography";

export default defineComponent({
  components: {
    AppIcon,
    AppNormalText,
    AppFileAttachment,
  },
  name: "ModalShoplistType",
  props: {
    action: {
      type: Function,
      default: () => {},
    },
  },
  setup(props) {
    const uploadeData = reactive<{
      image: {
        url: string;
        blob?: Blob;
      };
      video: {
        url: string;
        blob?: Blob;
      };
    }>({
      image: {
        url: "",
      },
      video: {
        url: "",
      },
    });

    const handleVideoURLReady = (url: string) => {
      uploadeData.video.url = url;
      if (props.action) {
        props.action("video", uploadeData.video.blob, uploadeData.video.url);
      }
    };

    const handleImageURLReady = (url: string) => {
      uploadeData.image.url = url;
      if (props.action) {
        props.action("image", uploadeData.image.blob, uploadeData.image.url);
      }
    };

    const handleVideoSelected = (data: any) => {
      uploadeData.video.blob = data;
    };

    const handleImageSelected = (data: any) => {
      uploadeData.image.blob = data;
    };

    const showChooseInstagramVideos = () => {
      Logic.Common.showModal({
        show: true,
        type: "choose_instagram_video",
        title: "Select Instagram Video",
        action: (data: InstagramMedia) => {
          props.action("instagram", data, data.media_url);
        },
      });
    };

    const handleInstagramSelected = async () => {
      const userBusiness = Logic.Auth.GetCurrentBusiness();
      if (userBusiness) {
        const connectedAccounts = userBusiness.connected_accounts || [];
        if (connectedAccounts.includes("instagram")) {
          showChooseInstagramVideos();
        } else {
          const businessUserId = userBusiness.business_user_id;
          await Logic.Common.openBrowser(
            `https://www.instagram.com/oauth/authorize?enable_fb_login=0&force_authentication=1&client_id=1925255027957818&redirect_uri=https://api.client.dev.getshoppoint.com/media/instagram/auth&response_type=code&scope=instagram_business_basic%2Cinstagram_business_manage_messages%2Cinstagram_business_manage_comments%2Cinstagram_business_content_publish&state=${businessUserId}`,
            () => {},
            () => {
              //
            }
          );
          Logic.Shoplist.CheckIfMediaAccountIsConnectedForm = {
            business_user_id: businessUserId,
            account_type: "instagram",
          };
          Logic.Shoplist.CheckIfMediaAccountIsConnected(() => {
            // Show modal to select user instagram videos
            showChooseInstagramVideos();
          });
        }
      }
    };

    return {
      Logic,
      uploadeData,
      handleVideoURLReady,
      handleImageURLReady,
      handleVideoSelected,
      handleImageSelected,
      handleInstagramSelected,
    };
  },
});
</script>
