import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_onboarding_slide = _resolveComponent("onboarding-slide")!
  const _component_onboarding_layout = _resolveComponent("onboarding-layout")!
  const _component_app_wrapper = _resolveComponent("app-wrapper")!

  return (_openBlock(), _createBlock(_component_app_wrapper, null, {
    default: _withCtx(() => [
      _createVNode(_component_onboarding_layout, { title: 'clear' }, {
        default: _withCtx(() => [
          _createVNode(_component_onboarding_slide)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}