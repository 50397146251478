import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col space-y-4 h-full" }
const _hoisted_2 = { class: "w-full flex flex-row justify-between items-center" }
const _hoisted_3 = { class: "flex flex-row space-x-3 items-center" }
const _hoisted_4 = {
  class: "w-full flex flex-col min-h-screen absolute left-0 space-y-4 mdlg:!border-l-[1px] mdlg:!border-r-[1px] mdlg:!border-grey-100 mdlg:dark:!border-grey-700 md:!border-l-[1px] md:!border-grey-100 md:dark:!border-grey-700",
  style: `top: calc(38% + (env(safe-area-inset-top) - 8px)) !important;`
}
const _hoisted_5 = { class: "w-full flex flex-col px-4" }
const _hoisted_6 = { class: "w-full flex flex-col bg-white rounded-[10px] px-3 py-3 space-y-2 z-50 drop-shadow-md dark:bg-primary-main" }
const _hoisted_7 = { class: "w-full flex flex-row items-center justify-between pb-3 border-b-[2px] border-grey-50 dark:border-primary-700" }
const _hoisted_8 = { class: "flex flex-col space-y-1" }
const _hoisted_9 = { class: "flex flex-row items-center space-x-1" }
const _hoisted_10 = { class: "w-full flex flex-col space-y-2 pt-2" }
const _hoisted_11 = { class: "w-full flex flex-row justify-between items-center" }
const _hoisted_12 = { class: "w-full h-[8px] rounded-[10px] bg-secondary-50 dark:bg-secondary-100 relative" }
const _hoisted_13 = {
  class: "w-full flex no-scrollbar flex-row space-x-3 flex-nowrap overflow-x-auto scrollbar-hide pl-4 pt-2",
  id: "cashback-savings-container"
}
const _hoisted_14 = { class: "flex flex-row pr-4 space-x-3" }
const _hoisted_15 = { class: "w-full flex flex-row items-center justify-between" }
const _hoisted_16 = { class: "flex flex-col space-y-1" }
const _hoisted_17 = ["onClick"]
const _hoisted_18 = { class: "w-full flex flex-row space-x-1" }
const _hoisted_19 = { class: "!font-bold pl-[2px]" }
const _hoisted_20 = { class: "w-full flex flex-col space-y-4 bg-white dark:bg-black rounded-[10px] px-4 pb-4 pt-2" }
const _hoisted_21 = { class: "w-full flex flex-row items-center justify-between" }
const _hoisted_22 = {
  key: 0,
  class: "w-full flex flex-col justify-center items-center h-[200px]"
}
const _hoisted_23 = {
  key: 0,
  class: "w-full flex flex-col justify-center items-center h-[200px]"
}
const _hoisted_24 = {
  key: 1,
  class: "w-full flex flex-col space-y-5"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_image_loader = _resolveComponent("app-image-loader")!
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_header_text = _resolveComponent("app-header-text")!
  const _component_app_loading_state = _resolveComponent("app-loading-state")!
  const _component_app_empty_state = _resolveComponent("app-empty-state")!
  const _component_app_transaction = _resolveComponent("app-transaction")!
  const _component_plain_layout = _resolveComponent("plain-layout")!
  const _component_app_wrapper = _resolveComponent("app-wrapper")!

  return (_openBlock(), _createBlock(_component_app_wrapper, null, {
    default: _withCtx(() => [
      _createVNode(_component_plain_layout, { class: "bg-white dark:bg-black !h-full" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_app_image_loader, {
              photoUrl: '/images/purple-bg.svg',
              class: "w-full flex flex-col space-y-3 px-4 !overflow-y-visible relative",
              style: {"padding-top":"calc(env(safe-area-inset-top) + 16px) !important","height":"calc(env(safe-area-inset-top) + 160px) !important"}
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_app_image_loader, {
                    photoUrl: '/images/avatar-1.png',
                    customClass: '!h-[40px] !w-[40px] rounded-full',
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showSideBar.value = true))
                  }),
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_app_icon, {
                      name: 'story-circle-purple',
                      customClass: '!h-[38px] cursor-pointer',
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (
                  _ctx.setStoryContent(_ctx.ContentType.Explore, {
                    id: 0,
                    type: 'collection',
                  })
                ))
                    }),
                    _createVNode(_component_app_icon, {
                      name: 'shopping-basket-circle-purple',
                      customClass: '!h-[38px] cursor-pointer',
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.Logic.Common.GoToRoute('/basket')))
                    })
                  ])
                ]),
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("div", _hoisted_7, [
                        _createElementVNode("div", _hoisted_8, [
                          _createVNode(_component_app_normal_text, { class: "!text-left !text-grey-main dark:!text-gray-50" }, {
                            default: _withCtx(() => _cache[6] || (_cache[6] = [
                              _createTextVNode(" Cashback Balance ")
                            ])),
                            _: 1
                          }),
                          _createElementVNode("span", _hoisted_9, [
                            _createVNode(_component_app_header_text, { class: "!font-bold text-left" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.Logic.Common.convertToMoney(
                            _ctx.UserWallet?.cash_point_balance,
                            true,
                            "ngn"
                          )), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_app_icon, {
                              name: 'hide-balance',
                              customClass: 'h-[13px]'
                            })
                          ])
                        ]),
                        _createElementVNode("div", {
                          class: "px-4 py-2 rounded-[30px] flex flex-row items-center justify-center space-x-2 bg-primary-800 cursor-pointer",
                          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.Logic.Common.GoToRoute(`/savings/topup`)))
                        }, [
                          _createVNode(_component_app_icon, {
                            name: 'plus-white',
                            customClass: 'h-[19px]'
                          }),
                          _createVNode(_component_app_normal_text, { class: "!text-white" }, {
                            default: _withCtx(() => _cache[7] || (_cache[7] = [
                              _createTextVNode(" Top up ")
                            ])),
                            _: 1
                          })
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_10, [
                        _createElementVNode("div", _hoisted_11, [
                          _createVNode(_component_app_normal_text, { class: "!text-black !text-[11px] dark:!text-white" }, {
                            default: _withCtx(() => [
                              _createTextVNode(" Weekly spend limit (" + _toDisplayString(_ctx.weeklySpendLimit) + "%) ", 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_app_normal_text, {
                            class: "!text-primary-300 cursor-pointer dark:!text-primary-100",
                            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.Logic.Common.GoToRoute('/others/membership')))
                          }, {
                            default: _withCtx(() => _cache[8] || (_cache[8] = [
                              _createTextVNode(" Upgrade ")
                            ])),
                            _: 1
                          })
                        ]),
                        _createElementVNode("div", _hoisted_12, [
                          _createElementVNode("div", {
                            class: "absolute top-0 left-0 h-full rounded-[10px] bg-[#FFC44D] dark:bg-secondary-500",
                            style: _normalizeStyle(`width: ${_ctx.weeklySpendLimit}%;`)
                          }, null, 4)
                        ])
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_13, [
                    _createElementVNode("div", _hoisted_14, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cashbackSavings, (item, index) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: index,
                          class: "w-[240px] flex flex-col space-y-3 bg-grey-50 dark:bg-gray-800 rounded-[6px] px-3 py-3"
                        }, [
                          _createElementVNode("div", _hoisted_15, [
                            _createElementVNode("div", _hoisted_16, [
                              _createVNode(_component_app_normal_text, { class: "!text-left !text-[10px] uppercase !font-semibold" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(item.title), 1)
                                ]),
                                _: 2
                              }, 1024),
                              _createVNode(_component_app_header_text, { class: "!text-sm !text-[#240046] !text-left" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.Logic.Common.convertToMoney(item.amount, true, "ngn")), 1)
                                ]),
                                _: 2
                              }, 1024)
                            ]),
                            _createElementVNode("div", {
                              class: "px-3 py-1 bg-white dark:bg-primary-700 rounded-[30px] items-center justify-between cursor-pointer",
                              onClick: ($event: any) => (
                        _ctx.monthIsClaimed(item.key) || !item.is_active
                          ? null
                          : _ctx.claimedMonthPoint(item.key)
                      )
                            }, [
                              _createVNode(_component_app_normal_text, { class: "!text-primary-400 !text-[11px] dark:!text-primary-100" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(item.redeemed
                            ? "Redeemed"
                            : item.is_active
                            ? "Redeem now"
                            : "Not available"), 1)
                                ]),
                                _: 2
                              }, 1024)
                            ], 8, _hoisted_17)
                          ]),
                          _createElementVNode("div", _hoisted_18, [
                            _createVNode(_component_app_icon, {
                              name: 'calendar-purple',
                              customClass: 'h-[14px]'
                            }),
                            _createVNode(_component_app_normal_text, { class: "!text-[11px] !text-[#5A189A] dark:!text-primary-200" }, {
                              default: _withCtx(() => [
                                _cache[9] || (_cache[9] = _createTextVNode(" Cashback available on: ")),
                                _createElementVNode("span", _hoisted_19, _toDisplayString(item.available_on), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ])
                        ]))
                      }), 128))
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_20, [
                    _createElementVNode("div", _hoisted_21, [
                      _createVNode(_component_app_normal_text, { class: "!font-semibold !text-sm text-left" }, {
                        default: _withCtx(() => _cache[10] || (_cache[10] = [
                          _createTextVNode(" Recent transactions ")
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_app_normal_text, {
                        class: "!text-[#9D4EDD] underline cursor-pointer",
                        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.Logic.Common.GoToRoute('/savings/transactions')))
                      }, {
                        default: _withCtx(() => _cache[11] || (_cache[11] = [
                          _createTextVNode(" View all ")
                        ])),
                        _: 1
                      })
                    ]),
                    (_ctx.loadingTransactions)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                          _createVNode(_component_app_loading_state, { class: "text-primary-500" })
                        ]))
                      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          (_ctx.transactions.length == 0)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                                _createVNode(_component_app_empty_state, {
                                  title: 'No transactions yet',
                                  "sub-title": 'Your transactions will appear here'
                                })
                              ]))
                            : (_openBlock(), _createElementBlock("div", _hoisted_24, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.transactions, (item, index) => {
                                  return (_openBlock(), _createBlock(_component_app_transaction, {
                                    key: index,
                                    data: item,
                                    class: "cursor-pointer",
                                    onClick: ($event: any) => (
                      item.transaction_type == 'wallet'
                        ? _ctx.Logic.Common.GoToRoute(`/savings/transactions/${item.id}`)
                        : _ctx.Logic.Common.GoToRoute(`/savings/points/${item.uuid}`)
                    )
                                  }, null, 8, ["data", "onClick"]))
                                }), 128))
                              ]))
                        ], 64))
                  ]),
                  _cache[12] || (_cache[12] = _createElementVNode("div", { class: "h-[120px]" }, null, -1))
                ])
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}