<template>
  <app-wrapper>
    <subpage-layout
      :title="'Leaderboard'"
      mainSectionClass="!bg-white dark:!bg-black !overflow-y-hidden !fixed top-0 left-0 mdlg:!relative md:!relative  w-full"
      :hasPadding="false"
      :topBarStyle="'!bg-transparent'"
      :parentContClass="'md:!h-screen'"
      class="!overflow-y-hidden md:!overflow-y-hidden"
      theme="light"
      :showBottomPadding="false"
    >
      <!-- Background icon -->
      <div class="absolute -top-[1%] -right-0">
        <img src="/images/leaderboard-bg-image.png" class="h-[140px]" />
      </div>

      <div
        class="w-full flex flex-col h-full flex-grow relative overflow-y-hidden"
      >
        <div class="xs:w-[90%] w-[85%] flex flex-row px-4">
          <app-normal-text :class="'!text-black dark:!text-white'">
            Climb the Shoppers Leaderboard. Shop, earn points, and unlock
            exclusive rewards!
          </app-normal-text>
        </div>

        <div
          class="w-full flex flex-col flex-grow h-full px-4 pt-4 z-50 overflow-y-hidden"
        >
          <div
            class="h-full flex flex-grow flex-col w-full rounded-[10px] space-y-3 py-3 relative overflow-y-auto"
          >
            <app-tabs :data="filters" v-model="selectedFilter" />
            <div
              class="w-full flex flex-col space-y-3"
              v-if="leaderboardUsers.length > 3"
            >
              <!-- Your position -->
              <div
                class="w-full flex flex-col space-y-1"
                v-if="(currentUser?.index || 0) > 2"
              >
                <app-normal-text
                  :color="'text-white'"
                  :customClass="'!font-semibold !uppercase !text-[12px]'"
                >
                  Your position
                </app-normal-text>
                <div
                  class="w-full flex flex-row items-center justify-between px-2 py-2 bg-white dark:bg-gray-800"
                >
                  <div class="flex flex-row items-center space-x-2">
                    <app-normal-text
                      :color="'text-black dark:!text-white'"
                      :customClass="'w-[20px] flex flex-row items-center justify-center !text-[12px]'"
                    >
                      {{ (currentUser?.index || 0) + 1 }}
                    </app-normal-text>
                    <div class="w-[38px]">
                      <app-image-loader
                        class="!rounded-full !w-[38px] !h-[38px] !bg-[#FFF7E6] flex items-center justify-center"
                        :photoUrl="currentUser?.user.profile?.photo_url || ''"
                      >
                        <app-normal-text
                          v-if="!currentUser?.user.profile?.photo_url"
                          :class="'!font-semibold !text-[12px] !bg-[#FFAA00] uppercase'"
                        >
                          {{ currentUser?.user.username?.charAt(0) }}
                        </app-normal-text>
                      </app-image-loader>
                    </div>

                    <app-normal-text
                      :color="'text-black dark:!text-white'"
                      :customClass="'!line-clamp-1 text-left !capitalize'"
                    >
                      {{ currentUser?.user.username }}
                    </app-normal-text>
                  </div>

                  <div
                    class="bg-[#5A189A] border-white border-opacity-30 flex flex-row justify-end px-2 py-[2px] rounded-[40px]"
                  >
                    <app-normal-text
                      :class="'dark:!text-white !text-black  !font-light  !text-[11px] !whitespace-nowrap'"
                    >
                      {{
                        Logic.Common.abbreviateNumber(currentUser?.points || 0)
                      }}
                      pts
                    </app-normal-text>
                  </div>
                </div>
              </div>

              <div
                class="w-full grid grid-cols-7 gap-3 border-b-[1px] dark:border-[#664D7E] border-gray-400 pb-3"
              >
                <!-- Second position -->
                <div
                  class="col-span-2 flex flex-col items-center justify-center space-y-1"
                >
                  <div
                    class="w-[84px] h-[84px] xs:w-[70px] xs:h-[70px] rounded-full border-[1px] relative border-white border-opacity-30 flex flex-col items-center justify-center"
                  >
                    <div
                      class="w-full flex flex-row items-center justify-center absolute top-[-30%] left-0"
                    >
                      <app-icon
                        :name="'second-position'"
                        :customClass="'h-[33px]'"
                      />
                    </div>
                    <app-image-loader
                      :photoUrl="leaderboardUsers[1].photo_url"
                      :class="'!h-[90%] !w-[90%] !border-[3px] !border-secondary-400 !bg-white !rounded-full flex items-center justify-center'"
                    >
                      <app-normal-text
                        v-if="!leaderboardUsers[1].photo_url"
                        :customClass="'!text-lg !font-semibold uppercase'"
                      >
                        {{ leaderboardUsers[1].name?.charAt(0) }}
                      </app-normal-text>
                    </app-image-loader>
                  </div>
                  <app-normal-text
                    :color="'text-black dark:!text-white'"
                    :customClass="'xs:!text-[9px] !text-[12px] !capitalize'"
                  >
                    {{ leaderboardUsers[1].name }}
                  </app-normal-text>
                  <span
                    class="px-3 py-[2px] xs:px-2 bg-white flex flex-row rounded-[15px] space-x-1 items-center"
                  >
                    <app-icon :name="'badge-game'" :customClass="'h-[13px]'" />
                    <app-normal-text
                      :customClass="'!text-[10px] xs:!text-[8px] !text-[#9D4EDD] !font-semibold !whitespace-nowrap'"
                      >{{ leaderboardUsers[1].points }} pts</app-normal-text
                    >
                  </span>
                </div>
                <!-- First position -->
                <div
                  class="col-span-3 flex flex-col items-center justify-center space-y-1"
                >
                  <div
                    class="w-[118px] h-[150px] flex flex-col items-center justify-center relative"
                  >
                    <div
                      class="absolute top-0 left-0 h-full w-full flex items-center justify-center"
                    >
                      <img
                        src="/images/icons/first-position.svg"
                        class="h-auto mt-[-23%]"
                      />
                    </div>
                    <div
                      class="w-[84px] h-[84px] rounded-full border-white flex flex-col items-center justify-center"
                    >
                      <app-image-loader
                        :photoUrl="leaderboardUsers[0].photo_url"
                        :class="'!h-[100%] !w-[100%] !bg-white !rounded-full flex items-center justify-center'"
                      >
                        <app-normal-text
                          v-if="!leaderboardUsers[0].photo_url"
                          :customClass="'!text-lg !font-semibold uppercase'"
                        >
                          {{ leaderboardUsers[0].name?.charAt(0) }}
                        </app-normal-text>
                      </app-image-loader>
                    </div>
                  </div>

                  <app-normal-text
                    :color="'text-black dark:!text-white'"
                    class="!text-[12px] !capitalize"
                  >
                    {{ leaderboardUsers[0].name }}
                  </app-normal-text>
                  <span
                    class="px-3 py-[2px] bg-white flex flex-row rounded-[15px] space-x-1 items-center"
                  >
                    <app-icon :name="'badge-game'" :customClass="'h-[13px]'" />
                    <app-normal-text
                      :customClass="'!text-[10px] !text-[#9D4EDD] !font-semibold !whitespace-nowrap'"
                      >{{ leaderboardUsers[0].points }} pts</app-normal-text
                    >
                  </span>
                </div>
                <!-- Third position -->
                <div
                  class="col-span-2 flex flex-col items-center justify-center space-y-1"
                >
                  <div
                    class="w-[84px] h-[84px] xs:w-[70px] xs:h-[70px] rounded-full border-[1px] relative border-white border-opacity-30 flex flex-col items-center justify-center"
                  >
                    <div
                      class="w-full flex flex-row items-center justify-center absolute top-[-30%] left-0"
                    >
                      <app-icon
                        :name="'thrid-position'"
                        :customClass="'h-[33px]'"
                      />
                    </div>
                    <app-image-loader
                      :photoUrl="leaderboardUsers[2].photo_url"
                      :class="'!h-[90%] !w-[90%] !border-[3px] !border-secondary-400 !bg-white flex items-center rounded-full justify-center'"
                    >
                      <app-normal-text
                        v-if="!leaderboardUsers[2].photo_url"
                        :color="'text-shop_dark_400'"
                        :customClass="'!text-lg !font-semibold uppercase'"
                      >
                        {{ leaderboardUsers[2].name?.charAt(0) }}
                      </app-normal-text>
                    </app-image-loader>
                  </div>

                  <app-normal-text
                    :color="'text-black dark:!text-white'"
                    :customClass="'xs:!text-[9px] !text-[12px] !capitalize'"
                  >
                    {{ leaderboardUsers[2].name }}
                  </app-normal-text>
                  <span
                    class="px-3 py-[2px] xs:px-2 bg-white flex flex-row rounded-[15px] space-x-1 items-center"
                  >
                    <app-icon :name="'badge-game'" :customClass="'h-[13px]'" />
                    <app-normal-text
                      :customClass="'!text-[10px] xs:!text-[8px]  !font-semibold !whitespace-nowrap !text-[#9D4EDD]'"
                      >{{ leaderboardUsers[2].points }} pts</app-normal-text
                    >
                  </span>
                </div>
              </div>
              <template v-for="(user, index) in leaderboardUsers" :key="index">
                <div
                  v-if="index > 2"
                  class="w-full flex flex-row items-center justify-between py-2 border-b-[1px] dark:border-[#664D7E] border-gray-400"
                >
                  <div class="flex flex-row items-center space-x-2">
                    <app-normal-text
                      :color="'text-black dark:!text-white'"
                      :customClass="'w-[20px] flex flex-row items-center justify-center'"
                    >
                      {{ index + 1 }}.
                    </app-normal-text>
                    <div class="w-[38px]">
                      <app-image-loader
                        :photoUrl="user.photo_url"
                        class="!rounded-full !h-[38px] !w-[38px] flex items-center justify-center !bg-[#FFF7E6]"
                      >
                        <app-normal-text
                          v-if="!user.photo_url"
                          :class="'!text-lg !font-semibold !text-[#FFAA00] uppercase'"
                        >
                          {{ user.name?.charAt(0) }}
                        </app-normal-text>
                      </app-image-loader>
                    </div>

                    <app-normal-text
                      :color="'text-black dark:!text-white'"
                      :customClass="'!line-clamp-1 text-left lowercase !text-[12px] !capitalize'"
                    >
                      {{ user.name }}
                    </app-normal-text>
                  </div>

                  <div
                    class="bg-[#5A189A] border-white border-opacity-30 flex flex-row justify-end px-2 py-[2px] rounded-[40px]"
                  >
                    <app-normal-text
                      :class="'!text-white !font-light !text-[11px] !whitespace-nowrap'"
                    >
                      {{ user.points }} pts
                    </app-normal-text>
                  </div>
                </div>
              </template>
            </div>

            <template v-else>
              <div
                class="w-full flex flex-col py-4 px-8 rounded-[10px] justify-center items-center space-y-3 h-full"
              >
                <span :class="'!text-[#A799B5] dark:!text-gray-300 !text-xs'">
                  You currently have less than
                  <b class="font-extrabold">4 friend</b> on Shoppoint. Invite
                  more friends with your
                  <b class="font-extrabold">referral code</b> to see this
                  leaderboard
                </span>

                <app-normal-text
                  class="!text-[#BA83E7] dark:!text-primary-200 !underline"
                  @click="Logic.Common.GoToRoute('/others/referral')"
                >
                  Invite friends
                </app-normal-text>
              </div>
            </template>
          </div>
        </div>
      </div>
    </subpage-layout>
  </app-wrapper>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, ref, watch } from "vue";
import { useMeta } from "vue-meta";
import { FormValidations, tabTitle } from "@shpt/ui-components/src/composable";
import {
  AppNormalText,
  AppImageLoader,
  AppIcon,
  AppTabs,
} from "@shpt/ui-components";
import { Logic } from "@shpt/logic";
import { LeaderboardUser } from "@shpt/logic/src/gql/graphql";
import AppWrapper from "@/components/AppWrapper.vue";

export default defineComponent({
  components: {
    AppNormalText,
    AppImageLoader,
    AppIcon,
    AppTabs,
    AppWrapper,
  },
  name: "LeaderboardPage",
  middlewares: {
    fetchRules: [
      {
        domain: "User",
        property: "GlobalLeaderboard",
        method: "GetGlobalLeaderBoard",
        params: [],
        requireAuth: true,
        ignoreProperty: true,
        condition: {
          routeSearchItem: "fullPath",
          searchQuery: "global",
        },
      },
      {
        domain: "User",
        property: "MyFriendsLeaderboard",
        method: "GetMyFriendsLeaderBoard",
        params: [],
        requireAuth: true,
        ignoreProperty: true,
        condition: {
          routeSearchItem: "fullPath",
          searchQuery: "global",
        },
      },
    ],
    tracking_data: {
      lable: "Leaderboard Page",
      stage_type: "neutral",
      end_stage: "",
    },
  },
  setup() {
    tabTitle.value = "";
    useMeta({
      title: "Leaderboard",
    });

    const GlobalLeaderboard = ref(Logic.User.GlobalLeaderboard);
    const MyFriendsLeaderboard = ref(Logic.User.MyFriendsLeaderboard);

    const currentUser = ref<LeaderboardUser>();

    const selectedFilter = ref("global");

    const filters = reactive([
      {
        id: "global",
        title: "Global",
      },
      {
        id: "my_friends",
        title: "My friends",
      },
    ]);

    const leaderboardUsers = reactive<
      {
        name: string;
        photo_url: string;
        points: string;
        id: string;
      }[]
    >([]);

    const setLeaderboardUsers = () => {
      leaderboardUsers.length = 0;

      if (selectedFilter.value == "global") {
        if (GlobalLeaderboard.value) {
          const currentUserData: any =
            GlobalLeaderboard.value[GlobalLeaderboard.value.length - 1];
          currentUser.value = currentUserData;
          GlobalLeaderboard.value?.forEach((item) => {
            if (
              leaderboardUsers.filter(
                (eachItem) => eachItem.id == item.user.uuid
              ).length == 0
            ) {
              leaderboardUsers.push({
                name: item.user.username || "",
                photo_url: item.user.profile?.photo_url || "",
                points: Logic.Common.abbreviateNumber(item.points || 0),
                id: item.user.uuid,
              });
            }
          });
        }
      }

      if (selectedFilter.value == "my_friends") {
        if (MyFriendsLeaderboard.value) {
          const currentUserData: any =
            MyFriendsLeaderboard.value[MyFriendsLeaderboard.value.length - 1];
          currentUser.value = currentUserData;
          MyFriendsLeaderboard.value?.forEach((item) => {
            if (
              leaderboardUsers.filter(
                (eachItem) => eachItem.id == item.user.uuid
              ).length == 0
            ) {
              leaderboardUsers.push({
                name: item.user.username || "",
                photo_url: item.user.profile?.photo_url || "",
                points: Logic.Common.abbreviateNumber(item.points || 0),
                id: item.user.uuid,
              });
            }
          });
        }
      }
    };

    watch(GlobalLeaderboard, () => {
      setLeaderboardUsers();
    });

    watch(selectedFilter, () => {
      setLeaderboardUsers();
    });

    onMounted(() => {
      Logic.User.watchProperty("GlobalLeaderboard", GlobalLeaderboard);
      Logic.User.watchProperty("MyFriendsLeaderboard", MyFriendsLeaderboard);
      setLeaderboardUsers();
    });

    return {
      FormValidations,
      filters,
      selectedFilter,
      currentUser,
      leaderboardUsers,
      Logic,
    };
  },
});
</script>
