<template>
  <app-wrapper>
    <subpage-layout :title="title" :goBackHandler="goBackHandler">
      <template v-slot:extra-topbar>
        <app-normal-text :custom-class="'!text-primary-400 '"
          >{{ "Step " + `(${onboardingStep.current + "/" + onboardingStep.total})` }}
        </app-normal-text>
      </template>

      <template v-slot:extra-topbar-row>
        <div
          :class="`${
            onboardingStep.current == onboardingStep.total
              ? 'bg-highlight-400'
              : 'bg-highlight-50'
          }  w-full  rounded-[999px] flex justify-evenly bg-highlight-400`"
        >
          <div
            v-for="(item, index) in onboardingStep.total"
            :key="item"
            :class="`${
              onboardingStep.current >= item ? 'bg-highlight-400' : 'bg-transparent'
            } ${index == 0 ? 'rounded-l-[999px]' : ''}  ${
              index == onboardingStep.total - 1 ? 'rounded-r-[999px]' : ' '
            } w-full h-1`"
          ></div>
        </div>
      </template>

      <div class="w-full flex flex-col space-y-4 pt-4 px-4">
        <!-- Step 1 -->
        <template v-if="onboardingStep.current == 1">
          <div class="w-full flex flex-row">
            <app-normal-text class="!text-[#664D7E]">
              Verify Your Phone Number.
            </app-normal-text>
          </div>

          <app-text-field
            :placeholder="'Enter phone number'"
            v-model="phoneVerifyForm.phone"
            :hasTitle="true"
            type="tel"
            :padding="'py-3 px-3'"
            name="Phone Number"
            ref="phone"
            :updateValue="phoneVerifyForm.phone"
            :rules="[
              FormValidations.RequiredRule,
              FormValidations.customValidator(
                phoneVerifyForm.phone.length == 11,
                'Phone number must be valid'
              ),
            ]"
            :content-rule="{
              addAfterCount: 0,
              characterToAdd: ``,
              max: 11,
            }"
          >
            <template v-slot:title> Phone Number </template>
          </app-text-field>
        </template>
        <!-- Step 2 -->

        <template v-if="onboardingStep.current == 2">
          <app-text-field
            :focus-border="'border-primary-400'"
            :has-title="true"
            :custom-class="'!bg-grey-50'"
            :placeholder="'Select date'"
            v-model="aboutForm.date_of_birth"
            name="Date of birth"
            type="date"
            ref="dob"
            required
            :rules="[
              FormValidations.RequiredRule,
              FormValidations.customValidator(
                mustBe18OrAbove(aboutForm.date_of_birth),
                `You must be 18 years old or above`
              ),
            ]"
          >
            <template v-slot:title> Your date of birth </template>
          </app-text-field>

          <app-select
            :placeholder="'Select your gender'"
            :hasTitle="true"
            :paddings="'py-3 px-4'"
            :options="genderOption"
            required
            ref="gender"
            v-model="aboutForm.gender"
          >
            <template v-slot:title> Your gender </template>
          </app-select>
        </template>

        <!-- Step 2 -->
        <template v-if="onboardingStep.current == 3">
          <div class="w-full flex flex-row">
            <app-normal-text class="!text-[#664D7E]">
              What kind of cashback offers and deals are you interested in? Select at
              least 5
            </app-normal-text>
          </div>

          <div class="w-full flex flex-row flex-wrap">
            <div
              class="py-2 pr-2"
              v-for="(category, index) in shoppingCategories().filter(
                (item) => item.is_parent
              )"
              :key="index"
            >
              <div
                :class="`border-[1px]  ${
                  !selectedCategory.includes(category.name)
                    ? 'border-[#E8E8E8]'
                    : 'border-[#9D4EDD]'
                } rounded-[48px] py-1 px-2 space-x-2 items-center flex flex-row ${
                  !selectedCategory.includes(category.name) ? '' : '!bg-[#9D4EDD]'
                }`"
                @click="toggleCategory(category.name)"
              >
                <app-normal-text
                  :class="` ${
                    !selectedCategory.includes(category.name)
                      ? '!text-[#240046]'
                      : '!text-white'
                  }`"
                >
                  {{ category.name }}
                </app-normal-text>
              </div>
            </div>
          </div>
        </template>

        <div class="h-[70px]"></div>
      </div>

      <fixed-container class="w-full flex-col flex bottom-0 left-0">
        <AppButton
          :padding="'py-3'"
          :custom-class="`rounded-[40px] w-full ${
            !formIItemIsValid ? '!opacity-[60%]' : ''
          }`"
          :bg-color="'bg-primary-main'"
          :text-color="'text-white'"
          :loading="loaderSetup.loading"
          @click="moveForward()"
        >
          Continue{{
            onboardingStep.current == 2 && selectedCategory.length > 0
              ? `(${selectedCategory.length})`
              : ""
          }}
        </AppButton>
      </fixed-container>
    </subpage-layout>
  </app-wrapper>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, reactive, ref, watch } from "vue";
import { useMeta } from "vue-meta";
import { Logic } from "@shpt/logic";
import { AppButton, AppNormalText, AppTextField, AppSelect } from "@shpt/ui-components";
import {
  FormValidations,
  isBusinessFocused,
  shoppingCategories,
} from "@shpt/ui-components/src/composable";
import { SelectOption } from "@shpt/ui-components/src/types";
import { Geolocation } from "@capacitor/geolocation";
import { loaderSetup } from "@shpt/ui-components/src/composable/common";
import AppWrapper from "@/components/AppWrapper.vue";
import FixedContainer from "@shpt/ui-components/src/components/AppCommon/FixedContainer.vue";
import { onIonViewWillEnter } from "@ionic/vue";

export default defineComponent({
  components: {
    AppButton,
    AppNormalText,
    AppTextField,
    AppSelect,
    AppWrapper,
    FixedContainer,
  },
  name: "UserPersonalizePage",
  layout: "Auth",
  middlewares: {
    fetchRules: [
      {
        domain: "Shoplist",
        property: "ManyCategories",
        method: "GetShoplistProductCategories",
        params: [1, 300, "CREATED_AT", "DESC", ""],
        requireAuth: true,
      },
    ],
    tracking_data: {
      lable: "Personalize Page",
      stage_type: "neutral",
      end_stage: "",
    },
  },
  setup() {
    useMeta({
      title: "Personalize Your Account",
    });

    const onboardingStep = reactive({
      current: 1,
      total: 2,
    });

    const AuthUser = ref(Logic.Auth.AuthUser);

    const title = ref("About You");

    const aboutForm = reactive({
      date_of_birth: "",
      gender: "",
      location: {
        lat: 6.465422,
        lng: 3.406448,
      },
    });

    const phoneVerifyForm = reactive({
      phone: "",
      code: "",
    });

    const genderOption = reactive<SelectOption[]>([
      {
        key: "male",
        value: "Male",
      },
      {
        key: "female",
        value: "Female",
      },
    ]);

    const selectedCategory = ref<string[]>([]);

    const toggleCategory = (category: string) => {
      if (selectedCategory.value.includes(category)) {
        selectedCategory.value = selectedCategory.value.filter(
          (item) => item != category
        );
      } else {
        selectedCategory.value.push(category);
      }
    };

    const goBackHandler = () => {
      if (onboardingStep.current <= 1) {
        Logic.Common.goBack();
      } else {
        onboardingStep.current--;
      }
    };

    const askForNotification = () => {
      Logic.Common.showModal({
        show: true,
        title: "Get Notified",
        type: "ask_for_permission",
        extraData: {
          info: "Be the first to know about top cashback deals and rewards",
          cta_copy: "Enable Notification",
        },
        action: async () => {
          try {
            await Logic.Notification.registerNotifications();
          } catch (error) {
            //
          }

          Logic.Common.showModal({ show: false });
          saveProfileDetails();
        },
      });
    };

    const askForLocation = () => {
      Logic.Common.showModal({
        show: true,
        title: "Location Based Rewards",
        type: "ask_for_permission",
        extraData: {
          info:
            "Shoppoint uses your location to show you relevant cashback rewards and deals.",
          cta_copy: "Allow Location Access",
        },
        action: async () => {
          try {
            await getCurrentPosition();
          } catch (error) {
            //
          }
          if (Logic.Common.currentBuildType() == "mobile") {
            askForNotification();
          } else {
            Logic.Common.showModal({ show: false });
            saveProfileDetails();
          }
        },
      });
    };

    const getCurrentPosition = async () => {
      const coordinates = await Geolocation.getCurrentPosition();

      if (coordinates.coords) {
        aboutForm.location.lat = coordinates.coords.latitude;
        aboutForm.location.lng = coordinates.coords.longitude;
      }

      localStorage.setItem("user_coord", JSON.stringify(aboutForm.location));
    };

    const moveForward = () => {
      if (formIItemIsValid.value) {
        if (onboardingStep.current < onboardingStep.total) {
          if (isBusinessFocused.value) {
            saveProfileDetails();
          } else {
            onboardingStep.current += 1;
          }

          return;
        }

        if (onboardingStep.current == onboardingStep.total) {
          // Show location permission
          askForLocation();
        }
      }
    };

    const mustBe18OrAbove = (date: string) => {
      const yearDifference = Logic.Common.momentInstance(new Date()).diff(
        Logic.Common.momentInstance(date),
        "years"
      );

      return yearDifference > 17;
    };

    const formIItemIsValid = computed(() => {
      if (onboardingStep.current == 1) {
        return aboutForm.date_of_birth != "" && aboutForm.gender != "";
      } else if (onboardingStep.current == 2) {
        return selectedCategory.value.length >= 5;
      }

      return false;
    });

    const saveProfileDetails = () => {
      Logic.User.UpdateProfileForm = {
        base_location: aboutForm.location,
        date_of_birth: aboutForm.date_of_birth,
        gender: aboutForm.gender,
        user_interests: selectedCategory.value,
      };

      Logic.User.UpdateProfile()?.then(async (data) => {
        if (data) {
          Logic.Common.hideLoader();
          if (isBusinessFocused.value) {
            await Logic.Auth.GetAuthUser();
            Logic.Common.setCurrentAccount("merchant");
            window.location.href = "/merchant/onboarding?is_business=yes";
          } else {
            Logic.Common.GoToRoute("/");
          }
        }
      });
    };

    watch(onboardingStep, () => {
      if (onboardingStep.current == 1) {
        title.value = "About You";
      }
      if (onboardingStep.current == 2) {
        title.value = "Your Interests";
      }
    });

    const setDefault = async () => {
      if (AuthUser.value?.profile?.gender) {
        onboardingStep.current = 2;
        return;
      }

      onboardingStep.total = 1;
    };

    onIonViewWillEnter(() => {
      setDefault();
    });

    onMounted(() => {
      Logic.Auth.watchProperty("AuthUser", AuthUser);

      if (Logic.Common.currentBuildType() == "web") {
        setDefault();
      }
    });

    return {
      Logic,
      selectedCategory,
      title,
      onboardingStep,
      aboutForm,
      FormValidations,
      genderOption,
      formIItemIsValid,
      phoneVerifyForm,
      loaderSetup,
      toggleCategory,
      goBackHandler,
      moveForward,
      mustBe18OrAbove,
      shoppingCategories,
    };
  },
});
</script>
