<template>
  <app-wrapper>
    <dashboard-layout topBarType="plain" title="Shop" :pageKey="'shoplist'">
      <div class="w-full flex flex-col space-y-4 pt-4">
        <!-- Search and delivery area -->
        <div class="w-full flex flex-row px-4">
          <div class="w-full flex flex-col">
            <app-text-field
              class="w-full"
              :placeholder="'Search for products'"
              :inputStyle="''"
              @focused="searchIsFocused = true"
              @blurred="searchIsFocused = false"
              v-model="searchValue"
            >
              <template v-slot:inner-prefix>
                <span>
                  <app-icon :name="'search-list'" :customClass="'h-[18px]'" />
                </span>
              </template>

              <template v-slot:inner-suffix>
                <app-loading
                  class="!text-primary-400 dark:!text-white"
                  v-if="searchValue.length > 2 && searchResultsLoading"
                />
              </template>
            </app-text-field>
          </div>
        </div>

        <template v-if="searchValue.length > 2">
          <div class="w-full flex flex-col space-y-3 px-4">
            <app-normal-text
              v-if="ShoplistSearchResults.data.length"
              class="w-full !text-left"
            >
              Search results for
              <span class="!font-semibold">"{{ searchValue.value }}"</span>
            </app-normal-text>

            <div
              class="w-full flex flex-col items-center justify-center h-[350px]"
              v-if="!ShoplistSearchResults.data.length && !searchResultsLoading"
            >
              <app-empty-state
                :title="'No products found'"
                :subTitle="`No products found for '${searchValue.value}'`"
              />
            </div>

            <div class="w-full flex flex-col" v-else>
              <app-virtual-scroller
                :data="ShoplistSearchResults.data"
                :pagination="ShoplistSearchResults.paginatorInfo"
                :fetchMore="fetchMoreShoplistSearchResults"
                :container-class="` grid grid-cols-2 gap-4 !space-y-0`"
              >
                <template #item-content="{ index, item }">
                  <template v-if="itemIsMediaBox(item)">
                    <app-shoplist-explore
                      :key="index"
                      :item="item"
                      @click="handleShoplsistClick(item)"
                    />
                  </template>
                  <template v-else>
                    <div
                      class="w-[165px] mdlg:!w-[240px] h-fit cursor-pointer"
                      :key="index"
                      @click="
                        Logic.Common.GoToRoute(`/brand?uuid=${item.data.uuid}`)
                      "
                    >
                      <div
                        class="w-[165px] mdlg:!w-[240px] mdlg:!h-[200px] bg-[#F4EDFB] rounded-[10px] px-4 py-4 items-center justify-center flex flex-col space-y-2"
                      >
                        <div>
                          <app-image-loader
                            :photo-url="item.data?.photo_url"
                            :class="'!w-[100px] !h-[100px] !rounded-full'"
                          />
                        </div>
                        <div
                          class="w-full flex flex-col space-y-1 items-center justify-center"
                        >
                          <app-normal-text
                            :customClass="'!text-sm !font-semibold'"
                          >
                            {{ item.data?.business_name }}
                          </app-normal-text>
                          <div class="flex flex-row space-x-1 items-center">
                            <app-normal-text
                              :customClass="'!text-gray-500 !text-[12px]'"
                            >
                              @{{ item.data?.business_tag }}
                            </app-normal-text>
                            <app-icon :name="'verified'" class="!h-[14px]" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </template>
                <template #skeleton-loaders>
                  <div
                    class="col-span-1 flex flex-col space-y-2 h-[160px] mdlg:!h-[200px] md:!h-[200px] rounded-[10px] skeleton"
                  ></div>
                  <div
                    class="col-span-1 flex flex-col space-y-2 h-[160px] mdlg:!h-[200px] md:!h-[200px] rounded-[10px] skeleton"
                  ></div>
                </template>
              </app-virtual-scroller>
            </div>
          </div>
        </template>

        <template v-else>
          <!-- Banner Ads -->
          <div class="w-full flex flex-col space-y-3 px-4">
            <app-banner-ad :data="bannerAds" />
          </div>

          <!-- Top shoplists -->
          <div class="w-full flex flex-col space-y-2">
            <div class="w-full flex flex-row items-center justify-between px-4">
              <app-header-text
                :customClass="'!text-left !text-sm !font-semibold'"
              >
                Products
              </app-header-text>
              <div class="!w-[150px] flex flex-col items-center justify-end">
                <app-select
                  :hasTitle="false"
                  :options="categoryOptions"
                  :paddings="'px-3 py-1'"
                  v-model="selectedCategory"
                  custom-class="!text-[12px] !rounded-[4px]"
                >
                </app-select>
              </div>
            </div>
            <div class="w-full flex flex-col">
              <app-virtual-scroller
                :container-class="'w-full !flex no-scrollbar !flex-row space-x-3 !space-y-0 flex-nowrap !pl-4 overflow-x-auto scrollbar-hide pt-2 pr-2'"
                :data="shoplistProducts.data"
                :pagination="shoplistProducts.paginatorInfo"
                :fetchMore="fetchMoreShoplistProducts"
                :fetchNew="fetchNewShoplistProducts"
                :direction="'horizontal'"
                ref="productVirtualScrollerRef"
              >
                <template #item-content="{ index, item }">
                  <div class="w-[165px] mdlg:!w-[240px] h-fit">
                    <app-shoplist-explore
                      class="w-[165px] mdlg:!w-[240px]"
                      :key="index"
                      @click="handleShoplsistClick(item)"
                      :item="item"
                      :mediaBoxStyle="'!h-[140px] mdlg:!h-[200px] md:!h-[200px]'"
                      :theme="Logic.Common.currentAppTheme()"
                    />
                  </div>
                </template>
                <template #skeleton-loaders>
                  <div class="w-[165px] mdlg:!w-[240px]">
                    <div
                      class="!h-[170px] mdlg:!h-[230px] w-[165px] mdlg:!w-[240px] skeleton !rounded-[10px]"
                    ></div>
                  </div>
                  <div class="w-[165px] mdlg:!w-[240px]">
                    <div
                      class="!h-[170px] mdlg:!h-[230px] w-[165px] mdlg:!w-[240px] skeleton !rounded-[10px]"
                    ></div>
                  </div>
                </template>
              </app-virtual-scroller>
            </div>
          </div>

          <!-- Featured Shoplists -->
          <div class="w-full flex flex-col space-y-2">
            <app-header-text
              :customClass="'!text-left px-4 !text-sm !font-semibold'"
            >
              Featured Shoplists
            </app-header-text>
            <div
              class="w-full flex no-scrollbar flex-row space-x-3 flex-nowrap pl-4 overflow-x-auto scrollbar-hide"
            >
              <div class="flex flex-row space-x-2 py-1 pr-4">
                <div
                  class="w-[340px] xs:!w-[270px] flex flex-col space-y-1 cursor-pointer"
                  v-for="(item, index) in sponsoredShoplists"
                  :key="index"
                  @click="
                    setStoryContent(ContentType.SponsoredShoplist, {
                      id: parseInt(item.id),
                      type: item.type,
                    })
                  "
                >
                  <app-media-box
                    :data="item.data"
                    class="h-[270px] xs:!h-[230px] !rounded-[10px] relative"
                    :isVideo="!item.data.is_grid"
                  >
                    <div
                      class="absolute top-0 left-0 h-full w-full bg-black !bg-opacity-30 !rounded-[10px] flex flex-col justify-between py-3 px-3"
                    >
                      <div class="flex flex-row justify-end">
                        <span
                          :class="`px-2 py-[2px] rounded-[4px] bg-primary-main  flex flex-row items-center justify-center`"
                        >
                          <div
                            class="flex flex-row space-x-1 justify-end flex-grow items-center"
                          >
                            <app-icon
                              :name="'cashback-icon'"
                              :customClass="'h-[15px]'"
                            />
                            <app-normal-text
                              :customClass="'!whitespace-nowrap !text-[11px] mdlg:!text-[12px] font-semibold !text-white'"
                            >
                              Earn
                              <span class="font-semibold pl-[1px]"
                                >{{
                                  numberToAbbrev(item.data.points || 0)
                                }}
                                Pts</span
                              >
                            </app-normal-text>
                          </div>
                        </span>
                      </div>

                      <div class="w-full flex flex-col space-y-1">
                        <app-normal-text
                          class="!text-left !text-white uppercase"
                        >
                          {{
                            Logic.Common.fomartDate(
                              item.data.expires_at || "",
                              "MMM Do"
                            )
                          }}
                        </app-normal-text>
                        <div class="w-full flex flex-col space-y-1">
                          <app-header-text
                            class="!text-white !text-left !font-semibold"
                          >
                            {{ item.data.title }}
                          </app-header-text>
                          <!-- All tags -->
                          <div
                            class="w-full flex flex-row space-x-1 items-center flex-nowrap"
                            v-if="item.data.tags.length"
                          >
                            <span
                              class="px-2 py-[1px] bg-transparent rounded-[20px] border-[1px] border-white"
                              v-for="(tag, index) in item.data.tags"
                              :key="index"
                            >
                              <app-normal-text class="!text-white !text-[10px]">
                                {{ tag }}
                              </app-normal-text>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </app-media-box>
                </div>
                <div
                  class="flex flex-col space-y-3 h-full justify-end"
                  v-if="showSponsoredShoplistIsLoading"
                >
                  <div
                    class="!h-[230px] w-[270px] skeleton !rounded-[10px]"
                  ></div>
                </div>
              </div>
              <div id="visibility-handle-sponsored-shoplist"></div>
            </div>
          </div>

          <!-- Top Brands -->
          <div class="w-full flex flex-col space-y-2">
            <app-header-text
              :customClass="'!text-left px-4 !text-sm !font-semibold'"
            >
              Top brands
            </app-header-text>

            <div class="w-full flex flex-col" v-if="ManyBusinesses">
              <app-virtual-scroller
                :container-class="'w-full !flex no-scrollbar !flex-row space-x-3 !space-y-0 flex-nowrap !pl-4 overflow-x-auto scrollbar-hide pt-2 pr-2'"
                :data="ManyBusinesses.data"
                :pagination="ManyBusinesses.paginatorInfo"
                :fetchMore="fetchMoreBusinesses"
                :direction="'horizontal'"
              >
                <template #item-content="{ index, item }">
                  <div
                    class="w-[165px] mdlg:!w-[240px] h-fit cursor-pointer"
                    :key="index"
                    @click="Logic.Common.GoToRoute(`/brand?uuid=${item.uuid}`)"
                  >
                    <div
                      class="w-[165px] mdlg:!w-[240px] bg-[#F4EDFB] rounded-[10px] px-4 py-4 items-center justify-center flex flex-col space-y-2"
                    >
                      <div>
                        <app-image-loader
                          :photo-url="item.photo_url"
                          :class="'!w-[100px] !h-[100px] !rounded-full'"
                        />
                      </div>
                      <div
                        class="w-full flex flex-col space-y-1 items-center justify-center"
                      >
                        <app-normal-text
                          :customClass="'!text-sm !font-semibold !line-clamp-1'"
                        >
                          {{ item.business_name }}
                        </app-normal-text>
                        <div class="flex flex-row space-x-1 items-center">
                          <app-normal-text
                            :customClass="'!text-gray-500 !text-[12px]'"
                          >
                            @{{ item.business_tag }}
                          </app-normal-text>
                          <app-icon :name="'verified'" class="!h-[14px]" />
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <template #skeleton-loaders>
                  <div class="w-[165px] mdlg:!w-[240px]">
                    <div
                      class="!h-[180px] mdlg:!h-[180px] w-[165px] mdlg:!w-[240px] skeleton !rounded-[10px]"
                    ></div>
                  </div>
                  <div class="w-[165px] mdlg:!w-[240px]">
                    <div
                      class="!h-[180px] mdlg:!h-[180px] w-[165px] mdlg:!w-[240px] skeleton !rounded-[10px]"
                    ></div>
                  </div>
                </template>
              </app-virtual-scroller>
            </div>
          </div>
        </template>

        <!-- Spacer -->
        <div class="h-[120px]"></div>
      </div>
    </dashboard-layout>
  </app-wrapper>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, ref, watch } from "vue";
import { useMeta } from "vue-meta";
import { onIonViewWillEnter } from "@ionic/vue";
import { Logic } from "@shpt/logic";
import { BannerAd } from "@/modules/common";
import {
  AppMediaBox,
  AppNormalText,
  AppTextField,
  AppIcon,
  AppBannerAd,
  AppHeaderText,
  AppLoading,
  AppVirtualScroller,
  AppEmptyState,
  AppShoplistExplore,
  AppSelect,
  AppImageLoader,
} from "@shpt/ui-components";
import {
  getMediaBox,
  getMediaBoxForProduct,
  numberToAbbrev,
  onExploreShoplistsScrolledToEnd,
  onRecommendedScrolledToEnd,
  onSponsoredShoplistScrolledToEnd,
  showExploreIsLoading,
  showRecommendationIsLoading,
  showSponsoredShoplistIsLoading,
} from "@shpt/ui-components/src/composable";
import { Advert, Collection, ContentType } from "@shpt/logic/src/gql/graphql";
import { setStoryContent } from "@shpt/ui-components/src/composable/story";
import AppWrapper from "@/components/AppWrapper.vue";
import { MediaBox } from "@shpt/ui-components/src/types";
import {
  ShoplistSearchResults,
  searchValue,
  searchResultsLoading,
} from "@shpt/ui-components/src/composable/shop";
import { ShoplistProduct } from "@shpt/ui-components/src/gql/graphql";
import { SelectOption } from "@shpt/logic/src/logic/types/common";
import { PaginatorInfo } from "@shpt/logic/dist/esm/gql/graphql";

export default defineComponent({
  components: {
    AppMediaBox,
    AppNormalText,
    AppTextField,
    AppIcon,
    AppBannerAd,
    AppHeaderText,
    AppWrapper,
    AppLoading,
    AppVirtualScroller,
    AppEmptyState,
    AppShoplistExplore,
    AppSelect,
    AppImageLoader,
  },
  name: "ShopListPage",
  middlewares: {
    fetchRules: [
      {
        domain: "Shoplist",
        property: "ManyCategoriesWithProducts",
        method: "GetCategoriesWithProducts",
        params: [],
        requireAuth: true,
      },
      {
        domain: "Shoplist",
        property: "ManyShoplistProducts",
        method: "GetShoplistProductsByCategory",
        params: [1, 8, "CREATED_AT", "DESC", "", "", false],
        requireAuth: true,
      },
      {
        domain: "User",
        property: "SponsoredShoplistContent",
        method: "GetUserContent",
        params: ["", ContentType.SponsoredShoplist, 1, 12],
        requireAuth: true,
      },
      {
        domain: "Shoplist",
        property: "ManyBusinesses",
        method: "GetBusiness",
        params: [1, 10],
        requireAuth: true,
      },
      {
        domain: "User",
        property: "ExploreContent",
        method: "GetUserContent",
        params: ["", ContentType.Explore, 1, 12, true],
        requireAuth: true,
      },
    ],
    tracking_data: {
      lable: "Shoplist Page",
      stage_type: "neutral",
      end_stage: "",
    },
  },
  layout: "Dashboard",
  setup() {
    useMeta({
      title: "Shop",
    });

    const authUser = ref(Logic.Auth.AuthUser);

    const selectedCategory = ref("All");

    const searchIsFocused = ref(false);
    const productVirtualScrollerRef = ref();

    const categoryNames = reactive<string[]>([]);

    const categoryOptions = reactive<SelectOption[]>([]);

    const ManyCategoriesWithProducts = ref(
      Logic.Shoplist.ManyCategoriesWithProducts
    );
    const ManyShoplistProducts = ref(Logic.Shoplist.ManyShoplistProducts);
    const ExploreContent = ref(Logic.User.ExploreContent);
    const SponsoredShoplistContent = ref(Logic.User.SponsoredShoplistContent);
    const SearchResults = ref(Logic.Shoplist.SearchResults);
    const ManyBusinesses = ref(Logic.Shoplist.ManyBusinesses);

    const recommendedShopLists = reactive<
      {
        data: MediaBox;
        id: string;
        type: string;
        colors: {
          bg: string;
          text: string;
        };
      }[]
    >([]);

    const exploreShoplists = reactive<
      {
        data: MediaBox;
        id: string;
        type: string;
        colors: {
          bg: string;
          text: string;
        };
      }[]
    >([]);

    const shoplistProducts = reactive<{
      data: {
        data: MediaBox;
        id: string;
        type: string;
        colors: {
          bg: string;
          text: string;
        };
      }[];
      paginatorInfo: PaginatorInfo | undefined;
    }>({
      data: [],
      paginatorInfo: undefined,
    });

    const sponsoredShoplists = reactive<
      {
        data: MediaBox;
        id: string;
        type: "collection" | "ad";
      }[]
    >([]);

    const bannerAds = reactive<BannerAd[]>([
      {
        base_image: "/images/temp/ad-image-1.png",
        has_video: false,
        variant: "red",
        content: {
          main_caption: "Weekly Meal Plans",
          sub_caption: "Save up to 10% Cashbash with",
          cta: "Shop List",
        },
      },
      {
        base_image: "/images/temp/ad-image-1.png",
        has_video: false,
        variant: "brown",
        content: {
          main_caption: "Weekly Meal Plans",
          sub_caption: "Save up to 10% Cashbash with",
          cta: "Shop List",
        },
      },
    ]);

    const setCategory = () => {
      categoryOptions.length = 0;
      categoryOptions.push({
        key: "All",
        value: "All Categories",
      });
      ManyCategoriesWithProducts.value?.forEach((item) => {
        categoryOptions.push({
          key: item.uuid,
          value: item.name,
        });
      });
    };

    const setManyShoplistProducts = () => {
      shoplistProducts.data.length = 0;

      ManyShoplistProducts.value?.data.forEach((item) => {
        const mediaBox = getMediaBoxForProduct(item as ShoplistProduct);
        shoplistProducts.data.push({
          data: mediaBox,
          id: item.id.toString(),
          type: "product",
          colors: {
            bg: "",
            text: "",
          },
        });
      });
      shoplistProducts.paginatorInfo =
        ManyShoplistProducts.value?.paginatorInfo;
    };

    const setRecommendedShoplists = () => {
      //
    };

    const setShoplistSearchResults = () => {
      ShoplistSearchResults.data.length = 0;

      // For products
      SearchResults.value?.products?.data.forEach((item) => {
        const mediaBox = getMediaBoxForProduct(item as ShoplistProduct);
        ShoplistSearchResults.data.push({
          data: mediaBox,
          id: item.id.toString(),
          type: "media",
        });

        // Add results to Logic.User.ExploreContent if the product item is not there already

        const existingContent = JSON.parse(
          JSON.stringify(Logic.User.ExploreContent)
        );
        const isExist = existingContent.contents.some(
          (content: any) => content.id === item.id
        );
        if (!isExist) {
          existingContent.contents.unshift({
            type: "product",
            data: item,
            id: parseInt(item.id),
          });

          Logic.User.ExploreContent = existingContent;
        }
      });

      // For collections
      SearchResults.value?.collections?.data.forEach((item) => {
        const mediaBox = getMediaBox(item as Collection);
        ShoplistSearchResults.data.push({
          data: mediaBox,
          id: item.id.toString(),
          type: "media",
        });

        // Add results to Logic.User.ExploreContent if the collection item is not there already

        const existingContent = JSON.parse(
          JSON.stringify(Logic.User.ExploreContent)
        );
        const isExist = existingContent.contents.some(
          (content: any) => content.id === item.id
        );
        if (!isExist) {
          existingContent.contents.unshift({
            type: "collection",
            data: item,
            id: parseInt(item.id),
          });

          Logic.User.ExploreContent = existingContent;
        }
      });

      // For businesses
      SearchResults.value?.businesses?.data.forEach((item) => {
        ShoplistSearchResults.data.push({
          data: JSON.parse(JSON.stringify(item)),
          id: item.id.toString(),
          type: "business",
        });
      });

      console.log(ShoplistSearchResults.data);
    };

    const setExploreShoplists = () => {
      exploreShoplists.length = 0;

      ExploreContent.value?.contents.forEach((item) => {
        if (item.type == "collection") {
          const mediaBox = getMediaBox(item.data as Collection);
          exploreShoplists.push({
            data: mediaBox,
            id: item.id.toString(),
            type: "collection",
            colors: {
              bg: "",
              text: "",
            },
          });
        }
        if (item.type == "product") {
          const mediaBox = getMediaBoxForProduct(item.data as ShoplistProduct);
          exploreShoplists.push({
            data: mediaBox,
            id: item.id.toString(),
            type: "product",
            colors: {
              bg: "",
              text: "",
            },
          });
        }
      });
    };

    const setSponsoredShoplists = () => {
      sponsoredShoplists.length = 0;

      SponsoredShoplistContent.value?.contents.forEach((item) => {
        const adContent: Advert = item.data as Advert;

        let adType: any = adContent.type;
        if (item.type == "ad" && adType == "shoppable") {
          const shoplistData = (item.data as Advert).media?.shoplist;
          if (shoplistData) {
            const mediaBox = getMediaBox(shoplistData);

            sponsoredShoplists.push({
              data: mediaBox,
              id: item.id.toString(),
              type: "ad",
            });
          }
        } else if (item.type == "collection") {
          const mediaBox = getMediaBox(item.data as Collection);
          sponsoredShoplists.push({
            data: mediaBox,
            id: item.id.toString(),
            type: "collection",
          });
        }
      });
    };

    const createRecommendedForYouObserver = () => {
      const options = {
        root: null, // Use the viewport as the root
        threshold: 1, // Trigger when 10% of the target is visible
      };

      const observer = new IntersectionObserver(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onRecommendedScrolledToEnd,
        options
      );
      const targetElement = document.getElementById(
        `visibility-handle-recommended-shoplist`
      );
      if (targetElement) {
        observer.observe(targetElement);
      }
    };

    const createSponsoredShoplistObserver = () => {
      const options = {
        root: null, // Use the viewport as the root
        threshold: 1, // Trigger when 10% of the target is visible
      };

      const observer = new IntersectionObserver(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onSponsoredShoplistScrolledToEnd,
        options
      );
      const targetElement = document.getElementById(
        `visibility-handle-sponsored-shoplist`
      );
      if (targetElement) {
        observer.observe(targetElement);
      }
    };

    const createExploreShoplistObserver = () => {
      const options = {
        root: null, // Use the viewport as the root
        threshold: 1, // Trigger when 10% of the target is visible
      };

      const observer = new IntersectionObserver(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onExploreShoplistsScrolledToEnd,
        options
      );
      const targetElement = document.getElementById(
        `visibility-handle-explore-shoplist`
      );
      if (targetElement) {
        observer.observe(targetElement);
      }
    };
    const itemIsMediaBox = (item: any): boolean => {
      return item?.type === "media";
    };

    const fetchMoreShoplistSearchResults = (nextPage: number) => {
      return Logic.Shoplist.SearchItems(
        searchValue.value,
        nextPage,
        ShoplistSearchResults.paginatorInfo?.perPage || 12,
        true
      )
        .then((response) => {
          if (response) {
            const existingData = JSON.parse(
              JSON.stringify(Logic.Shoplist.SearchResults)
            );

            if (existingData.products && response.products) {
              existingData.products.data = existingData.products.data.concat(
                response.products.data
              );
              existingData.products.paginatorInfo =
                response.products.paginatorInfo;
            }

            if (existingData.collections && response.collections) {
              existingData.collections.data =
                existingData.collections.data.concat(response.collections.data);
              existingData.collections.paginatorInfo =
                response.collections.paginatorInfo;
            }

            Logic.Shoplist.SearchResults = existingData;

            return true;
          }
        })
        .catch(() => {
          return false;
        });
    };

    const fetchMoreShoplistProducts = (nextPage: number) => {
      let hasCategory = "";

      if (selectedCategory.value != "All") {
        hasCategory = `{
          column: UUID
          operator: EQ
          value: "${selectedCategory.value}"
        }`;
      }

      return Logic.Shoplist.GetShoplistProductsByCategory(
        nextPage,
        8,
        "CREATED_AT",
        "DESC",
        "",
        hasCategory,
        true
      )
        .then((response) => {
          if (response) {
            const existingData = JSON.parse(
              JSON.stringify(Logic.Shoplist.ManyShoplistProducts)
            );

            if (existingData.data && response.data) {
              existingData.data = existingData.data.concat(response.data);
              existingData.paginatorInfo = response.paginatorInfo;
            }

            Logic.Shoplist.ManyShoplistProducts = existingData;

            return true;
          }
        })
        .catch(() => {
          return false;
        });
    };

    const fetchMoreBusinesses = (nextPage: number) => {
      return Logic.Shoplist.GetBusiness(nextPage, 10, true)
        .then((response) => {
          if (response) {
            const existingData = JSON.parse(
              JSON.stringify(Logic.Shoplist.ManyBusinesses)
            );

            if (existingData.data && response.data) {
              existingData.data = existingData.data.concat(response.data);
              existingData.paginatorInfo = response.paginatorInfo;
            }

            Logic.Shoplist.ManyBusinesses = existingData;

            return true;
          }
        })
        .catch(() => {
          return false;
        });
    };

    const fetchNewShoplistProducts = () => {
      let hasCategory = "";

      if (selectedCategory.value != "All") {
        hasCategory = `{
          column: UUID
          operator: EQ
          value: "${selectedCategory.value}"
        }`;
      }

      return Logic.Shoplist.GetShoplistProductsByCategory(
        1,
        8,
        "CREATED_AT",
        "DESC",
        "",
        hasCategory,
        false
      )
        .then(() => {
          return true;
        })
        .catch(() => {
          return false;
        });
    };

    const handleShoplsistClick = (item: any) => {
      if (item.type == "collection") {
        setStoryContent(ContentType.Explore, {
          id: parseInt(item.id),
          type: "collection",
        });
      } else {
        Logic.Common.GoToRoute(
          `/shoplist/product/${item.data.product_data?.uuid}`
        );
      }
    };

    const searchShoplist = () => {
      Logic.Common.debounce(() => {
        if (searchValue.value.length <= 2) {
          return;
        }
        searchResultsLoading.value = true;
        Logic.Shoplist.SearchItems(
          searchValue.value,
          ShoplistSearchResults.paginatorInfo?.currentPage || 1,
          ShoplistSearchResults.paginatorInfo?.perPage || 10,
          false
        )
          .then((data) => {
            if (data) {
              Logic.Shoplist.SearchResults = data;
              ShoplistSearchResults.paginatorInfo =
                data.products?.paginatorInfo && data.collections?.paginatorInfo
                  ? data.products.paginatorInfo.lastPage >
                    data.collections.paginatorInfo.lastPage
                    ? data.products.paginatorInfo
                    : data.collections.paginatorInfo
                  : data.products?.paginatorInfo ||
                    data.collections?.paginatorInfo;
            }
            searchResultsLoading.value = false;
          })
          .catch(() => {
            searchResultsLoading.value = false;
          });
      }, 500);
    };

    watch(selectedCategory, () => {
      shoplistProducts.data.length = 0;
      productVirtualScrollerRef.value?.resetList();
    });

    watch(searchValue, () => {
      searchShoplist();
    });

    watch(ManyShoplistProducts, () => {
      setManyShoplistProducts();
    });

    watch(ExploreContent, () => {
      setExploreShoplists();
    });

    watch(SponsoredShoplistContent, () => {
      setSponsoredShoplists();
    });

    watch(SearchResults, () => {
      setShoplistSearchResults();
    });

    onIonViewWillEnter(() => {
      setCategory();
      setSponsoredShoplists();
      setRecommendedShoplists();
      setExploreShoplists();
      setManyShoplistProducts();
      Logic.Game.CheckGameStatus();
    });

    onMounted(() => {
      Logic.Shoplist.watchProperty(
        "ManyCategoriesWithProducts",
        ManyCategoriesWithProducts
      );
      Logic.User.watchProperty("ExploreContent", ExploreContent);
      Logic.User.watchProperty(
        "SponsoredShoplistContent",
        SponsoredShoplistContent
      );
      Logic.Shoplist.watchProperty(
        "ManyShoplistProducts",
        ManyShoplistProducts
      );
      Logic.Shoplist.watchProperty("SearchResults", SearchResults);
      Logic.Shoplist.watchProperty("ManyBusinesses", ManyBusinesses);
      setTimeout(() => {
        createRecommendedForYouObserver();
        createSponsoredShoplistObserver();
        createExploreShoplistObserver();
      }, 200);

      if (Logic.Common.currentBuildType() == "web") {
        setCategory();
        setSponsoredShoplists();
        setRecommendedShoplists();
        setExploreShoplists();
        setManyShoplistProducts();
        Logic.Game.CheckGameStatus();
      } else {
        setCategory();
      }
    });

    return {
      Logic,
      authUser,
      categoryNames,
      selectedCategory,
      bannerAds,
      recommendedShopLists,
      exploreShoplists,
      sponsoredShoplists,
      showSponsoredShoplistIsLoading,
      showRecommendationIsLoading,
      showExploreIsLoading,
      searchIsFocused,
      searchValue,
      ContentType,
      ShoplistSearchResults,
      searchResultsLoading,
      categoryOptions,
      shoplistProducts,
      productVirtualScrollerRef,
      ManyBusinesses,
      itemIsMediaBox,
      fetchMoreBusinesses,
      fetchMoreShoplistProducts,
      setStoryContent,
      fetchMoreShoplistSearchResults,
      handleShoplsistClick,
      numberToAbbrev,
      fetchNewShoplistProducts,
    };
  },
});
</script>
