<template>
  <subpage-layout :title="'Account Settings'">
    <div class="w-full flex flex-col space-y-3 px-4">
      <div
        v-for="(item, index) in actions"
        :key="index"
        class="w-full flex flex-row px-3 py-3 bg-grey-50 dark:bg-gray-900 rounded-[8px] justify-between items-center cursor-pointer"
        @click="handleSettings(item)"
      >
        <div class="flex flex-row space-x-2 items-center">
          <div class="w-[37px]">
            <div
              class="w-[36px] h-[36px] rounded-full flex flex-row items-center justify-center bg-white dark:bg-slate-300"
            >
              <app-icon :name="item.icon" :class="item.icon_size" />
            </div>
          </div>
          <app-normal-text class="!text-left !font-semibold">
            {{ item.title }}
          </app-normal-text>
        </div>
        <div class="pr-1">
          <app-icon :name="'chevron-right-grey'" class="!h-[13px]" />
        </div>
      </div>

      <div
        class="w-full flex flex-row px-3 py-3 bg-grey-50 dark:bg-gray-900 rounded-[8px] justify-between items-center"
      >
        <div class="flex flex-row space-x-2 items-center">
          <app-normal-text class="!text-left !font-semibold">
            Choose Theme
          </app-normal-text>
        </div>
        <div class="flex flex-row space-x-3 items-center">
          <div
            @click="setAppTheme('light')"
            :class="`w-[40px] h-[40px] rounded-full border-[2px] !bg-white cursor-pointer ${
              Logic.Common.currentAppTheme() == 'light'
                ? 'border-primary-500'
                : 'border-gray-700 dark:border-gray-300'
            }`"
          ></div>
          <div
            @click="setAppTheme('dark')"
            :class="`w-[40px] h-[40px] rounded-full border-[2px] !bg-black  cursor-pointer ${
              Logic.Common.currentAppTheme() == 'dark'
                ? 'border-primary-500'
                : 'border-gray-700 dark:border-gray-300'
            }`"
          ></div>
        </div>
      </div>
    </div>
  </subpage-layout>
</template>

<script lang="ts">
import { defineComponent, reactive } from "vue";
import { Logic } from "../../../index";
import { AppIcon, AppNormalText } from "../../../components";
import { setAppTheme } from "../../../composable";
import SubpageLayout from "../../../components/AppLayouts/subpage.vue";

export default defineComponent({
  components: {
    AppIcon,
    AppNormalText,
    SubpageLayout,
  },
  name: "AccountSettingsPage",
  setup() {
    const actions = reactive([
      {
        title: "Membership",
        icon: "settings-membership",
        path: "/others/membership",
        icon_size: " ",
      },
      {
        title: "Change Password",
        icon: "setting-change-password",
        path: "",
        icon_size: "",
      },
      // {
      //   title: "Change Transaction Pin",
      //   icon: "setting-change-pin",
      //   path: "",
      //   icon_size: "",
      // },
    ]);

    const handleChangePassword = () => {
      Logic.Common.showModal({
        show: true,
        title: "Change password",
        type: "change_password",
        actionLabel: "Update",
        action: (data: any) => {
          Logic.Auth.UpdatePasswordForm = {
            old_password: data.old_password,
            password: data.confirm_new_password,
            user_uuid: Logic.Auth.AuthUser?.uuid || "",
          };
          Logic.Auth.UpdatePassword(true, true);
        },
        validateForm: true,
      });
    };

    const handleSettings = (item: any) => {
      if (item.icon == "settings-membership") {
        Logic.Common.GoToRoute("/others/membership");
      }

      if (item.icon == "setting-change-password") {
        handleChangePassword();
      }

      if (item.icon == "setting-change-pin") {
        Logic.Common.GoToRoute("/auth/verify-phone?pin_update=yes");
      }
    };

    return {
      Logic,
      actions,
      handleSettings,
      setAppTheme,
    };
  },
});
</script>
