<template>
  <app-wrapper>
    <onboarding-layout :title="'clear'">
      <onboarding-slide />
    </onboarding-layout>
  </app-wrapper>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useMeta } from "vue-meta";
import AppWrapper from "@/components/AppWrapper.vue";
import OnboardingSlide from "@shpt/ui-components/src/components/AppCommon/OnboardingSlide.vue";

export default defineComponent({
  components: {
    OnboardingSlide,
    AppWrapper,
  },
  name: "StartOnboardingPage",
  layout: "Onboarding",
  middlewares: {
    tracking_data: {
      lable: "Onboarding Slides Page",
      stage_type: "neutral",
      end_stage: "",
    },
  },
  setup() {
    useMeta({
      title: "Welcome to Shoppoint",
    });

    localStorage.setItem("user_onboarded", "true");

    return {};
  },
});
</script>
