<template>
  <auth-layout
    @button-clicked="Logic.Common.GoToRoute('/auth/login')"
    @go-back="Logic.Common.goBack()"
    :go-back="true"
    :right-button="true"
  >
    <template v-slot:button-text>Login</template>
    <template v-slot:header-text>Forgot Password</template>

    <app-form-wrapper
      ref="formComp"
      :parentRefs="parentRefs"
      class="w-full flex flex-col space-y-4 pt-4"
    >
      <div class="w-full flex flex-row">
        <app-normal-text class="!text-[#664D7E] dark:!text-primary-100">
          We will send a reset code to your registration email
        </app-normal-text>
      </div>

      <AppTextField
        :focus-border="'border-primary-400'"
        :has-title="true"
        :custom-class="'!bg-grey-50'"
        :placeholder="'Input Value'"
        type="text"
        name="email"
        :rules="[FormValidations.EmailRule]"
        v-model="formData.email"
        ref="email"
        required
      >
        <template v-slot:title> Email Address </template>
        <template v-slot:inner-prefix>
          <span class="pr-1">
            <app-icon :name="'envelope'" :customClass="'h-[14px]'" />
          </span>
        </template>
      </AppTextField>

      <div class="w-full flex-col flex pt-2">
        <AppButton
          :padding="'py-3'"
          :custom-class="'rounded-[40px]'"
          :bg-color="'bg-primary-400'"
          :text-color="'text-white'"
          :loading="loaderSetup.loading"
          @click.prevent="handleSendOTP"
        >
          Get OTP
        </AppButton>
      </div>

      <!-- <div class="flex items-center justify-center flex-row space-x-1">
          <div>
            <AppNormalText
              :custom-class="'underline'"
              :color="'text-primary-main'"
              >Need Help?
            </AppNormalText>
          </div>
        </div> -->
    </app-form-wrapper>
  </auth-layout>
</template>

<script lang="ts">
import { defineComponent, reactive, ref } from "vue";
import { Logic } from "../../../composable";
import { FormValidations } from "../../../composable";
import { loaderSetup } from "../../../composable/common";
import AppButton from "../../../components/AppButton";
import { AppTextField, AppFormWrapper } from "../../../components/AppForm";
import AppIcon from "../../../components/AppIcon";
import { AppNormalText } from "../../../components/AppTypography";
import AuthLayout from "../../../components/AppLayouts/auth.vue";

export default defineComponent({
  components: {
    AppTextField,
    AppButton,
    AppNormalText,
    AppIcon,
    AppFormWrapper,
    AuthLayout,
  },
  name: "ForgotPasswordPage",
  layout: "Auth",
  middlewares: {},
  setup() {
    const formData = reactive({
      email: "",
    });

    const formComp = ref<any>(null);

    const showAddEmailOTP = () => {
      Logic.Common.showModal({
        show: true,
        title: "Enter OTP",
        type: "verify_email_with_otp",
        action: () => {
          Logic.Common.hideLoader();
          Logic.Common.showModal({
            show: false,
          });
          Logic.Common.GoToRoute("/auth/reset-password");
        },
      });
    };

    const handleSendOTP = () => {
      Logic.Auth.SendResetPasswordEmailForm = {
        user_uuid: formData.email,
      };

      localStorage.setItem("reset_acc_email", formData.email);

      const state = formComp.value?.validate();

      if (state) {
        Logic.Auth.SendResetPasswordEmail(true, true)?.then((data) => {
          if (data) {
            showAddEmailOTP();
          }
        });
      }
    };

    return {
      formData,
      formComp,
      Logic,
      FormValidations,
      loaderSetup,
      handleSendOTP,
    };
  },
  data() {
    return {
      parentRefs: null,
    };
  },
  mounted() {
    const parentRefs: any = this.$refs;
    this.parentRefs = parentRefs;
  },
});
</script>
