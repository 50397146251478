import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, Transition as _Transition, Teleport as _Teleport } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col space-y-3 px-4" }
const _hoisted_2 = { class: "bg-grey-50 rounded-[10px] dark:!bg-gray-800 px-3 py-3 flex flex-row items-center justify-between sticky top-0" }
const _hoisted_3 = { class: "flex flex-row space-x-2 items-center" }
const _hoisted_4 = { class: "flex flex-col space-y-[1px] justify-center items-start" }
const _hoisted_5 = { class: "w-full flex flex-col space-y-1" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "flex flex-row space-x-2 items-center" }
const _hoisted_8 = { class: "w-[24px]" }
const _hoisted_9 = { class: "flex flex-row space-x-2 justify-end items-center" }
const _hoisted_10 = {
  key: 0,
  class: "w-[30px] h-[24px] rounded-[30px] bg-secondary-50 dark:!bg-secondary-500 flex items-center justify-center"
}
const _hoisted_11 = {
  key: 0,
  class: "w-full flex flex-col pt-1"
}
const _hoisted_12 = { class: "w-full flex flex-col space-y-[2px]" }
const _hoisted_13 = { class: "w-full flex flex-row pt-3" }
const _hoisted_14 = { class: "px-3 py-[2px] bg-primary-800 rounded-[30px] flex items-center justify-center" }
const _hoisted_15 = { class: "w-full flex flex-col space-y-2 bg-white dark:!bg-black px-4 sticky bottom-0 pt-3 justify-center items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_image_loader = _resolveComponent("app-image-loader")!
  const _component_app_header_text = _resolveComponent("app-header-text")!
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_icon = _resolveComponent("app-icon")!

  return (_openBlock(), _createBlock(_Teleport, { to: "#modal" }, [
    _createElementVNode("div", {
      class: "w-full h-screen flex flex-col justify-start items-start fixed lg:text-sm mdlg:text-[12px] text-xs bg-black !bg-opacity-30 dark:!bg-opacity-50 !font-inter z-[9999999999999]",
      onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.closeBar()))
    }, [
      _createVNode(_Transition, {
        name: "sidebar",
        onEnter: _ctx.onEnter,
        onLeave: _ctx.onLeave
      }, {
        default: _withCtx(() => [
          (_ctx.showSideBar)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "h-full bg-white flex flex-col w-[80%] xs:!w-[85%] justify-between space-y-3 overflow-y-auto relative dark:bg-black",
                style: {"padding-top":"calc(env(safe-area-inset-top) + 16px) !important","padding-bottom":"calc(env(safe-area-inset-bottom) + 16px) !important"},
                onClick: _withModifiers(($event: any) => (null), ["stop"])
              }, [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                      _createVNode(_component_app_image_loader, {
                        photoUrl: 
                    _ctx.Logic.Common.currentAccountType() == 'merchant'
                      ? _ctx.Logic.Auth.GetCurrentBusiness()?.photo_url
                        ? _ctx.Logic.Auth.GetCurrentBusiness()?.photo_url
                        : '/images/temp/vendor.svg'
                      : _ctx.Logic.Auth.AuthUser?.profile?.photo_url
                      ? _ctx.Logic.Auth.AuthUser?.profile?.photo_url
                      : '/images/avatar-1.png'
                  ,
                        customClass: '!h-[40px] !w-[40px] rounded-full border-[1px] border-grey-200',
                        onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.goToPath('/profile/user')), ["stop"]))
                      }, null, 8, ["photoUrl"]),
                      _createElementVNode("div", _hoisted_4, [
                        (_ctx.Logic.Common.currentAccountType() == 'client')
                          ? (_openBlock(), _createBlock(_component_app_header_text, {
                              key: 0,
                              class: "!text-sm text-left !line-clamp-1"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.Logic.Auth.AuthUser?.first_name) + " " + _toDisplayString(_ctx.Logic.Auth.AuthUser?.last_name), 1)
                              ]),
                              _: 1
                            }))
                          : (_openBlock(), _createBlock(_component_app_header_text, {
                              key: 1,
                              class: "!text-sm text-left !line-clamp-1"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.Logic.Auth.GetCurrentBusiness()?.business_name), 1)
                              ]),
                              _: 1
                            })),
                        (
                      _ctx.Logic.Common.currentAccountType() == 'client' ||
                      _ctx.Logic.Auth.BusinessUserCan('manage_profile')
                    )
                          ? (_openBlock(), _createBlock(_component_app_normal_text, {
                              key: 2,
                              class: "!text-primary-400 dark:!text-primary-100 text-left !text-[11px]",
                              onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (
                      _ctx.goToPath(
                        _ctx.Logic.Common.currentAccountType() == 'merchant'
                          ? '/merchant/profile/edit'
                          : '/profile/user'
                      )
                    ), ["stop"]))
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.Logic.Common.currentAccountType() == "merchant"
                        ? "Edit Profile"
                        : "View Profile"), 1)
                              ]),
                              _: 1
                            }))
                          : (_openBlock(), _createBlock(_component_app_normal_text, {
                              key: 3,
                              class: "!line-clamp-1"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.Logic.Auth.AuthUser?.full_name), 1)
                              ]),
                              _: 1
                            }))
                      ])
                    ]),
                    (
                  _ctx.Logic.Common.currentAccountType() == 'client' ||
                  _ctx.Logic.Auth.BusinessUserCan('manage_profile')
                )
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          class: "w-[32px] h-[32px] rounded-full bg-white flex items-center justify-center",
                          onClick: _cache[2] || (_cache[2] = ($event: any) => (
                  _ctx.goToPath(
                    _ctx.Logic.Common.currentAccountType() == 'merchant'
                      ? '/merchant/profile/edit'
                      : '/profile/edit'
                  )
                ))
                        }, [
                          _createVNode(_component_app_icon, {
                            name: 'edit-black',
                            customClass: 'h-[14px]'
                          })
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _createElementVNode("div", _hoisted_5, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sideBarItems, (item, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: index,
                        class: "px-1 py-[8px] flex flex-row justify-between items-center",
                        onClick: _withModifiers(($event: any) => (_ctx.goToPath(item.route_path)), ["stop"])
                      }, [
                        _createElementVNode("div", _hoisted_7, [
                          _createElementVNode("div", _hoisted_8, [
                            _createVNode(_component_app_icon, {
                              name: `sidebar/${item.icon.name}`,
                              customClass: item.icon.size
                            }, null, 8, ["name", "customClass"])
                          ]),
                          _createVNode(_component_app_normal_text, { class: "!text-left" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(item.name), 1)
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _createElementVNode("div", _hoisted_9, [
                          (item.counter > 0)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_10, [
                                _createVNode(_component_app_normal_text, { class: "!text-[10px] text-secondary-main" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(item.counter < 99 ? item.counter : "99+"), 1)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]))
                            : _createCommentVNode("", true),
                          _createVNode(_component_app_icon, {
                            name: 'chevron-right-grey',
                            customClass: 'h-[12px]'
                          })
                        ])
                      ], 8, _hoisted_6))
                    }), 128))
                  ]),
                  (_ctx.Logic.Common.currentAccountType() != 'merchant')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                        _createElementVNode("div", {
                          class: "w-full flex flex-col bg-primary-400 rounded-[10px] px-3 py-3 relative",
                          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.goToPath('/others/referral')))
                        }, [
                          _cache[9] || (_cache[9] = _createElementVNode("img", {
                            src: "/images/referral-coins.svg",
                            class: "h-[80%] absolute right-[5%] bottom-0"
                          }, null, -1)),
                          _createElementVNode("div", _hoisted_12, [
                            _createVNode(_component_app_normal_text, { class: "!text-left !text-white !text-[10px]" }, {
                              default: _withCtx(() => _cache[6] || (_cache[6] = [
                                _createTextVNode(" Refer Friends, Earn more ")
                              ])),
                              _: 1
                            }),
                            _createVNode(_component_app_normal_text, { class: "!font-semibold !text-white text-left !text-sm" }, {
                              default: _withCtx(() => _cache[7] || (_cache[7] = [
                                _createTextVNode(" Get 10% Bonus ")
                              ])),
                              _: 1
                            })
                          ]),
                          _createElementVNode("div", _hoisted_13, [
                            _createElementVNode("div", _hoisted_14, [
                              _createVNode(_component_app_normal_text, { class: "!text-white !text-[11px]" }, {
                                default: _withCtx(() => _cache[8] || (_cache[8] = [
                                  _createTextVNode(" Refer & Earn ")
                                ])),
                                _: 1
                              })
                            ])
                          ])
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_15, [
                  _createVNode(_component_app_normal_text, {
                    class: "!text-red-500 w-full text-center",
                    onClick: _cache[4] || (_cache[4] = _withModifiers(($event: any) => (_ctx.Logic.Auth.SignOut()), ["stop"]))
                  }, {
                    default: _withCtx(() => _cache[10] || (_cache[10] = [
                      _createTextVNode(" Logout ")
                    ])),
                    _: 1
                  })
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["onEnter", "onLeave"])
    ])
  ]))
}