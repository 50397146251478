<template>
  <auth-layout @go-back="Logic.Common.goBack()" :go-back="true">
    <template v-slot:header-text>Verify Phone Number</template>

    <app-form-wrapper
      ref="formComp"
      :parentRefs="parentRefs"
      class="w-full flex flex-col space-y-4 pt-4"
    >
      <div class="w-full flex flex-row">
        <app-normal-text class="!text-[#664D7E]">
          Verify Your Phone Number.
        </app-normal-text>
      </div>

      <app-text-field
        :placeholder="'Enter phone number'"
        v-model="phoneVerifyForm.phone"
        :hasTitle="true"
        type="tel"
        :padding="'py-3 px-3'"
        name="Phone Number"
        ref="phone"
        :updateValue="phoneVerifyForm.phone"
        :rules="[
          FormValidations.RequiredRule,
          FormValidations.customValidator(
            phoneVerifyForm.phone.length == 11,
            'Phone number must be valid'
          ),
        ]"
        :content-rule="{
          addAfterCount: 0,
          characterToAdd: ``,
          max: 11,
        }"
      >
        <template v-slot:title> Phone Number </template>
      </app-text-field>

      <div class="w-full flex-col flex pt-2">
        <AppButton
          :padding="'py-3'"
          :custom-class="'rounded-[40px]'"
          :bg-color="'bg-primary-main'"
          :text-color="'text-white'"
          @click.prevent="verifyPhone()"
          :loading="loaderSetup.loading"
        >
          Continue
        </AppButton>
      </div>
    </app-form-wrapper>
  </auth-layout>
</template>

<script lang="ts">
import { defineComponent, reactive, ref } from "vue";
import { Logic } from "../../../index";
import {
  AppTextField,
  AppButton,
  AppNormalText,
  AppIcon,
  AppFormWrapper,
} from "../../../components";
import { FormValidations } from "../../../composable";
import { loaderSetup } from "../../../composable/common";
import AuthLayout from "../../../components/AppLayouts/auth.vue";

export default defineComponent({
  components: {
    AppTextField,
    AppButton,
    AppNormalText,
    AppIcon,
    AppFormWrapper,
    AuthLayout,
  },
  name: "VerifyPhonePage",
  setup() {
    const phoneVerifyForm = reactive({
      phone: "",
      code: "",
    });

    const showAddPhoneOTP = () => {
      Logic.Common.showModal({
        show: true,
        title: "Enter OTP",
        type: "verify_phone",
        action: () => {
          if (Logic.Common.currentAccountType() == "merchant") {
            Logic.Common.GoToRoute("/onboarding");
          } else {
            Logic.Common.GoToRoute("/auth/personalize");
          }
        },
      });
    };

    const verifyPhone = () => {
      Logic.Auth.SaveAuthPhoneNumberForm = {
        user_uuid: Logic.Auth.AuthUser?.uuid || "",
        phone_number: Logic.Form.getPhoneNumber("+234", phoneVerifyForm.phone),
      };
      localStorage.setItem("acc_phone", phoneVerifyForm.phone);
      Logic.Auth.SaveAuthPhoneNumber()?.then((data) => {
        if (data) {
          showAddPhoneOTP();
        }
      });
    };

    return {
      phoneVerifyForm,
      FormValidations,
      Logic,
      loaderSetup,
      verifyPhone,
    };
  },
  data() {
    return {
      parentRefs: null,
    };
  },
  mounted() {
    const parentRefs: any = this.$refs;
    this.parentRefs = parentRefs;
  },
});
</script>
