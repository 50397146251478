import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, createElementVNode as _createElementVNode, withModifiers as _withModifiers, Teleport as _Teleport } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-error-main pl-[2px]"
}
const _hoisted_2 = ["id", "tabindex"]
const _hoisted_3 = {
  key: 0,
  class: "w-[26px]"
}
const _hoisted_4 = ["value", "placeholder"]
const _hoisted_5 = { class: "px-2 py-[2px] border-[1px] border-grey-200 bg-white dark:bg-black text-center flex flex-row space-x-[2px] items-center rounded-[6px]" }
const _hoisted_6 = {
  class: "w-full mdlg:!w-[60%] md:!w-[80%] grid grid-cols-12 h-full relative",
  id: "modalContent"
}
const _hoisted_7 = { class: "col-span-12 mdlg:!col-span-6 md:!col-span-8 relative h-full flex flex-col items-end justify-end mdlg:!items-center mdlg:!justify-center md:!justify-center md:!items-center" }
const _hoisted_8 = {
  key: 0,
  class: "w-full pt-1 sticky top-[18px] bg-white dark:bg-black dark:black"
}
const _hoisted_9 = {
  key: 1,
  class: "w-full flex flex-col space-y-1"
}
const _hoisted_10 = ["onClick"]
const _hoisted_11 = {
  key: 3,
  class: "h-full w-full flex flex-row items-center justify-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_image_loader = _resolveComponent("app-image-loader")!
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_text_field = _resolveComponent("app-text-field")!
  const _component_app_radio = _resolveComponent("app-radio")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(`flex flex-col space-y-2 ${_ctx.defaultSize} `)
    }, [
      (_ctx.hasTitle)
        ? (_openBlock(), _createBlock(_component_app_normal_text, { key: 0 }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "title", {}, undefined, true),
              (_ctx.required)
                ? (_openBlock(), _createElementBlock("span", _hoisted_1, "*"))
                : _createCommentVNode("", true)
            ]),
            _: 3
          }))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass(`flex relative flex-row items-center space-x-1 justify-between cursor-pointer w-full rounded-lg ${_ctx.customClass} bg-grey-50 !border-grey-50 border-[1px] focus:border-primary-400 ${_ctx.paddings}`),
        id: 'container' + _ctx.tabIndex,
        onFocus: _cache[1] || (_cache[1] = ($event: any) => {
        _ctx.showOption = true;
        _ctx.isFocused = true;
        _ctx.ShowSelectModal = true;
      }),
        onBlur: _cache[2] || (_cache[2] = ($event: any) => {
        _ctx.isFocused = false;
        _ctx.showOption = false;
      }),
        tabindex: _ctx.tabIndex,
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.ShowSelectModal = true))
      }, [
        (_ctx.getSelectedItemImage(_ctx.selectedKey))
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_app_image_loader, {
                "photo-url": _ctx.getSelectedItemImage(_ctx.selectedKey),
                customClass: `h-[26px] w-[26px] rounded-full `
              }, null, 8, ["photo-url"])
            ]))
          : _createCommentVNode("", true),
        (_ctx.firstIcon)
          ? (_openBlock(), _createBlock(_component_app_icon, {
              key: 1,
              name: `${_ctx.firstIcon}`,
              "custom-class": " h-4 w-4 cursor-pointer"
            }, null, 8, ["name"]))
          : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "inner-prefix", {}, undefined, true),
        (!_ctx.isMultiple)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
              (!_ctx.useHtml)
                ? (_openBlock(), _createElementBlock("input", {
                    key: 0,
                    ref: "select",
                    value: _ctx.withKey ? _ctx.valueData : _ctx.textValue,
                    placeholder: _ctx.placeholder,
                    disabled: "",
                    class: _normalizeClass(`text-black flex-grow bg-transparent focus input w-full focus:outline-none placeholder-grey-400 cursor-pointer  ${_ctx.inputStyle}`)
                  }, null, 10, _hoisted_4))
                : (_openBlock(), _createBlock(_component_app_normal_text, {
                    key: 1,
                    innerHTML: _ctx.withKey ? _ctx.valueData : _ctx.textValue,
                    class: _normalizeClass(`whitespace-nowrap xs:!text-[11px] !text-[12px] text-black  ${_ctx.inputStyle}`)
                  }, null, 8, ["innerHTML", "class"]))
            ], 64))
          : (_openBlock(), _createElementBlock("div", {
              key: 3,
              class: _normalizeClass(`w-full flex flex-row  flex-wrap items-center ${_ctx.inputStyle} cursor-pointer`)
            }, [
              (_ctx.selectedItems.length == 0)
                ? (_openBlock(), _createBlock(_component_app_normal_text, {
                    key: 0,
                    class: "!text-grey-400 text-left"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.placeholder), 1)
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectedItems, (item, index) => {
                return (_openBlock(), _createElementBlock("span", {
                  key: index,
                  class: "pr-1 pb-1"
                }, [
                  _createElementVNode("span", _hoisted_5, [
                    _createVNode(_component_app_normal_text, { class: "!text-[10px]" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.getSelectedOption(item)), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ])
                ]))
              }), 128))
            ], 2)),
        _createVNode(_component_app_icon, {
          onClick: _cache[0] || (_cache[0] = ($event: any) => {
          _ctx.showOption = true;
          _ctx.isFocused = true;
        }),
          name: `show-more${_ctx.theme == 'dark' ? '-white' : ''}`,
          "custom-class": `${_ctx.showMoreSize} cursor-pointer`
        }, null, 8, ["name", "custom-class"]),
        _renderSlot(_ctx.$slots, "inner-suffix", {}, undefined, true)
      ], 42, _hoisted_2)
    ], 2),
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      (_ctx.ShowSelectModal)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "fixed top-0 left-0 z-[99999999999999999] bg-black !bg-opacity-30 dark:!bg-opacity-50 flex w-full h-full flex-row items-end justify-end mdlg:!items-center mdlg:!justify-center md:!justify-center md:!items-center",
            onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.ShowSelectModal = false))
          }, [
            _createElementVNode("div", _hoisted_6, [
              _cache[8] || (_cache[8] = _createElementVNode("div", { class: "hidden col-span-3 md:!col-span-2 mdlg:!col-span-3 mdlg:!flex md:!flex flex-col sticky top-0" }, null, -1)),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", {
                  onClick: _withModifiers(($event: any) => (true), ["stop"]),
                  class: "rounded-t-2xl mdlg:!rounded-[10px] md:!rounded-[10px] flex flex-col space-y-2 bg-white dark:!bg-black dark:border-[1px] dark:border-gray-100 w-full absolute mdlg:!relative md:!relative overflow-y-auto h-[50%] mdlg:!max-h-[500px] md:!max-h-[400px] xs:!bottom-0 sm:!bottom-0 left-0 pb-3 px-3 mdlg:!pb-4 md:!pb-4 lg:!text-sm mdlg:!text-[12px] text-xs"
                }, [
                  _cache[7] || (_cache[7] = _createElementVNode("div", { class: "flex items-center justify-center sticky top-0 bg-white dark:!bg-black w-full pt-3" }, [
                    _createElementVNode("span", { class: "bg-gray-500 dark:bg-gray-200 rounded-full w-[30px] h-[4px]" })
                  ], -1)),
                  (_ctx.autoComplete)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                        _createVNode(_component_app_text_field, {
                          placeholder: "Search",
                          modelValue: _ctx.searchValue,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.searchValue) = $event)),
                          "update-value": _ctx.searchValue
                        }, null, 8, ["modelValue", "update-value"])
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.isMultiple)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectOptions, (option, index) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: index,
                            class: "flex w-full flex-row space-x-2 items-center cursor-pointer py-3 border-b-[1px] border-gray-300 dark:border-grey-100",
                            onClick: _withModifiers(($event: any) => (_ctx.selectValue(option)), ["stop"])
                          }, [
                            _createVNode(_component_app_icon, {
                              name: `${_ctx.itemIsSelected(option.key) ? 'checked' : 'not-checked'}`,
                              customClass: `h-[15px]`
                            }, null, 8, ["name"]),
                            _createVNode(_component_app_normal_text, { customClass: '!font-semibold' }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(option.alt_value ? option.alt_value : option.value), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ], 8, _hoisted_10))
                        }), 128))
                      ]))
                    : (_openBlock(), _createBlock(_component_app_radio, {
                        key: 2,
                        options: _ctx.selectOptions,
                        modelValue: _ctx.selectedKey,
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.selectedKey) = $event)),
                        onClick: _withModifiers(($event: any) => (true), ["stop"])
                      }, null, 8, ["options", "modelValue"])),
                  (_ctx.selectOptions.length == 0 && _ctx.hasSearch)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                        _createVNode(_component_app_normal_text, {
                          customClass: 'text-center',
                          color: 'text-gray-400'
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.searchIsLoading ? "Searching..." : _ctx.searchMessage), 1)
                          ]),
                          _: 1
                        })
                      ]))
                    : _createCommentVNode("", true)
                ])
              ]),
              _cache[9] || (_cache[9] = _createElementVNode("div", { class: "hidden col-span-3 md:!col-span-2 mdlg:!col-span-3 mdlg:!flex md:!flex flex-col sticky top-0" }, null, -1))
            ])
          ]))
        : _createCommentVNode("", true)
    ]))
  ], 64))
}