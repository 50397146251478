import { withModifiers as _withModifiers, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, Transition as _Transition, Teleport as _Teleport } from "vue"

const _hoisted_1 = {
  class: "relative w-full mdlg:!w-[60%] md:!w-[80%] grid grid-cols-12 h-full z-50",
  id: "modalContent"
}
const _hoisted_2 = { class: "col-span-12 mdlg:!col-span-6 md:!col-span-8 flex flex-row justify-center items-end mdlg:!items-center mdlg:!justify-center md:!justify-center md:!items-center h-full overflow-y-hidden rounded-t-[20px] mdlg:!rounded-[10px] md:!rounded-[10px] absolute w-full top-0 left-0 mdlg:!relative md:!relative" }
const _hoisted_3 = {
  class: /*@__PURE__*/_normalizeClass(`w-full flex flex-row items-center justify-between z-50 pt-4 pb-2 px-4 sticky  top-0  bg-white dark:!bg-black`)
}
const _hoisted_4 = { class: "flex flex-row items-center space-x-3" }
const _hoisted_5 = { class: "px-4 w-full flex flex-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_header_text = _resolveComponent("app-header-text")!
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_shoplistType = _resolveComponent("shoplistType")!
  const _component_promotionType = _resolveComponent("promotionType")!
  const _component_addCard = _resolveComponent("addCard")!
  const _component_sendGiftCard = _resolveComponent("sendGiftCard")!
  const _component_cardTermsAndCondition = _resolveComponent("cardTermsAndCondition")!
  const _component_filterExpenses = _resolveComponent("filterExpenses")!
  const _component_editReceipt = _resolveComponent("editReceipt")!
  const _component_policyContent = _resolveComponent("policyContent")!
  const _component_paymentProvider = _resolveComponent("paymentProvider")!
  const _component_info = _resolveComponent("info")!
  const _component_addBankAccount = _resolveComponent("addBankAccount")!
  const _component_enterTransactionPin = _resolveComponent("enterTransactionPin")!
  const _component_qrCode = _resolveComponent("qrCode")!
  const _component_subscriptionPlan = _resolveComponent("subscriptionPlan")!
  const _component_topupCashback = _resolveComponent("topupCashback")!
  const _component_changePassword = _resolveComponent("changePassword")!
  const _component_payment = _resolveComponent("payment")!
  const _component_requestFeedback = _resolveComponent("requestFeedback")!
  const _component_askForPermission = _resolveComponent("askForPermission")!
  const _component_newBadge = _resolveComponent("newBadge")!
  const _component_productRewardSetting = _resolveComponent("productRewardSetting")!
  const _component_addDeliveryArea = _resolveComponent("addDeliveryArea")!
  const _component_addProducts = _resolveComponent("addProducts")!
  const _component_reportReceipt = _resolveComponent("reportReceipt")!
  const _component_addShoplist = _resolveComponent("addShoplist")!
  const _component_sendInvitation = _resolveComponent("sendInvitation")!
  const _component_deliveryInfo = _resolveComponent("deliveryInfo")!
  const _component_businessInfo = _resolveComponent("businessInfo")!
  const _component_orderInfo = _resolveComponent("orderInfo")!
  const _component_joinBusiness = _resolveComponent("joinBusiness")!
  const _component_verifyEmailWithOTP = _resolveComponent("verifyEmailWithOTP")!
  const _component_badgeInfo = _resolveComponent("badgeInfo")!
  const _component_storeAdminUpdate = _resolveComponent("storeAdminUpdate")!
  const _component_chooseInstagramVideo = _resolveComponent("chooseInstagramVideo")!
  const _component_addProductToShoplists = _resolveComponent("addProductToShoplists")!
  const _component_verifyPhone = _resolveComponent("verifyPhone")!
  const _component_verifyEmail = _resolveComponent("verifyEmail")!

  return (_openBlock(), _createBlock(_Teleport, { to: "#modal" }, [
    _createVNode(_Transition, {
      name: "fade",
      appear: ""
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(`fixed top-0 w-screen h-screen lg:text-sm innerModal items-end justify-end mdlg:!items-center mdlg:!justify-center md:!items-center md:!justify-center mdlg:text-[12px] text-xs bg-black !bg-opacity-30 dark:!bg-opacity-50 flex flex-col overflow-y-auto `),
          style: {"z-index":"999999999999999"},
          id: "innerModal",
          onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (
          $props.setup.preventClose
            ? $props.setup?.closeAction()
            : $setup.Logic.Common.showModal({ show: false })
        ), ["stop"]))
        }, [
          _createElementVNode("div", {
            onClick: _withModifiers(($event: any) => (null), ["stop"]),
            id: "confetti-canvas",
            class: "absolute z-10 h-[100%] w-full top-0 left-0"
          }),
          _createElementVNode("div", _hoisted_1, [
            _cache[3] || (_cache[3] = _createElementVNode("div", { class: "hidden col-span-3 mdlg:!col-span-3 md:!col-span-2 mdlg:!flex md:!flex flex-col sticky top-0" }, null, -1)),
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", {
                onClick: _withModifiers(($event: any) => (null), ["stop"]),
                class: _normalizeClass(`min-h-[100px] w-full  flex h-auto  flex-col relative bg-white dark:!bg-black  dark:border-[1px] dark:border-gray-100 max-h-[94%] mdlg:!max-h-[600px] md:!max-h-[600px] rounded-t-[20px] overflow-y-auto pb-[calc(env(safe-area-inset-bottom))] mdlg:!pb-[calc(16px)] md:!pb-[calc(16px)] mdlg:!rounded-[10px] md:!rounded-[10px]`)
              }, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_app_header_text, { customClass: '!text-base' }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString($props.setup.title), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  (!$props.setup.preventClose)
                    ? (_openBlock(), _createBlock(_component_app_icon, {
                        key: 0,
                        name: `close-modal${
                    $setup.Logic.Common.currentAppTheme() == 'light' ? '' : '-white'
                  }`,
                        customClass: `h-[24px] !cursor-pointer`,
                        onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (
                    $props.setup.preventClose
                      ? $props.setup?.closeAction()
                      : $setup.Logic.Common.showModal({ show: false })
                  ), ["stop"]))
                      }, null, 8, ["name"]))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_5, [
                  ($props.setup?.type == 'shoplist_type')
                    ? (_openBlock(), _createBlock(_component_shoplistType, {
                        key: 0,
                        action: $props.setup?.action
                      }, null, 8, ["action"]))
                    : _createCommentVNode("", true),
                  ($props.setup?.type == 'promotion_type')
                    ? (_openBlock(), _createBlock(_component_promotionType, {
                        key: 1,
                        action: $props.setup?.action,
                        data: $props.setup.extraData
                      }, null, 8, ["action", "data"]))
                    : _createCommentVNode("", true),
                  ($props.setup.type == 'add_card')
                    ? (_openBlock(), _createBlock(_component_addCard, {
                        key: 2,
                        ref: "add_card"
                      }, null, 512))
                    : _createCommentVNode("", true),
                  ($props.setup.type == 'send_gift_card')
                    ? (_openBlock(), _createBlock(_component_sendGiftCard, {
                        key: 3,
                        ref: "send_gift_card",
                        action: $props.setup.action
                      }, null, 8, ["action"]))
                    : _createCommentVNode("", true),
                  ($props.setup.type == 'card_terms_and_condition')
                    ? (_openBlock(), _createBlock(_component_cardTermsAndCondition, {
                        key: 4,
                        action: $props.setup.action
                      }, null, 8, ["action"]))
                    : _createCommentVNode("", true),
                  ($props.setup.type == 'filter_expenses')
                    ? (_openBlock(), _createBlock(_component_filterExpenses, {
                        key: 5,
                        ref: "filter_expenses",
                        action: $props.setup.action
                      }, null, 8, ["action"]))
                    : _createCommentVNode("", true),
                  ($props.setup.type == 'edit_receipt')
                    ? (_openBlock(), _createBlock(_component_editReceipt, {
                        key: 6,
                        ref: "edit_receipt",
                        data: $props.setup.extraData,
                        action: $props.setup.action
                      }, null, 8, ["data", "action"]))
                    : _createCommentVNode("", true),
                  ($props.setup.type == 'policy_content')
                    ? (_openBlock(), _createBlock(_component_policyContent, {
                        key: 7,
                        data: $props.setup.extraData
                      }, null, 8, ["data"]))
                    : _createCommentVNode("", true),
                  ($props.setup.type == 'payment_provider')
                    ? (_openBlock(), _createBlock(_component_paymentProvider, {
                        key: 8,
                        data: $props.setup.extraData,
                        action: $props.setup.action
                      }, null, 8, ["data", "action"]))
                    : _createCommentVNode("", true),
                  ($props.setup.type == 'info')
                    ? (_openBlock(), _createBlock(_component_info, {
                        key: 9,
                        ref: "info",
                        data: $props.setup.extraData
                      }, null, 8, ["data"]))
                    : _createCommentVNode("", true),
                  ($props.setup.type == 'add_bank_account')
                    ? (_openBlock(), _createBlock(_component_addBankAccount, {
                        key: 10,
                        ref: "add_bank_account",
                        action: $props.setup.action
                      }, null, 8, ["action"]))
                    : _createCommentVNode("", true),
                  ($props.setup.type == 'enter_transaction_pin')
                    ? (_openBlock(), _createBlock(_component_enterTransactionPin, {
                        key: 11,
                        action: $props.setup.action,
                        data: $props.setup.extraData
                      }, null, 8, ["action", "data"]))
                    : _createCommentVNode("", true),
                  ($props.setup.type == 'qr_code')
                    ? (_openBlock(), _createBlock(_component_qrCode, {
                        key: 12,
                        data: $props.setup.extraData
                      }, null, 8, ["data"]))
                    : _createCommentVNode("", true),
                  ($props.setup.type == 'subscription_plan')
                    ? (_openBlock(), _createBlock(_component_subscriptionPlan, {
                        key: 13,
                        data: $props.setup.extraData,
                        action: $props.setup.action
                      }, null, 8, ["data", "action"]))
                    : _createCommentVNode("", true),
                  ($props.setup.type == 'topup_cashback')
                    ? (_openBlock(), _createBlock(_component_topupCashback, {
                        key: 14,
                        data: $props.setup.extraData,
                        action: $props.setup.action
                      }, null, 8, ["data", "action"]))
                    : _createCommentVNode("", true),
                  ($props.setup.type == 'change_password')
                    ? (_openBlock(), _createBlock(_component_changePassword, {
                        key: 15,
                        ref: "change_password",
                        action: $props.setup.action
                      }, null, 8, ["action"]))
                    : _createCommentVNode("", true),
                  ($props.setup.type == 'payment')
                    ? (_openBlock(), _createBlock(_component_payment, {
                        key: 16,
                        data: $props.setup.extraData,
                        action: $props.setup.action
                      }, null, 8, ["data", "action"]))
                    : _createCommentVNode("", true),
                  ($props.setup.type == 'request_feedback')
                    ? (_openBlock(), _createBlock(_component_requestFeedback, {
                        key: 17,
                        data: $props.setup.extraData,
                        action: $props.setup.action
                      }, null, 8, ["data", "action"]))
                    : _createCommentVNode("", true),
                  ($props.setup.type == 'ask_for_permission')
                    ? (_openBlock(), _createBlock(_component_askForPermission, {
                        key: 18,
                        data: $props.setup.extraData,
                        action: $props.setup.action
                      }, null, 8, ["data", "action"]))
                    : _createCommentVNode("", true),
                  ($props.setup.type == 'new_badge')
                    ? (_openBlock(), _createBlock(_component_newBadge, {
                        key: 19,
                        data: $props.setup.extraData,
                        action: $props.setup.action
                      }, null, 8, ["data", "action"]))
                    : _createCommentVNode("", true),
                  ($props.setup.type == 'product_reward_setting')
                    ? (_openBlock(), _createBlock(_component_productRewardSetting, {
                        key: 20,
                        data: $props.setup.extraData,
                        action: $props.setup.action
                      }, null, 8, ["data", "action"]))
                    : _createCommentVNode("", true),
                  ($props.setup.type == 'add_delivery_area')
                    ? (_openBlock(), _createBlock(_component_addDeliveryArea, {
                        key: 21,
                        data: $props.setup.extraData,
                        action: $props.setup.action
                      }, null, 8, ["data", "action"]))
                    : _createCommentVNode("", true),
                  ($props.setup.type == 'add_products')
                    ? (_openBlock(), _createBlock(_component_addProducts, {
                        key: 22,
                        data: $props.setup.extraData,
                        action: $props.setup.action
                      }, null, 8, ["data", "action"]))
                    : _createCommentVNode("", true),
                  ($props.setup.type == 'report_receipt')
                    ? (_openBlock(), _createBlock(_component_reportReceipt, {
                        key: 23,
                        ref: "report_receipt",
                        action: $props.setup.action
                      }, null, 8, ["action"]))
                    : _createCommentVNode("", true),
                  ($props.setup.type == 'add_shoplist')
                    ? (_openBlock(), _createBlock(_component_addShoplist, {
                        key: 24,
                        data: $props.setup.extraData,
                        action: $props.setup.action
                      }, null, 8, ["data", "action"]))
                    : _createCommentVNode("", true),
                  ($props.setup.type == 'send_invitation')
                    ? (_openBlock(), _createBlock(_component_sendInvitation, {
                        key: 25,
                        data: $props.setup.extraData,
                        action: $props.setup.action
                      }, null, 8, ["data", "action"]))
                    : _createCommentVNode("", true),
                  ($props.setup.type == 'delivery_info')
                    ? (_openBlock(), _createBlock(_component_deliveryInfo, {
                        key: 26,
                        data: $props.setup.extraData,
                        action: $props.setup.action
                      }, null, 8, ["data", "action"]))
                    : _createCommentVNode("", true),
                  ($props.setup.type == 'business_info')
                    ? (_openBlock(), _createBlock(_component_businessInfo, {
                        key: 27,
                        data: $props.setup.extraData,
                        action: $props.setup.action
                      }, null, 8, ["data", "action"]))
                    : _createCommentVNode("", true),
                  ($props.setup.type == 'order_info')
                    ? (_openBlock(), _createBlock(_component_orderInfo, {
                        key: 28,
                        data: $props.setup.extraData,
                        action: $props.setup.action
                      }, null, 8, ["data", "action"]))
                    : _createCommentVNode("", true),
                  ($props.setup.type == 'join_business')
                    ? (_openBlock(), _createBlock(_component_joinBusiness, {
                        key: 29,
                        data: $props.setup.extraData,
                        action: $props.setup.action
                      }, null, 8, ["data", "action"]))
                    : _createCommentVNode("", true),
                  ($props.setup.type == 'verify_email_with_otp')
                    ? (_openBlock(), _createBlock(_component_verifyEmailWithOTP, {
                        key: 30,
                        action: $props.setup.action
                      }, null, 8, ["action"]))
                    : _createCommentVNode("", true),
                  ($props.setup.type == 'badge_info')
                    ? (_openBlock(), _createBlock(_component_badgeInfo, {
                        key: 31,
                        data: $props.setup.extraData,
                        action: $props.setup.action
                      }, null, 8, ["data", "action"]))
                    : _createCommentVNode("", true),
                  ($props.setup.type == 'store_admin_update')
                    ? (_openBlock(), _createBlock(_component_storeAdminUpdate, {
                        key: 32,
                        data: $props.setup.extraData,
                        action: $props.setup.action
                      }, null, 8, ["data", "action"]))
                    : _createCommentVNode("", true),
                  ($props.setup.type == 'choose_instagram_video')
                    ? (_openBlock(), _createBlock(_component_chooseInstagramVideo, {
                        key: 33,
                        data: $props.setup.extraData,
                        action: $props.setup.action
                      }, null, 8, ["data", "action"]))
                    : _createCommentVNode("", true),
                  ($props.setup.type == 'add_product_to_shoplists')
                    ? (_openBlock(), _createBlock(_component_addProductToShoplists, {
                        key: 34,
                        data: $props.setup.extraData,
                        action: $props.setup.action
                      }, null, 8, ["data", "action"]))
                    : _createCommentVNode("", true),
                  ($props.setup.type == 'verify_phone')
                    ? (_openBlock(), _createBlock(_component_verifyPhone, {
                        key: 35,
                        action: $props.setup.action
                      }, null, 8, ["action"]))
                    : _createCommentVNode("", true),
                  ($props.setup.type == 'verify_email')
                    ? (_openBlock(), _createBlock(_component_verifyEmail, { key: 36 }))
                    : _createCommentVNode("", true)
                ]),
                _cache[2] || (_cache[2] = _createElementVNode("div", {
                  class: _normalizeClass(`'h-[20px]`)
                }, null, -1))
              ])
            ]),
            _cache[4] || (_cache[4] = _createElementVNode("div", { class: "hidden col-span-3 mdlg:!col-span-3 md:!col-span-2 mdlg:!flex flex-col sticky top-0" }, null, -1))
          ])
        ])
      ]),
      _: 1
    }),
    _cache[5] || (_cache[5] = _createElementVNode("div", {
      class: _normalizeClass(`w-full fixed bottom-0 left-0  z-[99999999999999999] bg-white`),
      style: {"height":"calc(env(safe-area-inset-bottom))"}
    }, null, -1))
  ]))
}