<template>
  <app-wrapper>
    <subpage-layout>
      <!-- Add to basket -->
      <template v-slot:extra-topbar>
        <div class="flex flex-col">
          <app-button
            :class="`!bg-success-50 !text-success-main dark:!bg-success-500 dark:!text-white`"
            v-if="!fromPreview"
            @click="handleAddToCart"
            id="add-to-cart-button"
          >
            {{ "Add to Shoplist" }}
          </app-button>
        </div>
      </template>

      <div class="w-full flex flex-col space-y-3">
        <div class="w-full flex flex-row px-4 justify-between items-center">
          <div class="flex flex-col space-y-1 justify-start">
            <app-header-text class="!text-base">
              {{ SingleShoplistProduct?.name }}
            </app-header-text>
            <div class="w-full flex flex-row space-x-2 items-center">
              <app-normal-text> 1.4k Shoppers </app-normal-text>
              <span class="h-[3px] w-[3px] rounded-full bg-grey-900 dark:bg-grey-50">
              </span>
              <div class="flex flex-row items-center space-x-1">
                <app-normal-text class="!text-success-500">
                  Earn
                  {{ numberToAbbrev(totalReward) }} Pts
                  {{
                    totalDiscount
                      ? `and (${Logic.Common.convertToMoney(
                          totalDiscount,
                          true,
                          "ngn"
                        )} off)`
                      : ""
                  }}
                </app-normal-text>
              </div>
            </div>
          </div>
        </div>

        <!-- Description -->
        <div class="w-full flex flex-col space-y-2 px-4">
          <app-normal-text class="!text-left !text-[#5A5A5A] dark:!text-grey-200">
            {{ SingleShoplistProduct?.description }}
          </app-normal-text>
        </div>

        <!-- Shoplist items -->
        <div class="w-full flex flex-col space-y-1 px-4">
          <div
            v-for="(item, index) in productLists"
            :key="index"
            class="w-full flex flex-col space-y-2 border-b-[1px] border-grey-100 py-3"
          >
            <div class="w-full flex flex-col">
              <app-image-loader
                :photoUrl="item.base_image"
                class="rounded-[10px] h-[300px] w-full"
              />
            </div>
            <div class="w-full flex flex-row space-x-2">
              <div class="flex flex-col items-end w-full justify-between space-y-1">
                <div class="w-full flex flex-col space-y-[2px]">
                  <app-header-text class="!text-sm text-left">
                    {{ item.title }}
                  </app-header-text>
                  <div class="w-full flex flex-row items-center space-x-2">
                    <!-- <app-normal-text class="!text-[11px] !text-grey-900">
                        {{ item.weight }}
                      </app-normal-text>
                      -->

                    <app-badge
                      :color="item.reward_type == 'cashback' ? 'purple' : 'purple'"
                      class="capitalize !py-[1px] !px-[6px] !text-[9px] !rounded-[14px]"
                    >
                      Earn

                      {{ numberToAbbrev(item.reward_point) }} Pts
                    </app-badge>

                    <span
                      class="h-[3px] w-[3px] rounded-full bg-grey-900 dark:bg-grey-200"
                    >
                    </span>

                    <app-normal-text
                      class="!text-[11px] !text-grey-900 dark:!text-grey-200"
                    >
                      {{ item.main_category }}
                    </app-normal-text>
                  </div>
                </div>

                <div class="w-full flex flex-row space-x-2 justify-between items-end">
                  <app-header-text class="!text-sm">
                    {{ Logic.Common.convertToMoney(item.price, true, "ngn") }}
                  </app-header-text>

                  <div class="flex flex-col space-y-1 relative pt-2">
                    <div
                      :class="`flex flex-row items-center rounded-[13.83px] ${
                        item.max_qty == 0 ? 'opacity-50' : ''
                      }`"
                    >
                      <div
                        @click="item.qty > 1 ? item.qty-- : null"
                        class="w-[28px] h-[28px] rounded-l-[13.83px] bg-[#F5EDFC] dark:bg-gray-800 flex items-center justify-center cursor-pointer"
                      >
                        <app-normal-text
                          class="!text-[13px] !text-primary-400 dark:!text-gray-100"
                        >
                          -
                        </app-normal-text>
                      </div>
                      <div
                        class="w-[28px] h-[28px] bg-[#F7F7F7] dark:bg-grey-800 flex items-center justify-center"
                      >
                        <app-normal-text
                          class="!text-grey-900 dark:!text-grey-50 !font-semibold"
                        >
                          {{ item.max_qty > 0 ? item.qty : "0" }}
                        </app-normal-text>
                      </div>
                      <div
                        @click="item.qty < item.max_qty ? item.qty++ : null"
                        class="w-[28px] h-[28px] rounded-r-[13.83px] bg-[#F5EDFC] dark:bg-gray-800 flex items-center justify-center cursor-pointer"
                      >
                        <app-normal-text
                          class="!text-[13px] !text-primary-400 dark:!text-gray-100"
                        >
                          +
                        </app-normal-text>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Similar products -->
        <div class="w-full flex flex-col space-y-3 px-4">
          <app-header-text class="!text-base !font-semibold">
            Similar products
          </app-header-text>
          <div class="w-full grid grid-cols-2 gap-2">
            <app-shoplist-explore
              v-for="(item, index) in similarProducts"
              :key="index"
              :item="item"
              @click="handleShoplsistClick(item)"
            />
          </div>
        </div>
        <!-- Spacer -->
        <div class="h-[120px]"></div>
      </div>

      <!-- Bottom section -->
      <fixed-container customClass="!px-0">
        <div
          class="w-full flex flex-row items-center justify-between border-t-[1px] px-4 pt-4 border-grey-50 bg-white dark:!bg-black"
        >
          <div class="flex flex-col space-y-[3px]">
            <app-header-text class="!text-base">
              {{ Logic.Common.convertToMoney(totalCost - totalDiscount, true, "ngn") }}
            </app-header-text>
            <div class="flex flex-row space-x-1 justify-end flex-grow items-center">
              <app-icon :name="'cashback-icon'" :customClass="'h-[15px]'" />
              <app-normal-text class="!text-[#898989] dark:!text-grey-200"
                >Earn {{ numberToAbbrev(totalReward) }} Points
              </app-normal-text>
            </div>
          </div>
          <div
            class="py-3 px-8 bg-primary-main flex justify-center items-center rounded-[999px] cursor-pointer"
            @click="buyNow"
            v-if="!fromPreview"
          >
            <app-normal-text class="!font-semibold !text-white">
              Buy Now
            </app-normal-text>
          </div>
        </div>
      </fixed-container>
    </subpage-layout>
  </app-wrapper>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, reactive, ref, watch } from "vue";
import { onIonViewWillEnter } from "@ionic/vue";
import { Logic } from "@shpt/logic";
import {
  AppHeaderText,
  AppNormalText,
  AppImageLoader,
  AppButton,
  AppBadge,
  AppIcon,
  AppShoplistExplore,
} from "@shpt/ui-components";
import {
  getMediaBoxForProduct,
  getUserPlanCashbackCommissionForProduct,
} from "@shpt/ui-components/src/composable";
import { ShoplistProduct } from "@shpt/ui-components/src/gql/graphql";
import AppWrapper from "@/components/AppWrapper.vue";
import FixedContainer from "@shpt/ui-components/src/components/AppCommon/FixedContainer.vue";
import { numberToAbbrev } from "@shpt/ui-components/src/composable";
import { MediaBox } from "@shpt/ui-components/src/types";
import { scrollToTop } from "@shpt/ui-components/src/composable";

type ProductList = {
  title: string;
  weight: string;
  main_category: string;
  price: number;
  base_image: string;
  qty: number;
  id: string;
  reward_type: string;
  data: ShoplistProduct;
  max_qty: number;
  discount: number;
  reward_percentage: number;
  reward_point: number;
  cashback: number;
  available_in_area: boolean;
  business: {
    name: string;
    id: string;
  };
  delivery: {
    id: string;
    cost: string;
  };
};

export default defineComponent({
  components: {
    AppHeaderText,
    AppNormalText,
    AppImageLoader,
    AppButton,
    AppBadge,
    AppWrapper,
    AppIcon,
    FixedContainer,
    AppShoplistExplore,
  },
  name: "ProductInfoPage",
  layout: "SubPage",
  middlewares: {
    fetchRules: [
      {
        domain: "Shoplist",
        property: "SingleShoplistProduct",
        method: "GetShoplistProduct",
        ignoreProperty: true,
        params: [],
        useRouteId: true,
        requireAuth: true,
      },
      {
        domain: "Shoplist",
        property: "ManySimilarProducts",
        method: "GetSimilarProducts",
        ignoreProperty: true,
        params: [],
        useRouteId: true,
        requireAuth: true,
      },
    ],
    tracking_data: {
      lable: "Product Info Page",
      stage_type: "neutral",
      end_stage: "",
    },
  },
  setup() {
    const SingleShoplistProduct = ref(Logic.Shoplist.SingleShoplistProduct);
    const ManySimilarProducts = ref(Logic.Shoplist.ManySimilarProducts);

    const similarProducts = reactive<
      {
        data: MediaBox;
        id: string;
        type: string;
        colors: {
          bg: string;
          text: string;
        };
      }[]
    >([]);

    const fromPreview = ref(false);

    const getRewardPercentage = (item: ProductList) => {
      const rewards = item.data.rewards;
      let currentReward = rewards.find(
        (reward) => reward.quantity == item.qty.toString()
      );

      if (!currentReward) {
        currentReward = rewards.find((reward) => reward.quantity == "-1");
      }

      let rewardPercentage = 0;

      if (currentReward) {
        rewardPercentage = parseFloat(currentReward.percentage);
      }

      let percentageToRemove = 0;

      const userPlanCashbackCommission = getUserPlanCashbackCommissionForProduct(
        item.data
      );

      if (userPlanCashbackCommission.cashbackCommission > 0) {
        const percentageCommissionToRemove =
          userPlanCashbackCommission.cashbackCommission / 100;

        percentageToRemove = rewardPercentage * percentageCommissionToRemove;
      }

      rewardPercentage = rewardPercentage - percentageToRemove;

      return rewardPercentage;
    };

    const totalReward = computed(() => {
      let total = 0;

      productLists.forEach((item) => {
        const rewards = item.data.rewards;
        const currentReward = rewards.find(
          (reward) => reward.quantity == item.qty.toString()
        );

        let rewardPercentage = getRewardPercentage(item);

        if (currentReward) {
          if (currentReward.type == "cashback") {
            const points = ((item.price * item.qty * rewardPercentage) / 100) * 10;
            total += points;
            item.cashback = rewardPercentage;
          }
          item.reward_type = currentReward.type;
        } else {
          const currentReward = rewards.find((reward) => reward.quantity == "-1");
          if (currentReward) {
            if (currentReward.type == "cashback") {
              const points = ((item.price * item.qty * rewardPercentage) / 100) * 10;
              total += points;
              item.cashback = rewardPercentage;
            }
            item.reward_type = currentReward.type;
          }
        }
      });
      return total;
    });

    const totalDiscount = computed(() => {
      let total = 0;
      productLists.forEach((item) => {
        const rewards = item.data.rewards;
        const currentReward = rewards.find(
          (reward) => reward.quantity == item.qty.toString()
        );

        let rewardPercentage = getRewardPercentage(item);

        if (currentReward) {
          item.reward_type = currentReward.type;
          if (currentReward.type == "discount") {
            const discount = item.price * item.qty * (rewardPercentage / 100);
            total += discount;
            item.discount = discount;
          }
        } else {
          const currentReward = rewards.find((reward) => reward.quantity == "-1");
          if (currentReward) {
            item.reward_type = currentReward.type;
            if (currentReward.type == "discount") {
              const discount = item.price * item.qty * (rewardPercentage / 100);
              total += discount;
              item.discount = discount;
            }
          }
        }
      });
      return total;
    });

    const totalCost = computed(() => {
      let total = 0;
      productLists.forEach((item) => {
        total += item.price * item.qty - item.discount;
      });
      return total;
    });

    const addedToCart = computed(() => {
      return false;
    });

    const mediaData = computed(() => {
      return SingleShoplistProduct.value
        ? getMediaBoxForProduct(SingleShoplistProduct.value)
        : undefined;
    });

    const productLists = reactive<ProductList[]>([]);

    const createProductList = () => {
      productLists.length = 0;

      if (!SingleShoplistProduct.value) {
        return;
      }

      [SingleShoplistProduct.value].forEach((product) => {
        let discount = 0;
        let reward_percentage = 0;
        let reward_point = 0;
        let cashback = 0;
        let rewardType = "";
        let currentReward = product?.rewards.find((reward) => reward.quantity == "1");

        if (!currentReward) {
          currentReward = product?.rewards.find((reward) => reward.quantity == "-1");
        }

        let finalRewardPercentage = 0;

        if (currentReward) {
          finalRewardPercentage = parseFloat(currentReward.percentage);

          let percentageToRemove = 0;

          const userPlanCashbackCommission = getUserPlanCashbackCommissionForProduct(
            product
          );

          if (userPlanCashbackCommission.cashbackCommission > 0) {
            const percentageCommissionToRemove =
              userPlanCashbackCommission.cashbackCommission / 100;

            percentageToRemove = finalRewardPercentage * percentageCommissionToRemove;
          }

          finalRewardPercentage = finalRewardPercentage - percentageToRemove;
        }

        if (currentReward) {
          rewardType = currentReward.type;
          if (currentReward.type == "discount") {
            discount = parseFloat(product.regular_price) * (finalRewardPercentage / 100);
          } else if (currentReward.type == "cashback") {
            cashback = finalRewardPercentage;
          }

          reward_point =
            ((parseFloat(product.regular_price) * finalRewardPercentage) / 100) * 10;

          reward_percentage = finalRewardPercentage;
        } else {
          const baseReward = product.rewards.find((reward) => reward.quantity == "-1");
          rewardType = baseReward?.type || "";
          if (baseReward?.type == "discount") {
            discount = parseFloat(product.regular_price) * (finalRewardPercentage / 100);
          } else if (baseReward?.type == "cashback") {
            cashback = finalRewardPercentage;
          }

          reward_point =
            ((parseFloat(product.regular_price) * finalRewardPercentage) / 100) * 10;
          reward_percentage = finalRewardPercentage;
        }

        productLists.push({
          base_image: product.primary_image_url,
          main_category: product.category.name,
          price: parseFloat(product.regular_price),
          title: product.name,
          weight: "",
          qty: 1,
          id: product.id,
          data: product,
          max_qty: product.stock_quantity,
          reward_type: rewardType,
          discount,
          reward_percentage,
          reward_point,
          cashback,
          available_in_area: true,
          business: {
            name: product.business?.business_name || "",
            id: product.business?.id || "",
          },
          delivery: {
            id: "",
            cost: "",
          },
        });
      });

      if (Logic.Common.currentBuildType() == "web") {
        // useMeta({
        //   title: `${SingleShoplistProduct.value?.name}`,
        // });
        document.title = `${SingleShoplistProduct?.value?.name} | Shoppoint`;
      }
    };

    const setPageState = () => {
      const currentPathQuery = Logic.Common.route?.query;

      if (currentPathQuery) {
        if (currentPathQuery.preview?.toString()) {
          fromPreview.value = true;
        }
      } else {
        fromPreview.value = true;
      }
    };

    const handleAddToCart = () => {
      Logic.Common.showModal({
        show: true,
        title: "Add to Shoplist",
        type: "add_product_to_shoplists",
        extraData: {
          product_id: SingleShoplistProduct.value?.id,
        },
        action: () => {
          console.log("add to shoplist");
        },
      });
    };

    const saveMetric = (event_type: string) => {
      Logic.Ad.SaveMetric({
        entity_type: "product",
        event_type,
        entity_uuid: SingleShoplistProduct.value?.uuid || "",
        event_metadata: JSON.stringify({
          type: "product",
          product_uuid: SingleShoplistProduct.value?.uuid || "",
        }),
      });
    };

    const setSimilarProducts = () => {
      similarProducts.length = 0;

      ManySimilarProducts.value?.forEach((product) => {
        const mediaBox = getMediaBoxForProduct(product as ShoplistProduct);
        similarProducts.push({
          data: mediaBox,
          id: product.id.toString(),
          type: "product",
          colors: {
            bg: "",
            text: "",
          },
        });
      });
    };

    const handleShoplsistClick = (item: any) => {
      Logic.Common.GoToRoute(`/shoplist/product/${item.data.product_data?.uuid}`);
    };

    const buyNow = async () => {
      saveMetric("clicked");
      Logic.Shoplist.Storage.set(
        `checkout_setup_product_${SingleShoplistProduct.value?.uuid}`,
        JSON.stringify({
          products: productLists,
          totalCost: totalCost.value,
          totalReward: totalReward.value,
          totalDiscount: totalDiscount.value,
        })
      ).then(() => {
        Logic.Common.GoToRoute(
          `/shoplist/checkout/${SingleShoplistProduct.value?.uuid}?product=true`
        );
      });
    };

    watch(ManySimilarProducts, () => {
      setSimilarProducts();
    });

    watch(SingleShoplistProduct, () => {
      createProductList();
      scrollToTop();
    });

    onIonViewWillEnter(() => {
      createProductList();
      setSimilarProducts();
      setPageState();
    });

    onMounted(() => {
      Logic.Shoplist.watchProperty("SingleShoplistProduct", SingleShoplistProduct);
      Logic.Shoplist.watchProperty("ManySimilarProducts", ManySimilarProducts);
      createProductList();
      setSimilarProducts();
      setPageState();
      // Scroll to top of #main-section after component is mounted
    });

    return {
      Logic,
      productLists,
      SingleShoplistProduct,
      mediaData,
      fromPreview,
      totalReward,
      addedToCart,
      totalCost,
      totalDiscount,
      similarProducts,
      buyNow,
      handleAddToCart,
      numberToAbbrev,
      handleShoplsistClick,
    };
  },
});
</script>
