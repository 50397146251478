import { OperationResult } from 'urql'
import { BaseApiService } from './common/BaseService'
import {
  AdDemography,
  Advert,
  AdvertPaginator,
  MutationCreateAdvertArgs,
  MutationCreateOrUpdateAdvertPollArgs,
  MutationSaveMetricArgs,
} from '../gql/graphql'

export default class AdApi extends BaseApiService {
  public GetAdDemographies = () => {
    const requestData = `
              query GetAdDemographies {
              GetAdDemographies {
                id
                uuid
                title
              }
            }
                `

    const response: Promise<OperationResult<{
      GetAdDemographies: AdDemography[]
    }>> = this.query(requestData, {})

    return response
  }

  public GetAdverts = (
    page: number,
    count: number,
    orderType = 'CREATED_AT',
    order: 'ASC' | 'DESC',
    whereQuery = '',
  ) => {
    const requestData = `
            query GetAdverts($page: Int!, $count: Int!){
                GetAdverts(first: $count, page: $page,
                    orderBy: {column: ${
                      orderType ? orderType : 'CREATED_AT'
                    }, order: ${order}}
                       ${whereQuery ? `where: ${whereQuery}` : ''} 
                       ){
                  paginatorInfo{
                    count
                    currentPage
                    firstItem
                    hasMorePages
                    lastItem
                    lastPage
                    total
                    lastPage
                  }
                  data{
                    id
                    uuid
                    title
                    status
                    media {
                    id
                    uuid
                    source_url
                    metadata {
                        image_url
                        video_base_url
                        media_type
                    }
                    media {
                        media_url
                        percentage
                    }
                    shoplist {
                        id
                        uuid
                        name
                        base_image_url
                        media_type
                        description
                        expires_at
                        status
                        media {
                        media_url
                        percentage
                        }
                        products {
                        id
                        name
                        description
                        category {
                            name
                        }
                        rewards {
                            id
                            type
                            percentage
                            quantity
                        }
                        business {
                            business_tag
                            business_name
                            uuid
                        }
                        regular_price
                        primary_image_url
                        stock_quantity
                        status
                        }
                        business {
                        id
                        uuid
                        business_name
                        business_tag
                        photo_url
                        }
                    }
                    cta_rules {
                        cta
                        landing_page_url
                        polls {
                        question
                        answer_type
                        option_type
                        options {
                            value
                            slug
                            image_url
                        }
                        }
                    }
                    created_at
                    updated_at
                    }
                    adset {
                    id
                    uuid
                    title
                    target_audience_rules {
                        gender
                        age_ranges
                        demographies
                        locations
                    }
                    ad_budget
                    maximum_cost
                    ad_start_date
                    ad_end_date
                    ad_daytime_start
                    ad_daytime_end
                    status
                    bidding_strategy
                    run_until_paused
                    }
                    performance {
                    impressions
                    clicks
                    conversions
                    }
                    type
                }
                 }
               }
                    `

    const response: Promise<OperationResult<{
      GetAdverts: AdvertPaginator
    }>> = this.query(requestData, {
      page,
      count,
    })

    return response
  }

  public GetSingleAdvert = (uuid: string) => {
    const requestData = `
    query GetSingleAdvert($uuid: String!) {
          GetSingleAdvert(uuid: $uuid) {
            id
            uuid
            title
            status
            created_at
            media {
              id
              uuid
              source_url
              metadata {
                image_url
                video_base_url
                media_type
              }
              media {
                media_url
                percentage
              }
              shoplist {
                id
                uuid
                name
                base_image_url
                media_type
                description
                expires_at
                status
                media {
                  media_url
                  percentage
                }
              }
              cta_rules {
                cta
                landing_page_url
                polls {
                  question
                  answer_type
                  option_type
                  options {
                    value
                    slug
                    image_url
                  }
                }
              }
              created_at
              updated_at
            }
            adset {
              id
              uuid
              title
              ad_budget
              maximum_cost
              ad_start_date
              ad_end_date
              ad_daytime_start
              ad_daytime_end
              status
              bidding_strategy
              run_until_paused
            }
            type
            ad_insights {
              reach {
                total
                impressions
              }
              engagements {
                total
                clicks
                website_visits
                likes
                shares
                saves
                purchases
                polls_completed
              }
              ad {
                amount_spent
                audience {
                  gender {
                    male
                    female
                  }
                  age_ranges {
                    title
                    count
                  }
                  locations {
                    title
                    count
                  }
                }
              }
              last_updated
            }
          }
        }
            `

    const response: Promise<OperationResult<{
      GetSingleAdvert: Advert
    }>> = this.query(requestData, {
      uuid,
    })

    return response
  }

  public CreateAdvert = (
    data: MutationCreateAdvertArgs,
    progressCb: (progress: number) => void,
  ) => {
    const requestData = `
		mutation CreateAdvert($promotion_title: String!, $objectives: OBJECTIVES!, $target_audience_rules: AdTargetAudienceRuleInput!, $ad_budget: String!, $maximum_cost: String!, $ad_start_date: String!, $ad_end_date: String!, $ad_daytime_start: Int!, $ad_daytime_end: Int!, $bidding_strategy: String!, $source_media: Upload, $source_media_type: String!, $metadata: AdMediaMetadataInput!, $cta_rules: AdCTARulesInput!, $type: String!, $run_until_paused: Boolean!) {
                CreateAdvert(
                    promotion_title: $promotion_title
                    objectives: $objectives
                    target_audience_rules: $target_audience_rules
                    ad_budget: $ad_budget
                    maximum_cost: $maximum_cost
                    ad_start_date: $ad_start_date
                    ad_end_date: $ad_end_date
                    ad_daytime_start: $ad_daytime_start
                    ad_daytime_end: $ad_daytime_end
                    bidding_strategy: $bidding_strategy
                    source_media: $source_media
                    source_media_type: $source_media_type
                    metadata: $metadata
                    cta_rules: $cta_rules
                    type: $type
                    run_until_paused: $run_until_paused
                ) {
                    id
                    uuid
                    title
                    status
                    user {
                    business {
                      uuid
                      business_name
                      business_tag
                      photo_url
                    }
                  }
                    media {
                    id
                    uuid
                    source_url
                    metadata {
                        image_url
                        video_base_url
                        media_type
                    }
                    media {
                        media_url
                        percentage
                    }
                    shoplist {
                        id
                        uuid
                        name
                        base_image_url
                        media_type
                        description
                        expires_at
                        status
                        media {
                        media_url
                        percentage
                        }
                        products {
                        id
                        name
                        description
                        category {
                            name
                        }
                        rewards {
                            id
                            type
                            percentage
                            quantity
                        }
                        business {
                            business_tag
                            business_name
                            uuid
                        }
                        regular_price
                        primary_image_url
                        stock_quantity
                        status
                        }
                        business {
                        id
                        uuid
                        business_name
                        business_tag
                        photo_url
                        }
                    }
                    cta_rules {
                        cta
                        landing_page_url
                        polls {
                        question
                        answer_type
                        option_type
                        options {
                            value
                            slug
                            image_url
                        }
                        }
                    }
                    created_at
                    updated_at
                    }
                    adset {
                    id
                    uuid
                    title
                    target_audience_rules {
                        gender
                        age_ranges
                        demographies
                        locations
                    }
                    ad_budget
                    maximum_cost
                    ad_start_date
                    ad_end_date
                    ad_daytime_start
                    ad_daytime_end
                    status
                    bidding_strategy
                    run_until_paused
                    }
                    performance {
                    impressions
                    clicks
                    conversions
                    }
                    type
                }
                }
		`

    const response: Promise<OperationResult<{
      CreateAdvert: Advert
    }>> = this.mutationWithProgress(requestData, data, progressCb)

    return response
  }

  public SaveMetric = (data: MutationSaveMetricArgs) => {
    const requestData = `
		mutation SaveMetric(
      $entity_type: String!
        $event_type: String!
        $entity_uuid: String!
        $event_metadata: String
        ) {
          SaveMetric(
          entity_type: $entity_type
          event_type: $event_type
          entity_uuid: $entity_uuid
          event_metadata: $event_metadata
          )  
        }  
	`

    const response: Promise<OperationResult<{
      SaveMetric: Boolean
    }>> = this.mutation(requestData, data)

    return response
  }

  public CreateOrUpdateAdvertPoll = (
    data: MutationCreateOrUpdateAdvertPollArgs,
  ) => {
    const requestData = `
		mutation CreateOrUpdateAdvertPoll(
      $advert_uuid: String!
      $answer: String!
      ) {
        CreateOrUpdateAdvertPoll(
          advert_uuid: $advert_uuid
          answer: $answer
        )
      }`

    const response: Promise<OperationResult<{
      CreateOrUpdateAdvertPoll: Boolean
    }>> = this.mutation(requestData, data)

    return response
  }

  public SubscribeToAdvertPoll = (
    advert_uuid: string,
    handleSubscription: Function,
    handleSubscribed: () => void,
  ) => {
    const requestData = `
		subscription SubscribeToAdvertPoll($advert_uuid: String!) {
      NewPollVote(advert_uuid: $advert_uuid) {
        id
        uuid
        user_id
        advert_uuid
        answer
      }
    }
  `

    const response = this.subscription(
      requestData,
      { advert_uuid },
      handleSubscription,
      handleSubscribed,
    )

    return response
  }
}
