import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col space-y-4 pt-2" }
const _hoisted_2 = { class: "w-full pt-3" }
const _hoisted_3 = { class: "w-full grid grid-cols-2 gap-4 px-4" }
const _hoisted_4 = { class: "col-span-1 flex flex-col" }
const _hoisted_5 = { class: "col-span-1 flex flex-col" }
const _hoisted_6 = { class: "w-full flex flex-col space-y-2" }
const _hoisted_7 = { class: "w-full flex flex-row space-x-2" }
const _hoisted_8 = {
  class: "w-full flex no-scrollbar flex-row space-x-3 flex-nowrap overflow-x-auto scrollbar-hide",
  id: "serviceContainerShoplist"
}
const _hoisted_9 = { class: "flex flex-row py-2 pr-4" }
const _hoisted_10 = ["onClick", "id"]
const _hoisted_11 = { class: "w-[18px]" }
const _hoisted_12 = { class: "w-full flex flex-col space-y-0 h-full" }
const _hoisted_13 = { class: "w-full grid grid-cols-2 gap-4 px-4" }
const _hoisted_14 = ["onClick"]
const _hoisted_15 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_app_swiper = _resolveComponent("app-swiper")!
  const _component_dashboard_layout = _resolveComponent("dashboard-layout")!
  const _component_app_wrapper = _resolveComponent("app-wrapper")!

  return (_openBlock(), _createBlock(_component_app_wrapper, null, {
    default: _withCtx(() => [
      _createVNode(_component_dashboard_layout, {
        topBarType: "plain",
        "has-cart": true,
        title: "Pay",
        pageKey: 'pay'
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", {
                    class: "w-full flex flex-row space-x-2 py-3 bg-primary-main items-center justify-center px-5 rounded-[30px] cursor-pointer",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.Logic.Common.GoToRoute(`/pay/user`)))
                  }, [
                    _createVNode(_component_app_normal_text, { class: "!text-white" }, {
                      default: _withCtx(() => _cache[3] || (_cache[3] = [
                        _createTextVNode(" Send ")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_app_icon, {
                      name: 'send-white',
                      customClass: 'h-[18px]'
                    })
                  ])
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", {
                    class: "w-full flex flex-row space-x-2 py-3 bg-primary-50 dark:bg-primary-100 items-center justify-center px-5 rounded-[30px] cursor-pointer",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.Logic.Common.GoToRoute(`/pay/merchant`)))
                  }, [
                    _createVNode(_component_app_normal_text, { class: "!text-primary-300 dark:!text-primary-500" }, {
                      default: _withCtx(() => _cache[4] || (_cache[4] = [
                        _createTextVNode(" Scan to pay ")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_app_icon, {
                      name: 'request-purple',
                      customClass: 'h-[20px]'
                    })
                  ])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _cache[5] || (_cache[5] = _createElementVNode("div", null, null, -1)),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.services, (service, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "flex flex-row pl-2 cursor-pointer",
                        key: index,
                        onClick: ($event: any) => (_ctx.slidePosition = index),
                        id: `service${index}`
                      }, [
                        _createElementVNode("div", {
                          class: _normalizeClass(` ${
                      _ctx.selectedService == service.id
                        ? 'bg-primary-main'
                        : 'bg-[#f3f3f3] dark:bg-gray-700'
                    } px-4 py-2 rounded-[30px] w-auto flex flex-row items-center space-x-1`)
                        }, [
                          _createElementVNode("div", _hoisted_11, [
                            _createVNode(_component_app_icon, {
                              name: 
                          _ctx.selectedService == service.id
                            ? `services/${service.icon}-active`
                            : `services/${service.icon}${
                                _ctx.Logic.Common.currentAppTheme() == 'light' ? '' : '-active'
                              }`
                        ,
                              customClass: `${service.icon_size}`
                            }, null, 8, ["name", "customClass"])
                          ]),
                          _createVNode(_component_app_normal_text, {
                            class: _normalizeClass(`!whitespace-nowrap ${
                        _ctx.selectedService == service.id
                          ? 'text-white'
                          : 'text-gray-600 dark:text-gray-50'
                      }`)
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(service.name), 1)
                            ]),
                            _: 2
                          }, 1032, ["class"])
                        ], 2)
                      ], 8, _hoisted_10))
                    }), 128))
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_12, [
                _createVNode(_component_app_swiper, {
                  "free-mode": false,
                  "show-pagination": false,
                  "space-between": 1,
                  "slide-per-view": 1,
                  currentSlidePosition: _ctx.currentSlidePosition,
                  "custom-class": "!h-auto ",
                  swiperClass: '',
                  modelValue: _ctx.slidePosition,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.slidePosition) = $event)),
                  id: "swiperContainerServices"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.services, (main_item, main_index) => {
                      return (_openBlock(), _createBlock(_component_swiper_slide, {
                        key: main_index,
                        class: "!w-full flex flex-col !h-auto"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_13, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.services.filter(
                      (eachItem) => eachItem.id == _ctx.selectedService
                    )[0].items, (item, index) => {
                              return (_openBlock(), _createElementBlock("div", {
                                key: index,
                                class: _normalizeClass(`col-span-1 rounded-[8px] flex flex-col justify-center items-center space-y-3 h-[115px] mdlg:!h-[140px] md:!h-[140px] cursor-pointer`),
                                style: _normalizeStyle(`background-color: ${item.bg_color};`),
                                onClick: ($event: any) => (
                      _ctx.Logic.Common.GoToRoute(
                        `/cashback/${
                          _ctx.services.filter((eachItem) => eachItem.id == _ctx.selectedService)[0]
                            .id
                        }?target=${item.name}`
                      )
                    )
                              }, [
                                _createElementVNode("img", {
                                  src: `images/brands/${item.icon}.png`,
                                  class: "!h-[55px]"
                                }, null, 8, _hoisted_15),
                                _createVNode(_component_app_normal_text, { class: "!text-center !font-semibold !capitalize dark:!text-black" }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(item.name), 1)
                                  ]),
                                  _: 2
                                }, 1024)
                              ], 12, _hoisted_14))
                            }), 128))
                          ])
                        ]),
                        _: 2
                      }, 1024))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["currentSlidePosition", "modelValue"])
              ])
            ]),
            _cache[6] || (_cache[6] = _createElementVNode("div", { class: "h-[120px]" }, null, -1))
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}