<template>
  <div
    :class="`w-full flex flex-row items-center justify-center bottom-0 left-0  bg-white dark:!bg-black  ${
      isRelative ? 'relative' : 'fixed'
    } `"
  >
    <div :class="`w-full  mdlg:!w-[70%] lg:!w-[65%]  grid grid-cols-12 `">
      <!-- Left side -->
      <div
        class="hidden col-span-3 mdlg:!flex flex-col md:!flex md:!col-span-4 mdlg:!col-span-3"
      ></div>

      <!-- Main section -->
      <div
        :class="`flex flex-col space-y-2 col-span-12 px-4 ${customClass} mdlg:!col-span-6 md:!col-span-8 items-center justify-center mdlg:!border-l-[1px] mdlg:!border-r-[1px] mdlg:!border-grey-100 md:!border-l-[1px]   md:!border-grey-100`"
        style="
          padding-bottom: calc(env(safe-area-inset-bottom) + 16px) !important;
        "
      >
        <slot />
      </div>

      <!-- Right side -->
      <div class="hidden col-span-3 mdlg:!flex flex-col"></div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Logic } from "../../index";

export default defineComponent({
  props: {
    isRelative: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: String,
      default: "",
    },
  },
  name: "FixedContainer",
  setup() {
    return {
      Logic,
    };
  },
});
</script>
