import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "w-full flex flex-row space-x-2 justify-between px-4 items-center py-4 sticky top-0 bg-white z-50",
  style: {"padding-top":"calc(env(safe-area-inset-top) + 16px) !important"}
}
const _hoisted_2 = { class: "flex flex-row space-x-2 items-center" }
const _hoisted_3 = { class: "flex flex-col space-y-[1px]" }
const _hoisted_4 = { class: "flex flex-row space-x-1 items-center" }
const _hoisted_5 = { class: "flex flex-row space-x-1 items-center" }
const _hoisted_6 = { class: "flex flex-row items-center justify-center space-x-2" }
const _hoisted_7 = { class: "flex flex-col" }
const _hoisted_8 = { class: "w-full flex flex-col overflow-y-auto relative h-full" }
const _hoisted_9 = { class: "w-full flex flex-col space-y-3 px-4" }
const _hoisted_10 = { class: "w-full flex flex-col" }
const _hoisted_11 = {
  key: 0,
  class: "h-[250px] w-full flex items-center justify-center"
}
const _hoisted_12 = {
  key: 1,
  class: "w-full flex flex-col space-y-2"
}
const _hoisted_13 = ["onClick"]
const _hoisted_14 = { class: "absolute top-0 left-0 h-full w-full bg-black !bg-opacity-30 !rounded-[10px] flex flex-col justify-between py-3 px-3" }
const _hoisted_15 = { class: "flex flex-row justify-end" }
const _hoisted_16 = {
  class: /*@__PURE__*/_normalizeClass(`px-2 py-[2px] rounded-[4px] bg-primary-main  flex flex-row items-center justify-center`)
}
const _hoisted_17 = { class: "flex flex-row space-x-1 justify-end flex-grow items-center" }
const _hoisted_18 = { class: "font-semibold pl-[1px]" }
const _hoisted_19 = { class: "w-full flex flex-col space-y-1" }
const _hoisted_20 = { class: "w-full flex flex-col space-y-1" }
const _hoisted_21 = {
  key: 0,
  class: "w-full flex flex-row space-x-1 items-center flex-nowrap"
}
const _hoisted_22 = { class: "w-full flex flex-col space-y-3 pt-1" }
const _hoisted_23 = { class: "w-full grid grid-cols-2 gap-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_image_loader = _resolveComponent("app-image-loader")!
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_banner_ad = _resolveComponent("app-banner-ad")!
  const _component_app_empty_state = _resolveComponent("app-empty-state")!
  const _component_app_header_text = _resolveComponent("app-header-text")!
  const _component_app_media_box = _resolveComponent("app-media-box")!
  const _component_app_shoplist_explore = _resolveComponent("app-shoplist-explore")!
  const _component_plain_layout = _resolveComponent("plain-layout")!
  const _component_app_wrapper = _resolveComponent("app-wrapper")!

  return (_openBlock(), _createBlock(_component_app_wrapper, null, {
    default: _withCtx(() => [
      _createVNode(_component_plain_layout, {
        title: 'clear',
        class: "overflow-y-auto h-screen"
      }, {
        topBar: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", {
                class: "w-[16px] cursor-pointer",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.Logic.Common.goBack()))
              }, [
                _createVNode(_component_app_icon, {
                  "custom-class": 'h-[14px]',
                  name: 'arrow-back'
                })
              ]),
              _createVNode(_component_app_image_loader, {
                photoUrl: _ctx.BusinessProfile?.photo_url ?? '/images/temp/brand.png',
                customClass: '!h-[40px] !w-[40px] rounded-full'
              }, null, 8, ["photoUrl"]),
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", null, [
                    _createVNode(_component_app_normal_text, { class: "!text-left !font-semibold !text-sm" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.BusinessProfile?.business_name), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _createVNode(_component_app_icon, {
                    name: 'verified',
                    class: "!h-[14px]"
                  })
                ]),
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_app_normal_text, { class: "!text-[11px] !text-gray-800 !text-left" }, {
                    default: _withCtx(() => [
                      _createTextVNode(" @" + _toDisplayString(_ctx.BusinessProfile?.business_tag), 1)
                    ]),
                    _: 1
                  }),
                  _cache[2] || (_cache[2] = _createElementVNode("span", { class: "h-[4px] w-[4px] rounded-full bg-gray-500" }, null, -1)),
                  _createVNode(_component_app_normal_text, { class: "!text-[11px] !text-gray-800 !text-left" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.numberToAbbrev(_ctx.followers || 0)) + " Follower" + _toDisplayString(_ctx.followers > 1 ? "s" : ""), 1)
                    ]),
                    _: 1
                  })
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_app_button, {
                  onClick: _ctx.followUnfollowBusiness,
                  customClass: `!rounded-full !py-1 !px-5 !text-[12px] ${
                  !_ctx.isFollowed
                    ? 'bg-primary-main !text-white'
                    : '!bg-primary-50 !text-primary-main'
                }`
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.isFollowed ? "Following" : "Follow"), 1)
                  ]),
                  _: 1
                }, 8, ["onClick", "customClass"])
              ]),
              _createElementVNode("div", {
                class: "h-[30px] w-[20px] flex items-center justify-center cursor-pointer",
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.showBusinessInfo && _ctx.showBusinessInfo(...args)))
              }, [
                _createVNode(_component_app_icon, {
                  name: 'info-circle',
                  customClass: 'h-[20px]'
                })
              ])
            ])
          ])
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_app_banner_ad)
              ]),
              (!_ctx.merchantShoplists.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                    _createVNode(_component_app_empty_state, {
                      title: 'No Offers here',
                      "sub-title": 'This merchant has no offers at the moment.'
                    })
                  ]))
                : _createCommentVNode("", true),
              (_ctx.merchantShoplists.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList([_ctx.merchantShoplists[0]], (item, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "w-full flex flex-col space-y-1 cursor-pointer",
                        key: index,
                        onClick: ($event: any) => (
                _ctx.setStoryContent(
                  _ctx.ContentType.BusinessContents,
                  {
                    id: parseInt(item.id),
                    type: item.type,
                  },
                  _ctx.currentBusinessUuid
                )
              )
                      }, [
                        _createVNode(_component_app_media_box, {
                          data: item.data,
                          class: "!h-[230px] mdlg:!h-[350px] !rounded-[10px] relative",
                          isVideo: !item.data.is_grid,
                          "is-large-view": true
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_14, [
                              _createElementVNode("div", _hoisted_15, [
                                _createElementVNode("span", _hoisted_16, [
                                  _createElementVNode("div", _hoisted_17, [
                                    _createVNode(_component_app_icon, {
                                      name: 'cashback-icon',
                                      customClass: 'h-[15px]'
                                    }),
                                    _createVNode(_component_app_normal_text, { customClass: '!whitespace-nowrap !text-[11px] mdlg:!text-[12px] font-semibold !text-white' }, {
                                      default: _withCtx(() => [
                                        _cache[3] || (_cache[3] = _createTextVNode(" Earn ")),
                                        _createElementVNode("span", _hoisted_18, _toDisplayString(_ctx.numberToAbbrev(item.data.points || 0)) + " Pts", 1)
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ])
                                ])
                              ]),
                              _createElementVNode("div", _hoisted_19, [
                                (item.data.expires_at)
                                  ? (_openBlock(), _createBlock(_component_app_normal_text, {
                                      key: 0,
                                      class: "!text-left !text-white uppercase"
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.Logic.Common.fomartDate(
                          item.data.expires_at || "",
                          "MMM Do"
                        )), 1)
                                      ]),
                                      _: 2
                                    }, 1024))
                                  : _createCommentVNode("", true),
                                _createElementVNode("div", _hoisted_20, [
                                  _createVNode(_component_app_header_text, { class: "!text-white !text-left !font-semibold" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(item.data.title), 1)
                                    ]),
                                    _: 2
                                  }, 1024),
                                  (item.data.tags.length)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.data.tags, (tag, index) => {
                                          return (_openBlock(), _createElementBlock("span", {
                                            class: "px-2 py-[1px] bg-transparent rounded-[20px] border-[1px] border-white",
                                            key: index
                                          }, [
                                            _createVNode(_component_app_normal_text, { class: "!text-white !text-[10px]" }, {
                                              default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(tag), 1)
                                              ]),
                                              _: 2
                                            }, 1024)
                                          ]))
                                        }), 128))
                                      ]))
                                    : _createCommentVNode("", true)
                                ])
                              ])
                            ])
                          ]),
                          _: 2
                        }, 1032, ["data", "isVideo"])
                      ], 8, _hoisted_13))
                    }), 128)),
                    _createElementVNode("div", _hoisted_22, [
                      _createVNode(_component_app_header_text, { customClass: '!text-left !text-sm !font-semibold' }, {
                        default: _withCtx(() => _cache[4] || (_cache[4] = [
                          _createTextVNode(" Cashback Offers ")
                        ])),
                        _: 1
                      }),
                      _createElementVNode("div", _hoisted_23, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.merchantShoplists, (item, index) => {
                          return (_openBlock(), _createBlock(_component_app_shoplist_explore, {
                            key: index,
                            item: item,
                            onClick: ($event: any) => (_ctx.handleShoplsistClick(item))
                          }, null, 8, ["item", "onClick"]))
                        }), 128))
                      ])
                    ]),
                    _cache[5] || (_cache[5] = _createElementVNode("div", { class: "h-[120px]" }, null, -1))
                  ]))
                : _createCommentVNode("", true)
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}