<template>
  <app-wrapper>
    <subpage-layout>
      <!-- Add to basket -->
      <template v-slot:extra-topbar>
        <div class="flex flex-col">
          <app-button
            :class="`${
              addedToCart
                ? '!bg-success-50 !text-success-main dark:!bg-success-500 dark:!text-white'
                : '!bg-primary-50 !text-primary-main dark:!bg-primary-500 dark:!text-white'
            }`"
            v-if="!fromPreview"
            @click="handleAddToCart"
          >
            {{ addedToCart ? "Saved Shoplist" : "Save Shoplist" }}
          </app-button>
        </div>
      </template>

      <div class="w-full flex flex-col space-y-3">
        <div class="w-full flex flex-row px-4 justify-between items-center">
          <div class="flex flex-col space-y-1 justify-start">
            <app-header-text class="!text-base">
              {{ SingleShoplistCollection?.name }}
            </app-header-text>
            <div class="w-full flex flex-row space-x-2 items-center">
              <app-normal-text> 1.4k Shoppers </app-normal-text>
              <span class="h-[3px] w-[3px] rounded-full bg-grey-900 dark:bg-grey-50">
              </span>
              <div class="flex flex-row items-center space-x-1">
                <app-normal-text class="!text-success-500">
                  Earn
                  {{ numberToAbbrev(totalReward) }} Pts
                  {{
                    totalDiscount
                      ? `and (${Logic.Common.convertToMoney(
                          totalDiscount,
                          true,
                          "ngn"
                        )} off)`
                      : ""
                  }}
                </app-normal-text>
              </div>
            </div>
          </div>
        </div>

        <!-- Description -->
        <div class="w-full flex flex-col space-y-2 px-4">
          <app-normal-text class="!text-left !text-[#5A5A5A] dark:!text-grey-200">
            {{ SingleShoplistCollection?.description }}
          </app-normal-text>
        </div>

        <!-- Shoplist items -->
        <div class="w-full flex flex-col space-y-1 px-4">
          <div
            v-for="(item, index) in productLists"
            :key="index"
            class="w-full flex flex-row justify-between space-x-2 border-b-[1px] border-grey-100 py-3 cursor-pointer"
            @click="Logic.Common.GoToRoute(`/shoplist/product/${item.uuid}`)"
          >
            <div class="w-full flex flex-row space-x-2">
              <div class="h-[82px]">
                <app-image-loader
                  :photoUrl="item.base_image"
                  class="rounded-[4.4px] h-[88px] w-[88px]"
                />
              </div>
              <div class="flex flex-col items-end w-full justify-between space-y-1">
                <div class="w-full flex flex-col space-y-[2px]">
                  <app-header-text class="!text-sm text-left">
                    {{ item.title }}
                  </app-header-text>
                  <div class="w-full flex flex-row items-center space-x-2">
                    <!-- <app-normal-text class="!text-[11px] !text-grey-900">
                      {{ item.weight }}
                    </app-normal-text>
                    -->

                    <app-badge
                      :color="item.reward_type == 'cashback' ? 'purple' : 'purple'"
                      class="capitalize !py-[1px] !px-[6px] !text-[9px] !rounded-[14px]"
                    >
                      Earn

                      {{ numberToAbbrev(item.reward_point) }} Pts
                    </app-badge>

                    <span
                      class="h-[3px] w-[3px] rounded-full bg-grey-900 dark:bg-grey-200"
                    >
                    </span>

                    <app-normal-text
                      class="!text-[11px] !text-grey-900 dark:!text-grey-200"
                    >
                      {{ item.main_category }}
                    </app-normal-text>
                  </div>
                </div>

                <div class="w-full flex flex-row space-x-2 justify-between items-end">
                  <app-header-text class="!text-sm">
                    {{ Logic.Common.convertToMoney(item.price, true, "ngn") }}
                  </app-header-text>

                  <div class="flex flex-col space-y-1 relative pt-2" @click.stop="null">
                    <div
                      :class="`flex flex-row items-center rounded-[13.83px] ${
                        item.max_qty == 0 ? 'opacity-50' : ''
                      }`"
                    >
                      <div
                        @click.stop="item.qty > 1 ? item.qty-- : null"
                        class="w-[28px] h-[28px] rounded-l-[13.83px] bg-[#F5EDFC] dark:bg-gray-800 flex items-center justify-center cursor-pointer"
                      >
                        <app-normal-text
                          class="!text-[13px] !text-primary-400 dark:!text-gray-100"
                        >
                          -
                        </app-normal-text>
                      </div>
                      <div
                        class="w-[28px] h-[28px] bg-[#F7F7F7] dark:bg-grey-800 flex items-center justify-center"
                      >
                        <app-normal-text
                          class="!text-grey-900 dark:!text-grey-50 !font-semibold"
                        >
                          {{ item.max_qty > 0 ? item.qty : "0" }}
                        </app-normal-text>
                      </div>
                      <div
                        @click.stop="item.qty < item.max_qty ? item.qty++ : null"
                        class="w-[28px] h-[28px] rounded-r-[13.83px] bg-[#F5EDFC] dark:bg-gray-800 flex items-center justify-center cursor-pointer"
                      >
                        <app-normal-text
                          class="!text-[13px] !text-primary-400 dark:!text-gray-100"
                        >
                          +
                        </app-normal-text>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Spacer -->
        <div class="h-[120px]"></div>
      </div>

      <!-- Bottom section -->
      <fixed-container customClass="!px-0">
        <div
          class="w-full flex flex-row items-center justify-between border-t-[1px] px-4 pt-4 border-grey-50 bg-white dark:!bg-black"
        >
          <div class="flex flex-col space-y-[3px]">
            <app-header-text class="!text-base">
              {{ Logic.Common.convertToMoney(totalCost - totalDiscount, true, "ngn") }}
            </app-header-text>
            <div class="flex flex-row space-x-1 justify-end flex-grow items-center">
              <app-icon :name="'cashback-icon'" :customClass="'h-[15px]'" />
              <app-normal-text class="!text-[#898989] dark:!text-grey-200">
                Earn
                {{ numberToAbbrev(totalReward) }} Points
              </app-normal-text>
            </div>
          </div>
          <div
            class="py-3 px-8 bg-primary-main flex justify-center items-center rounded-[999px] cursor-pointer"
            @click="buyNow"
            v-if="!fromPreview"
          >
            <app-normal-text class="!font-semibold !text-white">
              Buy Now
            </app-normal-text>
          </div>
        </div>
      </fixed-container>
    </subpage-layout>
  </app-wrapper>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, reactive, ref } from "vue";
import { useMeta } from "vue-meta";
import { onIonViewWillEnter } from "@ionic/vue";
import { Logic } from "@shpt/logic";
import {
  AppHeaderText,
  AppNormalText,
  AppImageLoader,
  AppButton,
  AppBadge,
  AppIcon,
} from "@shpt/ui-components";
import {
  getMediaBox,
  getUserPlanCashbackCommissionForProduct,
} from "@shpt/ui-components/src/composable";
import { ShoplistProduct } from "@shpt/ui-components/src/gql/graphql";
import AppWrapper from "@/components/AppWrapper.vue";
import FixedContainer from "@shpt/ui-components/src/components/AppCommon/FixedContainer.vue";
import { numberToAbbrev } from "@shpt/ui-components/src/composable";

type ProductList = {
  uuid: string;
  title: string;
  weight: string;
  main_category: string;
  price: number;
  base_image: string;
  qty: number;
  id: string;
  reward_type: string;
  data: ShoplistProduct;
  max_qty: number;
  discount: number;
  reward_percentage: number;
  reward_point: number;
  cashback: number;
  available_in_area: boolean;
  business: {
    name: string;
    id: string;
  };
  delivery: {
    id: string;
    cost: string;
  };
};

export default defineComponent({
  components: {
    AppHeaderText,
    AppNormalText,
    AppImageLoader,
    AppButton,
    AppBadge,
    AppWrapper,
    AppIcon,
    FixedContainer,
  },
  name: "ShoplistInfoPage",
  layout: "SubPage",
  middlewares: {
    fetchRules: [
      {
        domain: "Shoplist",
        property: "SingleShoplistCollection",
        method: "GetShoplistCollection",
        params: [],
        useRouteId: true,
        requireAuth: true,
      },
      {
        domain: "Shoplist",
        property: "ManyUserShoplistCarts",
        method: "GetUserShoplistCarts",
        params: [],
        requireAuth: true,
      },
    ],
    tracking_data: {
      lable: "Shopplist Info Page",
      stage_type: "neutral",
      end_stage: "",
    },
  },
  setup() {
    const SingleShoplistCollection = ref(Logic.Shoplist.SingleShoplistCollection);
    const ManyUserShoplistCarts = ref(Logic.Shoplist.ManyUserShoplistCarts);

    const fromPreview = ref(false);

    const getRewardPercentage = (item: ProductList) => {
      const rewards = item.data.rewards;
      let currentReward = rewards.find(
        (reward) => reward.quantity == item.qty.toString()
      );

      if (!currentReward) {
        currentReward = rewards.find((reward) => reward.quantity == "-1");
      }

      let rewardPercentage = 0;

      if (currentReward) {
        rewardPercentage = parseFloat(currentReward.percentage);
      }

      let percentageToRemove = 0;

      const userPlanCashbackCommission = getUserPlanCashbackCommissionForProduct(
        item.data
      );

      if (userPlanCashbackCommission.cashbackCommission > 0) {
        const percentageCommissionToRemove =
          userPlanCashbackCommission.cashbackCommission / 100;

        percentageToRemove = rewardPercentage * percentageCommissionToRemove;
      }

      rewardPercentage = rewardPercentage - percentageToRemove;

      return rewardPercentage;
    };

    const totalReward = computed(() => {
      let total = 0;

      productLists.forEach((item) => {
        const rewards = item.data.rewards;
        const currentReward = rewards.find(
          (reward) => reward.quantity == item.qty.toString()
        );

        let rewardPercentage = getRewardPercentage(item);

        if (currentReward) {
          if (currentReward.type == "cashback") {
            const points = ((item.price * item.qty * rewardPercentage) / 100) * 10;
            total += points;
            item.cashback = rewardPercentage;
          }
          item.reward_type = currentReward.type;
        } else {
          const currentReward = rewards.find((reward) => reward.quantity == "-1");
          if (currentReward) {
            if (currentReward.type == "cashback") {
              const points = ((item.price * item.qty * rewardPercentage) / 100) * 10;
              total += points;
              item.cashback = rewardPercentage;
            }
            item.reward_type = currentReward.type;
          }
        }
      });
      return total;
    });

    const totalDiscount = computed(() => {
      let total = 0;
      productLists.forEach((item) => {
        const rewards = item.data.rewards;
        const currentReward = rewards.find(
          (reward) => reward.quantity == item.qty.toString()
        );

        let rewardPercentage = getRewardPercentage(item);

        if (currentReward) {
          item.reward_type = currentReward.type;
          if (currentReward.type == "discount") {
            const discount = item.price * item.qty * (rewardPercentage / 100);
            total += discount;
            item.discount = discount;
          }
        } else {
          const currentReward = rewards.find((reward) => reward.quantity == "-1");
          if (currentReward) {
            item.reward_type = currentReward.type;
            if (currentReward.type == "discount") {
              const discount = item.price * item.qty * (rewardPercentage / 100);
              total += discount;
              item.discount = discount;
            }
          }
        }
      });
      return total;
    });

    const totalCost = computed(() => {
      let total = 0;
      productLists.forEach((item) => {
        total += item.price * item.qty - item.discount;
      });
      return total;
    });

    const addedToCart = computed(() => {
      return ManyUserShoplistCarts.value?.some(
        (item) => item.collection?.id == SingleShoplistCollection.value?.id
      );
    });

    const mediaData = computed(() => {
      return SingleShoplistCollection.value
        ? getMediaBox(SingleShoplistCollection.value)
        : undefined;
    });

    const productLists = reactive<ProductList[]>([]);

    const createProductList = () => {
      productLists.length = 0;

      SingleShoplistCollection.value?.products.forEach((product) => {
        let discount = 0;
        let reward_percentage = 0;
        let reward_point = 0;
        let cashback = 0;
        let rewardType = "";
        let currentReward = product.rewards.find((reward) => reward.quantity == "1");

        if (!currentReward) {
          currentReward = product.rewards.find((reward) => reward.quantity == "-1");
        }

        let finalRewardPercentage = 0;

        if (currentReward) {
          finalRewardPercentage = parseFloat(currentReward.percentage);

          let percentageToRemove = 0;

          const userPlanCashbackCommission = getUserPlanCashbackCommissionForProduct(
            product
          );

          if (userPlanCashbackCommission.cashbackCommission > 0) {
            const percentageCommissionToRemove =
              userPlanCashbackCommission.cashbackCommission / 100;

            percentageToRemove = finalRewardPercentage * percentageCommissionToRemove;
          }

          finalRewardPercentage = finalRewardPercentage - percentageToRemove;
        }

        if (currentReward) {
          rewardType = currentReward.type;
          if (currentReward.type == "discount") {
            discount = parseFloat(product.regular_price) * (finalRewardPercentage / 100);
          } else if (currentReward.type == "cashback") {
            cashback = finalRewardPercentage;
          }

          reward_point =
            ((parseFloat(product.regular_price) * finalRewardPercentage) / 100) * 10;
          reward_percentage = finalRewardPercentage;
        } else {
          const baseReward = product.rewards.find((reward) => reward.quantity == "-1");
          rewardType = baseReward?.type || "";
          if (baseReward?.type == "discount") {
            discount = parseFloat(product.regular_price) * (finalRewardPercentage / 100);
          } else if (baseReward?.type == "cashback") {
            cashback = finalRewardPercentage;
          }
          reward_point =
            ((parseFloat(product.regular_price) * finalRewardPercentage) / 100) * 10;
          reward_percentage = finalRewardPercentage;
        }

        productLists.push({
          uuid: product.uuid,
          base_image: product.primary_image_url,
          main_category: product.category.name,
          price: parseFloat(product.regular_price),
          title: product.name,
          weight: "",
          qty: 1,
          id: product.id,
          data: product,
          max_qty: product.stock_quantity,
          reward_type: rewardType,
          discount,
          reward_percentage,
          reward_point,
          cashback,
          available_in_area: true,
          business: {
            name: product.business?.business_name || "",
            id: product.business?.id || "",
          },
          delivery: {
            id: "",
            cost: "",
          },
        });
      });

      if (Logic.Common.currentBuildType() == "web") {
        useMeta({
          title: `${SingleShoplistCollection.value?.name}`,
        });

        document.title = `${SingleShoplistCollection?.value?.name} | Shoppoint`;
      }
    };

    const setPageState = () => {
      const currentPathQuery = Logic.Common.route?.query;

      if (currentPathQuery) {
        if (currentPathQuery.preview?.toString()) {
          fromPreview.value = true;
        }
      } else {
        fromPreview.value = true;
      }
    };

    const handleAddToCart = () => {
      if (addedToCart.value) {
        Logic.Common.GoToRoute("/basket");
      } else {
        saveMetric("added_to_basket");
        Logic.Common.showAlert({
          type: "success",
          show: true,
          message: "Shoplist has been added to your basket",
        });
        Logic.Shoplist.CreateUserShoplistCart(SingleShoplistCollection.value?.id || "")
          ?.then((data) => {
            if (!data) {
              //
            }
          })
          .catch(() => {
            Logic.Common.showAlert({
              type: "error",
              show: true,
              message: "Failed to add shoplist to basket",
            });
          });
      }
    };

    const saveMetric = (event_type: string) => {
      const sponsored_uuid = localStorage.getItem(
        `sponsored_shoplist_${SingleShoplistCollection.value?.uuid}`
      );
      Logic.Ad.SaveMetric({
        entity_type: "shoplist",
        event_type,
        entity_uuid: SingleShoplistCollection.value?.uuid || "",
        event_metadata: JSON.stringify({
          type: "shoplist",
          shoplist_uuid: SingleShoplistCollection.value?.uuid || "",
        }),
      });

      if (sponsored_uuid) {
        const sponsored_shoplist_request_uuid = localStorage.getItem(
          `sponsored_shoplist_request_uuid_${SingleShoplistCollection.value?.uuid}`
        );
        Logic.Ad.SaveMetric({
          entity_type: "advert",
          event_type,
          entity_uuid: sponsored_uuid,
          event_metadata: JSON.stringify({
            type: "advert",
            advert_uuid: sponsored_uuid,
            request_uuid: sponsored_shoplist_request_uuid,
          }),
        });
      }
    };

    const buyNow = async () => {
      saveMetric("clicked");
      Logic.Shoplist.Storage.set(
        `checkout_setup_${SingleShoplistCollection.value?.uuid}`,
        JSON.stringify({
          products: productLists,
          totalCost: totalCost.value,
          totalReward: totalReward.value,
          totalDiscount: totalDiscount.value,
        })
      ).then(() => {
        Logic.Common.GoToRoute(
          `/shoplist/checkout/${SingleShoplistCollection.value?.uuid}?collection=true`
        );
      });
    };

    onIonViewWillEnter(() => {
      createProductList();
      setPageState();
    });

    onMounted(() => {
      Logic.Shoplist.watchProperty("SingleShoplistCollection", SingleShoplistCollection);
      Logic.Shoplist.watchProperty("ManyUserShoplistCarts", ManyUserShoplistCarts);
      createProductList();
      setPageState();
    });

    return {
      Logic,
      productLists,
      SingleShoplistCollection,
      mediaData,
      fromPreview,
      totalReward,
      addedToCart,
      totalCost,
      totalDiscount,
      buyNow,
      handleAddToCart,
      numberToAbbrev,
    };
  },
});
</script>
