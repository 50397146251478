import { OperationResult } from 'urql'
import { BaseApiService } from './common/BaseService'
import {
  AccountLevel,
  Challenge,
  LeaderboardUser,
  MutationSubmitChallengeEntryArgs,
  PointEarned,
  User,
} from '../gql/graphql'

export default class GameApi extends BaseApiService {
  public GetAuthUserGameProfile = () => {
    const requestData = `
    query GetAuthUserGameProfile {
      GetAuthUser {
         game_profile {
           total_points
           pending_badges {
            id
            uuid
            title
            photo_url
            description
           }
           badges{
              id
              uuid
              title
              photo_url
              description
            }
           account_level {
            id
            uuid
            title
            nick_name
            min_points
            offer
            badge {
              id
              uuid
              title
              photo_url
              description
            }
           }
           challenges {
             id
             uuid
             challenge {
              id
              uuid
              title
              type
              photo_url
              game_type
              sub_title
              target_point
              rule{
                reward_amount
                reward_game_points
              }
             }
            participant {
                uuid
                points
                twisted_word
                created_at
              }
             title
             twisted_word
             status
             created_at
           }
         }
      }
      
      }
      `

    const response: Promise<OperationResult<{
      GetAuthUser: User
    }>> = this.query(requestData, {})

    return response
  }

  public GetChallenges = () => {
    const requestData = `
        query GetChallenges {
            GetChallenges {
              id
              uuid
              title
              type
              photo_url
              game_type
              sub_title
              target_point
              rule{
                reward_amount
                reward_game_points
              }
              active_instance {
                uuid
                title
                status
                twisted_word
                participant {
                  uuid
                  twisted_word
                  points
                  created_at
                }
              }
            }
          }
            `

    const response: Promise<OperationResult<{
      GetChallenges: Challenge[]
    }>> = this.query(requestData, {})

    return response
  }

  public GetAccountLevels = () => {
    const requestData = `
        query GetAccountLevels {
          GetAccountLevels {
            id
            uuid
            title
            nick_name
            min_points
            offer
            badge {
              uuid
              title
              photo_url
              description
            }
           }
          }
            `

    const response: Promise<OperationResult<{
      GetAccountLevels: AccountLevel[]
    }>> = this.query(requestData, {})

    return response
  }

  public GetChallenge = (uuid: string) => {
    const requestData = `
    query GetChallenge($uuid: String!) {
        GetChallenge(uuid: $uuid) {
          id
          uuid
          title
          type
          photo_url
          game_type
          sub_title
          target_point
          description
          created_at
          rule{
            reward_amount
            reward_game_points
          }
          active_instance {
            uuid
            title
            status
            twisted_word
            participant {
              uuid
              points
              twisted_word
              created_at
            }
          }
        }
      }
        `

    const response: Promise<OperationResult<{
      GetChallenge: Challenge
    }>> = this.query(requestData, { uuid })

    return response
  }

  public CheckGameStatus = () => {
    const requestData = `
    query CheckGameStatus {
      CheckGameStatus
    }
    `

    const response: Promise<OperationResult<{
      CheckGameStatus: number[]
    }>> = this.query(requestData, {})

    return response
  }

  public GetChallengeLeaderboard = (challenge_uuid: string) => {
    const requestData = `
    query GetChallengeLeaderboard($challenge_uuid: String!) {
        GetChallengeLeaderboard(challenge_uuid: $challenge_uuid) {
          id
          uuid
          points
          index
          user {
            full_name
            uuid
            username
            profile {
              uuid
              photo_url
              subscription_plan{
                id
              }
            }
          }
        }
      }
        `

    const response: Promise<OperationResult<{
      GetChallengeLeaderboard: LeaderboardUser[]
    }>> = this.query(requestData, { challenge_uuid })

    return response
  }

  public JoinChallenge = (challenge_id: string) => {
    const requestData = `
    mutation JoinChallenge($challenge_id: String!) {
        JoinChallenge(challenge_id: $challenge_id)  
      }
        `

    const response: Promise<OperationResult<{
      JoinChallenge: boolean
    }>> = this.mutation(requestData, { challenge_id })

    return response
  }

  public SubmitChallengeEntry = (data: MutationSubmitChallengeEntryArgs) => {
    const requestData = `
    mutation SubmitChallengeEntry($challenge_uuid: String!, $account_type: AccountType!, $guess_word: String!) {
      SubmitChallengeEntry(
          challenge_uuid: $challenge_uuid
          account_type: $account_type
          guess_word: $guess_word
        ) {
          id
          uuid
          money_amount
          points
          description
          event_slug
          claimed
          processing_status
          entity_uuid
          processed_at
          expire_at
          created_at
          updated_at
        }
      }
		`
    const response: Promise<OperationResult<{
      SubmitChallengeEntry: PointEarned
    }>> = this.mutation(requestData, data)

    return response
  }

  public SaveBadgeAcknowledged = (badge_id: number) => {
    const requestData = `
    mutation SaveBadgeAcknowledged($badge_id: Int!) {
      SaveBadgeAcknowledged(badge_id: $badge_id)
    }
		`
    const response: Promise<OperationResult<{
      SaveBadgeAcknowledged: boolean
    }>> = this.mutation(requestData, { badge_id })

    return response
  }

  public ClaimReward = (point_gained_uuid: string) => {
    const requestData = `
    mutation ClaimReward($point_gained_uuid: String!) {
      ClaimReward(point_earned_uuid: $point_gained_uuid) {
        id
        uuid
        money_amount
        points
        description
        event_slug
        claimed
        processing_status
        entity_uuid
        processed_at
        expire_at
        created_at
        updated_at
      }
    }
		`
    const response: Promise<OperationResult<{
      ClaimReward: PointEarned
    }>> = this.mutation(requestData, { point_gained_uuid })

    return response
  }

  public UpdateProcessingTime = (point_gained_uuid: string) => {
    const requestData = `
    mutation UpdateProcessingTime($point_gained_uuid: String!) {
      UpdateProcessingTime(point_earned_uuid: $point_gained_uuid) 
    }
		`
    const response: Promise<OperationResult<{
      UpdateProcessingTime: number
    }>> = this.mutation(requestData, { point_gained_uuid })

    return response
  }
}
