import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col space-y-4 pt-4 px-4" }
const _hoisted_2 = { class: "w-full flex flex-row" }
const _hoisted_3 = { class: "w-full flex flex-row" }
const _hoisted_4 = { class: "w-full flex flex-row flex-wrap" }
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_text_field = _resolveComponent("app-text-field")!
  const _component_app_select = _resolveComponent("app-select")!
  const _component_AppButton = _resolveComponent("AppButton")!
  const _component_fixed_container = _resolveComponent("fixed-container")!
  const _component_subpage_layout = _resolveComponent("subpage-layout")!
  const _component_app_wrapper = _resolveComponent("app-wrapper")!

  return (_openBlock(), _createBlock(_component_app_wrapper, null, {
    default: _withCtx(() => [
      _createVNode(_component_subpage_layout, {
        title: _ctx.title,
        goBackHandler: _ctx.goBackHandler
      }, {
        "extra-topbar": _withCtx(() => [
          _createVNode(_component_app_normal_text, { "custom-class": '!text-primary-400 ' }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString("Step " + `(${_ctx.onboardingStep.current + "/" + _ctx.onboardingStep.total})`), 1)
            ]),
            _: 1
          })
        ]),
        "extra-topbar-row": _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass(`${
            _ctx.onboardingStep.current == _ctx.onboardingStep.total
              ? 'bg-highlight-400'
              : 'bg-highlight-50'
          }  w-full  rounded-[999px] flex justify-evenly bg-highlight-400`)
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.onboardingStep.total, (item, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: item,
                class: _normalizeClass(`${
              _ctx.onboardingStep.current >= item ? 'bg-highlight-400' : 'bg-transparent'
            } ${index == 0 ? 'rounded-l-[999px]' : ''}  ${
              index == _ctx.onboardingStep.total - 1 ? 'rounded-r-[999px]' : ' '
            } w-full h-1`)
              }, null, 2))
            }), 128))
          ], 2)
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (_ctx.onboardingStep.current == 1)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_app_normal_text, { class: "!text-[#664D7E]" }, {
                      default: _withCtx(() => _cache[4] || (_cache[4] = [
                        _createTextVNode(" Verify Your Phone Number. ")
                      ])),
                      _: 1
                    })
                  ]),
                  _createVNode(_component_app_text_field, {
                    placeholder: 'Enter phone number',
                    modelValue: _ctx.phoneVerifyForm.phone,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.phoneVerifyForm.phone) = $event)),
                    hasTitle: true,
                    type: "tel",
                    padding: 'py-3 px-3',
                    name: "Phone Number",
                    ref: "phone",
                    updateValue: _ctx.phoneVerifyForm.phone,
                    rules: [
              _ctx.FormValidations.RequiredRule,
              _ctx.FormValidations.customValidator(
                _ctx.phoneVerifyForm.phone.length == 11,
                'Phone number must be valid'
              ),
            ],
                    "content-rule": {
              addAfterCount: 0,
              characterToAdd: ``,
              max: 11,
            }
                  }, {
                    title: _withCtx(() => _cache[5] || (_cache[5] = [
                      _createTextVNode(" Phone Number ")
                    ])),
                    _: 1
                  }, 8, ["modelValue", "updateValue", "rules"])
                ], 64))
              : _createCommentVNode("", true),
            (_ctx.onboardingStep.current == 2)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createVNode(_component_app_text_field, {
                    "focus-border": 'border-primary-400',
                    "has-title": true,
                    "custom-class": '!bg-grey-50',
                    placeholder: 'Select date',
                    modelValue: _ctx.aboutForm.date_of_birth,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.aboutForm.date_of_birth) = $event)),
                    name: "Date of birth",
                    type: "date",
                    ref: "dob",
                    required: "",
                    rules: [
              _ctx.FormValidations.RequiredRule,
              _ctx.FormValidations.customValidator(
                _ctx.mustBe18OrAbove(_ctx.aboutForm.date_of_birth),
                `You must be 18 years old or above`
              ),
            ]
                  }, {
                    title: _withCtx(() => _cache[6] || (_cache[6] = [
                      _createTextVNode(" Your date of birth ")
                    ])),
                    _: 1
                  }, 8, ["modelValue", "rules"]),
                  _createVNode(_component_app_select, {
                    placeholder: 'Select your gender',
                    hasTitle: true,
                    paddings: 'py-3 px-4',
                    options: _ctx.genderOption,
                    required: "",
                    ref: "gender",
                    modelValue: _ctx.aboutForm.gender,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.aboutForm.gender) = $event))
                  }, {
                    title: _withCtx(() => _cache[7] || (_cache[7] = [
                      _createTextVNode(" Your gender ")
                    ])),
                    _: 1
                  }, 8, ["options", "modelValue"])
                ], 64))
              : _createCommentVNode("", true),
            (_ctx.onboardingStep.current == 3)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_app_normal_text, { class: "!text-[#664D7E]" }, {
                      default: _withCtx(() => _cache[8] || (_cache[8] = [
                        _createTextVNode(" What kind of cashback offers and deals are you interested in? Select at least 5 ")
                      ])),
                      _: 1
                    })
                  ]),
                  _createElementVNode("div", _hoisted_4, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.shoppingCategories().filter(
                (item) => item.is_parent
              ), (category, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "py-2 pr-2",
                        key: index
                      }, [
                        _createElementVNode("div", {
                          class: _normalizeClass(`border-[1px]  ${
                  !_ctx.selectedCategory.includes(category.name)
                    ? 'border-[#E8E8E8]'
                    : 'border-[#9D4EDD]'
                } rounded-[48px] py-1 px-2 space-x-2 items-center flex flex-row ${
                  !_ctx.selectedCategory.includes(category.name) ? '' : '!bg-[#9D4EDD]'
                }`),
                          onClick: ($event: any) => (_ctx.toggleCategory(category.name))
                        }, [
                          _createVNode(_component_app_normal_text, {
                            class: _normalizeClass(` ${
                    !_ctx.selectedCategory.includes(category.name)
                      ? '!text-[#240046]'
                      : '!text-white'
                  }`)
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(category.name), 1)
                            ]),
                            _: 2
                          }, 1032, ["class"])
                        ], 10, _hoisted_5)
                      ]))
                    }), 128))
                  ])
                ], 64))
              : _createCommentVNode("", true),
            _cache[9] || (_cache[9] = _createElementVNode("div", { class: "h-[70px]" }, null, -1))
          ]),
          _createVNode(_component_fixed_container, { class: "w-full flex-col flex bottom-0 left-0" }, {
            default: _withCtx(() => [
              _createVNode(_component_AppButton, {
                padding: 'py-3',
                "custom-class": `rounded-[40px] w-full ${
            !_ctx.formIItemIsValid ? '!opacity-[60%]' : ''
          }`,
                "bg-color": 'bg-primary-main',
                "text-color": 'text-white',
                loading: _ctx.loaderSetup.loading,
                onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.moveForward()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Continue" + _toDisplayString(_ctx.onboardingStep.current == 2 && _ctx.selectedCategory.length > 0
              ? `(${_ctx.selectedCategory.length})`
              : ""), 1)
                ]),
                _: 1
              }, 8, ["custom-class", "loading"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["title", "goBackHandler"])
    ]),
    _: 1
  }))
}