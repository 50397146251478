import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col space-y-3 py-4 pt-2 relative md:!pb-0 mdlg:!pb-0" }
const _hoisted_2 = { class: "w-full flex flex-col pt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_button = _resolveComponent("app-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_app_normal_text, {
      customClass: 'text-left',
      class: "!text-gray-700"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.data.info), 1)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_app_button, {
        class: "w-full py-3",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.action()))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.data.cta_copy), 1)
        ]),
        _: 1
      })
    ])
  ]))
}