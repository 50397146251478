<template>
  <div
    :class="`w-full flex flex-col ${isFullScreen ? 'h-full' : 'h-auto'} `"
    :id="`story-${uniqueKey}`"
  >
    <template v-for="(item, index) in data" :key="index">
      <app-media-box
        @touchstart="!videoIsReady && pauseTimer()"
        @touchend="!videoIsReady && resumeTimer()"
        :data="item.media"
        :isVideo="!item.media.is_grid"
        :playButtonStyle="'!h-[50px]'"
        class="!rounded-[0px] !relative mdlg:!rounded-[10px] md:!rounded-[10px]"
        v-if="currentIndex == index && contentIsMounted"
        :isGrid="false"
        :isFullScreen="isFullScreen"
        ref="mediaBoxRef"
        @videoSettingsChanged="
          (data) => {
            videoSettings = data;
          }
        "
        @currentTime="
          (data) => {
            timeLoader.current = data;
          }
        "
        @totalDuration="
          (data) => {
            baseDuration = data;
          }
        "
        :style="
          !isFullScreen
            ? `height: calc(100vh - env(safe-area-inset-bottom) -  env(safe-area-inset-top) - 120px);`
            : 'height: 100%'
        "
        :allow-play="true"
        :loopVideo="false"
        @loaded="imageLoaded = true"
      >
        <div class="w-full h-full absolute top-0 left-0">
          <div class="w-full h-full flex flex-col justify-between">
            <!-- Top sections -->
            <div
              class="w-full flex flex-col space-y-2 bg-gradient-to-b from-black !from-opacity-20 to-transparent px-3 py-3 h-[20%] relative mdlg:!rounded-t-[10px] md:!rounded-t-[10px]"
              :style="` padding-top: ${
                isFullScreen ? ' calc(env(safe-area-inset-top) + 12px)' : '12px'
              }  !important;`"
              @click.stop="null"
            >
              <!-- Progress Bar -->
              <div
                class="w-full h-[3px] bg-white !bg-opacity-50 rounded-full overflow-hidden"
                v-if="!hasEdit"
              >
                <div
                  class="h-full bg-white transition-all duration-200 ease-linear"
                  :style="{
                    width: `${(timeLoader.current / timeLoader.total) * 100}%`,
                  }"
                ></div>
              </div>
              <!-- Story info -->
              <div class="w-full flex flex-row items-center justify-between">
                <div class="flex flex-row space-x-1 items-center">
                  <app-image-loader
                    :photoUrl="item.media.user_info.image"
                    :customClass="'!h-[40px] !w-[40px] rounded-full cursor-pointer'"
                    @click.stop="handleActions('profile')"
                  />
                  <div class="flex flex-col items-start">
                    <app-normal-text class="!font-semibold !text-white">
                      {{ item.media.user_info.name }}
                    </app-normal-text>

                    <app-normal-text class="!font-semibold !text-white !text-[10px]">
                      @{{ item.media.user_info.handle }}
                    </app-normal-text>
                  </div>
                </div>

                <div class="flex flex-row items-center space-x-3">
                  <div
                    v-if="videoSettings.loading"
                    class="w-[40px] h-[40px] bg-black bg-opacity-50 rounded-full flex items-center justify-center"
                  >
                    <svg
                      :class="`animate-spin  h-5 w-5  text-white`"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        class="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        stroke-width="4"
                      ></circle>
                      <path
                        class="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  </div>
                  <div
                    v-if="item.media.video_url && !videoSettings.paused"
                    class="w-[40px] h-[40px] bg-black bg-opacity-50 rounded-full flex items-center justify-center cursor-pointer"
                    @click.stop="mediaBoxRef[0].toggleMuteUnmute()"
                  >
                    <app-icon
                      :name="videoSettings.muted ? 'mute-white' : 'unmute-white'"
                      class="!h-[14px]"
                    />
                  </div>

                  <app-icon
                    v-if="isFullScreen"
                    @click.stop="Logic.Common.goBack()"
                    :name="'cross-white'"
                    :customClass="'h-[17px] cursor-pointer'"
                  />
                </div>
              </div>
            </div>

            <!-- Bottom sections -->
            <transition name="slide-out-bottom">
              <div
                v-if="
                  (item.media.video_url && videoSettings.paused) ||
                  !item.media.video_url ||
                  !hideOnPlay
                "
                class="w-full flex flex-row justify-between items-end px-3 py-3 h-[40%] mdlg:!rounded-b-[10px] md:!rounded-b-[10px]"
                :style="`background: linear-gradient(to top, rgba(0, 0, 0, 0.6), transparent);   padding-bottom: ${
                  isFullScreen ? ' calc(env(safe-area-inset-bottom) + 12px)' : '12px'
                } !important;`"
              >
                <div class="flex flex-col space-y-3 w-full">
                  <!-- Action icons -->
                  <div
                    class="flex flex-col space-y-4 w-full justify-end items-end"
                    v-if="!hasEdit"
                  >
                    <span
                      class="flex flex-col space-y-1 items-center justify-center"
                      v-if="item.media.data?.uuid"
                    >
                      <span
                        @click.stop="handleActions('like')"
                        class="h-[40px] w-[40px] rounded-full bg-white !bg-opacity-20 flex items-center justify-center cursor-pointer"
                      >
                        <app-icon
                          :name="isLiked ? 'heart-red' : 'heart-white'"
                          class="!h-[18px]"
                        >
                        </app-icon>
                      </span>
                      <app-normal-text class="!text-white !text-[10px]">
                        {{ numberToAbbrev(likeCount) }}
                      </app-normal-text>
                    </span>

                    <!-- <span
                      class="h-[40px] w-[40px] rounded-full bg-white !bg-opacity-20 flex items-center justify-center"
                      @click.stop="handleActions('chat')"
                    >
                      <app-icon name="message-white" class="!h-[18px]">
                      </app-icon>
                    </span> -->
                    <span
                      class="h-[40px] w-[40px] rounded-full bg-white !bg-opacity-20 flex items-center justify-center cursor-pointer"
                      @click.stop="handleActions('share')"
                    >
                      <app-icon name="share-white-story" class="!h-[18px]"> </app-icon>
                    </span>
                  </div>
                  <!-- All tags  and icons-->
                  <div
                    class="w-full flex flex-row justify-between items-end"
                    v-if="item.type != 'polls'"
                  >
                    <div
                      class="flex flex-row space-x-2 items-center flex-nowrap"
                      v-if="item.media.tags.length"
                    >
                      <span
                        class="px-2 py-[1px] bg-transparent rounded-[20px] border-[1px] border-white"
                        v-for="(tag, index) in item.media.tags"
                        :key="index"
                      >
                        <app-normal-text class="!text-white !text-[11px]">
                          {{ tag }}
                        </app-normal-text>
                      </span>
                    </div>
                  </div>

                  <!-- For Shoplist type -->
                  <template v-if="item.type == 'shoplist'">
                    <div
                      class="w-full bg-white dark:!bg-gray-900 !bg-opacity-[84%] rounded-[10px] py-3 px-3 flex flex-row space-x-3"
                    >
                      <!-- Grid images -->
                      <div class="w-[90px]">
                        <div class="w-[90px] h-[90px] grid grid-cols-2 z-0">
                          <app-image-loader
                            :photoUrl="image.url"
                            :class="`${image.width} h-full rounded-none`"
                            v-for="(image, index) in gridImages"
                            :key="index"
                            :style="`${image.border} `"
                          />
                        </div>
                      </div>

                      <div class="w-full flex flex-col space-y-2 justify-between">
                        <!-- Shoplist info -->
                        <div class="w-full flex flex-col space-y-1">
                          <app-normal-text class="!font-bold !text-sm text-left">
                            {{ item.shoplist.title }}
                          </app-normal-text>

                          <div
                            class="w-full flex flex-row space-x-2 items-center"
                            v-if="!hideExtraShoplistInfo"
                          >
                            <app-normal-text class="!text-[11px] text-left">
                              {{ item.shoplist.base_tag }}
                            </app-normal-text>
                            <span
                              class="h-[3px] w-[4px] rounded-full bg-black dark:!bg-white"
                            >
                            </span>
                            <app-normal-text
                              :customClass="'!whitespace-nowrap !text-[11px] mdlg:!text-[12px] font-semibold '"
                            >
                              Earn
                              <span class="font-semibold pl-[1px]"
                                >{{ numberToAbbrev(item.media.points || 0) }} Pts</span
                              >
                            </app-normal-text>
                          </div>
                        </div>

                        <!-- Price and CTA -->
                        <div class="w-full flex flex-row items-center justify-between">
                          <app-normal-text class="!font-bold !text-base text-left">
                            {{
                              Logic.Common.convertToMoney(
                                item.shoplist.price,
                                false,
                                "ngn"
                              )
                            }}
                          </app-normal-text>

                          <div class="flex flex-col" v-if="!hideExtraShoplistInfo">
                            <app-button
                              :padding="'px-4 py-2'"
                              class="!text-[12px] mdlg:!text-xs"
                              @click.stop="previewShoplist(item)"
                            >
                              Shoplist
                            </app-button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-if="item.type == 'website_visit'">
                    <div class="w-full flex flex-col">
                      <app-button
                        class="!bg-white w-full py-3 !rounded-[7px] !text-black dark:!text-black !font-semibold cursor-pointer"
                        @click.stop="openBrowser(item.website_visit.landing_page_url)"
                      >
                        {{ item.website_visit?.cta }}
                      </app-button>
                    </div>
                  </template>
                  <template v-if="item.type == 'polls'">
                    <div
                      class="w-full flex flex-col space-y-2 items-center justify-center"
                    >
                      <app-normal-text
                        class="w-full text-center !font-bold !text-xl !text-white uppercase pb-1 px-3"
                      >
                        {{ item.polls?.question }}
                      </app-normal-text>

                      <!-- Binary Options -->
                      <div
                        v-if="
                          item.polls?.answer_type === 'binary' ||
                          item.polls.options.length == 2
                        "
                        class="w-full grid grid-cols-2 gap-2"
                      >
                        <template v-for="(option, index) in getPollSummary" :key="index">
                          <template
                            v-if="
                              (item.polls.options.filter((eachitem) => eachitem.image_url)
                                .length == 0 &&
                                hasEdit) ||
                              (!hasEdit &&
                                pollSummary.filter((eachitem) => eachitem.image_url)
                                  .length == 0 &&
                                hasEdit)
                            "
                          >
                            <button
                              class="focus:outline-none col-span-1 py-3 bg-white rounded-[6px] flex justify-center items-center relative cursor-pointer"
                              @click.stop="handlePollAnswer(option.slug)"
                            >
                              <div
                                class="absolute top-0 left-0 w-full h-full flex flex-row items-center justify-center"
                              >
                                <span class="text-black !font-semibold !text-xs">
                                  {{ option.value }}
                                </span>

                                <div
                                  class="absolute top-0 left-0 w-full h-full flex flex-row items-center justify-center"
                                  v-if="hasAnsweredPoll"
                                >
                                  <div
                                    class="absolute top-0 left-0 h-full flex items-center justify-center bg-primary-100 rounded-bl-[6px] rounded-br-[6px]"
                                    :style="`width: ${option.percentage}%`"
                                  ></div>
                                  <div
                                    class="absolute top-0 right-0 px-1 py-1 h-full flex items-center justify-center z-20"
                                  >
                                    <span
                                      class="w-[30px] h-[30px] flex items-center justify-center"
                                    >
                                      <app-normal-text
                                        class="!text-black !text-[10px] !font-semibold"
                                      >
                                        {{
                                          parseFloat(
                                            parseFloat(
                                              option.percentage?.toFixed(2) || "0"
                                            ).toString()
                                          )
                                        }}%
                                      </app-normal-text>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </button>
                          </template>
                          <div
                            v-else
                            class="col-span-1 rounded-[6px] bg-white flex flex-col cursor-pointer"
                            @click.stop="handlePollAnswer(option.slug)"
                          >
                            <app-image-loader
                              :photoUrl="option.image_url || ''"
                              class="h-[120px] mdlg:!h-[150px] rounded-t-[6px] w-full"
                              @loaded="loadedImages++"
                            />
                            <div
                              class="py-1 flex flex-row items-center justify-center relative w-full"
                            >
                              <span class="text-black !font-semibold !text-[11px] z-50">
                                {{ option.value }}
                              </span>

                              <div
                                class="absolute top-0 left-0 w-full h-full flex flex-row items-center justify-center"
                                v-if="hasAnsweredPoll"
                              >
                                <div
                                  class="absolute top-0 left-0 h-full flex items-center justify-center bg-primary-100 rounded-bl-[6px] rounded-br-[6px]"
                                  :style="`width: ${option.percentage}%`"
                                ></div>
                                <div
                                  class="absolute top-0 right-0 px-1 py-1 h-full flex items-center justify-center z-20"
                                >
                                  <span
                                    class="w-[30px] h-[30px] flex items-center justify-center"
                                  >
                                    <app-normal-text
                                      class="!text-black !text-[10px] !font-semibold"
                                    >
                                      {{
                                        parseFloat(
                                          parseFloat(
                                            option.percentage?.toFixed(2) || "0"
                                          ).toString()
                                        )
                                      }}%
                                    </app-normal-text>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </template>
                      </div>

                      <!-- Multiple Options -->
                      <div
                        v-if="
                          item.polls?.answer_type === 'options' &&
                          item.polls.options.length > 2
                        "
                        class="w-full grid grid-cols-2 gap-3"
                      >
                        <template v-for="(option, index) in getPollSummary" :key="index">
                          <button
                            v-if="
                              item.polls?.option_type === 'plain' &&
                              item.polls.options.filter((eachitem) => eachitem.image_url)
                                .length == 0
                            "
                            class="focus:outline-none col-span-2 py-3 bg-white rounded-[6px] flex justify-center items-center cursor-pointer"
                            @click.stop="handlePollAnswer(option.slug)"
                          >
                            <span class="text-black !font-semibold !text-xs">
                              {{ option.value }}
                            </span>
                          </button>
                          <div
                            v-else
                            class="col-span-1 rounded-[6px] bg-white flex flex-col cursor-pointer"
                            @click.stop="handlePollAnswer(option.slug)"
                          >
                            <app-image-loader
                              :photoUrl="option.image_url || ''"
                              class="h-[120px] mdlg:!h-[150px] rounded-t-[6px] w-full"
                              @loaded="loadedImages++"
                            />
                            <div
                              class="py-2 flex flex-row items-center justify-center relative"
                            >
                              <span class="text-black !font-semibold !text-[11px] z-50">
                                {{ option.value }}
                              </span>
                              <div
                                class="absolute top-0 left-0 w-full h-full flex flex-row items-center justify-center"
                                v-if="hasAnsweredPoll"
                              >
                                <div
                                  class="absolute top-0 left-0 h-full flex items-center justify-center bg-primary-100 rounded-bl-[6px] rounded-br-[6px]"
                                  :style="`width: ${option.percentage}%`"
                                ></div>
                                <div
                                  class="absolute top-0 right-0 px-1 py-1 h-full flex items-center justify-center z-20"
                                >
                                  <span
                                    class="w-[30px] h-[30px] flex items-center justify-center"
                                  >
                                    <app-normal-text
                                      class="!text-black !text-[10px] !font-semibold"
                                    >
                                      {{
                                        parseFloat(
                                          parseFloat(
                                            option.percentage?.toFixed(2) || "0"
                                          ).toString()
                                        )
                                      }}%
                                    </app-normal-text>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </template>
                      </div>

                      <div class="w-full flex flex-row items-center justify-center pt-2">
                        <span
                          class="text-center text-gray-300 !text-[11px]"
                          v-if="!hasAnsweredPoll"
                        >
                          Vote to see poll result
                        </span>
                        <span class="text-center text-gray-300 !text-[11px]" v-else>
                          {{
                            numberToAbbrev(
                              pollSummary.reduce((acc, curr) => acc + curr.count, 0)
                            )
                          }}
                          Vote{{
                            pollSummary.reduce((acc, curr) => acc + curr.count, 0) > 1
                              ? "s"
                              : ""
                          }}
                        </span>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </transition>
          </div>
        </div>

        <!-- Bottom blank -->
        <div
          v-if="isFullScreen"
          class="w-full absolute bottom-0 left-0 bg-black z-30"
          style="height: calc(env(safe-area-inset-bottom))"
        ></div>
      </app-media-box>
    </template>
    <transition name="slide-out-bottom">
      <slot name="bottom-section" />
    </transition>
  </div>
</template>
<script lang="ts">
import { Logic, numberToAbbrev } from "../../composable";
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  toRef,
  watch,
} from "vue";
import AppIcon from "../AppIcon";
import AppMediaBox from "../AppMediaBox";
import { AppNormalText, AppHeaderText } from "../AppTypography";
import { Story } from "../../types";
import AppImageLoader from "../AppImageLoader";
import AppButton from "../AppButton";
import { FavoriteType, PollSummary } from "../../gql/graphql";

export default defineComponent({
  components: {
    AppIcon,
    AppNormalText,
    AppHeaderText,
    AppMediaBox,
    AppImageLoader,
    AppButton,
  },
  props: {
    data: {
      type: Object as () => Story[],
    },
    borderRadius: {
      type: Number,
      default: 10,
    },
    hasEdit: {
      type: Boolean,
      default: false,
    },
    isFullScreen: {
      type: Boolean,
      default: true,
    },
    hideOnPlay: {
      type: Boolean,
      default: true,
    },
    slideIndex: {
      type: Number,
      default: 0,
    },
    currentIndex: {
      type: Number,
      default: 0,
    },
    listenToSpaceBar: {
      type: Boolean,
      default: true,
    },
    hideExtraShoplistInfo: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["storyViewIsDone"],
  name: "AppStoryView",
  setup(props, context) {
    const currentIndex = ref(0);
    const handleAction = (action: string) => {
      if (action == "item-list") {
        Logic.Common.GoToRoute("/shoplist/id");
      }
    };

    const dataRef = toRef(props, "data");

    const contentIsMounted = ref(true);

    const hasAnsweredPoll = ref(false);

    const pollAnswer = ref("");

    const uniqueKey = Logic.Common.makeid(16);

    const baseDuration = ref(0);

    const timerInterval = ref<any>(null);

    const currentSlideIndex = toRef(props, "currentIndex");

    const timeLoader = reactive({
      current: 0,
      total: 5,
    });

    const slideIsActive = ref(false);

    const isLiked = ref(false);

    const likeCount = ref(0);

    const videoSettings = ref({
      paused: true,
      muted: false,
      current_time: 0,
      total_duration: 0,
      loading: false,
    });

    const dataToRef = toRef(props, "data");

    const loadedImages = ref<number>(0);

    const showSettings = ref(false);

    const mediaBoxRef = ref();

    const isPreviewMove = ref(false);

    const storyIndex = ref(0);

    const imageLoaded = ref(false);

    const pollSummary = ref<PollSummary[]>([]);

    const gridImages = ref<
      {
        url: string;
        width: string;
        border: string;
      }[]
    >([]);

    const getPollSummary = computed<Partial<PollSummary>[] | undefined>(() => {
      if (props.data) {
        return props.hasEdit ? props.data[0]?.polls?.options : pollSummary.value;
      } else {
        return [];
      }
    });

    const setGridImages = () => {
      if (!props.data) {
        return;
      }
      const propGridImages = props.data[0].shoplist?.product_images;

      let finalImages: string[] = [];
      if (propGridImages) {
        if (propGridImages.length <= 4) {
          finalImages = propGridImages;
        } else {
          finalImages = propGridImages.slice(0, 4);
        }
      }
      gridImages.value.length = 0;

      if (finalImages.length == 4) {
        gridImages.value.push({
          url: finalImages[0],
          width: `col-span-1`,
          border: `border-top-left-radius: ${props.borderRadius}px;`,
        });
        gridImages.value.push({
          url: finalImages[1],
          width: `col-span-1`,
          border: `border-top-right-radius: ${props.borderRadius}px;`,
        });
        gridImages.value.push({
          url: finalImages[2],
          width: `col-span-1 `,
          border: `border-bottom-left-radius: ${props.borderRadius}px;`,
        });
        gridImages.value.push({
          url: finalImages[3],
          width: `col-span-1`,
          border: `border-bottom-right-radius: ${props.borderRadius}px;`,
        });
      } else if (finalImages.length == 3) {
        gridImages.value.push({
          url: finalImages[0],
          width: `col-span-2`,
          border: `border-top-right-radius: ${props.borderRadius}px; border-top-left-radius: ${props.borderRadius}px;`,
        });
        gridImages.value.push({
          url: finalImages[1],
          width: `col-span-1`,
          border: `border-bottom-left-radius: ${props.borderRadius}px;`,
        });
        gridImages.value.push({
          url: finalImages[2],
          width: `col-span-1`,
          border: `border-bottom-right-radius: ${props.borderRadius}px;`,
        });
      } else if (finalImages.length == 2) {
        gridImages.value.push({
          url: finalImages[0],
          width: `col-span-1`,
          border: `border-top-left-radius: ${props.borderRadius}px; border-bottom-left-radius: ${props.borderRadius}px;`,
        });
        gridImages.value.push({
          url: finalImages[1],
          width: `col-span-1`,
          border: `border-top-right-radius: ${props.borderRadius}px; border-bottom-right-radius: ${props.borderRadius}px;`,
        });
      } else {
        gridImages.value.push({
          url: finalImages[0],
          width: `col-span-2`,
          border: `border-bottom-right-radius: ${props.borderRadius}px; border-bottom-left-radius: ${props.borderRadius}px; border-top-right-radius: ${props.borderRadius}px; border-top-left-radius: ${props.borderRadius}px;`,
        });
      }
    };

    const handleIntersect = (entries: any) => {
      entries.forEach((entry: any) => {
        if (entry.isIntersecting) {
          if (props.data?.length) {
            if (props.data[0].media.video_url) {
              setTimeout(() => {
                mediaBoxRef.value?.forEach((comp: any) => comp.resumeVideo());
              }, 400);
            }
          }
        } else {
          if (!videoSettings.value.paused) {
            if (props.data?.length) {
              if (props.data[0].media.video_url) {
                mediaBoxRef.value?.forEach((comp: any) => comp.pauseVideo());
              }
            }
          }
          pauseTimer();
        }
      });
    };

    const createObserver = () => {
      const options = {
        root: null, // Use the viewport as the root
        threshold: 0.1, // Trigger when 10% of the target is visible
      };

      const observer = new IntersectionObserver(handleIntersect, options);
      const targetElement = document.getElementById(`story-${uniqueKey}`);
      if (targetElement) {
        observer.observe(targetElement);
      }
    };

    const handlePollAnswer = (answer: string, force: boolean = false) => {
      if (props.hasEdit) {
        return;
      }

      console.log("handlePollAnswer", answer, force);

      if (pollAnswer.value && !force) {
        return;
      }

      pauseTimer();

      if (!force) {
        hasAnsweredPoll.value = true;
      }

      if (props.data) {
        hasAnsweredPoll.value = true;
        pollAnswer.value = answer;
        //  Update polls summary

        pollSummary.value =
          props.data[0]?.polls?.summary?.map((item: PollSummary) => {
            if (item.slug == answer) {
              item.count += 1;
            }
            return item;
          }) || [];

        // update poll percentage
        const totalVotes = pollSummary.value.reduce((acc, curr) => acc + curr.count, 0);
        pollSummary.value =
          pollSummary.value.map((item: PollSummary) => {
            item.percentage = totalVotes > 0 ? (item.count / totalVotes) * 100 : 0;
            return item;
          }) || [];

        Logic.Ad.CreateOrUpdateAdvertPollForm = {
          advert_uuid: props.data[0]?.sponsor_uuid || "",
          answer,
        };
        Logic.Ad.CreateOrUpdateAdvertPoll()?.then(() => {
          if (!force) {
            saveMetric("poll_completed");
          }
          resumeTimer();
        });

        Logic.User.UpdatePollVote(props.data[0]?.sponsor_uuid || "", answer);
      }
    };

    const openBrowser = async (url: string) => {
      saveMetric("clicked");
      pauseTimer();
      await Logic.Common.openBrowser(
        url,
        () => {},
        () => {
          saveMetric("page_loaded");
        }
      );
    };

    const previewShoplist = (item: any) => {
      if (props.hasEdit) {
        isPreviewMove.value = true;
      } else {
        saveMetric("clicked");
        isPreviewMove.value = false;
        if (props.data) {
          if (props.data[0]?.is_sponsored) {
            localStorage.setItem(
              `sponsored_shoplist_${props.data[0].shoplist?.uuid}`,
              `${props.data[0].sponsor_uuid}`
            );
            localStorage.setItem(
              `sponsored_shoplist_request_uuid_${props.data[0].shoplist?.uuid}`,
              `${props.data[0].request_uuid}`
            );
          }
        }
      }
      Logic.Shoplist.SingleShoplistCollection = item.media.data;

      pauseTimer();

      Logic.Common.GoToRoute(
        `/shoplist/${item.media.uuid}${props.hasEdit ? "?preview=yes" : ""}`
      );
    };

    const videoIsReady = computed(() => {
      return (
        props.isFullScreen &&
        props.data &&
        (props.data[0]?.media?.video_url?.length || 0) > 0
      );
    });

    const setTimeLoader = (reset: boolean = true) => {
      if (videoIsReady.value) {
        timeLoader.current = 0;
        if (mediaBoxRef.value) {
          const mediaComp = mediaBoxRef.value[0];
          const checkVideoDuration = setInterval(() => {
            if (mediaComp.videoTotalDuration) {
              timeLoader.total = mediaComp.videoTotalDuration;
              clearInterval(checkVideoDuration);
            }
          }, 100);
        }
        saveMetric("viewed");
      } else {
        if (!imageLoaded.value) {
          return;
        }
        if (props.data) {
          if (props.data[0]?.type == "polls") {
            let imagesCount = 0;
            props.data[0]?.polls?.options.forEach((option: any) => {
              if (option.image_url) {
                imagesCount++;
              }
            });

            if (loadedImages.value < imagesCount) {
              return;
            }
          }
        }
        saveMetric("viewed");
        if (reset) {
          timeLoader.current = 0;
          timeLoader.total = 6;
        }

        if (timerInterval.value) {
          clearInterval(timerInterval.value);
        }

        timerInterval.value = setInterval(() => {
          timeLoader.current += 1 / 5;
          if (timeLoader.current >= timeLoader.total) {
            clearInterval(timerInterval.value);

            timerInterval.value = null;
          }
        }, 200);
      }
    };

    const runOnIonViewDidEnter = async () => {
      if (mediaBoxRef.value) {
        mediaBoxRef.value?.forEach((comp: any) => comp.runOnIonViewDidEnter());
      }
      isPreviewMove.value = false;
    };

    const runOnIonViewDidLeave = () => {
      if (!isPreviewMove.value) {
        if (mediaBoxRef.value) {
          mediaBoxRef.value?.forEach((comp: any) => comp.runOnIonViewDidLeave());
        }
      }
    };

    const pauseTimer = () => {
      if (videoIsReady.value) {
        if (mediaBoxRef.value) {
          mediaBoxRef.value?.forEach((comp: any) => comp.pauseVideo());
        }
      } else {
        if (timerInterval.value) {
          clearInterval(timerInterval.value);

          timerInterval.value = null;
        }
      }
    };

    const resumeTimer = () => {
      if (videoIsReady.value) {
        if (mediaBoxRef.value) {
          mediaBoxRef.value?.forEach((comp: any) => comp.resumeVideo());
        }
      } else {
        setTimeLoader(false);
      }
    };

    const handleActions = (action: "like" | "chat" | "share" | "profile") => {
      if (action == "like") {
        isLiked.value = !isLiked.value;

        if (!isLiked.value) {
          likeCount.value--;
        } else {
          likeCount.value++;
          saveMetric("liked");
        }

        Logic.Shoplist.LikeUnlikeCollectionForm = {
          content: isLiked.value ? FavoriteType.Like : FavoriteType.Unlike,
          collection_uuid: props.data ? props.data[0]?.media?.data?.uuid : "",
        };
        Logic.Shoplist.LikeUnlikeCollection()?.then(() => {
          if (props.data) {
            Logic.Shoplist.GetShoplistCollection(
              props.data ? props.data[0]?.media?.data?.uuid || "" : ""
            );
          }
        });
      } else if (action == "profile") {
        if (props.data) {
          pauseTimer();
          Logic.Common.GoToRoute(
            `/brand?uuid=${props.data[0]?.media?.data?.business?.uuid}`
          );
        }
      }
    };

    const onSlideInView = (isActive: boolean, index: number) => {
      console.log("onSlideInView", index);

      loadedImages.value = 0;

      if (mediaBoxRef.value) {
        mediaBoxRef.value?.forEach((comp: any) => comp.runOnIonViewDidEnter());
      }

      storyIndex.value = index;
      timeLoader.current = 0;

      isPreviewMove.value = false;
      contentIsMounted.value = true;

      if (isActive) {
        slideIsActive.value = true;
        setTimeLoader(!isPreviewMove.value);
      }
    };

    const onSlideOutView = (mild: boolean = false) => {
      console.log("onSlideOutView");
      timeLoader.current = 0;
      if (!isPreviewMove.value && !mild) {
        if (mediaBoxRef.value) {
          mediaBoxRef.value?.forEach((comp: any) => comp.runOnIonViewDidLeave());
        }
      }

      if (!mild) {
        contentIsMounted.value = false;
      }

      if (timerInterval.value) {
        clearInterval(timerInterval.value);

        timerInterval.value = null;
      }

      slideIsActive.value = false;
    };

    const closeBottom = () => {
      showSettings.value = false;
    };

    const saveMetric = (event_type: string) => {
      if (props.data && slideIsActive.value) {
        if (props.data[0]?.shoplist?.uuid) {
          Logic.Ad.SaveMetric({
            entity_type: "shoplist",
            event_type,
            entity_uuid: props.data[0]?.shoplist?.uuid,
            event_metadata: JSON.stringify({
              type: "shoplist",
              shoplist_uuid: props.data[0]?.shoplist?.uuid,
              story_index: storyIndex.value,
            }),
          });
        }

        if (props.data[0]?.is_sponsored) {
          Logic.Ad.SaveMetric({
            entity_type: "advert",
            event_type,
            entity_uuid: props.data[0]?.sponsor_uuid || "",
            event_metadata: JSON.stringify({
              type: "advert",
              advert_uuid: props.data[0]?.sponsor_uuid,
              story_index: storyIndex.value,
              request_uuid: props.data[0]?.request_uuid,
            }),
          });
        }
      }
    };

    watch(imageLoaded, () => {
      if (imageLoaded.value) {
        setTimeLoader();
      }
    });

    watch(loadedImages, () => {
      if (loadedImages.value) {
        setTimeLoader();
      }
    });

    watch(dataRef, () => {
      if (dataRef.value) {
        setGridImages();
      }
    });

    watch(timeLoader, () => {
      // Save "watched_{seconds}" metric every 5 seconds
      if (timeLoader.current % 5 < 1 && timeLoader.current > 0) {
        const watchedSeconds = Math.floor(timeLoader.current);
        if (watchedSeconds > 0) {
          Logic.Common.debounce(() => {
            saveMetric(`watched_${watchedSeconds}`);
          }, 500);
        }
      }
      if (timeLoader.current >= timeLoader.total) {
        context.emit("storyViewIsDone", storyIndex.value);
        saveMetric("watch_completed");
      }
    });

    onMounted(() => {
      setGridImages();
      setTimeout(() => {
        createObserver();
      }, 500);
      document.addEventListener("visibilitychange", () => {
        setTimeout(() => {
          if (window.location.pathname == "/story") {
            if (document.hidden) {
              if (currentSlideIndex.value == props.slideIndex) {
                pauseTimer();
              }
            } else {
              if (currentSlideIndex.value == props.slideIndex) {
                resumeTimer();
              }
            }
          }
        }, 500);
      });

      if (props.data) {
        isLiked.value = props.data[0]?.media?.data?.user_is_favorite || false;
        likeCount.value = props.data[0]?.media?.data?.favorites_count || 0;

        if (props.data[0]?.type == "polls" && props.data[0]?.polls?.summary?.length) {
          pollSummary.value = props.data[0]?.polls?.summary;

          const pollOptions = props.data[0]?.polls?.options;

          pollSummary.value.forEach((item: PollSummary) => {
            if (pollOptions) {
              item.image_url = pollOptions.find(
                (option: any) => option.slug == item.slug
              )?.image_url;
            }
          });

          if (props.data[0]?.polls?.user_poll_vote) {
            pollAnswer.value = props.data[0]?.polls?.user_poll_vote.answer;
            hasAnsweredPoll.value = true;
          }

          // Subscribe to advert poll
          Logic.Ad.SubscribeToAdvertPoll(
            props.data[0]?.sponsor_uuid || "",
            (data: any) => {
              if (data.user_id != Logic.Auth.AuthUser?.id) {
                handlePollAnswer(data.answer, true);
              }
            }
          );
        }
      }
      // Listen to spacebar
      if (props.listenToSpaceBar) {
        const handleSpacebar = (event: KeyboardEvent) => {
          if (event.code === "Space") {
            event.preventDefault();
            if (timerInterval.value || videoSettings.value?.paused == false) {
              pauseTimer();
            } else {
              resumeTimer();
            }
          }
        };

        window.addEventListener("keydown", handleSpacebar);
      }
    });

    onUnmounted(() => {
      window.removeEventListener("keydown", () => {
        //
      });
    });

    return {
      Logic,
      currentIndex,
      gridImages,
      mediaBoxRef,
      videoSettings,
      showSettings,
      uniqueKey,
      isLiked,
      contentIsMounted,
      timeLoader,
      baseDuration,
      videoIsReady,
      timerInterval,
      imageLoaded,
      likeCount,
      pollAnswer,
      hasAnsweredPoll,
      pollSummary,
      getPollSummary,
      loadedImages,
      handlePollAnswer,
      numberToAbbrev,
      openBrowser,
      handleAction,
      closeBottom,
      runOnIonViewDidEnter,
      runOnIonViewDidLeave,
      onSlideInView,
      onSlideOutView,
      previewShoplist,
      setTimeLoader,
      pauseTimer,
      resumeTimer,
      handleActions,
    };
  },
});
</script>
<style scoped>
.slide-out-bottom-enter-active,
.slide-out-bottom-leave-active {
  transition: transform 0.5s ease;
}
.slide-out-bottom-enter {
  transform: translateY(100%);
}
.slide-out-bottom-leave-to {
  transform: translateY(100%);
}

body {
  background: black !important;
}
</style>
