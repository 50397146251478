<template>
  <div :class="` ${customClass} `">
    <swiper
      :slides-per-view="slidePerView"
      :space-between="spaceBetween"
      @swiper="onSwiper"
      :modules="modules"
      @slideChange="onSlideChange"
      :class="swiperClass"
      :direction="direction"
      :autoplay="autoPlay"
      :loop="loop"
      ref="swiperRef"
      :id="`swiperRef${tabIndex}`"
    >
      <slot />
    </swiper>
    <div
      v-if="showPagination"
      class="w-full pt-3 flex flex-row items-center space-x-1 justify-center cursor-pointer"
    >
      <span
        :class="`rounded w-[25px] h-[3px] ${
          activeSlide == index ? 'bg-primary-400' : 'bg-grey-200 bg-opacity-60'
        }`"
        v-for="(slide, index) in slideCount"
        @click="swiperInstance?.slideTo(index)"
        :key="index"
      >
      </span>
    </div>
  </div>
</template>
<script lang="ts">
// Import Swiper Vue.js components
import { Swiper } from "swiper/vue";

// Import Swiper styles
import "swiper/css";

// import required modules
import { Autoplay } from "swiper/modules";
import { defineComponent, onBeforeUnmount, onMounted, ref, toRef, watch } from "vue";

export default defineComponent({
  components: {
    Swiper,
  },
  props: {
    customClass: {
      type: String,
      default: "",
    },
    slideCount: {
      type: Number,
      default: 3,
    },
    showPagination: {
      type: Boolean,
      default: false,
    },
    slidePerView: {
      type: Number as () => any,
      default: "auto",
    },
    spaceBetween: {
      type: Number,
      default: 15,
    },
    freeMode: {
      type: Boolean,
      default: true,
    },
    loop: {
      type: Boolean,
      default: false,
    },
    swiperClass: {
      type: String,
      default: "pr-6",
    },
    currentSlidePosition: {
      type: Number,
      default: 0,
    },
    direction: {
      type: String as () => "vertical" | "horizontal",
      default: "horizontal",
    },
    autoPlay: {
      type: Object as () => any,
      default: {},
    },
  },
  name: "AppSwiper",
  emits: ["update:modelValue"],
  setup(props, context: any) {
    const activeSlide = ref(0);
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    const tabIndex = Math.floor(Math.random() * 10000);

    const swiperRef = ref();
    const observer = ref();

    const swiperInstance = ref<any>();
    const onSwiper = (swiper: any) => {
      if (!props.autoPlay.delay) {
        swiper.autoplay.stop();
      }
      swiperInstance.value = swiper;
    };

    const updateSwiper = () => {
      swiperInstance.value.update();
    };

    const onSlideChange = (swiper: any) => {
      activeSlide.value = swiper.activeIndex;
      context.emit("update:modelValue", activeSlide.value);
    };

    const handleIntersect = (entries: any) => {
      if (entries.length) {
        swiperInstance.value.autoplay.start();
      }
      entries.forEach((entry: any) => {
        //
      });
    };

    const goToNextSlide = () => {
      swiperInstance.value?.slideNext();
    };

    const goToPrevSlide = () => {
      swiperInstance.value?.slidePrev();
    };

    const createObserver = () => {
      const options = {
        root: null, // Use the viewport as the root
        threshold: 0.1, // Trigger when 10% of the target is visible
      };

      observer.value = new IntersectionObserver(handleIntersect, options);
      const targetElement = document.getElementById(`swiperRef${tabIndex}`);
      observer.value.observe(targetElement);
    };

    const currentSlidePositionRef = toRef(props, "currentSlidePosition");

    watch(currentSlidePositionRef, () => {
      swiperInstance.value.slideTo(currentSlidePositionRef.value);
    });

    onMounted(() => {
      if (props.autoPlay.delay) {
        setTimeout(() => {
          createObserver();
        }, 500);
      }
    });

    onBeforeUnmount(() => {
      if (props.autoPlay.delay) {
        observer.value.disconnect();
      }
    });

    return {
      onSwiper,
      onSlideChange,
      updateSwiper,
      goToNextSlide,
      goToPrevSlide,
      swiperInstance,
      modules: [Autoplay],
      activeSlide,
      swiperRef,
      tabIndex,
    };
  },
});
</script>
