import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col space-y-3 h-full flex-grow" }
const _hoisted_2 = { class: "w-full flex flex-col px-4" }
const _hoisted_3 = { class: "flex flex-row items-center justify-between" }
const _hoisted_4 = { class: "w-full flex flex-col space-y-1" }
const _hoisted_5 = { class: "w-full h-[8px] rounded-[10px] bg-secondary-50 relative" }
const _hoisted_6 = { class: "w-full flex flex-row items-center justify-between" }
const _hoisted_7 = { class: "space-x-1 flex flex-row items-center" }
const _hoisted_8 = { class: "space-x-1 flex flex-row items-center" }
const _hoisted_9 = { class: "w-full flex flex-col px-4" }
const _hoisted_10 = { class: "w-full flex flex-row justify-center items-center h-[98px] space-x-2 bg-grey-50 dark:bg-gray-900 rounded-[10px]" }
const _hoisted_11 = { class: "flex flex-col space-y-0 items-start pt-[4px]" }
const _hoisted_12 = { class: "!font-normal !text-xs" }
const _hoisted_13 = { class: "w-full flex flex-col space-y-4 bg-white dark:bg-black rounded-[12px] py-4 px-4 items-center" }
const _hoisted_14 = {
  key: 0,
  class: "w-full flex flex-row items-center justify-between pb-3 border-b-[1px] border-[#E8E8E8]"
}
const _hoisted_15 = { class: "w-full grid grid-cols-2 gap-3" }
const _hoisted_16 = { class: "col-span-1 flex flex-col space-y-1 items-start" }
const _hoisted_17 = { class: "col-span-1 flex flex-col space-y-1 items-end" }
const _hoisted_18 = { class: "w-full flex flex-col space-y-2 border-t-[1px] border-[#E8E8E8] pt-3" }
const _hoisted_19 = { class: "w-full flex flex-col space-y-3" }
const _hoisted_20 = { class: "w-full flex flex-col space-y-1 border-t-[1px] border-[#E8E8E8] pt-3" }
const _hoisted_21 = { class: "w-full flex flex-col space-y-2" }
const _hoisted_22 = { class: "w-full flex flex-row items-center justify-between" }
const _hoisted_23 = { class: "w-full flex flex-col space-y-1 border-t-[1px] border-[#E8E8E8] pt-3" }
const _hoisted_24 = { class: "w-full flex flex-col space-y-2" }
const _hoisted_25 = { class: "w-full flex flex-row items-center justify-between" }
const _hoisted_26 = {
  key: 1,
  class: "w-full flex flex-col py-3 px-3 rounded-[12px] bg-primary-50"
}
const _hoisted_27 = { class: "!font-bold" }
const _hoisted_28 = { class: "!font-bold" }
const _hoisted_29 = { class: "col-span-1 flex flex-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_image_loader = _resolveComponent("app-image-loader")!
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_header_text = _resolveComponent("app-header-text")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_fixed_container = _resolveComponent("fixed-container")!
  const _component_subpage_layout = _resolveComponent("subpage-layout")!
  const _component_app_wrapper = _resolveComponent("app-wrapper")!

  return (_openBlock(), _createBlock(_component_app_wrapper, null, {
    default: _withCtx(() => [
      _createVNode(_component_subpage_layout, {
        title: 'Receipt',
        hasSearch: false,
        hasCart: false
      }, {
        "extra-topbar": _withCtx(() => [
          _createElementVNode("div", {
            class: "px-4 py-1 bg-primary-50 dark:bg-primary-500 rounded-[999px] flex items-center justify-center flex-row cursor-pointer",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.reportReceipt()))
          }, [
            _createVNode(_component_app_normal_text, { class: "!text-primary-500 !font-semibold dark:!text-primary-50" }, {
              default: _withCtx(() => _cache[4] || (_cache[4] = [
                _createTextVNode(" Report ")
              ])),
              _: 1
            })
          ])
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              (!_ctx.isEditable)
                ? (_openBlock(), _createBlock(_component_app_image_loader, {
                    key: 0,
                    photoUrl: '/images/purple-bg.svg',
                    class: "w-full flex flex-col space-y-3 px-3 py-3 rounded-[10px]"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_3, [
                        _createVNode(_component_app_normal_text, { class: "!text-white" }, {
                          default: _withCtx(() => _cache[5] || (_cache[5] = [
                            _createTextVNode("Weekly Spend limit")
                          ])),
                          _: 1
                        }),
                        _createVNode(_component_app_normal_text, {
                          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.Logic.Common.GoToRoute('/subscriptions'))),
                          class: "!underline !text-[#D8B8F1] cursor-pointer"
                        }, {
                          default: _withCtx(() => _cache[6] || (_cache[6] = [
                            _createTextVNode("Upgrade ")
                          ])),
                          _: 1
                        })
                      ]),
                      _createElementVNode("div", _hoisted_4, [
                        _createElementVNode("div", _hoisted_5, [
                          _createElementVNode("div", {
                            class: "absolute top-0 left-0 h-full rounded-[10px] bg-[#FFC44D]",
                            style: _normalizeStyle(`width: ${
                    ((_ctx.AuthUser?.receipt_week_spend || 0) /
                      parseFloat(
                        _ctx.AuthUser?.profile?.subscription_plan
                          .receipt_weekly_spend_limit || '0'
                      ) <=
                    1
                      ? (_ctx.AuthUser?.receipt_week_spend || 0) /
                        parseFloat(
                          _ctx.AuthUser?.profile?.subscription_plan
                            .receipt_weekly_spend_limit || '0'
                        )
                      : 1) * 100
                  }%`)
                          }, null, 4)
                        ]),
                        _createElementVNode("div", _hoisted_6, [
                          _createElementVNode("div", _hoisted_7, [
                            _cache[7] || (_cache[7] = _createElementVNode("span", { class: "h-[10px] w-[10px] rounded-[2px] bg-[#FFC44D]" }, null, -1)),
                            _createVNode(_component_app_normal_text, { class: "!text-white !font-semibold" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.Logic.Common.convertToMoney(
                        _ctx.AuthUser?.receipt_week_spend || 400000,
                        true,
                        "ngn"
                      )), 1)
                              ]),
                              _: 1
                            })
                          ]),
                          _createElementVNode("div", _hoisted_8, [
                            _createVNode(_component_app_normal_text, { class: "!text-white !font-semibold" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.Logic.Common.convertToMoney(
                        _ctx.AuthUser?.profile?.subscription_plan
                          .receipt_weekly_spend_limit || 7900000,
                        true,
                        "ngn"
                      )), 1)
                              ]),
                              _: 1
                            }),
                            _cache[8] || (_cache[8] = _createElementVNode("span", { class: "h-[10px] w-[10px] rounded-[2px] bg-white" }, null, -1))
                          ])
                        ])
                      ])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_app_icon, {
                  name: 'point-white',
                  class: "!h-[48px]"
                }),
                _createElementVNode("div", _hoisted_11, [
                  _createVNode(_component_app_normal_text, { class: "!text-[11px] uppercase !text-[#240046] dark:!text-gray-50 !font-semibold" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.isEarned ? "Points Rewarded" : "Points to earn"), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_app_header_text, { class: "!text-[#240046] !font-semibold !text-xl" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.Logic.Common.convertToMoney(
                    _ctx.isEarned
                      ? _ctx.SingleScannedReceipt?.game_points
                      : _ctx.receiptInfo.points,
                    false,
                    ""
                  )) + " pts ", 1),
                      _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.isEarned
                      ? `(${_ctx.Logic.Common.convertToMoney(
                          _ctx.SingleScannedReceipt?.point_to_claim,
                          false,
                          "ngn"
                        )})`
                      : ""), 1)
                    ]),
                    _: 1
                  })
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_13, [
              (_ctx.isEditable)
                ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                    _createVNode(_component_app_normal_text, { class: "!font-semibold" }, {
                      default: _withCtx(() => _cache[9] || (_cache[9] = [
                        _createTextVNode(" Purchase Details ")
                      ])),
                      _: 1
                    }),
                    _createElementVNode("div", {
                      class: "px-3 py-[3px] bg-primary-main rounded-[80px] flex flex-row items-center space-x-2 cursor-pointer",
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showEditReceipt()))
                    }, [
                      _createVNode(_component_app_icon, {
                        name: 'edit',
                        class: "!h-[13px]"
                      }),
                      _createVNode(_component_app_normal_text, { class: "!text-white" }, {
                        default: _withCtx(() => _cache[10] || (_cache[10] = [
                          _createTextVNode("Edit")
                        ])),
                        _: 1
                      })
                    ])
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("div", _hoisted_16, [
                  _createVNode(_component_app_normal_text, { class: "!text-sm !font-semibold" }, {
                    default: _withCtx(() => _cache[11] || (_cache[11] = [
                      _createTextVNode(" Merchant Name ")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_app_normal_text, { class: "!text-grey-700 dark:!text-gray-300" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.receiptInfo.merchant_name), 1)
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", _hoisted_17, [
                  _createVNode(_component_app_normal_text, { customClass: '!font-semibold !text-sm ' }, {
                    default: _withCtx(() => _cache[12] || (_cache[12] = [
                      _createTextVNode(" Date of Payment ")
                    ])),
                    _: 1
                  }),
                  _createVNode(_component_app_normal_text, { class: "!text-grey-700 dark:!text-gray-300" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.receiptInfo.date), 1)
                    ]),
                    _: 1
                  })
                ])
              ]),
              _createElementVNode("div", _hoisted_18, [
                _createVNode(_component_app_normal_text, { class: "!text-sm !font-semibold" }, {
                  default: _withCtx(() => _cache[13] || (_cache[13] = [
                    _createTextVNode(" Item Description ")
                  ])),
                  _: 1
                }),
                _createElementVNode("div", _hoisted_19, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.receiptInfo.items, (item, index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "w-full flex flex-row items-center justify-between",
                      key: index
                    }, [
                      _createVNode(_component_app_normal_text, { class: "!text-gray-700 dark:!text-gray-300" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.name), 1)
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_app_normal_text, { class: "!font-semibold" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.price), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]))
                  }), 128))
                ])
              ]),
              _createElementVNode("div", _hoisted_20, [
                _createElementVNode("div", _hoisted_21, [
                  _createElementVNode("div", _hoisted_22, [
                    _createVNode(_component_app_normal_text, { class: "!text-gray-700 dark:!text-gray-300" }, {
                      default: _withCtx(() => _cache[14] || (_cache[14] = [
                        _createTextVNode(" Total ")
                      ])),
                      _: 1
                    }),
                    _createVNode(_component_app_normal_text, { class: "!font-semibold" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.receiptInfo.total), 1)
                      ]),
                      _: 1
                    })
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_23, [
                _createElementVNode("div", _hoisted_24, [
                  _createElementVNode("div", _hoisted_25, [
                    _createVNode(_component_app_normal_text, null, {
                      default: _withCtx(() => _cache[15] || (_cache[15] = [
                        _createTextVNode(" Processing status ")
                      ])),
                      _: 1
                    }),
                    _createElementVNode("span", {
                      class: _normalizeClass(`px-4 py-1  ${
                    _ctx.receiptInfo.status == 'expired' ? 'bg-red-500' : ''
                  } ${
                    _ctx.receiptInfo.status == 'successful' ? 'bg-green-500' : ''
                  }  ${
                    _ctx.receiptInfo.status == 'claimed' ? 'bg-secondary-500' : ''
                  } rounded-[20px]`)
                    }, [
                      _createVNode(_component_app_normal_text, { class: "!text-white !capitalize" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.receiptInfo.status), 1)
                        ]),
                        _: 1
                      })
                    ], 2)
                  ])
                ])
              ]),
              (_ctx.receiptInfo.status == 'expired')
                ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                    _createVNode(_component_app_normal_text, null, {
                      default: _withCtx(() => [
                        _cache[16] || (_cache[16] = _createTextVNode(" As a ")),
                        _createElementVNode("span", _hoisted_27, _toDisplayString(_ctx.AuthUser?.profile?.subscription_plan.name), 1),
                        _cache[17] || (_cache[17] = _createTextVNode(" subscription member, you can only claim reward on receipts that are less than or at ")),
                        _createElementVNode("span", _hoisted_28, _toDisplayString(_ctx.AuthUser?.profile?.subscription_plan.receipt_scan_allowed_days) + " days", 1),
                        _cache[18] || (_cache[18] = _createTextVNode(" old. "))
                      ]),
                      _: 1
                    })
                  ]))
                : _createCommentVNode("", true)
            ]),
            _cache[19] || (_cache[19] = _createElementVNode("div", { class: "h-[100px]" }, null, -1))
          ]),
          (!_ctx.isEarned)
            ? (_openBlock(), _createBlock(_component_fixed_container, {
                key: 0,
                class: "z-50 bg-[#F9F9FF]"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    class: _normalizeClass(`w-full grid ${
            _ctx.receiptInfo.status != 'successful' ? 'grid-cols-1' : 'grid-cols-1'
          } gap-4`)
                  }, [
                    _createElementVNode("div", _hoisted_29, [
                      _createVNode(_component_app_button, {
                        customClass: `w-full ${
                _ctx.receiptInfo.status != 'successful' ? '' : ''
              }`,
                        padding: 'py-3 xs:py-3',
                        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.processReceipt())),
                        border: 
                _ctx.receiptInfo.status != 'successful' ? '' : 'border-b-[3px]'
              
                      }, {
                        default: _withCtx(() => _cache[20] || (_cache[20] = [
                          _createTextVNode(" Continue ")
                        ])),
                        _: 1
                      }, 8, ["customClass", "border"])
                    ])
                  ], 2)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}