<template>
  <span ref="spanRef" contenteditable="true" @input="handleInput"></span>
</template>

<script lang="ts">
import { ref, defineComponent, onMounted } from "vue";

export default defineComponent({
  name: "AppContentEditable",
  props: {
    defaultValue: {
      type: String,
      required: true,
    },
  },
  emits: ["update:modelValue", "contentChanged"],
  setup(props, context) {
    const spanRef = ref();

    onMounted(() => {
      spanRef.value.innerText = props.defaultValue;
    });

    const handleInput = (event: any) => {
      context.emit("update:modelValue", event.target.innerText);
      context.emit("contentChanged", event.target.innerText);
    };

    return {
      spanRef,
      handleInput,
    };
  },
});
</script>
<style scoped></style>
