import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col space-y-4 pt-2" }
const _hoisted_2 = { class: "w-full flex no-scrollbar flex-row space-x-3 flex-nowrap pl-4 overflow-x-auto scrollbar-hide mdlg:!items-center mdlg:!justify-center md:!justify-center md:!items-center" }
const _hoisted_3 = { class: "flex flex-row space-x-3 py-2 pr-4" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "w-full flex flex-col space-y-2" }
const _hoisted_6 = { class: "w-full flex no-scrollbar flex-row space-x-3 flex-nowrap pl-4 overflow-x-auto scrollbar-hide" }
const _hoisted_7 = { class: "flex flex-row space-x-2 py-1 pr-4" }
const _hoisted_8 = {
  key: 0,
  class: "flex flex-col space-y-3 h-full justify-end"
}
const _hoisted_9 = { class: "w-full flex flex-col space-y-4" }
const _hoisted_10 = { class: "w-full flex flex-row items-center justify-between px-4" }
const _hoisted_11 = { class: "w-full grid grid-cols-2 gap-3 px-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_header_text = _resolveComponent("app-header-text")!
  const _component_app_shoplist_recommended = _resolveComponent("app-shoplist-recommended")!
  const _component_app_shoplist_explore = _resolveComponent("app-shoplist-explore")!
  const _component_dashboard_layout = _resolveComponent("dashboard-layout")!
  const _component_app_wrapper = _resolveComponent("app-wrapper")!

  return (_openBlock(), _createBlock(_component_app_wrapper, null, {
    default: _withCtx(() => [
      _createVNode(_component_dashboard_layout, {
        "is-home": true,
        pageKey: 'home'
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: _normalizeClass(`flex flex-col space-y-1 items-center justify-center w-[65px]  mdlg:!w-[75px] cursor-pointer ${
                option.title == 'Snap' || option.title == 'Pay' ? 'mdlg:!hidden' : ''
              }`),
                    key: index,
                    onClick: ($event: any) => (option.action())
                  }, [
                    _createVNode(_component_app_icon, {
                      name: option.icon,
                      customClass: '!h-[65px] mdlg:!h-[75px]'
                    }, null, 8, ["name"]),
                    _createVNode(_component_app_normal_text, { class: "!text-[11px] text-center !whitespace-nowrap dark:!text-white" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(option.title), 1)
                      ]),
                      _: 2
                    }, 1024)
                  ], 10, _hoisted_4))
                }), 128))
              ])
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_app_header_text, { customClass: '!text-left px-4 !text-sm !font-semibold' }, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createTextVNode(" Recommended for you ")
                ])),
                _: 1
              }),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.recommendedShopLists, (item, index) => {
                    return (_openBlock(), _createBlock(_component_app_shoplist_recommended, {
                      item: item,
                      onClick: ($event: any) => (
                  _ctx.setStoryContent(_ctx.ContentType.RecommendedForYou, {
                    id: parseInt(item.id),
                    type: 'collection',
                  })
                ),
                      key: index
                    }, null, 8, ["item", "onClick"]))
                  }), 128)),
                  (_ctx.showRecommendationIsLoading)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_8, _cache[2] || (_cache[2] = [
                        _createElementVNode("div", { class: "w-auto h-[10px] skeleton rounded" }, null, -1),
                        _createElementVNode("div", { class: "!h-[210px] w-[165px] skeleton !rounded-[7.9px]" }, null, -1)
                      ])))
                    : _createCommentVNode("", true)
                ]),
                _cache[3] || (_cache[3] = _createElementVNode("div", { id: "visibility-handle-recommended" }, null, -1))
              ])
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createVNode(_component_app_header_text, { class: "!text-sm !font-semibold" }, {
                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                    _createTextVNode(" Cashback offers for you ")
                  ])),
                  _: 1
                }),
                _createVNode(_component_app_normal_text, {
                  class: "!underline !text-primary-800 dark:!text-primary-300 cursor-pointer",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (
                _ctx.setStoryContent(_ctx.ContentType.Explore, {
                  id: 0,
                  type: 'collection',
                })
              ))
                }, {
                  default: _withCtx(() => _cache[5] || (_cache[5] = [
                    _createTextVNode(" See all ")
                  ])),
                  _: 1
                })
              ]),
              _createElementVNode("div", _hoisted_11, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.exploreShoplists, (item, index) => {
                  return (_openBlock(), _createBlock(_component_app_shoplist_explore, {
                    key: index,
                    item: item,
                    onClick: ($event: any) => (_ctx.handleShoplsistClick(item))
                  }, null, 8, ["item", "onClick"]))
                }), 128)),
                (_ctx.showExploreIsLoading)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _cache[6] || (_cache[6] = _createElementVNode("div", { class: "col-span-1 flex flex-col space-y-2 h-[180px] mdlg:!h-[250px] md:!h-[250px] rounded-[10px] skeleton" }, null, -1)),
                      _cache[7] || (_cache[7] = _createElementVNode("div", { class: "col-span-1 flex flex-col space-y-2 h-[180px] mdlg:!h-[250px] md:!h-[250px] rounded-[10px] skeleton" }, null, -1))
                    ], 64))
                  : _createCommentVNode("", true),
                _cache[8] || (_cache[8] = _createElementVNode("div", {
                  class: "col-span-2",
                  id: "visibility-handle-offers"
                }, null, -1))
              ])
            ]),
            _cache[9] || (_cache[9] = _createElementVNode("div", { class: "h-[120px]" }, null, -1))
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}