<template>
  <app-wrapper :innerClass="'!bg-transparent'">
    <onboarding-layout class="!bg-transparent" :title="'clear'" id="videoContainer">
      <div
        :class="`w-full flex flex-col items-center justify-start space-y-2 fixed !font-inter h-screen !pt-0 top-0 left-0 z-[9999999999999]  px-4`"
      >
        <div
          class="w-full flex flex-row items-center justify-between !bg-opacity-100 !opacity-100 z-[99999] !bg-transparent"
          style="padding-top: calc(env(safe-area-inset-top) + 16px) !important"
        >
          <div class="flex flex-row items-center space-x-3">
            <div
              :class="`w-[40px] h-[40px] xs:w-[43px] xs:h-[43px] rounded-full flex flex-row items-center justify-center border-[1px] border-white`"
              @click="
                Logic.Common.goBack();
                CameraPreview.stop();
              "
            >
              <app-icon :name="'chevron-left-white'" :customClass="'h-[14px]'" />
            </div>
            <div class="flex flex-col space-y-1">
              <app-header-text :color="'!text-white'" class="!font-semibold">
                Snap a receipt
              </app-header-text>
            </div>
          </div>
        </div>
        <div class="w-full flex flex-row justify-center items-start z-[99999]"></div>
        <div
          :class="`w-[95%] h-full h-min-[300px] flex-grow flex flex-col transparent-center  items-center rounded-[22px] relative  `"
          id="scanContainer"
        >
          <app-icon
            :name="'scan-top-left'"
            :customClass="'h-auto absolute top-[-1%] left-[-1%] z-50'"
          />
          <app-icon
            :name="'scan-top-right'"
            :customClass="'h-auto absolute top-[-1%] right-[-1%] z-50'"
          />
          <app-icon
            :name="'scan-bottom-left'"
            :customClass="'h-auto absolute bottom-[-1%] left-[-1%] z-50'"
          />
          <app-icon
            :name="'scan-bottom-right'"
            :customClass="'h-auto absolute bottom-[-1%] right-[-1%] z-50'"
          />

          <div
            :class="`scan bg-shop_purple_400 w-[96%] h-[4px] z-0 shadow-2xl shadow-inherit  ${
              !showScan ? 'invisible' : ''
            }`"
            id="scan"
          ></div>

          <div
            class="w-full absolute top-0 left-0 h-full flex flex-col rounded-[22px] overflow-y-auto"
            v-if="snappedImages.length"
          >
            <div
              class="w-full flex flex-col h-full"
              v-for="(imageUrl, index) in snappedImages"
              :key="index"
            >
              <app-image-loader :photoUrl="imageUrl.url" :customClass="'w-full h-full'" />
            </div>
          </div>

          <div
            class="w-full absolute top-[88%] left-0 flex flex-row space-x-3 items-center justify-center"
          >
            <div
              @click="captureImage()"
              class="w-[60px] h-[60px] xs:w-[50px] xs:h-[50px] rounded-full bg-white shadow-custom flex flex-row justify-center items-center"
              v-if="snappedImages.length == 0"
            >
              <app-icon :name="'scan'" :customClass="'h-[28px] xs:h-[22px]'"> </app-icon>
            </div>

            <template v-else>
              <div
                @click="reSnap()"
                class="w-[60px] h-[60px] xs:w-[50px] xs:h-[50px] rounded-full bg-white shadow-custom flex flex-row justify-center items-center"
              >
                <app-icon :name="'redo'" :customClass="'h-[28px] xs:h-[22px]'">
                </app-icon>
              </div>

              <div
                @click="analyzeScannedImage(snappedImages[0].blob)"
                class="w-[60px] h-[60px] xs:w-[50px] xs:h-[50px] rounded-full bg-primary-400 shadow-custom flex flex-row justify-center items-center"
              >
                <app-icon :name="'send-snap'" :customClass="'h-[30px] xs:h-[24px]'">
                </app-icon>
              </div>
            </template>
          </div>
        </div>
        <div
          class="w-full flex flex-row justify-center items-start z-[99999] pt-3"
          style="padding-bottom: calc(env(safe-area-inset-bottom) + 16px) !important"
        ></div>
      </div>
    </onboarding-layout>
  </app-wrapper>
</template>

<script lang="ts">
import { defineComponent, onMounted, onUnmounted, ref } from "vue";
import { useMeta } from "vue-meta";
import { onIonViewDidEnter, onIonViewDidLeave, onIonViewWillEnter } from "@ionic/vue";
import { Logic } from "@shpt/logic";
import {
  CameraPreview,
  CameraPreviewOptions,
  CameraPreviewPictureOptions,
} from "@capacitor-community/camera-preview";
import fixOrientation from "fix-orientation";
import { AppHeaderText, AppIcon, AppImageLoader } from "@shpt/ui-components";
import AppWrapper from "@/components/AppWrapper.vue";

export default defineComponent({
  components: {
    AppHeaderText,
    AppIcon,
    AppImageLoader,
    AppWrapper,
  },
  name: "SnapPage",
  middlewares: {
    fetchRules: [],
    tracking_data: {
      lable: "Snap Page",
      stage_type: "neutral",
      end_stage: "",
    },
  },
  layout: "Onboarding",
  setup() {
    useMeta({
      title: "Snap",
    });

    const showScanImage = ref(false);

    const rotateVideo = ref(false);

    const imageFileUrl = ref("");

    // const ClientAppContent = ref(Logic.User.ClientAppContent);

    const selfieVideoElement = ref<any>(document.getElementById("videoScan"));

    const selfieCanvasElement = ref<any>(document.getElementById("scanCanvas"));

    const showScan = ref(false);

    const snappedImages = ref<
      {
        url: string;
        blob: Blob;
      }[]
    >([]);

    const showSelfie = async () => {
      showScanImage.value = false;
      selfieVideoElement.value = document.getElementById("video");
      selfieCanvasElement.value = document.getElementById("scanCanvas");

      const cameraPreviewOptions: CameraPreviewOptions = {
        position: "rear",
        disableAudio: true,
        parent: "videoContainer",
        toBack: true,
        rotateWhenOrientationChanged: false,
        width: window.screen.width,
        height: window.screen.height,
        enableHighResolution: true,
      };

      CameraPreview.start(cameraPreviewOptions);
    };

    const analyzeScannedImage = (image: Blob) => {
      Logic.Shop.ScanReciept(image, reSnap).then((data) => {
        if (data) {
          CameraPreview.stop();
          Logic.Common.GoToRoute("/shop/scan/info");
        }
      });
    };

    const reSnap = () => {
      snappedImages.value.length = 0;
    };

    // const showSnapPolicy = () => {
    //   const snapPolicySeen = localStorage.getItem("snapPolicySeen");
    //   if (!snapPolicySeen) {
    //     Logic.Common.showModal({
    //       show: true,
    //       title: "Valid Receipt Snapping",
    //       type: "policy_content",
    //       preventClose: false,
    //       actionLabel: "Okay. Got it!",
    //       extraData: {
    //         title: "Terms and Conditions of Receipt Snapping on Shoppoint App",
    //         videoUrl:
    //           "https://nyc3.digitaloceanspaces.com/roof-income/videos/GhLmvxlXY3zNVJpP.m3u8",
    //         hasPadding: false,
    //       },
    //       action: () => {
    //         Logic.User.UpdateProfileForm = {
    //           snapping_terms_accepted: true,
    //         };
    //         Logic.User.UpdateProfile(true);
    //         Logic.User.SaveUserActivity(
    //           "Accepted Receipt Snapping Terms & Conditions",
    //           "action"
    //         );
    //         Logic.Common.showModal({ show: false });
    //         localStorage.setItem("snapPolicySeen", "true");
    //       },
    //     });
    //   }
    // };

    const captureImage = async () => {
      const cameraPreviewPictureOptions: CameraPreviewPictureOptions = {
        quality: 50,
      };

      const result = await CameraPreview.capture(cameraPreviewPictureOptions);
      const base64PictureData = result.value;
      await fixOrientation(
        "data:image/jpeg;base64," + base64PictureData,
        { image: true },
        (fixed: string) => {
          Logic.Common.base64ToBlob(fixed).then((blobImage) => {
            // analyzeScannedImage(blobImage);

            imageFileUrl.value = URL.createObjectURL(blobImage);

            snappedImages.value.push({
              url: imageFileUrl.value,
              blob: blobImage,
            });

            showScan.value = false;
          });
        }
      );
    };

    onIonViewDidEnter(() => {
      showSelfie();
      Logic.Shop.ReceiptTrials = 0;
      // setTimeout(() => {
      //   showSnapPolicy();
      // }, 1000);
    });

    onIonViewWillEnter(() => {
      snappedImages.value.length = 0;
    });

    onIonViewDidLeave(() => {
      setTimeout(() => {
        CameraPreview.stop();
      }, 2000);
    });

    onMounted(() => {
      // Logic.User.watchProperty("ClientAppContent", ClientAppContent);
      setTimeout(() => {
        Logic.Common.initializeAdmob();
      }, 2000);

      if (Logic.Common.currentBuildType() == "web") {
        snappedImages.value.length = 0;
        showSelfie();
        Logic.Shop.ReceiptTrials = 0;
      }
    });

    onUnmounted(() => {
      if (Logic.Common.currentBuildType() == "web") {
        setTimeout(() => {
          CameraPreview.stop();
        }, 1000);
      }
    });

    return {
      Logic,
      showScanImage,
      rotateVideo,
      imageFileUrl,
      showScan,
      CameraPreview,
      snappedImages,
      captureImage,
      reSnap,
      analyzeScannedImage,
    };
  },
});
</script>
<style scoped>
.transparent-center {
  box-shadow: 0 0 0 5000px rgba(0, 0, 0, 0.7) !important;
  -webkit-appearance: none;
  -webkit-box-shadow: 0 0 0 5000px rgba(0, 0, 0, 0.7) !important;
  appearance: none;
}

ion-content {
  --background: transparent !important;
}

ion-page {
  --background: transparent !important;
}

body {
  background-color: transparent !important;
}

#mainApp {
  background-color: transparent !important;
}

#ionPageApp {
  background-color: transparent !important;
  --background: transparent;
}

#mainContent {
  background-color: transparent !important;
  --background: transparent;
}
</style>
