<template>
  <app-wrapper>
    <subpage-layout :title="'Transaction Details'">
      <div
        class="w-full flex flex-col space-y-5 h-full flex-grow px-4 pb-4 relative overflow-y-auto"
      >
        <div class="w-full flex flex-col items-center justify-center">
          <app-normal-text :customClass="'!font-semibold'">
            Amount
          </app-normal-text>
          <app-header-text
            :color="'text-primary-500 dark:!text-primary-300'"
            :customClass="'!text-2xl'"
          >
            {{ SinglePointTransaction?.dr_or_cr == "credit" ? "+" : "-" }}
            {{
              Logic.Common.convertToMoney(
                SinglePointTransaction?.amount,
                true,
                "",
                true,
                "NGN "
              )
            }}
          </app-header-text>
        </div>

        <div
          class="w-full flex flex-col space-y-3 bg-grey-50 rounded-[10px] dark:bg-gray-900"
        >
          <div class="w-full xs:px-3 xs:py-3 py-4 px-4 flex flex-col space-y-3">
            <div
              class="w-full flex flex-row justify-between items-center space-x-3"
              v-for="(item, index) in details"
              :key="index"
            >
              <app-normal-text>
                {{ item.title }}
              </app-normal-text>

              <app-normal-text
                :color="'!line-clamp-1 !text-right'"
                :customClass="'!font-semibold'"
              >
                {{ item.value }}
              </app-normal-text>
            </div>
          </div>
        </div>

        <div
          v-if="
            hasMoreOptions &&
            (SinglePointTransaction?.status == 'success' ||
              SinglePointTransaction?.status == 'successful') &&
            SinglePointTransaction.extra_data
          "
          class="w-full grid grid-cols-2 bg-grey-50 rounded-[10px] dark:bg-gray-900"
        >
          <template v-if="!isGiftCard">
            <div
              class="col-span-1 py-3 px-3 xs:px-2 xs:py-2 border-r-[1px] flex items-center flex-row justify-center"
            >
              <div
                class="flex flex-row items-center space-x-2 cursor-pointer"
                @click="
                  Logic.Common.shareReceipt(
                    SinglePointTransaction?.reference || '',
                    'image'
                  )
                "
              >
                <span
                  class="flex flex-row items-center justify-center xs:h-[35px] xs:w-[35px] h-[44px] w-[44px] rounded-full border-[1px] border-grey-200"
                >
                  <app-icon
                    :name="'share-image'"
                    :customClass="'h-[16px] xs:h-[16px]'"
                  />
                </span>
                <app-normal-text
                  :color="'text-primary-500 dark:text-primary-300'"
                >
                  Share as image
                </app-normal-text>
              </div>
            </div>
            <div
              class="col-span-1 py-3 px-3 xs:px-2 xs:py-2 flex items-center flex-row justify-center"
            >
              <div
                class="flex flex-row items-center space-x-2 cursor-pointer"
                @click="
                  Logic.Common.shareReceipt(
                    SinglePointTransaction?.reference || '',
                    'pdf'
                  )
                "
              >
                <span
                  class="flex flex-row items-center justify-center xs:h-[35px] xs:w-[35px] h-[44px] w-[44px] rounded-full border-[1px] border-grey-200"
                >
                  <app-icon
                    :name="'share-receipt'"
                    :customClass="'h-[20px] xs:h-[18px]'"
                  />
                </span>
                <app-normal-text
                  :color="'text-primary-500 dark:text-primary-300'"
                >
                  Share as PDF
                </app-normal-text>
              </div>
            </div>
          </template>
          <template v-else>
            <div
              class="col-span-2 py-3 px-3 xs:px-2 xs:py-2 flex items-center flex-row justify-center"
            >
              <div
                class="flex flex-row items-center space-x-2"
                @click="sendGiftCard()"
              >
                <span
                  class="flex flex-row items-center justify-center xs:h-[35px] xs:w-[35px] h-[44px] w-[44px] rounded-full border-[1px] border-grey-200"
                >
                  <app-icon
                    :name="'badges-icon'"
                    :customClass="'h-[18px] xs:h-[18px]'"
                  />
                </span>
                <app-normal-text
                  :color="'text-primary-500 dark:text-primary-300'"
                >
                  Send to a friend
                </app-normal-text>
              </div>
            </div>
          </template>
        </div>
      </div>

      <div class="h-[100px]"></div>

      <!-- Continue button -->
      <fixed-container>
        <app-button
          :customClass="'w-full'"
          :padding="'py-3 xs:py-3'"
          @click="Logic.Common.goBack()"
        >
          Close
        </app-button>
      </fixed-container>
    </subpage-layout>
  </app-wrapper>
</template>

<script lang="ts">
import {
  capitalize,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from "vue";
import { useMeta } from "vue-meta";
import { tabTitle } from "@shpt/ui-components/src/composable";
import { onIonViewWillEnter } from "@ionic/vue";
import {
  AppHeaderText,
  AppNormalText,
  AppIcon,
  AppButton,
} from "@shpt/ui-components";
import { Logic } from "@shpt/logic";
import AppWrapper from "@/components/AppWrapper.vue";
import FixedContainer from "@shpt/ui-components/src/components/AppCommon/FixedContainer.vue";

export default defineComponent({
  components: {
    AppHeaderText,
    AppNormalText,
    AppIcon,
    AppButton,
    AppWrapper,
    FixedContainer,
  },
  name: "PointTransactionDetailsPage",
  middlewares: {
    fetchRules: [
      {
        domain: "Wallet",
        property: "SinglePointTransaction",
        method: "GetPointTransaction",
        params: [],
        useRouteId: true,
        requireAuth: true,
        ignoreProperty: true,
      },
    ],
    tracking_data: {
      lable: "Payment Details Page",
      stage_type: "neutral",
      end_stage: "",
    },
  },
  setup() {
    tabTitle.value = "";
    useMeta({
      title: "Point Transaction Details",
    });

    const SinglePointTransaction = ref(Logic.Wallet.SinglePointTransaction);

    const isGiftCard = ref(false);

    const details = reactive<
      {
        title: string;
        value: string;
      }[]
    >([]);

    const hasMoreOptions = ref(true);

    const setTransactionDetails = () => {
      details.length = 0;

      hasMoreOptions.value = true;

      const transactionExtraData = SinglePointTransaction.value?.extra_data
        ? JSON.parse(JSON.parse(SinglePointTransaction.value.extra_data))
        : {};

      if (transactionExtraData["beneficiary_account_no"]) {
        details.push(
          {
            title: "Recipient",
            value: `${transactionExtraData["beneficiary_name"]}`,
          },
          {
            title: "Narration",
            value: `${transactionExtraData["narration"]}`,
          }
        );
      }

      details.push(
        {
          title: "Title",
          value: SinglePointTransaction.value?.description || "",
        },
        {
          title: "Reference",
          value: SinglePointTransaction.value?.reference || "",
        },
        {
          title: "Status",
          value: capitalize(SinglePointTransaction.value?.status || ""),
        }
      );

      if (transactionExtraData["shoppoint_username"]) {
        details.push({
          title: "To Shoppoint handle",
          value: `@${transactionExtraData["shoppoint_username"]}`,
        });

        hasMoreOptions.value = false;
      }

      if (transactionExtraData["token"]) {
        details.push({
          title: "Token",
          value: `${transactionExtraData["token"]}`,
        });
      }

      if (SinglePointTransaction.value?.reference?.includes("shpt")) {
        hasMoreOptions.value = false;
      }

      details.push({
        title: "Date",
        value: Logic.Common.fomartDate(
          SinglePointTransaction.value?.created_at || "",
          "D MMMM, YYYY H:mma"
        ),
      });

      if (transactionExtraData["purchase_data"]) {
        if (transactionExtraData["purchase_data"]["pin"]) {
          if (transactionExtraData["purchase_data"]["pin"]["instructions"]) {
            isGiftCard.value = true;
          }
        }
      }
    };

    const sendGiftCard = () => {
      Logic.Common.showModal({
        show: true,
        title: "Send Gift Card",
        type: "send_gift_card",
        actionLabel: "Send",
        action: (data: any) => {
          Logic.Shop.SendGiftCardForm = {
            email: data.email,
            name: data.name,
            transaction_uuid: SinglePointTransaction.value?.uuid || "",
          };
          Logic.Shop.SendGiftCard()?.then(() => {
            //
          });
        },
        validateForm: true,
      });
    };

    onIonViewWillEnter(() => {
      setTransactionDetails();
    });

    watch(SinglePointTransaction, () => {
      setTransactionDetails();
    });

    onMounted(() => {
      Logic.Wallet.watchProperty(
        "SinglePointTransaction",
        SinglePointTransaction
      );
      setTransactionDetails();
    });

    return {
      details,
      Logic,
      SinglePointTransaction,
      hasMoreOptions,
      isGiftCard,
      sendGiftCard,
    };
  },
});
</script>
