import currency from 'currency.js'
import moment from 'moment'
import { CombinedError } from 'urql'
import { reactive } from 'vue'
import {
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteLocationNormalizedLoaded,
  Router,
} from 'vue-router'
import { Logic } from '..'
import { AlertSetup, FetchRule, LoaderSetup, ModalSetup } from '../types/common'
import { FileSharer } from '@byteowls/capacitor-filesharer'
import Echo from 'laravel-echo'
import pusher from 'pusher-js'
import {
  AdMob,
  AdOptions,
  AdLoadInfo,
  InterstitialAdPluginEvents,
  RewardAdOptions,
  RewardAdPluginEvents,
  AdMobRewardItem,
  AdmobConsentStatus,
} from '@capacitor-community/admob'
import { FilePicker } from '@capawesome/capacitor-file-picker'
import { Browser } from '@capacitor/browser'
import { NativeAudio } from '@capacitor-community/native-audio'

// @ts-ignore
window.Pusher = pusher

import { getPlatforms, isPlatform } from '@ionic/vue'
// import { FirebaseCrashlytics } from '@capacitor-community/firebase-crashlytics'

// @ts-expect-error
window.Pusher = require('pusher-js')

export default class Common {
  public router: Router | undefined = undefined

  private initiatedAudios: string[] = []

  public callIdentity: string | undefined = undefined

  public currentLayout = reactive({
    name: '',
    path: '',
    from: {
      name: '',
      path: '',
    },
  })

  public callContactList: any = {}

  public adInitialized = false

  public sideBarItems = reactive<
    {
      name: string
      icon: {
        name: string
        size: string
      }
      counter: number
      route_path: string
    }[]
  >([])
  public webSideBarItems = reactive<
    {
      name: string
      icon: {
        name: string
        size: string
        inactive_size: string
      }
      counter: number
      route_path: string
      routeTag: string
    }[]
  >([])

  public webSideBarMore = reactive<
    {
      name: string
      icon: {
        name: string
        size: string
        inactive_size: string
      }
      counter: number
      route_path: string
      routeTag: string
    }[]
  >([])

  public bottomBarItems = reactive<
    {
      path: string
      icon: string
      routeTag: string
      name: string
      icon_size: string
      icon_inactive_size: string
    }[]
  >([])

  public abbreviateNumber = (number: number) => {
    return Intl.NumberFormat('en-US', {
      notation: 'compact',
      maximumFractionDigits: 1,
    }).format(number)
  }

  public preloadAudios = () => {
    NativeAudio.preload({
      assetId: 'confetti',
      assetPath: 'confetti.mp3',
      audioChannelNum: 1,
      isUrl: false,
    })
  }

  public initiateWebSocket = () => {
    try {
      if (!this.laravelEcho) {
        this.laravelEcho = new Echo({
          broadcaster: 'pusher',
          key: process.env.VUE_APP_PUSHER_APP_KEY,
          // cluster: 'eu',
          // forceTLS: true,
          // encrypted: true,
          forceTLS: true,
          disableStats: true,
          wsPort: process.env.VUE_APP_PUSHER_PORT,
          wsHost: process.env.VUE_APP_PUSHER_HOST,
          auth: {
            headers: {
              authorization: `Bearer ${Logic.Auth.AccessToken}`,
            },
          },
          authEndpoint: `${process.env.VUE_APP_BACKEND_URL}/broadcasting/auth`,
        })
      }
    } catch (error) {
      console.error(error)
    }
  }

  public countDownToAction = (periodInSecond: number, callback: Function) => {
    let start = periodInSecond
    // @ts-ignore
    window.globalTimer = setInterval(() => {
      if (start <= 0) {
        // @ts-ignore
        clearInterval(window.globalTimer)
        callback()
      } else {
        start--
      }
    }, 1000)
  }

  public clearTimer = () => {
    // @ts-ignore
    clearInterval(window.globalTimer)
  }

  //   private webAudio: HTMLAudioElement | undefined = undefined

  /*
   * Javascript implementation of Fisher-Yates shuffle algorithm
   * http://stackoverflow.com/questions/2450954/how-to-randomize-a-javascript-array
   */
  public shuffleArray(array: any[]) {
    let currentIndex = array.length
    let temporaryValue
    let randomIndex

    // While there remain elements to shuffle...
    while (currentIndex !== 0) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex)
      currentIndex -= 1

      // And swap it with the current element.
      temporaryValue = array[currentIndex]
      array[currentIndex] = array[randomIndex]
      array[randomIndex] = temporaryValue
    }

    return array
  }

  public groupArrayBy = (xs: any[], key: string) => {
    return xs.reduce(function (rv, x) {
      ;(rv[x[key]] = rv[x[key]] || []).push(x)
      return rv
    }, {})
  }

  public loadScript(src: string, callback: any) {
    var script = document.createElement('script')
    script.src = src
    script.async = true

    script.onload = callback

    document.head.appendChild(script)
  }

  public route: RouteLocationNormalizedLoaded | undefined = undefined

  public apiUrl: string | undefined = undefined

  public watchInterval: number | undefined = undefined

  public laravelEcho: Echo | undefined

  public loadingState = false

  public showBottomNav = false

  public SetRouter = (router: Router) => {
    this.router = router
  }

  public SetRoute = (route: RouteLocationNormalizedLoaded) => {
    this.route = route
  }

  public currentAccountType = (): 'client' | 'merchant' => {
    // @ts-ignore
    return localStorage.getItem('current_account_type') || 'client'
  }

  public setCurrentAccount = (type: 'client' | 'merchant') => {
    localStorage.setItem('current_account_type', type)
  }

  public currentBuildType = (): 'web' | 'mobile' => {
    return process.env.VUE_APP_BUILD_TYPE as 'web' | 'mobile'
  }

  public currentAppTheme = (): 'light' | 'dark' => {
    return document.documentElement.classList.contains('dark')
      ? 'dark'
      : 'light'
  }

  public openBrowser = async (
    url: string,
    onBrowserFinished: () => void,
    onBrowserPageLoaded: () => void,
  ) => {
    Browser.addListener('browserFinished', onBrowserFinished)
    Browser.addListener('browserPageLoaded', onBrowserPageLoaded)
    await Browser.open({ url })
  }

  public currentMediaQuery = () => {
    const width = window.innerWidth

    // Updated media query breakpoints
    if (width <= 330) {
      return 'xs'
    } else if (width <= 640) {
      return 'sm'
    } else if (width >= 768 && width <= 1030) {
      return 'md'
    } else if (width >= 1030 && width <= 1280) {
      return 'mdlg'
    } else if (width >= 1280 && width <= 1580) {
      return 'xl'
    } else if (width >= 1580) {
      return '2xl'
    } else {
      return '2xl'
    }
  }

  public setSideBar = () => {
    this.sideBarItems.length = 0
    this.webSideBarItems.length = 0
    this.webSideBarMore.length = 0

    if (this.currentAccountType() == 'client') {
      this.sideBarItems = [
        {
          name: 'Notifications',
          icon: {
            name: 'notification',
            size: 'h-[18px]',
          },
          counter: 12,
          route_path: '/others/notifications',
        },
        {
          name: 'Expense Tracker',
          icon: {
            name: 'expense-tracker',
            size: 'h-[16px]',
          },
          counter: 0,
          route_path: '/others/expense_tracker',
        },
        {
          name: 'Leaderboard',
          icon: {
            name: 'leaderboard',
            size: 'h-[18px]',
          },
          counter: 0,
          route_path: '/others/leaderboard?fullPath=global',
        },
        {
          name: 'Badges',
          icon: {
            name: 'badges',
            size: 'h-[18px]',
          },
          counter: 0,
          route_path: '/others/badges',
        },
        {
          name: 'Activities',
          icon: {
            name: 'cashback-rewards',
            size: 'h-[18px]',
          },
          counter: 9,
          route_path: '/others/rewards?tab=scan',
        },
        {
          name: 'Rewards',
          icon: {
            name: 'cashback-offers',
            size: 'h-[18px]',
          },
          counter: 0,
          route_path: '/others/challenges',
        },
        {
          name: 'Account Settings',
          icon: {
            name: 'managed-account',
            size: 'h-[16px]',
          },
          counter: 0,
          route_path: '/others/account_settings',
        },
        {
          name: 'Privacy & Support',
          icon: {
            name: 'privacy-support',
            size: 'h-[18px]',
          },
          counter: 0,
          route_path: '/others/privacy_support',
        },
      ]

      this.webSideBarItems = [
        {
          name: 'For You',
          icon: {
            name: 'for-you',
            size: 'h-[25px]',
            inactive_size: 'h-[25px]',
          },
          counter: 0,
          route_path: '/',
          routeTag: 'base',
        },
        {
          name: 'Shop',
          icon: {
            name: 'shop',
            size: 'h-[23px]',
            inactive_size: 'h-[25px]',
          },
          counter: 0,
          route_path: '/shoplist',
          routeTag: '/shoplist',
        },
        {
          name: 'Shoplist',
          icon: {
            name: 'basket',
            size: 'h-[25px]',
            inactive_size: 'h-[25px]',
          },
          counter: 0,
          route_path: '/basket',
          routeTag: '/basket',
        },
        {
          name: 'Pay',
          icon: {
            name: 'cashback',
            size: 'h-[25px]',
            inactive_size: 'h-[25px]',
          },
          counter: 0,
          route_path: '/cashback',
          routeTag: '/cashback',
        },
        {
          name: 'Rewards',
          icon: {
            name: 'challenges',
            size: 'h-[25px]',
            inactive_size: 'h-[25px]',
          },
          counter: 0,
          route_path: '/others/challenges',
          routeTag: '/others/challenges',
        },
        {
          name: 'Balance',
          icon: {
            name: 'savings',
            size: 'h-[25px]',
            inactive_size: 'h-[25px]',
          },
          counter: 0,
          route_path: '/savings',
          routeTag: '/savings',
        },
        {
          name: 'Expense Tracker',
          icon: {
            name: 'expense-tracker',
            size: 'h-[25px]',
            inactive_size: 'h-[25px]',
          },
          counter: 0,
          route_path: '/others/expense_tracker',
          routeTag: '/others/expense_tracker',
        },
        {
          name: 'Activities',
          icon: {
            name: 'cashback-rewards',
            size: 'h-[25px]',
            inactive_size: 'h-[25px]',
          },
          counter: 0,
          route_path: '/others/rewards?tab=scan',
          routeTag: '/others/rewards',
        },
        {
          name: 'Notifications',
          icon: {
            name: 'notification',
            size: 'h-[25px]',
            inactive_size: 'h-[25px]',
          },
          counter: 0,
          route_path: '/others/notifications',
          routeTag: '/notifications',
        },
      ]

      this.webSideBarMore = [
        {
          name: 'Leaderboard',
          icon: {
            name: 'leaderboard',
            size: 'h-[25px]',
            inactive_size: 'h-[25px]',
          },
          counter: 0,
          route_path: '/others/leaderboard?fullPath=global',
          routeTag: '/others/leaderboard',
        },
        {
          name: 'Badges',
          icon: {
            name: 'badges',
            size: 'h-[25px]',
            inactive_size: 'h-[25px]',
          },
          counter: 0,
          route_path: '/others/badges',
          routeTag: '/others/badges',
        },
        {
          name: 'Account Settings',
          icon: {
            name: 'account-settings',
            size: 'h-[25px]',
            inactive_size: 'h-[25px]',
          },
          counter: 0,
          route_path: '/others/account_settings',
          routeTag: '/others/account_settings',
        },
        {
          name: 'Support & Privacy',
          icon: {
            name: 'support',
            size: 'h-[25px]',
            inactive_size: 'h-[25px]',
          },
          counter: 0,
          route_path: '/others/privacy_support',
          routeTag: '/others/privacy_support',
        },
      ]
    } else {
      this.sideBarItems = [
        {
          name: 'Notifications',
          icon: {
            name: 'notification',
            size: 'h-[18px]',
          },
          counter: 0,
          route_path: '/others/notifications',
        },
        {
          name: 'Orders',
          icon: {
            name: 'cashback-rewards',
            size: 'h-[18px]',
          },
          counter: 0,
          route_path: '/orders',
        },
        {
          name: 'Store Admins',
          icon: {
            name: 'merchant-admin',
            size: 'h-[16px]',
          },
          counter: 0,
          route_path: '/admins',
        },
        {
          name: 'Store Locations',
          icon: {
            name: 'delivery-area-icon',
            size: 'h-[16px]',
          },
          counter: 0,
          route_path: '/delivery_areas',
        },
        {
          name: 'Settlement Accounts',
          icon: {
            name: 'expense-tracker',
            size: 'h-[16px]',
          },
          counter: 0,
          route_path: '/settlement_accounts',
        },
        {
          name: 'Accept Payments',
          icon: {
            name: 'accept-payment',
            size: 'h-[16px]',
          },
          counter: 0,
          route_path: '/accept_payments',
        },
        {
          name: 'Account Settings',
          icon: {
            name: 'managed-account',
            size: 'h-[16px]',
          },
          counter: 0,
          route_path: '/others/account_settings',
        },
        {
          name: 'Privacy & Support',
          icon: {
            name: 'privacy-support',
            size: 'h-[18px]',
          },
          counter: 0,
          route_path: '/others/privacy_support',
        },
      ]

      this.webSideBarItems = [
        {
          name: 'Home',
          icon: {
            name: 'home',
            size: 'h-[25px]',
            inactive_size: 'h-[25px]',
          },
          counter: 0,
          route_path: '/',
          routeTag: 'base',
        },
        {
          name: 'Products',
          icon: {
            name: 'products',
            size: 'h-[25px]',
            inactive_size: 'h-[25px]',
          },
          counter: 0,
          route_path: '/products',
          routeTag: '/products',
        },
        {
          name: 'Shoplists',
          icon: {
            name: 'shoplist',
            size: 'h-[25px]',
            inactive_size: 'h-[25px]',
          },
          counter: 0,
          route_path: '/shoplists',
          routeTag: '/shoplists',
        },
        {
          name: 'Promote',
          icon: {
            name: 'promote',
            size: 'h-[25px]',
            inactive_size: 'h-[25px]',
          },
          counter: 0,
          route_path: '/promote',
          routeTag: '/promote',
        },
        {
          name: 'Wallet',
          icon: {
            name: 'wallet',
            size: 'h-[25px]',
            inactive_size: 'h-[25px]',
          },
          counter: 0,
          route_path: '/wallet',
          routeTag: '/wallet',
        },
        {
          name: 'Accept Payment',
          icon: {
            name: 'accept-payment',
            size: 'h-[25px]',
            inactive_size: 'h-[25px]',
          },
          counter: 0,
          route_path: '/accept_payments',
          routeTag: '/accept_payments',
        },
        {
          name: 'Orders',
          icon: {
            name: 'orders',
            size: 'h-[25px]',
            inactive_size: 'h-[25px]',
          },
          counter: 0,
          route_path: '/orders',
          routeTag: '/orders',
        },
        {
          name: 'Notifications',
          icon: {
            name: 'notification',
            size: 'h-[25px]',
            inactive_size: 'h-[25px]',
          },
          counter: 0,
          route_path: '/others/notifications',
          routeTag: '/notifications',
        },
      ]

      this.webSideBarMore = [
        {
          name: 'Store Admin',
          icon: {
            name: 'store-admins',
            size: 'h-[25px]',
            inactive_size: 'h-[25px]',
          },
          counter: 0,
          route_path: '/admins',
          routeTag: '/admins',
        },
        {
          name: 'Store Locations',
          icon: {
            name: 'store-locations',
            size: 'h-[25px]',
            inactive_size: 'h-[25px]',
          },
          counter: 0,
          route_path: '/delivery_areas',
          routeTag: '/delivery_areas',
        },
        {
          name: 'Settlement Accounts',
          icon: {
            name: 'settlement-account',
            size: 'h-[25px]',
            inactive_size: 'h-[25px]',
          },
          counter: 0,
          route_path: '/settlement_accounts',
          routeTag: '/settlement_accounts',
        },
        {
          name: 'Account Settings',
          icon: {
            name: 'account-settings',
            size: 'h-[25px]',
            inactive_size: 'h-[25px]',
          },
          counter: 0,
          route_path: '/others/account_settings',
          routeTag: '/others/account_settings',
        },
        {
          name: 'Support & Privacy',
          icon: {
            name: 'support',
            size: 'h-[25px]',
            inactive_size: 'h-[25px]',
          },
          counter: 0,
          route_path: '/others/privacy_support',
          routeTag: '/others/privacy_support',
        },
      ]
    }
  }

  public setBottomBar = () => {
    this.bottomBarItems.length = 0

    if (this.currentAccountType() == 'client') {
      this.bottomBarItems = [
        {
          path: '/',
          icon: 'for-you',
          routeTag: 'base',
          name: 'For you',
          icon_size: 'h-[20px] xs:h-[20px]',
          icon_inactive_size: 'h-[20px] xs:h-[20px]',
        },
        {
          path: '/shoplist',
          icon: 'shop',
          routeTag: 'shoplist',
          name: 'Shop',
          icon_size: 'h-[20px] xs:h-[20px]',
          icon_inactive_size: 'h-[20px] xs:h-[20px]',
        },
        {
          path: '/snap',
          icon: 'snap',
          routeTag: 'snap',
          name: 'Snap',
          icon_size: 'h-[20px] xs:h-[20px] ',
          icon_inactive_size: 'h-[22px] xs:h-[22px]',
        },
        {
          path: '/cashback',
          icon: 'cashback',
          routeTag: 'cashback',
          name: 'Pay',
          icon_size: 'h-[20px] xs:h-[20px]',
          icon_inactive_size: 'h-[20px] xs:h-[20px]',
        },
        {
          path: '/savings',
          icon: 'savings',
          routeTag: 'savings',
          name: 'Balance',
          icon_size: 'h-[16px] xs:h-[16px]',
          icon_inactive_size: 'h-[20px] xs:h-[20px]',
        },
      ]
    } else {
      this.bottomBarItems = [
        {
          path: '/',
          icon: 'home',
          routeTag: 'base',
          name: 'Home',
          icon_size: 'h-[18px] xs:h-[18px]',
          icon_inactive_size: 'h-[18px] xs:h-[18px]',
        },
        {
          path: '/products',
          icon: 'products',
          routeTag: '/products',
          name: 'Products',
          icon_size: 'h-[18px] xs:h-[18px]',
          icon_inactive_size: 'h-[18px] xs:h-[18px]',
        },
        {
          path: '/shoplists',
          icon: 'shoplists',
          routeTag: '/shoplists',
          name: 'Shoplists',
          icon_size: 'h-[18px] xs:h-[18px]',
          icon_inactive_size: 'h-[18px] xs:h-[18px]',
        },
        {
          path: '/promote',
          icon: 'promote',
          routeTag: '/promote',
          name: 'Promote',
          icon_size: 'h-[18px] xs:h-[18px]',
          icon_inactive_size: 'h-[18px] xs:h-[18px]',
        },
        {
          path: '/wallet',
          icon: 'wallet',
          routeTag: '/wallet',
          name: 'Wallet',
          icon_size: 'h-[16px] xs:h-[16px]',
          icon_inactive_size: 'h-[16px] xs:h-[16px]',
        },
      ]
    }
  }

  public preloadAudioFiles = () => {
    // load audio file to memory

    if (!this.initiatedAudios.includes('ring')) {
      if (isPlatform('mobileweb')) {
        // NativeAudio.preload({
        //   assetPath: 'ring.mp3',
        //   assetId: 'ring',
        //   volume: 1.0,
        //   audioChannelNum: 1,
        // })
      } else if (isPlatform('iphone') || isPlatform('ipad')) {
        // NativeAudio.preload({
        //   assetPath: 'public/assets/sounds/ring.mp3',
        //   assetId: 'ring',
        //   volume: 1.0,
        //   audioChannelNum: 1,
        // })
      } else if (isPlatform('android')) {
        // this.webAudio = new Audio('/assets/sounds/ring.mp3')
      }

      this.initiatedAudios.push('ring')
    }
  }

  public arraySum = (arrayData: any[]) => {
    return arrayData.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0,
    )
  }

  public showConfetti = (elementId = '') => {
    const end = Date.now() + 5 * 1000

    NativeAudio.play({
      assetId: 'confetti',
    })

    // go Buckeyes!
    const colors = [
      '#FF1493',
      '#00FFFF',
      '#FFD700',
      '#32CD32',
      '#FF4500',
      '#8A2BE2',
    ]

    ;(async function frame() {
      const confettiTarget =
        elementId !== '' ? document.getElementById(elementId) : window
      if (!confettiTarget) {
        NativeAudio.stop({
          assetId: 'confetti',
        })
        console.error('Confetti target element not found')
        return
      }

      if (elementId !== '') {
        // you should  only initialize a canvas once, so save this function
        // we'll save it to the canvas itself for the purpose of this demo
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        confettiTarget.confetti =
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          confettiTarget.confetti ||
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          (await window.confetti.create(confettiTarget, { resize: true }))
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      confettiTarget.confetti({
        particleCount: 9,
        angle: 60,
        spread: 50,
        origin: { x: 0 },
        colors: colors,
      })
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      confettiTarget.confetti({
        particleCount: 5,
        angle: 120,
        spread: 55,
        origin: { x: 1 },
        colors: colors,
      })
      if (Date.now() < end) {
        requestAnimationFrame(frame)
      } else {
        setTimeout(() => {
          NativeAudio.stop({
            assetId: 'confetti',
          })
        }, 4000)
      }
    })().then(() => {
      // console.log('Confetti animation completed')
    })
  }

  public pickFiles = async (typesParam: string[]) => {
    try {
      let result: any = undefined

      const types = typesParam.map((item) => item.trim())

      if (types.includes('image/png') && !types.includes('video/mp4')) {
        result = await FilePicker.pickImages({
          readData: true,
        })
      } else {
        result = await FilePicker.pickFiles({
          types: types,
          readData: true,
        })
      }

      const fullResult = result.files.map((file: any) => {
        const fileBlob = this.b64toBlob(file.data || '')
        return new File([fileBlob as BlobPart], file.name, {
          type: file.mimeType,
        })
      })

      return fullResult
    } catch (error) {
      console.log(error)
    }
  }

  public initializeAdmob = async (): Promise<void> => {
    if (!this.adInitialized) {
      const { status } = await AdMob.trackingAuthorizationStatus()

      if (status === 'notDetermined') {
        /**
         * If you want to explain TrackingAuthorization before showing the iOS dialog,
         * you can show the modal here.
         * ex)
         * const modal = await this.modalCtrl.create({
         *   component: RequestTrackingPage,
         * });
         * await modal.present();
         * await modal.onDidDismiss();  // Wait for close modal
         **/
      }

      let setUp: any = {
        testingDevices: ['2077ef9a63d2b398840261c8221a0c9b'],
        initializeForTesting: true,
        requestTrackingAuthorization: true,
      }

      if (process.env.VUE_APP_STATE == 'prod') {
        setUp = {
          requestTrackingAuthorization: true,
        }
      }

      AdMob.initialize(setUp)

      const consentInfo = await AdMob.requestConsentInfo()

      if (
        consentInfo.isConsentFormAvailable &&
        consentInfo.status === AdmobConsentStatus.REQUIRED
      ) {
        const {} = await AdMob.showConsentForm()
        this.adInitialized = true
      } else {
        this.adInitialized = true
      }
    }
  }

  public showInterstitialAd = async (): Promise<void> => {
    if (this.adInitialized) {
      AdMob.addListener(
        InterstitialAdPluginEvents.Loaded,
        (_info: AdLoadInfo) => {
          // Subscribe prepared interstitial
        },
      )

      let adId = ''

      if (getPlatforms()[0] == 'android') {
        adId = process.env.VUE_APP_INTERSTITIAL_AD_UNIT_ANDROID || ''
      } else {
        adId = process.env.VUE_APP_INTERSTITIAL_AD_UNIT_IOS || ''
      }

      const options: AdOptions = {
        adId,
        isTesting: process.env.VUE_APP_STATE != 'prod',
        // npa: true
      }
      await AdMob.prepareInterstitial(options)
      await AdMob.showInterstitial()
    }
  }

  public loadInterstitialAd = async (handler: Function): Promise<void> => {
    if (this.adInitialized) {
      this.showLoader({
        loading: true,
      })

      AdMob.addListener(
        InterstitialAdPluginEvents.Loaded,
        (_info: AdLoadInfo) => {
          // Subscribe prepared rewardVideo
          this.hideLoader()
          handler()
        },
      )

      let adId = ''

      if (getPlatforms()[0] == 'android') {
        adId = process.env.VUE_APP_INTERSTITIAL_AD_UNIT_ANDROID || ''
      } else {
        adId = process.env.VUE_APP_INTERSTITIAL_AD_UNIT_IOS || ''
      }

      const options: AdOptions = {
        adId,
        isTesting: process.env.VUE_APP_STATE != 'prod',
        // npa: true
      }
      await AdMob.prepareInterstitial(options)
      await AdMob.showInterstitial()
    }
  }

  public showRewardAd = async (handler: Function): Promise<void> => {
    if (this.adInitialized) {
      this.showLoader({
        loading: true,
      })
      AdMob.addListener(RewardAdPluginEvents.Loaded, (_info: AdLoadInfo) => {
        // Subscribe prepared rewardVideo
        this.hideLoader()
      })

      AdMob.addListener(RewardAdPluginEvents.FailedToLoad, () => {
        // Subscribe prepared FailedToLoad
      })

      AdMob.addListener(RewardAdPluginEvents.FailedToShow, () => {
        // Subscribe prepared FailedToShow
      })

      AdMob.addListener(
        RewardAdPluginEvents.Rewarded,
        (_rewardItem: AdMobRewardItem) => {
          // Subscribe user rewarded
        },
      )

      AdMob.addListener(RewardAdPluginEvents.Dismissed, () => {
        handler()
      })

      let adId = ''

      if (getPlatforms()[0] == 'android') {
        adId = process.env.VUE_APP_REWARD_AD_UNIT_ANDROID || ''
      } else {
        adId = process.env.VUE_APP_REWARD_AD_UNIT_IOS || ''
      }

      const options: RewardAdOptions = {
        adId,
        isTesting: process.env.VUE_APP_STATE != 'prod',
        // npa: true
        // ssv: {
        //   userId: "A user ID to send to your SSV"
        //   customData: JSON.stringify({ ...MyCustomData })
        //}
      }
      await AdMob.prepareRewardVideoAd(options)
      await AdMob.showRewardVideoAd()
    }
  }

  public shareImageFile = (base64String: string) => {
    this.shareFile('Shoppoint_QRCode.jpg', base64String, 'image/jpeg')
      .then(() => {
        this.hideLoader()
      })
      .catch(() => {
        this.showError(
          undefined,
          'Unable to image. Please try again',
          'Try again',
        )
      })
  }

  public shareReceipt = (reference: string, type: 'image' | 'pdf') => {
    this.showLoader({
      loading: true,
    })
    this.fetchFile(
      `${process.env.VUE_APP_BACKEND_URL}/generate-receipt?reference=${reference}&type=${type}`,
    )
      .then((response: any) => {
        const fileName = `${response.filename}_${reference}.${
          type == 'image' ? 'jpg' : 'pdf'
        }`
        const blobFile = response.blobFile

        this.blobToBase64(blobFile).then((responseFile: any) => {
          this.shareFile(
            fileName,
            responseFile.split(',')[1],
            type == 'image' ? 'image/jpeg' : 'application/pdf',
          )
            .then(() => {
              this.hideLoader()
            })
            .catch(() => {
              this.showError(
                undefined,
                'Unable to share generated document. Please try again',
                'Try again',
              )
            })
        })
      })
      .catch(() => {
        this.showError(
          undefined,
          'Unable to generated transaction info. Please try again',
          'Try again',
        )
      })
  }

  public loaderSetup: LoaderSetup = reactive({
    show: false,
    useModal: false,
    hasError: false,
    loading: false,
    message: '',
    ctaText: '',
    ctaFunction: () => {},
    icon: 'success-check',
    title: '',
    isInteractive: true,
  })

  public modalSetup: ModalSetup = reactive({
    show: false,
    title: '',
    type: 'select_account',
    actionLabel: '',
    action: () => {},
  })

  public alertSetup = reactive<AlertSetup>({
    show: false,
    message: '',
    type: 'success',
  })

  public SetApiUrl = (apiUrl: string) => {
    this.apiUrl = apiUrl
  }

  public GoToRoute = (path: string) => {
    this.router?.push(path)
  }

  public goBack = () => {
    const ignoreBackRoute = this.route?.query.ignoreBackRoute
      ? this.route.query.ignoreBackRoute.toString()
      : null
    const routeMiddlewares: any = this.route?.meta.middlewares
    const goBackRoute = routeMiddlewares?.goBackRoute

    const backRouteFromQuery = this.route?.query.backRoute?.toString()

    if (backRouteFromQuery) {
      this.GoToRoute(backRouteFromQuery)
    } else if (typeof goBackRoute == 'function' && !ignoreBackRoute) {
      this.GoToRoute(goBackRoute())
    } else if (typeof goBackRoute == 'string' && !ignoreBackRoute) {
      this.GoToRoute(goBackRoute)
    } else {
      window.history.length > 1 ? this.router?.go(-1) : this.router?.push('/')
    }
  }

  public showSuccess = (
    message: string,
    action: any = undefined,
    actionLabel = 'Got it!',
    point = 0,
    icon: any = 'success-check',
  ) => {
    Logic.Common.showLoader({
      loading: false,
    })
    Logic.Common.showModal({
      type: 'request_feedback',
      show: true,
      action: () => {
        Logic.Common.showModal({ show: false })
      },
      title: '',
      extraData: {
        icon,
        title: message,
        point,
        buttons: [
          {
            label: actionLabel,
            action: () => {
              if (action) {
                action()
              } else {
                this.showModal({ show: false })
              }
            },
          },
        ],
      },
    })
  }

  public showAlert = (alertSetup: AlertSetup) => {
    const showAlertHandler = () => {
      this.alertSetup = alertSetup
      setTimeout(() => {
        this.alertSetup = {
          show: false,
          message: '',
          type: 'success',
        }
      }, 5100)
    }
    if (this.alertSetup.show) {
      // sleep for 5 seconds
      new Promise((resolve) => setTimeout(resolve, 5000)).then(() => {
        this.alertSetup = {
          show: false,
          message: '',
          type: 'success',
        }

        showAlertHandler()
      })
    } else {
      showAlertHandler()
    }
  }

  public showError = (
    error: CombinedError | undefined,
    fallbackMsg = '',
    ctaText = 'Close',
    ctaAction: Function | undefined = undefined,
    preventClose = false,
    closeAction: Function | undefined = undefined,
  ) => {
    const message = error?.graphQLErrors[0].message

    // FirebaseCrashlytics.addLogMessage({
    //   message,
    // }).then(() => {
    //   //
    // })
    Logic.Common.showLoader({
      loading: false,
    })

    this.showModal({
      show: true,
      title: '',
      type: 'request_feedback',
      preventClose,
      closeAction,
      action: () => {
        //
      },
      extraData: {
        icon: 'error-warning',
        title: message ? message : fallbackMsg,
        buttons: ctaAction
          ? [
              {
                label: ctaText,
                action: () => {
                  if (ctaAction) {
                    ctaAction()
                  } else {
                    this.showModal({ show: false })
                  }
                },
              },
            ]
          : [],
      },
    })
  }

  public copytext = (text: string) => {
    const el = document.createElement('textarea')
    el.value = text
    el.setAttribute('readonly', '')
    el.style.position = 'absolute'
    el.style.left = '-9999px'
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
    this.showAlert({
      show: true,
      message: 'Copied to clipboard',
      type: 'success',
    })
  }

  public getLabel = (data: any, key: string) => {
    const thisData = data.filter((Option: any) => {
      return Option.key == key
    })

    return thisData.length > 0 ? thisData[0].value : ''
  }

  public showLoader = (loaderSetup: LoaderSetup) => {
    this.loaderSetup = loaderSetup
  }

  public showModal = (modalSetupData: ModalSetup) => {
    this.modalSetup = modalSetupData
  }

  public makeid = (length: number) => {
    var result = ''
    var characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    var charactersLength = characters.length
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  }

  public getFormatedPeriod = (durationInMin: string) => {
    const duration = parseFloat(durationInMin)

    if (duration >= 60) {
      const hours = (duration / 60).toFixed(1)
      return `${parseFloat(hours)}${parseFloat(hours) > 1 ? 'hrs' : 'hr'}`
    } else {
      return `${duration}mins`
    }
  }

  public hideLoader = () => {
    const Loader: LoaderSetup = {
      show: false,
      useModal: false,
      loading: false,
    }
    this.loaderSetup = Loader
  }

  public globalParameters = reactive<{
    currency: string
  }>({
    currency: 'ngn',
  })

  public momentInstance = moment

  public convertMinutesToHoursAndMinutes(
    minutes: number,
  ): { hours: number; minutes: number } {
    const hours = Math.floor(minutes / 60)
    const remainingMinutes = minutes % 60

    return {
      hours: hours,
      minutes: remainingMinutes,
    }
  }

  public convertToSlug = (text: string) => {
    return text
      .toLowerCase()
      .replace(/ /g, '-')
      .replace(/[^\w-]+/g, '')
  }

  public groupArray(array: any[], num: number) {
    const group = []

    for (let i = 0; i < array.length; i += num) {
      group.push(array.slice(i, i + num))
    }

    return group
  }

  public getMostFrequentColorFromURL = (imageUrl: string): Promise<string> => {
    return new Promise((resolve, reject) => {
      // Create a new image element
      const image = new Image()
      image.crossOrigin = 'Anonymous' // Handle CORS issues

      image.onload = () => {
        // Create a canvas and get the context
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')

        if (!ctx) {
          reject('Canvas context not available')
          return
        }

        // Set canvas dimensions to image dimensions
        canvas.width = image.width
        canvas.height = image.height

        // Draw the image onto the canvas
        ctx.drawImage(image, 0, 0, image.width, image.height)

        // Get image data from the canvas
        const imageData = ctx.getImageData(0, 0, image.width, image.height)
        const data = imageData.data

        // Count occurrences of each color
        const colorCount: { [key: string]: number } = {}
        for (let i = 0; i < data.length; i += 4) {
          const r = data[i]
          const g = data[i + 1]
          const b = data[i + 2]
          const color = `${r},${g},${b}`

          if (colorCount[color]) {
            colorCount[color]++
          } else {
            colorCount[color] = 1
          }
        }

        // Find the most frequent color
        let maxCount = 0
        let mostFrequentColor = ''
        for (const color in colorCount) {
          if (colorCount[color] > maxCount) {
            maxCount = colorCount[color]
            mostFrequentColor = color
          }
        }

        // Create a faded version of the color
        const [r, g, b] = mostFrequentColor.split(',').map(Number)
        const fadedColor = `rgb(${Math.floor(r * 0.8)}, ${Math.floor(
          g * 0.8,
        )}, ${Math.floor(b * 0.8)})`

        resolve(fadedColor)
      }

      image.onerror = () => {
        reject('Failed to load image')
      }

      // Set the image source to the URL
      image.src = imageUrl
    })
  }

  public convertToMoney = (
    float: any,
    withZeros = true,
    currencyType = 'ngn',
    withSymbol = true,
    customSymbol = '',
  ) => {
    let currencySymbol = ''
    if (currencyType == 'usd') {
      currencySymbol = '$ '
    } else if (currencyType == 'ngn') {
      currencySymbol = '₦'
    }
    if (!withSymbol) {
      currencySymbol = ''
    }

    if (customSymbol) {
      currencySymbol = customSymbol
    }
    if (withZeros) {
      return currency(float, {
        separator: ',',
        symbol: currencySymbol,
      }).format()
    } else {
      return (
        currencySymbol +
        new Intl.NumberFormat().format(parseFloat(parseFloat(float).toFixed(2)))
      )
    }
  }

  private isString = (x: any) => {
    return Object.prototype.toString.call(x) === '[object String]'
  }

  public searchArray = (arr: any[], searchKey: string) => {
    return arr.filter((obj) => {
      return Object.keys(obj).some((key) => {
        return this.isString(obj[key]) ? obj[key].includes(searchKey) : false
      })
    })
  }

  public debounce = (
    method = () => {
      //
    },
    delay = 500,
  ) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    if (typeof window.LIT !== 'undefined') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      clearTimeout(window.LIT)
    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    window.LIT = setTimeout(() => {
      method()
    }, delay)
  }

  public watchProperty = (objectToWatch: any, objectToUpdate: any) => {
    let upatedValue = (this as any)[`${objectToWatch}`]
    const watchAction = () => {
      upatedValue = (this as any)[`${objectToWatch}`]
      if (objectToUpdate) {
        objectToUpdate.value = upatedValue
      }
      this.watchInterval = window.requestAnimationFrame(watchAction)
    }

    watchAction()
  }

  public stopWatchAction = () => {
    if (this.watchInterval != undefined) {
      window.cancelAnimationFrame(this.watchInterval)
    }
  }

  public fetchFile = (url: string) => {
    return new Promise(function (resolve, reject) {
      // Get file name from url.
      const xhr = new XMLHttpRequest()
      xhr.responseType = 'blob'
      xhr.onload = function () {
        resolve(xhr)
      }
      xhr.onerror = reject
      xhr.open('GET', url)
      xhr.send()
    }).then(function (xhr: any) {
      const filename = url.substring(url.lastIndexOf('/') + 1).split('?')[0]
      return {
        filename,
        blobFile: xhr.response,
      }
    })
  }

  public blobToBase64 = (blob: Blob) => {
    return new Promise((resolve, _) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result)
      reader.readAsDataURL(blob)
    })
  }

  public base64ToBlob = (url: string) => {
    return fetch(url)
      .then((res) => res.blob())
      .then((data) => {
        return data
      })
  }

  public b64toBlob = (b64Data: string, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data)
    const byteArrays: any[] = []

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize)

      const byteNumbers = new Array(slice.length)
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i)
      }

      const byteArray = new Uint8Array(byteNumbers)
      byteArrays.push(byteArray)
    }

    const blob = new Blob(byteArrays, { type: contentType })
    return blob
  }

  // const getSwiperDirection =

  public shareFile = (
    fileName: string,
    base64Data: string,
    contentType: string,
  ) => {
    return FileSharer.share({
      filename: fileName,
      contentType,
      base64Data,
    })
      .then((response) => {
        console.log(response)
      })
      .catch((error) => {
        console.error('File sharing failed', error.message)
      })
  }

  public fomartDate = (date: string, format: string) => {
    return moment(date).format(format)
  }

  public countDownTime = (endTime: string) => {
    return moment(moment(endTime).diff(moment.now())).format('mm:ss')
  }

  public timeFromNow = (time: string) => {
    return moment(time).fromNow()
  }

  public updatedData = (oldData: any, newData: any) => {
    if (oldData != undefined && newData != undefined) {
      return { ...oldData, ...newData }
    }
    return oldData
  }

  public preFetchRouteData = (
    routeTo: RouteLocationNormalized,
    next: NavigationGuardNext,
    routeFrom: RouteLocationNormalized,
  ) => {
    const allActions: Promise<any>[] = []
    if (this.loaderSetup.loading) {
      return
    }

    const routeMiddlewares: any = routeTo.meta.middlewares

    // handle fetchRules

    const fetchRules: FetchRule[] = routeMiddlewares.fetchRules || []

    let BreakException = {}

    // Hide modal
    this.showModal({ show: false })

    try {
      for (let index = 0; index < fetchRules.length; index++) {
        const rule: FetchRule = JSON.parse(JSON.stringify(fetchRules[index]))

        if (rule.requireAuth) {
          if (!Logic.Auth.AuthUser) {
            window.location.href = '/auth/login'

            throw BreakException
          }
        }

        let addRuleToRequest = true

        if (rule.condition) {
          switch (rule.condition.routeSearchItem) {
            case 'fullPath':
              addRuleToRequest = routeTo['fullPath'].includes(
                rule.condition.searchQuery,
              )
              break
            case 'params':
              addRuleToRequest = routeTo['params'][rule.condition.searchQuery]
                ? true
                : false
              break
            case 'query':
              addRuleToRequest = routeTo['query'][rule.condition.searchQuery]
                ? true
                : false
              break
            default:
              break
          }
        }

        if (addRuleToRequest) {
          // @ts-ignore
          const domain = Logic[rule.domain]

          let fetchData = false

          if (domain[rule.property] == undefined) {
            fetchData = true
          }

          if (
            typeof rule.ignoreProperty == 'function' &&
            rule.ignoreProperty()
          ) {
            fetchData = true
          }

          if (rule.ignoreProperty) {
            fetchData = true
          }

          if (rule.subProperty) {
            if (domain[rule.property][rule.subProperty] == undefined) {
              fetchData = true
            }
          }

          if (fetchData) {
            allActions.push(
              new Promise((resolve) => {
                const routeId = []
                if (rule.useRouteId) {
                  routeId.push(routeTo.params.id.toString())
                }

                if (rule.useRouteQuery) {
                  const allQueries: any[] = []
                  rule.queries?.forEach((item) => {
                    allQueries.unshift(routeTo.query[item])
                  })
                  rule.params.unshift(...allQueries)
                }

                // update userid
                rule.params.forEach((param) => {
                  if (typeof param === 'object') {
                    if (param.where) {
                      param.where.forEach((item: any) => {
                        if (item.field == 'user.id' || item.field == 'userId') {
                          item.value = Logic.Auth.AuthUser?.id
                        }
                      })
                    }
                  }
                })

                const allParameter = [...new Set(rule.params)]

                if (routeId.length) {
                  allParameter.unshift(...routeId)
                }

                const request = domain[rule.method](...allParameter)

                request?.then((value: any) => {
                  resolve(value)
                })
              }),
            )
          } else {
            if (rule.silentUpdate) {
              // run in silence
              if (rule.useRouteId) {
                rule.params.unshift(routeTo.params.id.toString())
              }
              if (rule.useRouteQuery) {
                const allQueries: any[] = []
                rule.queries?.forEach((item) => {
                  allQueries.unshift(routeTo.query[item])
                })
                rule.params.unshift(...allQueries)
              }
              rule.params = [...new Set(rule.params)]
              setTimeout(() => {
                domain[rule.method](...rule.params)
              }, 1000)
            }
          }
        }
      }
    } catch (error) {
      if (error !== BreakException) throw error
    }

    // save user activities

    if (routeMiddlewares.tracking_data) {
      const trackingData: any = routeMiddlewares.tracking_data
      Logic.User.SaveUserActivity(
        trackingData.lable,
        'page_view',
        undefined,
        trackingData,
      )
    }

    const showBottomNav = () => {
      // page layout
      const layout: any = routeTo.meta?.layout
      if (layout == 'Dashboard') {
        this.showBottomNav = true
      } else {
        this.showBottomNav = false
      }

      this.currentLayout = {
        name: routeTo.meta.layout as string,
        path: routeTo.path as string,
        from: {
          name: routeFrom.meta.layout as string,
          path: routeFrom.path as string,
        },
      }
    }

    if (allActions.length > 0) {
      this.showLoader({
        loading: true,
        show: false,
      })

      Promise.all(allActions).then(() => {
        this.hideLoader()
        showBottomNav()
        return next()
      })
    } else {
      this.hideLoader()
      showBottomNav()
      return next()
    }
  }
}
