<template>
  <app-wrapper>
    <dashboard-layout :is-home="true" :pageKey="'home'">
      <div class="w-full flex flex-col space-y-4 pt-2">
        <!-- Options -->
        <div
          class="w-full flex no-scrollbar flex-row space-x-3 flex-nowrap pl-4 overflow-x-auto scrollbar-hide mdlg:!items-center mdlg:!justify-center md:!justify-center md:!items-center"
        >
          <div class="flex flex-row space-x-3 py-2 pr-4">
            <div
              :class="`flex flex-col space-y-1 items-center justify-center w-[65px]  mdlg:!w-[75px] cursor-pointer ${
                option.title == 'Snap' || option.title == 'Pay' ? 'mdlg:!hidden' : ''
              }`"
              v-for="(option, index) in options"
              :key="index"
              @click="option.action()"
            >
              <app-icon :name="option.icon" :customClass="'!h-[65px] mdlg:!h-[75px]'" />
              <app-normal-text
                class="!text-[11px] text-center !whitespace-nowrap dark:!text-white"
              >
                {{ option.title }}
              </app-normal-text>
            </div>
          </div>
        </div>

        <!-- Recommended for you-->
        <div class="w-full flex flex-col space-y-2">
          <app-header-text :customClass="'!text-left px-4 !text-sm !font-semibold'">
            Recommended for you
          </app-header-text>
          <div
            class="w-full flex no-scrollbar flex-row space-x-3 flex-nowrap pl-4 overflow-x-auto scrollbar-hide"
          >
            <div class="flex flex-row space-x-2 py-1 pr-4">
              <app-shoplist-recommended
                :item="item"
                v-for="(item, index) in recommendedShopLists"
                @click="
                  setStoryContent(ContentType.RecommendedForYou, {
                    id: parseInt(item.id),
                    type: 'collection',
                  })
                "
                :key="index"
              />

              <div
                class="flex flex-col space-y-3 h-full justify-end"
                v-if="showRecommendationIsLoading"
              >
                <div class="w-auto h-[10px] skeleton rounded"></div>
                <div class="!h-[210px] w-[165px] skeleton !rounded-[7.9px]"></div>
              </div>
            </div>

            <div id="visibility-handle-recommended"></div>
          </div>
        </div>

        <!-- Recent shoplists -->
        <div class="w-full flex flex-col space-y-4">
          <div class="w-full flex flex-row items-center justify-between px-4">
            <app-header-text class="!text-sm !font-semibold">
              Cashback offers for you
            </app-header-text>

            <app-normal-text
              class="!underline !text-primary-800 dark:!text-primary-300 cursor-pointer"
              @click="
                setStoryContent(ContentType.Explore, {
                  id: 0,
                  type: 'collection',
                })
              "
            >
              See all
            </app-normal-text>
          </div>

          <!-- Top shoplists by category -->
          <div class="w-full grid grid-cols-2 gap-3 px-4">
            <app-shoplist-explore
              v-for="(item, index) in exploreShoplists"
              :key="index"
              :item="item"
              @click="handleShoplsistClick(item)"
            />

            <template v-if="showExploreIsLoading">
              <div
                class="col-span-1 flex flex-col space-y-2 h-[180px] mdlg:!h-[250px] md:!h-[250px] rounded-[10px] skeleton"
              ></div>
              <div
                class="col-span-1 flex flex-col space-y-2 h-[180px] mdlg:!h-[250px] md:!h-[250px] rounded-[10px] skeleton"
              ></div>
            </template>

            <div class="col-span-2" id="visibility-handle-offers"></div>
          </div>
        </div>

        <!-- Spacer -->
        <div class="h-[120px]"></div>
      </div>
    </dashboard-layout>
  </app-wrapper>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, ref, watch } from "vue";
import { useMeta } from "vue-meta";
import { onIonViewWillEnter } from "@ionic/vue";
import { Logic } from "@shpt/logic";
import {
  AppIcon,
  AppNormalText,
  AppHeaderText,
  AppShoplistRecommended,
  AppShoplistExplore,
} from "@shpt/ui-components";
import {
  getMediaBox,
  getMediaBoxForProduct,
  numberToAbbrev,
  onExploreShoplistsScrolledToEnd,
  onRecommendedScrolledToEnd,
  shoppingCategories,
  showExploreIsLoading,
  showRecommendationIsLoading,
} from "@shpt/ui-components/src/composable";
import { ContentType } from "@shpt/logic/src/gql/graphql";
import { Collection, ShoplistProduct } from "@shpt/ui-components/src/gql/graphql";
import { MediaBox } from "@shpt/ui-components/src/types";
import { setStoryContent } from "@shpt/ui-components/src/composable/story";
import AppWrapper from "@/components/AppWrapper.vue";

export default defineComponent({
  components: {
    AppIcon,
    AppNormalText,
    AppHeaderText,
    AppWrapper,
    AppShoplistRecommended,
    AppShoplistExplore,
  },
  name: "HomePage",
  middlewares: {
    fetchRules: [
      {
        domain: "User",
        property: "WhatsNewContent",
        method: "GetUserContent",
        params: ["", ContentType.WhatsNew, 1, 12],
        requireAuth: true,
      },
      {
        domain: "User",
        property: "FavoriteContent",
        method: "GetUserContent",
        params: ["", ContentType.Favorite, 1, 12],
        requireAuth: true,
      },
      {
        domain: "User",
        property: "LimitedContent",
        method: "GetUserContent",
        params: ["", ContentType.Limited, 1, 12],
        requireAuth: true,
      },
      {
        domain: "User",
        property: "ExploreContent",
        method: "GetUserContent",
        params: ["", ContentType.Explore, 1, 12, true],
        requireAuth: true,
      },
      {
        domain: "User",
        property: "RecommendedForYouContent",
        method: "GetUserContent",
        params: ["", ContentType.RecommendedForYou, 1, 12],
        requireAuth: true,
      },
    ],
    tracking_data: {
      lable: "Home Page",
      stage_type: "neutral",
      end_stage: "",
    },
  },
  layout: "Dashboard",
  setup() {
    useMeta({
      title: "For You",
    });

    const slidePosition = ref(0);

    const currentSlidePosition = ref(0);

    const selectedCategory = ref("All");

    const categoryNames = reactive<string[]>([]);

    const RecommendedForYouContent = ref(Logic.User.RecommendedForYouContent);
    const ExploreContent = ref(Logic.User.ExploreContent);

    watch(slidePosition, () => {
      currentSlidePosition.value = slidePosition.value;
    });

    const options = reactive([
      {
        title: "Snap",
        icon: "options/snap",
        action: () => {
          Logic.Common.GoToRoute("/snap");
        },
      },
      {
        title: "Pay",
        icon: "options/pay",
        action: () => {
          Logic.Common.GoToRoute("/pay/merchant");
        },
      },
      {
        title: "What's New",
        icon: "options/whats-new",
        action: () => {
          setStoryContent(ContentType.WhatsNew, {
            id: 0,
            type: "collection",
          });
        },
      },
      {
        title: "Favorite",
        icon: "options/favourite",
        action: () => {
          setStoryContent(ContentType.Favorite, {
            id: 0,
            type: "collection",
          });
        },
      },
      {
        title: "Limited",
        icon: "options/limited",
        action: () => {
          setStoryContent(ContentType.Limited, {
            id: 0,
            type: "collection",
          });
        },
      },
      {
        title: "Explore",
        icon: "options/explore",
        action: () => {
          setStoryContent(ContentType.Explore, {
            id: 0,
            type: "collection",
          });
        },
      },
    ]);

    const recommendedShopLists = reactive<
      {
        data: MediaBox;
        id: string;
        colors: {
          bg: string;
          text: string;
        };
      }[]
    >([]);
    const exploreShoplists = reactive<
      {
        data: MediaBox;
        id: string;
        type: string;
        colors: {
          bg: string;
          text: string;
        };
      }[]
    >([]);

    const authUser = ref(Logic.Auth.AuthUser);

    const setCategory = () => {
      categoryNames.length = 0;
      categoryNames.push("All");

      categoryNames.push(...shoppingCategories().map((item) => item.name));
    };

    const setShoplistColors = (focus: "recommended" | "explore") => {
      if (focus == "recommended") {
        // recommendedShopLists.forEach((item) => {
        //   Logic.Common.getMostFrequentColorFromURL(item.data.base_image).then(
        //     (color) => {
        //       if (color) {
        //         const fullColor = getColors(color);
        //         item.colors.bg = fullColor.bgColor;
        //         item.colors.text = fullColor.textColor;
        //       }
        //     }
        //   );
        // });
      }
    };

    const setRecommendedShoplists = () => {
      recommendedShopLists.length = 0;

      RecommendedForYouContent.value?.contents.forEach((item) => {
        if (item.type == "collection") {
          const mediaBox = getMediaBox(item.data as Collection);
          recommendedShopLists.push({
            data: mediaBox,
            id: item.id.toString(),
            colors: {
              bg: "",
              text: "",
            },
          });
        }
      });

      setShoplistColors("recommended");
    };

    const handleShoplsistClick = (item: any) => {
      if (item.type == "collection") {
        setStoryContent(ContentType.Explore, {
          id: parseInt(item.id),
          type: "collection",
        });
      } else {
        Logic.Common.GoToRoute(`/shoplist/product/${item.data.product_data?.uuid}`);
      }
    };

    const setExploreShoplists = () => {
      exploreShoplists.length = 0;

      ExploreContent.value?.contents.forEach((item) => {
        if (item.type == "collection") {
          const mediaBox = getMediaBox(item.data as Collection);
          exploreShoplists.push({
            data: mediaBox,
            id: item.id.toString(),
            type: "collection",
            colors: {
              bg: "",
              text: "",
            },
          });
        }
        if (item.type == "product") {
          const mediaBox = getMediaBoxForProduct(item.data as ShoplistProduct);
          exploreShoplists.push({
            data: mediaBox,
            id: item.id.toString(),
            type: "product",
            colors: {
              bg: "",
              text: "",
            },
          });
        }
      });
    };

    const createRecommendedForYouObserver = () => {
      const options = {
        root: null, // Use the viewport as the root
        threshold: 1, // Trigger when 10% of the target is visible
      };

      const observer = new IntersectionObserver(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onRecommendedScrolledToEnd,
        options
      );
      const targetElement = document.getElementById(`visibility-handle-recommended`);
      if (targetElement) {
        observer.observe(targetElement);
      }
    };

    const createExploreShoplistsObserver = () => {
      const options = {
        root: null, // Use the viewport as the root
        threshold: 1, // Trigger when 10% of the target is visible
      };

      const observer = new IntersectionObserver(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        onExploreShoplistsScrolledToEnd,
        options
      );
      const targetElement = document.getElementById(`visibility-handle-offers`);
      if (targetElement) {
        observer.observe(targetElement);
      }
    };

    onIonViewWillEnter(() => {
      Logic.Game.CheckGameStatus();
      setCategory();
    });

    watch(RecommendedForYouContent, () => {
      // console.log(RecommendedForYouContent.value);
    });

    watch(ExploreContent, () => {
      setExploreShoplists();
    });

    onMounted(() => {
      setCategory();
      Logic.User.watchProperty("RecommendedForYouContent", RecommendedForYouContent);
      Logic.User.watchProperty("ExploreContent", ExploreContent);
      setRecommendedShoplists();
      setExploreShoplists();
      setTimeout(() => {
        createRecommendedForYouObserver();
        createExploreShoplistsObserver();
      }, 200);

      if (Logic.Common.currentBuildType() == "web") {
        Logic.Game.CheckGameStatus();
      }
    });

    return {
      Logic,
      authUser,
      options,
      slidePosition,
      currentSlidePosition,
      categoryNames,
      selectedCategory,
      recommendedShopLists,
      exploreShoplists,
      showRecommendationIsLoading,
      showExploreIsLoading,
      ContentType,
      setStoryContent,
      handleShoplsistClick,
      numberToAbbrev,
    };
  },
});
</script>
