import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-full h-full grid grid-cols-2 absolute top-0 left-0 z-0"
}
const _hoisted_2 = {
  key: 3,
  class: "w-full absolute bottom-0 left-0 px-3 pb-3 flex flex-col space-y-2 z-10"
}
const _hoisted_3 = {
  key: 0,
  class: "w-full flex flex-row items-center justify-between"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_image_loader = _resolveComponent("app-image-loader")!
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_header_text = _resolveComponent("app-header-text")!
  const _component_app_video_player = _resolveComponent("app-video-player")!

  return (_openBlock(), _createBlock(_component_app_image_loader, {
    customClass: '!w-full flex flex-row relative',
    photoUrl: 
      _ctx.isGrid && _ctx.data.is_grid
        ? ''
        : `${_ctx.videoIsReady || _ctx.enforceGrid ? '' : _ctx.data.base_image}`
    ,
    onClick: _ctx.handleClick
  }, {
    default: _withCtx(() => [
      (!_ctx.isFullScreen && !_ctx.allowPlay)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            ((_ctx.isGrid && _ctx.data.is_grid) || _ctx.enforceGrid)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.gridImages, (image, index) => {
                    return (_openBlock(), _createBlock(_component_app_image_loader, {
                      photoUrl: image.url,
                      class: _normalizeClass(`${image.width} h-full rounded-none`),
                      key: index,
                      style: _normalizeStyle(`${image.border} `)
                    }, null, 8, ["photoUrl", "class", "style"]))
                  }), 128))
                ]))
              : _createCommentVNode("", true),
            (!_ctx.data.is_story_like && _ctx.data.user_info)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 1,
                  class: _normalizeClass(`absolute  ${
          _ctx.isLargeView && !_ctx.data.is_story_like ? 'left-[3%]  top-[5%]' : ''
        }
${!_ctx.isLargeView && !_ctx.data.is_story_like ? 'left-[5%]  !top-[5%]' : ''}   
   
`)
                }, [
                  _createVNode(_component_app_image_loader, {
                    photoUrl: _ctx.data.user_info.image,
                    class: _normalizeClass(`!rounded-full ${_ctx.data.user_info.image_size}`)
                  }, null, 8, ["photoUrl", "class"])
                ], 2))
              : _createCommentVNode("", true),
            (_ctx.isVideo)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 2,
                  class: _normalizeClass(`absolute top-[43%] z-10 ${
          _ctx.isLargeView ? 'left-[45%]' : 'left-[47%]'
        } `)
                }, [
                  _createVNode(_component_app_icon, {
                    name: 'play',
                    customClass: `${
            _ctx.isLargeView ? 'h-[30px]' : 'h-[23px]'
          } ${_ctx.playButtonStyle}`
                  }, null, 8, ["customClass"])
                ], 2))
              : _createCommentVNode("", true),
            (!_ctx.data.is_clear)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  (_ctx.data.is_large_view)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                        _createVNode(_component_app_header_text, { class: "!text-white line-clamp-1 !font-semibold" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.data.title), 1)
                          ]),
                          _: 1
                        })
                      ]))
                    : _createCommentVNode("", true)
                ]))
              : _createCommentVNode("", true),
            (_ctx.data.is_story_like && !_ctx.data.is_clear && _ctx.data.user_info)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 4,
                  class: _normalizeClass(`absolute  z-10 
    ${_ctx.data.is_story_like ? 'right-[5%]  top-[5%]' : ''}    
`)
                }, [
                  _createVNode(_component_app_image_loader, {
                    photoUrl: _ctx.data.user_info.image,
                    class: _normalizeClass(`!rounded-full ${_ctx.data.user_info.image_size}`)
                  }, null, 8, ["photoUrl", "class"])
                ], 2))
              : _createCommentVNode("", true)
          ], 64))
        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            (_ctx.isVideo && _ctx.videoSettings.paused)
              ? (_openBlock(), _createElementBlock("span", {
                  key: 0,
                  class: _normalizeClass(`absolute top-[43%] z-10 cursor-pointer ${
          _ctx.isLargeView ? 'left-[45%]' : 'left-[47%]'
        } `),
                  onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.togglePlayPause()), ["stop"]))
                }, [
                  _createVNode(_component_app_icon, {
                    name: 'play',
                    customClass: `${
            _ctx.isLargeView ? 'h-[30px]' : 'h-[23px]'
          } ${_ctx.playButtonStyle}`
                  }, null, 8, ["customClass"])
                ], 2))
              : _createCommentVNode("", true),
            (_ctx.videoIsReady)
              ? (_openBlock(), _createBlock(_component_app_video_player, {
                  key: 1,
                  videoUrl: _ctx.data.video_url,
                  "is-paused": _ctx.videoSettings.paused,
                  "onUpdate:isPaused": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.videoSettings.paused) = $event)),
                  "is-muted": _ctx.videoSettings.muted,
                  "onUpdate:isMuted": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.videoSettings.muted) = $event)),
                  "is-buffering": _ctx.videoSettings.loading,
                  "onUpdate:isBuffering": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.videoSettings.loading) = $event)),
                  onCurrentTime: _cache[4] || (_cache[4] = 
            (data) => {
              _ctx.videoCurrentTime = data;
              _ctx.context.emit('currentTime', data);
            }
          ),
                  videoTitle: _ctx.data.title,
                  videoDescription: `Playing ${_ctx.data.title}`,
                  coverImage: _ctx.data.base_image,
                  ref: "videoPlayerRef",
                  isFullScreen: _ctx.isFullScreen,
                  class: "mdlg:!rounded-[10px] md:!rounded-[10px]",
                  loopVideo: _ctx.loopVideo
                }, null, 8, ["videoUrl", "is-paused", "is-muted", "is-buffering", "videoTitle", "videoDescription", "coverImage", "isFullScreen", "loopVideo"]))
              : _createCommentVNode("", true)
          ], 64)),
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["photoUrl", "onClick"]))
}