import { Logic } from '.'
import { Badge } from '../gql/graphql'

const badgeIsWon = (id: string) => {
  const currentBadge = Logic.Auth.AuthUser?.game_profile?.badges?.filter(
    (item) => item.id == id,
  )

  return (currentBadge?.length || 0) > 0
}

const showBadgeInfo = (data: Badge, isWon: boolean) => {
  Logic.Common.showModal({
    show: true,
    type: 'badge_info',
    extraData: {
      data: data,
      isWon: isWon,
    },
  })
}

export { badgeIsWon, showBadgeInfo }
