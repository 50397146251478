<template>
  <div class="w-full flex flex-row justify-between py-1">
    <div class="space-x-2 flex flex-row">
      <div class="w-[37px]">
        <div class="w-[37px]">
          <app-icon :name="data.type" :customClass="'h-[36px] w-[36px]'" />
        </div>
      </div>
      <div class="flex flex-col space-y-1">
        <app-header-text class="!text-left !line-clamp-1 text-xs !font-semibold">
          {{ data.description }}
        </app-header-text>
        <app-normal-text class="!text-left !text-grey-500 !text-[11px] !font-light">
          {{ data.time }}
        </app-normal-text>
      </div>
    </div>

    <div class="flex flex-col space-y-1 items-end">
      <app-normal-text class="text-right !whitespace-nowrap !font-semibold">
        {{ data.type == "credit" ? "+" : "-"
        }}{{ Logic.Common.convertToMoney(data.amount, false, "", false, "NGN") }}
      </app-normal-text>

      <span
        class="px-2 py-[2px] bg-[#F5EDFC] flex items-center justify-center rounded-[40px] dark:!bg-primary-300"
      >
        <app-normal-text
          class="!text-[9px] !text-[#9D4EDD] !whitespace-nowrap dark:!text-white"
        >
          {{ data.category }}
        </app-normal-text>
      </span>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import AppImageLoader from "../AppImageLoader";
import { AppNormalText, AppHeaderText } from "../AppTypography";
import AppIcon from "../AppIcon";
import { Logic } from "../../composable";

export default defineComponent({
  components: {
    AppImageLoader,
    AppNormalText,
    AppHeaderText,
    AppIcon,
  },
  props: {
    data: {
      type: Object as () => {
        id: string;
        description: string;
        type: string;
        time: string;
        amount: number;
        category: string;
      },
      required: true,
    },
  },
  emits: ["update:modelValue"],
  name: "AppTransaction",
  setup() {
    return {
      Logic,
    };
  },
});
</script>
