<template>
  <app-wrapper>
    <plain-layout :title="'clear'" class="overflow-y-auto h-screen">
      <template v-slot:topBar>
        <div
          class="w-full flex flex-row space-x-2 justify-between px-4 items-center py-4 sticky top-0 bg-white z-50"
          style="padding-top: calc(env(safe-area-inset-top) + 16px) !important"
        >
          <div class="flex flex-row space-x-2 items-center">
            <div class="w-[16px] cursor-pointer" @click="Logic.Common.goBack()">
              <app-icon :custom-class="'h-[14px]'" :name="'arrow-back'">
              </app-icon>
            </div>
            <app-image-loader
              :photoUrl="BusinessProfile?.photo_url ?? '/images/temp/brand.png'"
              :customClass="'!h-[40px] !w-[40px] rounded-full'"
            />
            <div class="flex flex-col space-y-[1px]">
              <div class="flex flex-row space-x-1 items-center">
                <div>
                  <app-normal-text class="!text-left !font-semibold !text-sm">
                    {{ BusinessProfile?.business_name }}
                  </app-normal-text>
                </div>
                <app-icon :name="'verified'" class="!h-[14px]" />
              </div>
              <div class="flex flex-row space-x-1 items-center">
                <app-normal-text class="!text-[11px] !text-gray-800 !text-left">
                  @{{ BusinessProfile?.business_tag }}
                </app-normal-text>
                <span class="h-[4px] w-[4px] rounded-full bg-gray-500"> </span>
                <app-normal-text class="!text-[11px] !text-gray-800 !text-left">
                  {{ numberToAbbrev(followers || 0) }}
                  Follower{{ followers > 1 ? "s" : "" }}
                </app-normal-text>
              </div>
            </div>
          </div>

          <div class="flex flex-row items-center justify-center space-x-2">
            <div class="flex flex-col">
              <app-button
                @click="followUnfollowBusiness"
                :customClass="`!rounded-full !py-1 !px-5 !text-[12px] ${
                  !isFollowed
                    ? 'bg-primary-main !text-white'
                    : '!bg-primary-50 !text-primary-main'
                }`"
              >
                {{ isFollowed ? "Following" : "Follow" }}
              </app-button>
            </div>
            <div
              class="h-[30px] w-[20px] flex items-center justify-center cursor-pointer"
              @click="showBusinessInfo"
            >
              <app-icon :name="'info-circle'" :customClass="'h-[20px]'" />
            </div>
          </div>
        </div>
      </template>

      <div class="w-full flex flex-col overflow-y-auto relative h-full">
        <!-- Top bar -->
        <div class="w-full flex flex-col space-y-3 px-4">
          <!-- Banner ad -->
          <div class="w-full flex flex-col">
            <app-banner-ad />
          </div>

          <template v-if="!merchantShoplists.length">
            <div class="h-[250px] w-full flex items-center justify-center">
              <app-empty-state
                :title="'No Offers here'"
                :sub-title="'This merchant has no offers at the moment.'"
              />
            </div>
          </template>

          <!-- Brand Shoplists -->
          <div
            class="w-full flex flex-col space-y-2"
            v-if="merchantShoplists.length"
          >
            <!-- Top -->
            <div
              class="w-full flex flex-col space-y-1 cursor-pointer"
              v-for="(item, index) in [merchantShoplists[0]]"
              :key="index"
              @click="
                setStoryContent(
                  ContentType.BusinessContents,
                  {
                    id: parseInt(item.id),
                    type: item.type,
                  },
                  currentBusinessUuid
                )
              "
            >
              <app-media-box
                :data="item.data"
                class="!h-[230px] mdlg:!h-[350px] !rounded-[10px] relative"
                :isVideo="!item.data.is_grid"
                :is-large-view="true"
              >
                <div
                  class="absolute top-0 left-0 h-full w-full bg-black !bg-opacity-30 !rounded-[10px] flex flex-col justify-between py-3 px-3"
                >
                  <div class="flex flex-row justify-end">
                    <span
                      :class="`px-2 py-[2px] rounded-[4px] bg-primary-main  flex flex-row items-center justify-center`"
                    >
                      <div
                        class="flex flex-row space-x-1 justify-end flex-grow items-center"
                      >
                        <app-icon
                          :name="'cashback-icon'"
                          :customClass="'h-[15px]'"
                        />
                        <app-normal-text
                          :customClass="'!whitespace-nowrap !text-[11px] mdlg:!text-[12px] font-semibold !text-white'"
                        >
                          Earn
                          <span class="font-semibold pl-[1px]"
                            >{{
                              numberToAbbrev(item.data.points || 0)
                            }}
                            Pts</span
                          >
                        </app-normal-text>
                      </div>
                    </span>
                  </div>

                  <div class="w-full flex flex-col space-y-1">
                    <app-normal-text
                      class="!text-left !text-white uppercase"
                      v-if="item.data.expires_at"
                    >
                      {{
                        Logic.Common.fomartDate(
                          item.data.expires_at || "",
                          "MMM Do"
                        )
                      }}
                    </app-normal-text>
                    <div class="w-full flex flex-col space-y-1">
                      <app-header-text
                        class="!text-white !text-left !font-semibold"
                      >
                        {{ item.data.title }}
                      </app-header-text>
                      <!-- All tags -->
                      <div
                        class="w-full flex flex-row space-x-1 items-center flex-nowrap"
                        v-if="item.data.tags.length"
                      >
                        <span
                          class="px-2 py-[1px] bg-transparent rounded-[20px] border-[1px] border-white"
                          v-for="(tag, index) in item.data.tags"
                          :key="index"
                        >
                          <app-normal-text class="!text-white !text-[10px]">
                            {{ tag }}
                          </app-normal-text>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </app-media-box>
            </div>

            <!-- Cashback Offers -->
            <div class="w-full flex flex-col space-y-3 pt-1">
              <app-header-text
                :customClass="'!text-left !text-sm !font-semibold'"
              >
                Cashback Offers
              </app-header-text>
              <div class="w-full grid grid-cols-2 gap-3">
                <app-shoplist-explore
                  v-for="(item, index) in merchantShoplists"
                  :key="index"
                  :item="item"
                  @click="handleShoplsistClick(item)"
                />
              </div>
            </div>

            <!-- Spacer -->
            <div class="h-[120px]"></div>
          </div>
        </div>
      </div>
    </plain-layout>
  </app-wrapper>
</template>

<script lang="ts">
import { defineComponent, onMounted, watch, reactive, ref } from "vue";
import { useMeta } from "vue-meta";
import { onIonViewWillEnter } from "@ionic/vue";
import { Logic } from "@shpt/logic";
import {
  AppIcon,
  AppNormalText,
  AppImageLoader,
  AppBannerAd,
  AppHeaderText,
  AppMediaBox,
  AppEmptyState,
  AppShoplistExplore,
  AppButton,
} from "@shpt/ui-components";
import { MediaBox } from "@shpt/ui-components/src/types";
import {
  getMediaBox,
  getMediaBoxForProduct,
} from "@shpt/ui-components/src/composable";
import {
  Collection,
  ShoplistProduct,
} from "@shpt/ui-components/src/gql/graphql";
import { setStoryContent } from "@shpt/ui-components/src/composable/story";
import { ContentType, FollowAction } from "@shpt/logic/src/gql/graphql";
import AppWrapper from "@/components/AppWrapper.vue";
import { numberToAbbrev } from "@shpt/ui-components/src/composable";

export default defineComponent({
  components: {
    AppIcon,
    AppNormalText,
    AppImageLoader,
    AppBannerAd,
    AppHeaderText,
    AppMediaBox,
    AppEmptyState,
    AppWrapper,
    AppShoplistExplore,
    AppButton,
  },
  name: "BrandProfilePage",
  layout: "Onboarding",
  middlewares: {
    fetchRules: [
      {
        domain: "User",
        property: "BusinessProfile",
        method: "GetBusinessProfile",
        params: [],
        requireAuth: true,
        useRouteQuery: true,
        queries: ["uuid"],
        ignoreProperty: true,
      },
      {
        domain: "User",
        property: "MerchantShoplistsContent",
        method: "GetUserContent",
        params: [ContentType.BusinessContents, 1, 50, true, false],
        requireAuth: true,
        useRouteQuery: true,
        queries: ["uuid"],
        ignoreProperty: true,
      },
    ],
    tracking_data: {
      lable: "Brand Profile Page",
      stage_type: "neutral",
      end_stage: "",
    },
  },
  setup() {
    useMeta({
      title: "",
    });

    const currentBusinessUuid = ref("");

    const BusinessProfile = ref(Logic.User.BusinessProfile);
    const MerchantShoplistsContent = ref(Logic.User.MerchantShoplistsContent);
    const isFollowed = ref(false);
    const followers = ref(0);

    const showBusinessInfo = () => {
      Logic.Common.showModal({
        show: true,
        title: `About ${BusinessProfile.value?.business_name}`,
        type: "business_info",
        action: () => {
          // Logic.Common.goBack();
        },
        extraData: {
          data: BusinessProfile.value,
        },
      });
    };

    const merchantShoplists = reactive<
      {
        data: MediaBox;
        id: string;
        type: string;
        colors: {
          bg: string;
          text: string;
        };
      }[]
    >([]);

    const setMerchantShoplists = () => {
      merchantShoplists.length = 0;

      MerchantShoplistsContent.value?.contents.forEach((item) => {
        if (item.type == "collection") {
          const mediaBox = getMediaBox(item.data as Collection);
          merchantShoplists.push({
            data: mediaBox,
            id: item.id.toString(),
            type: "collection",
            colors: {
              bg: "",
              text: "",
            },
          });
        }
        if (item.type == "product") {
          const mediaBox = getMediaBoxForProduct(item.data as ShoplistProduct);
          merchantShoplists.push({
            data: mediaBox,
            id: item.id.toString(),
            type: "product",
            colors: {
              bg: "",
              text: "",
            },
          });
        }
      });
      currentBusinessUuid.value =
        Logic.Common.route?.query?.uuid?.toString() || "";

      useMeta({
        title: BusinessProfile.value?.business_name || "",
      });
    };

    const handleShoplsistClick = (item: any) => {
      if (item.type == "collection") {
        setStoryContent(ContentType.Explore, {
          id: parseInt(item.id),
          type: "collection",
        });
      } else {
        Logic.Common.GoToRoute(
          `/shoplist/product/${item.data.product_data?.uuid}`
        );
      }
    };

    const followUnfollowBusiness = () => {
      Logic.User.FollowUnfollowBusinessForm = {
        action: isFollowed.value ? FollowAction.Unfollow : FollowAction.Follow,
        business_uuid: BusinessProfile.value?.uuid,
      };

      isFollowed.value = !isFollowed.value;

      followers.value = isFollowed.value
        ? followers.value + 1
        : followers.value - 1;

      Logic.User.FollowUnfollowBusiness()?.then((data) => {
        if (data) {
          //
        }
      });
    };

    const setFollowedStatus = () => {
      isFollowed.value = BusinessProfile.value?.is_following || false;
      followers.value = BusinessProfile.value?.followers || 0;
    };

    onIonViewWillEnter(() => {
      setMerchantShoplists();
      setFollowedStatus();
    });

    watch(MerchantShoplistsContent, () => {
      setMerchantShoplists();
    });

    watch(BusinessProfile, () => {
      setFollowedStatus();
    });

    onMounted(() => {
      Logic.User.watchProperty("BusinessProfile", BusinessProfile);
      Logic.User.watchProperty(
        "MerchantShoplistsContent",
        MerchantShoplistsContent
      );
      setMerchantShoplists();
      setFollowedStatus();
    });

    return {
      merchantShoplists,
      Logic,
      BusinessProfile,
      ContentType,
      currentBusinessUuid,
      isFollowed,
      followers,
      numberToAbbrev,
      setStoryContent,
      showBusinessInfo,
      handleShoplsistClick,
      followUnfollowBusiness,
    };
  },
});
</script>
