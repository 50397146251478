<template>
  <video
    :id="`player${tabIndex}`"
    :class="`op-player__media  cursor-pointer ${
      isFullScreen ? 'video-fullscreen' : 'h-full object-cover'
    }`"
    :controls="false"
    :loop="loopVideo"
    :autoplay="autoplay"
    playsInline
    @click="togglePlayPause"
    :poster="coverImage"
    :muted="true"
  >
    <source
      :src="
        videoUrl.replaceAll(
          'https://shpt.blob.core.windows.net/',
          'https://shptstorage-grazapdjemh4bzct.z02.azurefd.net/'
        )
      "
    />
  </video>
</template>
<script lang="ts">
import OpenPlayerJS from "openplayerjs";
import "./index.css";
import {
  defineComponent,
  onMounted,
  onUnmounted,
  ref,
  toRef,
  watch,
} from "vue";
import { PlayerOptions } from "openplayerjs/types/interfaces";

export default defineComponent({
  props: {
    videoUrl: {
      type: String,
      required: true,
    },
    height: {
      type: Number,
      default: 500,
    },
    autoplay: {
      type: Boolean,
      default: true,
    },
    hidePlayButton: {
      type: Boolean,
      default: false,
    },
    isFullScreen: {
      type: Boolean,
      default: true,
    },
    coverImage: {
      type: String,
      default: "",
    },
    loopVideo: {
      type: Boolean,
      default: true,
    },
    videoTitle: {
      type: String,
      default: "",
    },
    videoDescription: {
      type: String,
      default: "",
    },
    options: {
      type: Object as () => PlayerOptions,
      default: (): PlayerOptions => {
        return {
          controls: {
            layers: {
              right: [],
            },
            alwaysVisible: false,
          },
          mode: "fit",
          hls: {
            autoStartLoad: true, // Start playing automatically after manifest is loaded
            capLevelToPlayerSize: true, // Cap level to the player size
            maxBufferLength: 60, // Control buffer length for best network adaptation
            manifestLoadingTimeout: 10000, // Set timeout for manifest loading
            manifestLoadingMaxRetryCount: 3, // Set maximum retry count for manifest loading
            manifestLoadingRetryDelay: 2000, // Set delay between retries for manifest loading
          },
        };
      },
    },
  },
  name: "AppVideoPlayer",
  emits: [
    "update:isPaused",
    "update:isMuted",
    "update:timeUpdated",
    "update:isBuffering",
    "totalDuration",
    "currentTime",
  ],
  setup: (props, context) => {
    const tabIndex = Math.floor(Math.random() * 100000);

    const videoUrlToRef = toRef(props, "videoUrl");

    const currentTime = ref(0);
    const totalVideoDuration = ref(0);

    const isInViewPort = ref(false);

    let playerInstance: OpenPlayerJS | undefined = undefined;

    const setPageMetadata = () => {
      // Update the page title
      document.title = `${props.videoTitle} | Shoppoint`;

      // Update meta description
      let descriptionMeta = document.querySelector('meta[name="description"]');
      document
        .querySelector('meta[property="og:image"]')
        ?.setAttribute("content", props.coverImage);
      document
        .querySelector('meta[property="og:video"]')
        ?.setAttribute("content", props.videoUrl);
      document
        .querySelector('meta[property="og:url"]')
        ?.setAttribute("content", props.videoUrl);
      if (descriptionMeta) {
        descriptionMeta.setAttribute("content", props.videoDescription);
      } else {
        const meta = document.createElement("meta");
        meta.name = "description";
        meta.content = props.videoDescription;
        document.head.appendChild(meta);
      }
    };

    const setPlayer = () => {
      try {
        playerInstance = new OpenPlayerJS(`player${tabIndex}`, props.options);

        playerInstance.init();

        playerInstance
          .getElement()
          .addEventListener("timeupdate", (event: any) => {
            context.emit("update:timeUpdated", event.target?.currentTime);
            currentTime.value = event.target?.currentTime;
            context.emit("currentTime", event.target?.currentTime);
          });

        playerInstance
          .getElement()
          .addEventListener("loadedmetadata", (event: any) => {
            totalVideoDuration.value = event.target?.duration;
            if (event.target?.duration) {
              context.emit("totalDuration", event.target?.duration);
            }
          });

        // Listen for OpenPlayer.js events
        playerInstance.getElement().addEventListener("waiting", () => {
          console.log("OpenPlayer: Video is buffering...");
        });

        const videoElement = playerInstance.getElement();

        // Listen for OpenPlayer.js video events
        videoElement.addEventListener("waiting", () => {
          context.emit("update:isBuffering", true);
        });

        videoElement.addEventListener("play", () => {
          setPageMetadata();
        });

        videoElement.addEventListener("playing", () => {
          if (!isInViewPort.value) {
            mute();
            pause();
          }
          context.emit("update:isBuffering", false);
        });
      } catch (error) {
        console.log(error);
      }
    };

    const setVideoDuration = () => {
      try {
        let videoDuration = 0;
        return new Promise((resolve, reject) => {
          const checkIfVideoIsReady = setInterval(() => {
            if (playerInstance?.getMedia().duration) {
              // HAVE_ENOUGH_DATA
              clearInterval(checkIfVideoIsReady);
              videoDuration = playerInstance?.getMedia().duration || 0;
              if (videoDuration) {
                context.emit("totalDuration", videoDuration);
              }
              resolve(videoDuration);
            }
          }, 100);
        });
      } catch (error) {
        console.log(error);
      }
    };

    const pause = () => {
      try {
        playerInstance?.pause();
        context.emit("update:isPaused", true);
      } catch (error) {
        console.log(error);
      }
    };

    const play = () => {
      try {
        unmute();
        playerInstance?.play();
        context.emit("update:isPaused", false);
      } catch (error) {
        console.log(error);
      }
    };

    const stop = () => {
      try {
        playerInstance?.stop();
      } catch (error) {
        console.log(error);
      }
    };

    const mute = () => {
      try {
        if (playerInstance) {
          playerInstance.getMedia().muted = true;
          context.emit("update:isMuted", true);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const unmute = () => {
      try {
        if (playerInstance) {
          playerInstance.getMedia().muted = false;
          context.emit("update:isMuted", false);
        }
      } catch (error) {
        console.log(error);
      }
    };

    const destroy = () => {
      try {
        playerInstance?.destroy();
        playerInstance = undefined;
      } catch (error) {
        console.log(error);
      }
    };

    const togglePlayPause = () => {
      try {
        if (playerInstance) {
          if (playerInstance.getMedia()?.paused) {
            play();
          } else {
            pause();
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    const toggleMuteUnmute = () => {
      try {
        if (playerInstance) {
          if (playerInstance.getMedia().muted) {
            unmute();
          } else {
            mute();
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    const reMountPlayer = () => {
      //
    };

    const handleIntersect = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // play();
          isInViewPort.value = true;
        } else {
          isInViewPort.value = false;
        }
      });
    };

    const createObserver = () => {
      const options = {
        root: null, // Use the viewport as the root
        threshold: 0.1, // Trigger when 10% of the target is visible
      };

      const observer = new IntersectionObserver(handleIntersect, options);
      const targetElement = document.getElementById(`player${tabIndex}`);
      if (targetElement) {
        observer.observe(targetElement);
      }
    };

    watch(videoUrlToRef, () => {
      try {
        if (playerInstance) {
          playerInstance?.pause();
          playerInstance?.destroy();
        }
      } catch (error) {
        console.log(error);
      }
      setTimeout(() => {
        setPlayer();
      }, 300);
    });

    onMounted(() => {
      setTimeout(() => {
        setPlayer();
        createObserver();
      }, 300);
    });

    onUnmounted(() => {
      destroy();
    });

    return {
      tabIndex,
      currentTime,
      totalVideoDuration,
      pause,
      play,
      stop,
      destroy,
      mute,
      unmute,
      togglePlayPause,
      toggleMuteUnmute,
      reMountPlayer,
      setVideoDuration,
    };
  },
});
</script>
<style scoped>
.video-fullscreen {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.op-controls {
  display: none;
}
</style>
