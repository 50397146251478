<template>
  <app-wrapper>
    <subpage-layout :title="'Cashback History'">
      <template v-slot:extra-topbar-row>
        <div class="w-full flex flex-col pl-4 pb-2">
          <div
            class="w-full flex no-scrollbar flex-row space-x-3 flex-nowrap overflow-x-auto scrollbar-hide"
          >
            <div class="flex flex-row space-x-3 pr-4">
              <div
                v-for="(item, index) in filters"
                :key="index"
                :class="`px-4 py-1 cursor-pointer ${
                  selectedFilter == item.id
                    ? 'bg-primary-main'
                    : 'bg-grey-50 dark:bg-gray-800'
                }  rounded-[20px]  flex flex-row items-center justify-center`"
                @click="selectedFilter = item.id"
              >
                <app-normal-text
                  :class="`${
                    selectedFilter == item.id
                      ? '!text-white'
                      : '!text-grey-700 dark:!text-gray-100'
                  }`"
                  :custom-class="'whitespace-nowrap'"
                >
                  {{ item.title }}
                </app-normal-text>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div
        class="w-full flex flex-col space-y-0 h-full flex-grow relative top-0"
      >
        <div class="w-full flex flex-col space-y-3 pt-3 px-4">
          <template v-if="allRewards.length">
            <app-virtual-scroller
              :data="allRewards"
              :pagination="paginationData"
              :fetch-more="getMoreRewards"
            >
              <template #item-content="{ index, item }">
                <div
                  :key="index"
                  @click="item.action()"
                  :class="`w-full flex flex-col  space-y-3 bg-white dark:bg-black rounded-[8px] border-[1px] border-grey-100 cursor-pointer`"
                >
                  <div
                    class="w-full flex flex-row items-center justify-between px-3 pt-3"
                  >
                    <div class="flex flex-row space-x-2 items-start">
                      <div class="w-[40px]">
                        <div
                          class="h-[40px] w-[40px] rounded-full bg-primary-50 items-center justify-center flex"
                        >
                          <app-icon
                            :name="item.icon"
                            :customClass="'h-[20px] xs:h-[18px]'"
                          />
                        </div>
                      </div>

                      <div class="flex flex-col">
                        <app-normal-text
                          :color="'text-[#240046]'"
                          :customClass="'!font-semibold  capitalize'"
                        >
                          {{ item.title }}
                        </app-normal-text>
                        <div class="flex flex-col space-y-1">
                          <app-normal-text class="text-grey-700">
                            {{ item.subtitle }}
                          </app-normal-text>
                          <app-normal-text class="text-grey-700">
                            {{ item.datetime }}
                          </app-normal-text>
                        </div>
                      </div>
                    </div>

                    <div class="flex flex-col h-[60px] justify-between">
                      <app-normal-text
                        :color="'text-[#2A2A2A] dark:!text-white'"
                        :customClass="'!font-bold text-right !text-sm'"
                      >
                        {{ item.total_spend }}
                      </app-normal-text>

                      <div class="flex flex-row">
                        <app-badge :color="item.status_color">{{
                          item.status
                        }}</app-badge>
                      </div>
                    </div>
                  </div>

                  <div
                    class="w-full flex flex-col px-3 pb-3 border-t-[1px] border-grey-100 pt-3"
                  >
                    <div class="flex flex-row space-x-1">
                      <app-normal-text
                        :color="'text-grey-700 dark:!text-gray-400'"
                        :customClass="'!whitespace-nowrap '"
                      >
                        Earnings:
                        <span class="font-semibold text-black dark:text-white">
                          {{ item.points }}
                          Pts ({{ item.amount }})</span
                        >
                      </app-normal-text>
                    </div>
                  </div>
                </div>
              </template>
              <template #skeleton-loaders>
                <div class="w-full flex flex-col space-y-2">
                  <div class="w-[40%] h-[10px] rounded skeleton"></div>
                  <div
                    class="w-full flex flex-col space-y-2 skeleton h-[60px] rounded-[10px]"
                  ></div>
                </div>
              </template>
            </app-virtual-scroller>
          </template>

          <div
            v-else
            class="w-full flex flex-col py-4 px-8 h-[200px] rounded-[10px] bg-primary-50 dark:!bg-gray-800 justify-center items-center space-y-3"
          >
            <app-normal-text :customClass="'text-center'">
              {{ filters[selectedIndex].emptyState.label }}
            </app-normal-text>

            <app-button
              :border="''"
              :padding="'py-1 px-4'"
              @click="filters[selectedIndex].emptyState.action()"
              >{{ filters[selectedIndex].emptyState.btnText }}</app-button
            >
          </div>

          <div class="h-[100px]"></div>
        </div>
      </div>
    </subpage-layout>
  </app-wrapper>
</template>

<script lang="ts">
import {
  capitalize,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from "vue";
import { useMeta } from "vue-meta";
import { onIonViewDidEnter } from "@ionic/vue";
import { Logic } from "@shpt/logic";
import { FlightRevalidationDetails } from "@shpt/logic/src/gql/graphql";
import { RateApp } from "capacitor-rate-app";
import {
  AppBadge,
  AppNormalText,
  AppIcon,
  AppButton,
  AppVirtualScroller,
} from "@shpt/ui-components";
import { PaginatorInfo } from "@shpt/ui-components/src/gql/graphql";
import { showOrderInfo } from "@shpt/ui-components/src/composable/shop";
import AppWrapper from "@/components/AppWrapper.vue";

interface RewardData {
  subtitle: string;
  title: string;
  icon: string;
  points: string;
  status: string;
  datetime: string;
  status_color: "green" | "red" | "yellow" | "blue";
  uuid: string;
  amount: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  action: Function;
  total_spend: string;
}

export default defineComponent({
  components: {
    AppBadge,
    AppNormalText,
    AppIcon,
    AppButton,
    AppVirtualScroller,
    AppWrapper,
  },
  name: "CashbackRewardsPage",
  layout: "SubPage",
  middlewares: {
    tracking_data: {
      lable: "Cashback Rewards Page",
      stage_type: "neutral",
      end_stage: "",
    },
    fetchRules: [
      {
        domain: "Wallet",
        property: "CashbackRewards",
        subProperty: "scan",
        method: "GetCashbackRewards",
        params: [],
        requireAuth: true,
        silentUpdate: true,
        useRouteQuery: true,
        queries: ["tab"],
      },
    ],
  },
  setup() {
    useMeta({
      title: "Cashback Rewards",
    });

    const selectedFilter = ref<"scan" | "pay" | "bonus" | "shop">("scan");
    const selectedIndex = ref(0);
    const paginationData = ref<PaginatorInfo>();

    const CashbackRewards = ref(Logic.Wallet.CashbackRewards);

    const filters = reactive<
      {
        title: string;
        id: "scan" | "pay" | "bonus" | "shop";
        unread: number;
        emptyState: {
          label: string;
          btnText: string;
          action: () => void;
        };
      }[]
    >([
      {
        title: "Receipt Snap",
        id: "scan",
        unread: 0,
        emptyState: {
          label: "You are yet to scan a receipt",
          btnText: "Snap your first receipt",
          action: () => {
            Logic.Common.GoToRoute("/snap");
          },
        },
      },
      {
        title: "Shop",
        id: "shop",
        unread: 0,
        emptyState: {
          label: "You are yet to purchase an item",
          btnText: "Shop now",
          action: () => {
            Logic.Common.GoToRoute("/shoplist");
          },
        },
      },
      // {
      //   title: "Travel",
      //   id: "travel",
      //   unread: 0,
      //   emptyState: {
      //     label: "You are yet to book a flight",
      //     btnText: "Book Fligth",
      //     action: () => {
      //       Logic.Common.GoToRoute("/shop/category/flight");
      //     },
      //   },
      // },
      {
        title: "Payment",
        id: "pay",
        unread: 0,
        emptyState: {
          label: "You are yet to make a payment",
          btnText: "Send money",
          action: () => {
            Logic.Common.GoToRoute("/pay/user");
          },
        },
      },
      {
        title: "Bonus",
        id: "bonus",
        unread: 0,
        emptyState: {
          label: "You have no bonus yet",
          btnText: "Join a challenge",
          action: () => {
            Logic.Common.GoToRoute("/others/challenges");
          },
        },
      },
    ]);

    const allRewards = reactive<RewardData[]>([]);

    const shopSlugs = [
      "AIRTIME_PURCHASE",
      "DATA_PURCHASE",
      "CABLE_TV_PURCHASE",
      "ELECTRICITY_PURCHASE",
      "SHOPLIST_PURCHASED",
    ];

    const paySlugs = ["EXTERNAL_BANK_TRANSFER", "SHPT_TO_SHPT_TRANSFER"];

    const scanSlugs = ["SCANNED_RECEIPT"];

    const travelSlugs = ["NEW_FLIGHT_BOOKED"];

    const setRewards = () => {
      allRewards.length = 0;

      filters.forEach((item) => {
        item.unread = 0;
      });

      const ManyPointEarned = CashbackRewards.value[selectedFilter.value];

      ManyPointEarned?.forEach((reward) => {
        let title = reward.description.replace("Shoppoint for", "");
        let subtitle = "";
        let icon = "reward";

        // if (reward.points <= 0) {
        //   reward.claimed = true;
        // }

        if (reward.event_slug == "SCANNED_RECEIPT") {
          title = capitalize(
            `${
              reward.scanned_receipt?.merchant_name?.toLocaleLowerCase() || ""
            }`
          );
          subtitle = `#${
            reward.scanned_receipt?.invoice_no?.toLocaleLowerCase() || ""
          }`;
          icon = "scan";
        } else if (shopSlugs.includes(reward.event_slug)) {
          title = title.replace("Shoppoint for ", "");
          icon = "shop_items";

          if (reward.sale) {
            title = `Bought ${Logic.Common.convertToMoney(
              reward.sale.total_amount,
              false,
              "ngn"
            )} ${reward.sale?.product?.description.replace("Buy ", "")}`;
          }
        } else if (reward.event_slug == "SHPT_TO_SHPT_TRANSFER") {
          title = `Shpt-to-shpt money transfer`;
          icon = "make-payment";

          if (reward.transaction) {
            title = `${Logic.Common.convertToMoney(
              reward.transaction.amount,
              false,
              "ngn"
            )} Sent to ${reward.transaction?.description?.replace(
              "Money transfer | ",
              ""
            )}`;

            const extraData = JSON.parse(
              JSON.parse(reward.transaction.extra_data || "")
            );
            if (extraData["shoppoint_user_uuid"]) {
              subtitle = `@${extraData["shoppoint_username"]}`;
            }
          }
        } else if (reward.event_slug == "EXTERNAL_BANK_TRANSFER") {
          title = title.replace("Shpt. for ", "");
          icon = "make-payment";
        }

        let status = "Claimed";
        let status_color: "green" | "red" | "yellow" | "blue" = "green";

        if (!reward.claimed) {
          if (reward.processing_status == "processing") {
            status = "Processing";
            status_color = "yellow";
          } else if (reward.processing_status == "processed") {
            const expiryMinutesLeft = Logic.Common.momentInstance(
              reward.expire_at
            ).diff(Logic.Common.momentInstance(new Date()), "minute");

            if (expiryMinutesLeft > 0) {
              const daysDiff = Logic.Common.momentInstance(
                reward.expire_at
              ).diff(Logic.Common.momentInstance(new Date()), "days");
              status = `Expires in ${daysDiff} day${daysDiff > 1 ? "s" : ""}`;
              status_color = "blue";
            } else {
              status = "Expired";
              status_color = "red";
            }
          } else {
            status = "Failed";
            status_color = "red";
          }
        }

        if (reward.event_slug == "SCANNED_RECEIPT") {
          if (reward.scanned_receipt?.status != "successful") {
            status = capitalize(reward.scanned_receipt?.status || "");
            status_color = "red";
            reward.points = 0;
            reward.game_points = 0;
          }
        }

        if (reward.event_slug == "SHOPLIST_PURCHASED") {
          const orderStatus = reward.order?.status;

          if (orderStatus == "cancelled" || orderStatus == "failed") {
            status = "Cancelled";
            status_color = "red";
          } else if (orderStatus != "completed") {
            status = capitalize(orderStatus || "");
            status_color = "yellow";
          }
          title = `${reward.order?.collection?.name} Purchase`;
          subtitle = `#${reward.order?.uuid.split("-")[0].toUpperCase()}`;
        }

        const isClaimed = reward.claimed;

        const rewardData: RewardData = {
          title,
          subtitle,
          datetime: Logic.Common.fomartDate(
            reward.created_at,
            "D MMM YYYY | H:mma"
          ),
          icon,
          amount: Logic.Common.convertToMoney(reward.points, false, "ngn"),
          points: Logic.Common.convertToMoney(
            reward.game_points > 0 ? reward.game_points : reward.points,
            false,
            ""
          ),
          status,
          status_color,
          uuid: reward.uuid,
          total_spend: Logic.Common.convertToMoney(
            selectedFilter.value == "bonus"
              ? reward.points
              : reward.money_amount,
            false,
            "ngn"
          ),
          action: () => {
            if (isClaimed) {
              if (
                reward.scanned_receipt &&
                reward.event_slug == "SCANNED_RECEIPT"
              ) {
                Logic.Shop.SingleScannedReceipt = {
                  point_to_claim: reward.points,
                  scanned_receipt: reward.scanned_receipt,
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-expect-error
                  game_points:
                    reward.game_points > 0 ? reward.game_points : reward.points,
                };

                Logic.Common.GoToRoute(
                  `/snap/info?earned=true&isEditable=true`
                );
              } else if (shopSlugs.includes(reward.event_slug)) {
                if (reward.sale?.transaction) {
                  Logic.Common.GoToRoute(
                    `/savings/transactions/${reward.sale?.transaction?.uuid}?ignoreBackRoute=true`
                  );
                } else if (reward.sale?.point_transaction) {
                  Logic.Common.GoToRoute(
                    `/savings/points/${reward.sale?.point_transaction?.uuid}?ignoreBackRoute=true`
                  );
                } else if (reward.event_slug == "SHOPLIST_PURCHASED") {
                  if (reward.order) {
                    showOrderInfo(
                      reward.order?.uuid?.split("-")[0] || "",
                      reward.order,
                      false
                    );
                  }
                }
              } else if (reward.event_slug == "SHPT_TO_SHPT_TRANSFER") {
                if (reward.transaction) {
                  Logic.Common.GoToRoute(
                    `/savings/transactions/${reward.entity_uuid}?ignoreBackRoute=true`
                  );
                } else if (reward.entity_uuid) {
                  Logic.Common.GoToRoute(
                    `/savings/points/${reward.entity_uuid}?ignoreBackRoute=true`
                  );
                }
              } else if (reward.event_slug == "EXTERNAL_BANK_TRANSFER") {
                Logic.Common.GoToRoute(
                  `/savings/transactions/${reward.entity_uuid?.replace(
                    "transfer-",
                    ""
                  )}&ignoreBackRoute=true`
                );
              } else if (reward.event_slug == "NEW_FLIGHT_BOOKED") {
                Logic.Common.GoToRoute(
                  `/rewards/bookings/flight/${reward.sale?.booked_flight?.reference}`
                );
              } else {
                Logic.Common.GoToRoute(
                  `/others/new_point_earned?point_earned_uuid=${reward.uuid}`
                );
              }
            } else {
              if (reward.event_slug == "SHOPLIST_PURCHASED") {
                if (reward.order) {
                  showOrderInfo(
                    reward.order?.uuid?.split("-")[0] || "",
                    reward.order,
                    false
                  );
                }
                return;
              }
              Logic.Common.GoToRoute(
                `/others/new_point_earned?point_earned_uuid=${reward.uuid}`
              );
            }
          },
        };

        if (
          shopSlugs.includes(reward.event_slug) &&
          selectedFilter.value == "shop"
        ) {
          allRewards.push(rewardData);
        } else if (
          paySlugs.includes(reward.event_slug) &&
          selectedFilter.value == "pay"
        ) {
          allRewards.push(rewardData);
        } else if (
          scanSlugs.includes(reward.event_slug) &&
          selectedFilter.value == "scan"
        ) {
          allRewards.push(rewardData);
        } else if (
          !shopSlugs.includes(reward.event_slug) &&
          !paySlugs.includes(reward.event_slug) &&
          !scanSlugs.includes(reward.event_slug) &&
          selectedFilter.value == "bonus"
        ) {
          allRewards.push(rewardData);
        } else if (
          travelSlugs.includes(reward.event_slug) &&
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          selectedFilter.value == "travel"
        ) {
          const extraData = JSON.parse(
            reward.sale?.booked_flight?.extra_data || ""
          );

          const flightDetails: FlightRevalidationDetails = extraData.details;

          const flightSegments = flightDetails.OriginDestinationOptions[0];

          rewardData.title = `${
            flightSegments.FlightSegments[0].DepartureCity
          } - ${
            flightSegments.FlightSegments[
              flightSegments.FlightSegments.length - 1
            ].ArrivalCity
          }`;
          rewardData.subtitle = `${flightDetails.Airline.Name}`;

          rewardData.icon = "flight-booking";

          allRewards.push(rewardData);
        }

        if (status_color == "blue" && reward.points > 0) {
          if (shopSlugs.includes(reward.event_slug)) {
            filters[1].unread++;
          } else if (paySlugs.includes(reward.event_slug)) {
            filters[2].unread++;
          } else if (scanSlugs.includes(reward.event_slug)) {
            filters[0].unread++;
          } else {
            filters[3].unread++;
          }
        }
      });
    };

    const getMoreRewards = (nextPage: number) => {
      return Logic.Wallet.GetCashbackRewards(
        selectedFilter.value,
        nextPage,
        true
      )
        ?.then((response) => {
          if (response) {
            const existingData = JSON.parse(
              JSON.stringify(Logic.Wallet.CashbackRewards)
            );
            existingData[selectedFilter.value] = existingData[
              selectedFilter.value
            ].concat(response.data);
            existingData.paginatorInfo = response.paginatorInfo;

            Logic.Wallet.CashbackRewards = existingData;

            return true;
          } else {
            return false;
          }
        })
        .catch(() => {
          return false;
        });
    };

    watch(CashbackRewards, () => {
      paginationData.value = CashbackRewards.value.paginatorInfo;
      setRewards();
    });

    watch(selectedFilter, () => {
      filters.forEach((item, index) => {
        if (item.id == selectedFilter.value) {
          selectedIndex.value = index;
        }
      });

      if (CashbackRewards.value[selectedFilter.value]) {
        setRewards();
        Logic.Wallet.GetCashbackRewards(selectedFilter.value);
      } else {
        Logic.Common.showLoader({ show: true, loading: true });
        Logic.Wallet.GetCashbackRewards(selectedFilter.value)?.then(
          (response) => {
            if (response) {
              Logic.Common.hideLoader();
              setRewards();
            }
          }
        );
      }
    });

    onIonViewDidEnter(() => {
      if (
        CashbackRewards.value &&
        (CashbackRewards.value.scan?.length || 0) > 4
      ) {
        const ratingRequested = localStorage.getItem("ratings_requested");

        if (!ratingRequested) {
          RateApp.requestReview();
          localStorage.setItem("ratings_requested", "true");
        }
      }
    });

    onMounted(() => {
      Logic.Wallet.watchProperty("CashbackRewards", CashbackRewards);
      if (Logic.Common.route?.query?.tab) {
        const tab: any = Logic.Common.route?.query?.tab.toString();

        selectedFilter.value = tab;

        filters.forEach((item, index) => {
          if (item.id == selectedFilter.value) {
            selectedIndex.value = index;
          }
        });
      }
      paginationData.value = CashbackRewards.value.paginatorInfo;
      setRewards();
    });

    return {
      filters,
      selectedFilter,
      allRewards,
      Logic,
      selectedIndex,
      paginationData,
      getMoreRewards,
    };
  },
});
</script>
