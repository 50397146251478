<template>
  <div :class="`flex flex-col space-y-2 ${defaultSize} `">
    <app-normal-text v-if="hasTitle">
      <slot name="title" />
      <span v-if="required" class="text-error-main pl-[2px]">*</span>
    </app-normal-text>
    <div
      :class="`flex relative flex-row items-center space-x-1 justify-between cursor-pointer w-full rounded-lg ${customClass} bg-grey-50 !border-grey-50 border-[1px] focus:border-primary-400 ${paddings}`"
      :id="'container' + tabIndex"
      @focus="
        showOption = true;
        isFocused = true;
        ShowSelectModal = true;
      "
      @blur="
        isFocused = false;
        showOption = false;
      "
      :tabindex="tabIndex"
      @click="ShowSelectModal = true"
    >
      <div class="w-[26px]" v-if="getSelectedItemImage(selectedKey)">
        <app-image-loader
          :photo-url="getSelectedItemImage(selectedKey)"
          :customClass="`h-[26px] w-[26px] rounded-full `"
        />
      </div>
      <app-icon
        v-if="firstIcon"
        :name="`${firstIcon}`"
        custom-class=" h-4 w-4 cursor-pointer"
      />
      <slot name="inner-prefix" />

      <template v-if="!isMultiple">
        <input
          v-if="!useHtml"
          ref="select"
          :value="withKey ? valueData : textValue"
          :placeholder="placeholder"
          disabled
          :class="`text-black flex-grow bg-transparent focus input w-full focus:outline-none placeholder-grey-400 cursor-pointer  ${inputStyle}`"
        />
        <app-normal-text
          v-else
          v-html="withKey ? valueData : textValue"
          :class="`whitespace-nowrap xs:!text-[11px] !text-[12px] text-black  ${inputStyle}`"
        >
        </app-normal-text>
      </template>
      <template v-else>
        <div
          :class="`w-full flex flex-row  flex-wrap items-center ${inputStyle} cursor-pointer`"
        >
          <app-normal-text
            class="!text-grey-400 text-left"
            v-if="selectedItems.length == 0"
          >
            {{ placeholder }}
          </app-normal-text>

          <span v-for="(item, index) in selectedItems" :key="index" class="pr-1 pb-1">
            <span
              class="px-2 py-[2px] border-[1px] border-grey-200 bg-white dark:bg-black text-center flex flex-row space-x-[2px] items-center rounded-[6px]"
            >
              <app-normal-text class="!text-[10px]">
                {{ getSelectedOption(item) }}
              </app-normal-text>
            </span>
          </span>
        </div>
      </template>

      <app-icon
        @click="
          showOption = true;
          isFocused = true;
        "
        :name="`show-more${theme == 'dark' ? '-white' : ''}`"
        :custom-class="`${showMoreSize} cursor-pointer`"
      />
      <slot name="inner-suffix" />
    </div>
  </div>

  <Teleport to="body">
    <div
      v-if="ShowSelectModal"
      class="fixed top-0 left-0 z-[99999999999999999] bg-black !bg-opacity-30 dark:!bg-opacity-50 flex w-full h-full flex-row items-end justify-end mdlg:!items-center mdlg:!justify-center md:!justify-center md:!items-center"
      @click="ShowSelectModal = false"
    >
      <div
        class="w-full mdlg:!w-[60%] md:!w-[80%] grid grid-cols-12 h-full relative"
        id="modalContent"
      >
        <!-- Left side -->
        <div
          class="hidden col-span-3 md:!col-span-2 mdlg:!col-span-3 mdlg:!flex md:!flex flex-col sticky top-0"
        ></div>

        <!-- Main section -->
        <div
          class="col-span-12 mdlg:!col-span-6 md:!col-span-8 relative h-full flex flex-col items-end justify-end mdlg:!items-center mdlg:!justify-center md:!justify-center md:!items-center"
        >
          <div
            @click.stop="true"
            class="rounded-t-2xl mdlg:!rounded-[10px] md:!rounded-[10px] flex flex-col space-y-2 bg-white dark:!bg-black dark:border-[1px] dark:border-gray-100 w-full absolute mdlg:!relative md:!relative overflow-y-auto h-[50%] mdlg:!max-h-[500px] md:!max-h-[400px] xs:!bottom-0 sm:!bottom-0 left-0 pb-3 px-3 mdlg:!pb-4 md:!pb-4 lg:!text-sm mdlg:!text-[12px] text-xs"
          >
            <div
              class="flex items-center justify-center sticky top-0 bg-white dark:!bg-black w-full pt-3"
            >
              <span
                class="bg-gray-500 dark:bg-gray-200 rounded-full w-[30px] h-[4px]"
              ></span>
            </div>
            <div
              class="w-full pt-1 sticky top-[18px] bg-white dark:bg-black dark:black"
              v-if="autoComplete"
            >
              <app-text-field
                placeholder="Search"
                v-model="searchValue"
                :update-value="searchValue"
              >
              </app-text-field>
            </div>
            <template v-if="isMultiple">
              <div class="w-full flex flex-col space-y-1">
                <div
                  v-for="(option, index) in selectOptions"
                  :key="index"
                  class="flex w-full flex-row space-x-2 items-center cursor-pointer py-3 border-b-[1px] border-gray-300 dark:border-grey-100"
                  @click.stop="selectValue(option)"
                >
                  <app-icon
                    :name="`${itemIsSelected(option.key) ? 'checked' : 'not-checked'}`"
                    :customClass="`h-[15px]`"
                  />
                  <app-normal-text :customClass="'!font-semibold'">
                    {{ option.alt_value ? option.alt_value : option.value }}
                  </app-normal-text>
                </div>
              </div>
            </template>
            <app-radio
              v-else
              :options="selectOptions"
              v-model="selectedKey"
              @click.stop="true"
            />
            <div
              class="h-full w-full flex flex-row items-center justify-center"
              v-if="selectOptions.length == 0 && hasSearch"
            >
              <app-normal-text :customClass="'text-center'" :color="'text-gray-400'">
                {{ searchIsLoading ? "Searching..." : searchMessage }}
              </app-normal-text>
            </div>
          </div>
        </div>

        <!-- Right side -->
        <div
          class="hidden col-span-3 md:!col-span-2 mdlg:!col-span-3 mdlg:!flex md:!flex flex-col sticky top-0"
        ></div>
      </div>
    </div>
  </Teleport>
</template>

<script lang="ts">
import { Logic } from "../../composable";
import { SelectOption } from "../../types";
import { capitalize, defineComponent, onMounted, ref, toRef, watch, PropType } from "vue";
import AppIcon from "../AppIcon/index.vue";
import AppNormalText from "../AppTypography/normalText.vue";
import AppTextField from "./textField.vue";
import AppRadio from "./radio.vue";
import AppImageLoader from "../AppImageLoader";

export default defineComponent({
  name: "AppSelect",
  components: {
    AppIcon,
    AppNormalText,
    AppTextField,
    AppRadio,
    AppImageLoader,
  },
  props: {
    placeholderColor: {
      type: String,
      default: "placeholder-grey-400",
    },
    firstIcon: {
      type: String,
    },
    withKey: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    options: {
      type: Array as () => SelectOption[],
    },
    paddings: {
      type: String,
      default: "py-4 px-3",
    },
    padding: {
      type: String,
      default: "py-4 px-3",
    },
    customClass: {
      type: String,
      default: "",
    },
    defaultValues: {
      required: false,
      default: [],
    },
    modelValue: {
      type: [String, Array] as PropType<string | any[]>,
      default: () => [],
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
    updateKey: {
      type: String,
      default: "",
    },
    hasTitle: {
      type: Boolean,
      default: false,
    },
    defaultSize: {
      type: String,
      default: "w-full",
    },
    autoComplete: {
      type: Boolean,
      default: false,
    },
    useHtml: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    showMoreSize: {
      type: String,
      default: "h-[8px] w-[14px]",
    },
    inputStyle: {
      type: String,
      default: "",
    },
    theme: {
      type: String,
      default: "light",
    },
    hasSearch: {
      type: Boolean,
      default: false,
    },
    searchMessage: {
      type: String,
      default: "",
    },
    searchIsLoading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue", "OnOptionSelected", "OnSearch"],
  setup(props: any, context: any) {
    const isFocused = ref(false);
    const showOption = ref(false);

    const tabIndex = Math.random();

    const ShowSelectModal = ref(false);

    const updateKeyRef = toRef(props, "updateKey");

    const OptionRef = toRef(props, "options");

    const searchResult = ref<SelectOption[]>([]);

    const selectedKey = ref();

    const valueData = ref("");

    const textValue = ref("");

    const searchValue = ref("");

    const selectOptions = ref<any[]>([]);

    const validationStatus = ref(false);

    const prepareSelectOptions = () => {
      selectOptions.value.length = 0;

      if (Array.isArray(searchResult.value)) {
        searchResult.value.forEach((item: any) => {
          selectOptions.value.push({
            key: item.key,
            value: `${item.value}${props.withKey ? ` (${item.key})` : ""}`,
            hasIcon: item.hasIcon ? item.hasIcon : false,
            extras: item.extras ? item.extras : "",
            isImage: item.isImage ? item.isImage : false,
            imageUrl: item.imageUrl ? item.imageUrl : "",
            disabled: item.disabled ? item.disabled : false,
            alt_value: item.alt_value ? item.alt_value : "",
          });
        });
      }
    };

    watch(selectedKey, () => {
      if (selectedKey.value != 0) {
        const selectedOption = searchResult.value.filter((eachItem: any) => {
          return eachItem.key == selectedKey.value;
        });
        selectValue(selectedOption[0]);
        ShowSelectModal.value = false;
      }
    });

    const selectedItems = ref<any>([]);

    watch(props, () => {
      prepareSelectOptions();
    });

    watch(updateKeyRef, () => {
      const selectedOption = searchResult.value.filter((eachItem: any) => {
        return eachItem.key.toString() == updateKeyRef.value.toString();
      });
      selectedKey.value = updateKeyRef.value;
      if (selectedOption.length > 0) {
        selectValue(selectedOption[0]);
      }
    });

    onMounted(() => {
      searchValue.value = "";
      if (props.defaultValues.length > 0) {
        selectedItems.value = props.defaultValues;
      }
      if (props.options) {
        OptionRef.value = props.options;
        searchResult.value = props.options;
      }

      if (props.modelValue) {
        if (props.isMultiple) {
          selectedItems.value = props.modelValue;
        } else {
          const selectedOption = searchResult.value.filter((eachItem: any) => {
            return eachItem.key == props.modelValue;
          });
          selectedKey.value = props.modelValue;
          if (selectedOption.length > 0) {
            selectValue(selectedOption[0]);
          }
        }
      }
      prepareSelectOptions();
      setThemeColors();
    });

    const itemIsSelected = (inputKey: string) => {
      const item = selectedItems.value.filter((key: any) => {
        return key == inputKey;
      });

      return item.length > 0;
    };

    const selectValue = (option: any) => {
      if (!option) {
        return;
      }
      if (props.autoComplete) {
        if (option) {
          context.emit("OnOptionSelected", option.key);
        }

        isFocused.value = false;
        showOption.value = false;

        if (props.withKey) {
          valueData.value = option.key;
        } else {
          valueData.value = option.value;
          textValue.value = option.value;
        }
      }
      if (props.isMultiple) {
        if (option && itemIsSelected(option?.key)) {
          selectedItems.value = selectedItems.value.filter((key: any) => {
            return key != option.key;
          });
          return;
        }
        selectedItems.value.push(option.key);
        context.emit("update:modelValue", selectedItems.value);
        context.emit("OnOptionSelected", option.key);
      } else {
        if (option) {
          context.emit("update:modelValue", option.key);
          context.emit("OnOptionSelected", option);
          if (props.withKey) {
            valueData.value = option.key;
          } else {
            valueData.value = option.value;
            textValue.value = option.value;
          }
          isFocused.value = false;
          showOption.value = false;

          document.getElementById("container" + tabIndex)?.blur();
        }
      }
    };

    const getSelectedOption = (keyValue: any) => {
      const option = props.options.filter((eachItem: any) => {
        return eachItem.key == keyValue;
      });

      return option[0]?.value;
    };

    const getSelectedItemImage = (keyValue: any) => {
      const option = searchResult.value.filter((eachItem: any) => {
        return eachItem.key == keyValue;
      });

      // @ts-ignore
      return option[0]?.imageUrl;
    };

    const searchOption = () => {
      if (props.autoComplete && !props.handleSearch) {
        searchResult.value = Logic.Common.searchArray(
          OptionRef.value,
          capitalize(searchValue.value)
        );
      }
    };

    const checkValidation = () => {
      if (selectedKey.value) {
        validationStatus.value = true;
      }
    };

    watch(searchValue, () => {
      if (props.hasSearch) {
        context.emit("OnSearch", searchValue.value);
      } else {
        if (props.handleSearch) {
          props.handleSearch(searchValue.value, ...props.extraSearchParams);
        } else {
          searchOption();
        }
      }
    });

    watch(OptionRef, () => {
      searchOption();
    });

    watch(searchResult, () => {
      prepareSelectOptions();
    });

    const setThemeColors = () => {
      const root = document.documentElement;
      if (props.theme === "dark") {
        root.style.setProperty("--placeholder-color", "#9d9d9d");
        root.style.setProperty("--text-color", "#ffffff");
      } else {
        root.style.setProperty("--placeholder-color", "#9d9d9d");
        root.style.setProperty("--text-color", "#240046");
      }
    };

    watch(() => props.theme, setThemeColors);

    return {
      showOption,
      valueData,
      validationStatus,
      isFocused,
      selectValue,
      tabIndex,
      textValue,
      itemIsSelected,
      selectedItems,
      getSelectedOption,
      ShowSelectModal,
      selectOptions,
      selectedKey,
      searchValue,
      searchResult,
      checkValidation,
      getSelectedItemImage,
      setThemeColors,
    };
  },
});
</script>
<style scoped>
input:disabled::placeholder,
textarea:disabled::placeholder {
  -webkit-text-fill-color: var(--placeholder-color);
  -webkit-opacity: 1;
  opacity: 1;
  color: var(--placeholder-color);
}

input:disabled,
textarea:disabled {
  -webkit-text-fill-color: var(--text-color);
  -webkit-opacity: 1;
  opacity: 1;
  color: var(--text-color);
}
</style>
