<template>
  <app-image-loader
    :customClass="'!w-full flex flex-row relative'"
    :photoUrl="
      isGrid && data.is_grid
        ? ''
        : `${videoIsReady || enforceGrid ? '' : data.base_image}`
    "
    @click="handleClick"
  >
    <template v-if="!isFullScreen && !allowPlay">
      <!-- Grid images -->
      <div
        class="w-full h-full grid grid-cols-2 absolute top-0 left-0 z-0"
        v-if="(isGrid && data.is_grid) || enforceGrid"
      >
        <app-image-loader
          :photoUrl="image.url"
          :class="`${image.width} h-full rounded-none`"
          v-for="(image, index) in gridImages"
          :key="index"
          :style="`${image.border} `"
        />
      </div>

      <!-- Avatar -->
      <span
        v-if="!data.is_story_like && data.user_info"
        :class="`absolute  ${
          isLargeView && !data.is_story_like ? 'left-[3%]  top-[5%]' : ''
        }
${!isLargeView && !data.is_story_like ? 'left-[5%]  !top-[5%]' : ''}   
   
`"
      >
        <app-image-loader
          :photoUrl="data.user_info.image"
          :class="`!rounded-full ${data.user_info.image_size}`"
        />
      </span>

      <!-- Play button -->
      <span
        :class="`absolute top-[43%] z-10 ${
          isLargeView ? 'left-[45%]' : 'left-[47%]'
        } `"
        v-if="isVideo"
      >
        <app-icon
          :name="'play'"
          :customClass="`${
            isLargeView ? 'h-[30px]' : 'h-[23px]'
          } ${playButtonStyle}`"
        />
      </span>

      <div
        v-if="!data.is_clear"
        class="w-full absolute bottom-0 left-0 px-3 pb-3 flex flex-col space-y-2 z-10"
      >
        <!-- All tags -->
        <!-- <div
          class="w-full flex flex-row space-x-2 items-center flex-nowrap"
          v-if="data.tags.length"
        >
          <span
            class="px-2 py-[2px] bg-transparent rounded-[10.73px] border-[1px] border-white"
            v-for="(tag, index) in data.tags"
            :key="index"
          >
            <app-normal-text class="!text-white !text-[11px]">
              {{ tag }}
            </app-normal-text>
          </span>
        </div> -->

        <!-- Large title -->
        <div
          class="w-full flex flex-row items-center justify-between"
          v-if="data.is_large_view"
        >
          <app-header-text class="!text-white line-clamp-1 !font-semibold">
            {{ data.title }}
          </app-header-text>
        </div>
      </div>

      <span
        v-if="data.is_story_like && !data.is_clear && data.user_info"
        :class="`absolute  z-10 
    ${data.is_story_like ? 'right-[5%]  top-[5%]' : ''}    
`"
      >
        <app-image-loader
          :photoUrl="data.user_info.image"
          :class="`!rounded-full ${data.user_info.image_size}`"
        />
      </span>
    </template>
    <template v-else>
      <!-- Play button -->
      <span
        :class="`absolute top-[43%] z-10 cursor-pointer ${
          isLargeView ? 'left-[45%]' : 'left-[47%]'
        } `"
        v-if="isVideo && videoSettings.paused"
        @click.stop="togglePlayPause()"
      >
        <app-icon
          :name="'play'"
          :customClass="`${
            isLargeView ? 'h-[30px]' : 'h-[23px]'
          } ${playButtonStyle}`"
        />
      </span>
      <!--  Video Player Fullscreen -->
      <template v-if="videoIsReady">
        <app-video-player
          :videoUrl="data.video_url"
          v-model:is-paused="videoSettings.paused"
          v-model:is-muted="videoSettings.muted"
          v-model:is-buffering="videoSettings.loading"
          @currentTime="
            (data) => {
              videoCurrentTime = data;
              context.emit('currentTime', data);
            }
          "
          :videoTitle="data.title"
          :videoDescription="`Playing ${data.title}`"
          :coverImage="data.base_image"
          ref="videoPlayerRef"
          :isFullScreen="isFullScreen"
          class="mdlg:!rounded-[10px] md:!rounded-[10px]"
          :loopVideo="loopVideo"
        />
      </template>
    </template>

    <slot />
  </app-image-loader>
</template>
<script lang="ts">
import { MediaBox } from "../../types";
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRef,
  watch,
} from "vue";
import AppIcon from "../AppIcon";
import AppImageLoader from "../AppImageLoader";
import { AppNormalText, AppHeaderText } from "../AppTypography";
import AppVideoPlayer from "../AppVideoPlayer";

export default defineComponent({
  components: {
    AppImageLoader,
    AppIcon,
    AppNormalText,
    AppHeaderText,
    AppVideoPlayer,
  },
  props: {
    isBasketType: {
      type: Boolean,
      default: false,
    },
    isVideo: {
      type: Boolean,
      default: true,
    },
    playButtonStyle: {
      type: String,
      default: "",
    },
    data: {
      type: Object as () => MediaBox,
    },
    borderRadius: {
      type: Number,
      default: 10,
    },
    isLargeView: {
      type: Boolean,
      default: false,
    },
    isGrid: {
      type: Boolean,
      default: true,
    },
    isPlayable: {
      type: Boolean,
      default: false,
    },
    isFullScreen: {
      type: Boolean,
      default: false,
    },
    allowPlay: {
      type: Boolean,
      default: false,
    },
    loopVideo: {
      type: Boolean,
      default: true,
    },
    enforceGrid: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["videoSettingsChanged", "currentTime", "totalDuration"],
  name: "AppMediaBox",
  setup(props, context) {
    const gridImages = ref<
      {
        url: string;
        width: string;
        border: string;
      }[]
    >([]);

    const videoPlayerRef = ref();

    const videoCurrentTime = ref(0);
    const videoTotalDuration = ref(0);

    const dataRef = toRef(props, "data");

    const videoSettings = reactive({
      paused: true,
      muted: false,
      current_time: 0,
      loading: false,
      total_duration: 0,
    });

    const videoIsReady = computed(() => {
      return (
        (props.isFullScreen || props.allowPlay) &&
        props.isVideo &&
        (props.data?.video_url?.length || 0) > 0
      );
    });

    const setGridImages = () => {
      const propGridImages = props.data?.grid_images;

      let finalImages: string[] = [];
      if (propGridImages) {
        if (propGridImages.length <= 4) {
          finalImages = propGridImages;
        } else {
          finalImages = propGridImages.slice(0, 4);
        }
      }

      gridImages.value.length = 0;

      if (finalImages.length == 4) {
        gridImages.value.push({
          url: finalImages[0],
          width: `col-span-1`,
          border: `border-top-left-radius: ${props.borderRadius}px;`,
        });
        gridImages.value.push({
          url: finalImages[1],
          width: `col-span-1`,
          border: `border-top-right-radius: ${props.borderRadius}px;`,
        });
        gridImages.value.push({
          url: finalImages[2],
          width: `col-span-1 `,
          border: `border-bottom-left-radius: ${props.borderRadius}px;`,
        });
        gridImages.value.push({
          url: finalImages[3],
          width: `col-span-1`,
          border: `border-bottom-right-radius: ${props.borderRadius}px;`,
        });
      } else if (finalImages.length == 3) {
        gridImages.value.push({
          url: finalImages[0],
          width: `col-span-2`,
          border: `border-top-right-radius: ${props.borderRadius}px; border-top-left-radius: ${props.borderRadius}px;`,
        });
        gridImages.value.push({
          url: finalImages[1],
          width: `col-span-1`,
          border: `border-bottom-left-radius: ${props.borderRadius}px;`,
        });
        gridImages.value.push({
          url: finalImages[2],
          width: `col-span-1`,
          border: `border-bottom-right-radius: ${props.borderRadius}px;`,
        });
      } else if (finalImages.length == 2) {
        gridImages.value.push({
          url: finalImages[0],
          width: `col-span-1`,
          border: `border-top-left-radius: ${props.borderRadius}px; border-bottom-left-radius: ${props.borderRadius}px;`,
        });
        gridImages.value.push({
          url: finalImages[1],
          width: `col-span-1`,
          border: `border-top-right-radius: ${props.borderRadius}px; border-bottom-right-radius: ${props.borderRadius}px;`,
        });
      } else {
        gridImages.value.push({
          url: finalImages[0],
          width: `col-span-2`,
          border: `border-bottom-right-radius: ${props.borderRadius}px; border-bottom-left-radius: ${props.borderRadius}px; border-top-right-radius: ${props.borderRadius}px; border-top-left-radius: ${props.borderRadius}px;`,
        });
      }
    };

    const togglePlayPause = () => {
      if (videoIsReady && videoPlayerRef.value) {
        videoPlayerRef.value.togglePlayPause();
      }
    };

    const pauseVideo = () => {
      if (videoIsReady && videoPlayerRef.value) {
        videoPlayerRef.value.pause();
      }
    };

    const resumeVideo = () => {
      if (videoIsReady && videoPlayerRef.value) {
        videoPlayerRef.value.play();
      }
    };

    const handleClick = () => {
      if (videoIsReady.value && videoPlayerRef.value) {
        videoPlayerRef.value.togglePlayPause();
      }
    };

    const toggleMuteUnmute = () => {
      videoPlayerRef.value?.toggleMuteUnmute();
    };

    const runOnIonViewDidEnter = async () => {
      if (videoIsReady) {
        if (videoPlayerRef.value) {
          videoPlayerRef.value?.reMountPlayer();
          videoTotalDuration.value =
            await videoPlayerRef.value?.setVideoDuration();
        }
      }
    };

    const runOnIonViewDidLeave = () => {
      if (videoIsReady) {
        if (videoPlayerRef.value) {
          videoPlayerRef.value?.destroy();
        }
      }
    };

    watch(videoSettings, () => {
      context.emit("videoSettingsChanged", videoSettings);
    });

    watch(dataRef, () => {
      if (dataRef.value) {
        setGridImages();
      }
    });

    onMounted(() => {
      setGridImages();
    });

    return {
      gridImages,
      videoPlayerRef,
      videoSettings,
      videoIsReady,
      videoCurrentTime,
      videoTotalDuration,
      context,
      handleClick,
      toggleMuteUnmute,
      togglePlayPause,
      runOnIonViewDidEnter,
      runOnIonViewDidLeave,
      pauseVideo,
      resumeVideo,
    };
  },
});
</script>
