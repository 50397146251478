<template>
  <div class="w-full flex flex-row h-full items-center justify-center">
    <div
      class="w-full h-full mdlg:!h-screen md:!h-screen lg:text-sm mdlg:text-[12px] grid grid-cols-12 text-xs bg-white dark:bg-black !font-inter relative overflow-y-auto md:!overflow-y-hidden mdlg:!overflow-y-hidden"
      style="padding-bottom: calc(env(safe-area-inset-bottom) + 16px) !important"
    >
      <!-- Left side -->
      <div class="hidden col-span-5 flex-col sticky top-0 h-screen md:!col-span-5"></div>

      <!-- Main section -->
      <div
        class="col-span-12 mdlg:!col-span-12 md:!col-span-12 flex flex-col px-4 pb-4 relative items-start justify-start mdlg:!items-center mdlg:!justify-center md:!items-center md:!justify-center"
      >
        <!-- Logo -->
        <div
          class="w-full hidden mdlg:!flex flex-row justify-center items-center py-4 px-4 z-40 md:!flex pb-8"
        >
          <app-icon
            :name="`logo-full${
              Logic.Common.currentAppTheme() == 'light' ? '' : '-white'
            }`"
            class="h-[40px]"
          />
        </div>
        <div
          class="w-full flex flex-col mdlg:!w-[30%] md:!w-[60%] md:!bg-white md:dark:!bg-black mdlg:!bg-white mdlg:dark:!bg-black md:!rounded-xl md:!shadow-custom mdlg:!shadow-custom dark:md:border-[1px] dark:md:border-gray-600 dark:mdlg:border-[1px] dark:mdlg:border-gray-600 md:!pb-4 md:!pl-4 md:!pr-4 mdlg:!pl-4 mdlg:!pr-4 mdlg:!pb-4"
        >
          <div
            class="flex justify-between border-b-[2px] border-grey-50 dark:border-grey-700 pb-4 sticky top-0 bg-white dark:bg-black"
            style="padding-top: calc(env(safe-area-inset-top) + 16px) !important"
          >
            <div class="flex items-center flex-row space-x-3">
              <AppIcon
                @click="$emit('go-back')"
                v-if="goBack"
                :custom-class="'w-[14px] cursor-pointer'"
                :name="
                  Logic.Common.currentAppTheme() == 'light'
                    ? 'arrow-left'
                    : 'arrow-left-white'
                "
              >
              </AppIcon>
              <AppHeaderText>
                <slot name="header-text"></slot>
              </AppHeaderText>
            </div>

            <AppButton
              @click="$emit('button-clicked')"
              v-if="rightButton"
              :custom-class="'rounded-[40px] font-500 text-[14px] '"
              :bg-color="'bg-primary-50 dark:bg-primary-400'"
              :text-color="'text-primary-500 dark:text-primary-50'"
              :padding="'px-3 py-[2px]'"
            >
              <slot name="button-text"></slot>
            </AppButton>
          </div>

          <slot />
        </div>

        <div
          class="w-full hidden mdlg:!flex flex-row justify-center items-center py-4 px-4 z-40 md:!flex pt-5"
        >
          <app-normal-text class="text-center !text-gray-500 dark:!text-gray-300">
            © 2024 Shoppoint Rewards. All rights reserved.
          </app-normal-text>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import AppButton from "../AppButton";
import AppIcon from "../AppIcon";
import { AppHeaderText, AppNormalText } from "../AppTypography";
import { Logic } from "../../index";

export default defineComponent({
  components: {
    AppIcon,
    AppHeaderText,
    AppButton,
    AppNormalText,
  },
  props: {
    rightButton: {
      type: Boolean,
      default: false,
    },
    goBack: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    tabIsActive: {
      type: Function,
      default: () => {},
    },
  },
  setup() {
    return {
      Logic,
    };
  },
});
</script>
