<template>
  <auth-layout
    @button-clicked="Logic.Common.GoToRoute('/auth/login')"
    @go-back="Logic.Common.goBack()"
    :go-back="true"
    :right-button="true"
  >
    <template v-slot:button-text>Login</template>
    <template v-slot:header-text>Sign Up</template>

    <app-form-wrapper
      ref="formComp"
      :parentRefs="parentRefs"
      class="w-full flex flex-col space-y-4 pt-4"
    >
      <div class="w-full flex flex-row">
        <app-normal-text
          class="!text-[#664D7E] dark:!text-primary-100"
          v-if="Logic.Common.currentAccountType() == 'client'"
        >
          Join Shoppoint and get
          <span class="text-primary-400 font-semibold dark:!text-primary-50"
            >1,000 points</span
          >
          sign up bonus
        </app-normal-text>
        <app-normal-text
          class="!text-[#664D7E] dark:!text-primary-100"
          v-if="Logic.Common.currentAccountType() == 'merchant'"
        >
          Signup and get
          <span class="text-primary-400 font-semibold dark:!text-primary-50"
            >Free N100,000 Advert Credit</span
          >
          to promote your products
        </app-normal-text>
      </div>

      <!-- Choose Country -->
      <app-select
        :placeholder="'Choose Country'"
        :hasTitle="true"
        :paddings="'py-3 px-3'"
        :customClass="'dark:!bg-grey-800'"
        :options="countries"
        :update-key="formData.country"
        v-model="formData.country"
        :theme="Logic.Common.currentAppTheme()"
      >
        <template v-slot:title> Country of residence </template>
      </app-select>

      <template v-if="formData.country != 'NG'">
        <div
          class="w-full flex flex-col py-3 px-3 rounded-[10px] bg-purple-50 dark:bg-purple-300"
        >
          <app-normal-text class="!flex !flex-row !text-center !space-x-2">
            <span class="text-left !text-gray-700">
              <span class="!font-extrabold !text-black">Shoppoint Rewards</span>
              is coming soon to your country!
            </span>
          </app-normal-text>
        </div>
      </template>
      <template v-else>
        <AppTextField
          :has-title="true"
          :custom-class="''"
          :placeholder="'Input Value'"
          :focus-border="'border-primary-400'"
          name="Full name"
          ref="full_name"
          :rules="[
            FormValidations.RequiredRule,
            FormValidations.customValidator(
              formData.full_name.length > 5,
              'Require atleast 5 characters'
            ),
          ]"
          v-model="formData.full_name"
        >
          <template v-slot:title> Full Name </template>
          <template v-slot:inner-prefix>
            <span class="pr-1">
              <app-icon :name="'user'" :customClass="'h-[16px]'" />
            </span>
          </template>
        </AppTextField>

        <AppTextField
          :has-title="true"
          :custom-class="''"
          :placeholder="'Input Value'"
          :type="'email'"
          ref="email"
          name="Email"
          :focus-border="'border-primary-400'"
          :rules="[FormValidations.EmailRule]"
          v-model="formData.email"
        >
          <template v-slot:title> Email </template>
          <template v-slot:inner-prefix>
            <span class="pr-1">
              <app-icon :name="'envelope'" :customClass="'h-[14px]'" />
            </span>
          </template>
        </AppTextField>

        <div class="flex flex-col space-y-3 pt-2">
          <AppButton
            @click.prevent="signUp()"
            :padding="'py-3'"
            :bg-color="'bg-primary-main'"
            :text-color="'text-white'"
            :loading="loaderSetup.loading"
          >
            Continue
          </AppButton>
        </div>

        <div>
          <app-normal-text
            :customClass="'text-left md:!text-center mdlg:!text-center'"
          >
            By signing up, you agree to our
            <a
              href="https://www.getshoppoint.com/terms"
              target="_blank"
              class="text-primary-400 dark:!text-primary-200"
              >Terms of Service</a
            >
            and
            <a
              href="https://www.getshoppoint.com/privacy-policy"
              target="_blank"
              class="text-primary-400 dark:!text-primary-200"
              >Privacy Policy</a
            >.
          </app-normal-text>
        </div>
      </template>
    </app-form-wrapper>

    <SSO
      :fromAction="'signUp'"
      class="pt-5"
      v-if="formData.country == 'NG'"
      :isRelative="true"
    />
  </auth-layout>
</template>

<script lang="ts">
import { defineComponent, reactive, ref } from "vue";
import { Logic } from "../../../index";
import {
  AppTextField,
  AppIcon,
  AppButton,
  AppNormalText,
  AppFormWrapper,
  AppSelect,
} from "../../../components";
import { FormValidations } from "../../../composable";
import { loaderSetup } from "../../../composable/common";
import { SelectOption } from "@shpt/logic/src/logic/types/common";
import AuthLayout from "../../../components/AppLayouts/auth.vue";
import SSO from "../../../components/AppCommon/SSO.vue";

export default defineComponent({
  components: {
    AppTextField,
    AppNormalText,
    AppFormWrapper,
    AppIcon,
    AppButton,
    AppSelect,
    SSO,
    AuthLayout,
  },
  name: "SignUp",
  setup() {
    const formComp = ref();

    const formData = reactive({
      country: "NG",
      full_name: "",
      email: "",
    });

    const countries = reactive<SelectOption[]>([
      {
        key: "NG",
        value: "Nigeria",
        imageUrl: "https://d38v990enafbk6.cloudfront.net/Nigeria.png",
      },
      {
        key: "GH",
        value: "Ghana (Coming soon)",
        imageUrl: "/images/flags/ghana.png",
        disabled: true,
      },
      {
        key: "KE",
        value: "Kenya (Coming soon)",
        imageUrl: "/images/flags/kenya.png",
        disabled: true,
      },
      {
        key: "ZA",
        value: "South Africa (Coming soon)",
        imageUrl: "/images/flags/south-africa.png",
        disabled: true,
      },
      {
        key: "UG",
        value: "Uganda (Coming soon)",
        imageUrl: "/images/flags/uganda.png",
        disabled: true,
      },
      {
        key: "GB",
        value: "United Kingdom (Coming soon)",
        imageUrl: "https://d38v990enafbk6.cloudfront.net/united_kingdom.png",
        disabled: true,
      },
      {
        key: "CA",
        value: "Canada (Coming soon)",
        imageUrl: "https://d38v990enafbk6.cloudfront.net/Canada.png",
        disabled: true,
      },
      {
        key: "US",
        value: "United State of America (Coming soon)",
        imageUrl: "https://d38v990enafbk6.cloudfront.net/USA.png",
        disabled: true,
      },
    ]);

    const signUp = () => {
      const state = formComp.value.validate();

      if (state) {
        const names = formData.full_name.split(" ");

        Logic.Auth.SignUpForm = {
          email: formData.email,
          first_name: names[0],
          last_name: names.length > 1 ? names.slice(1).join(" ") : undefined,
          is_sso: false,
        };

        Logic.Auth.SignUp(true)?.then((data) => {
          if (data?.SignUp && !data?.SignUp.email_verified_at) {
            Logic.Common.showModal({
              show: true,
              type: "verify_email",
              title: "Verify Your Email",
              preventClose: true,
              closeAction: () => {
                //
              },
            });
          } else if (data?.SignUp) {
            if (!data?.SignUp.has_password) {
              Logic.Common.GoToRoute("/auth/set-password");
            } else {
              if (!Logic.Auth.AuthUser?.phone_verified_at) {
                Logic.Common.GoToRoute("/auth/verify-phone");
                return;
              } else {
                if (Logic.Common.currentAccountType() == "client") {
                  Logic.Common.GoToRoute("/auth/personalize");
                } else {
                  Logic.Common.GoToRoute("/onboarding");
                }
              }
            }
          }
        });
      }
    };

    return {
      Logic,
      formData,
      formComp,
      FormValidations,
      loaderSetup,
      countries,
      signUp,
    };
  },
  data() {
    return {
      parentRefs: null,
    };
  },
  mounted() {
    const parentRefs: any = this.$refs;
    this.parentRefs = parentRefs;
  },
});
</script>
