<template>
  <label
    v-if="!standard"
    class="flex items-center cursor-pointer space-x-2 w-full justify-between"
    @click="selected = selected ? false : true"
  >
    <slot />

    <AppIcon
      :name="`${selected ? 'checked' : 'not-checked'}`"
      :customClass="`h-[15px]`"
    />
  </label>

  <div v-else class="flex w-fit flex-col space-y-2">
    <div
      @click="selected = selected ? false : true"
      class="flex w-full flex-row space-x-2 cursor-pointer items-start"
    >
      <span class="pt-[2px] w-[25px]">
        <AppIcon
          :name="`${selected ? 'checked' : 'not-checked'}`"
          :customClass="`h-[15px]`"
        />
      </span>
      <slot />
    </div>
  </div>
  <!-- <input
    :class="' checked:border-primary-500 '"
    :checked="selected"
    type="checkbox"
    @click="selected = selected ? false : true"
  /> -->
</template>
<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import AppIcon from "../AppIcon/index.vue";

export default defineComponent({
  components: {
    AppIcon,
  },
  props: {
    standard: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: Boolean,
    },
  },
  name: "AppCheckbox",
  emits: ["update:modelValue"],
  setup(props, context: any) {
    const selected = ref(false);

    watch(selected, () => {
      context.emit("update:modelValue", selected.value);
    });

    onMounted(() => {
      selected.value = props.modelValue;
    });

    return {
      selected,
    };
  },
});
</script>
