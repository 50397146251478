import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "countdown"
}
const _hoisted_2 = ["data-init-value"]
const _hoisted_3 = { class: "figure hours hours-1" }
const _hoisted_4 = ["id"]
const _hoisted_5 = ["id"]
const _hoisted_6 = ["id"]
const _hoisted_7 = ["id"]
const _hoisted_8 = { class: "figure hours hours-2 mb-2" }
const _hoisted_9 = ["id"]
const _hoisted_10 = ["id"]
const _hoisted_11 = ["id"]
const _hoisted_12 = ["id"]
const _hoisted_13 = ["data-init-value"]
const _hoisted_14 = { class: "figure min min-1" }
const _hoisted_15 = ["id"]
const _hoisted_16 = ["id"]
const _hoisted_17 = ["id"]
const _hoisted_18 = ["id"]
const _hoisted_19 = { class: "figure min min-2 mb-2" }
const _hoisted_20 = ["id"]
const _hoisted_21 = ["id"]
const _hoisted_22 = ["id"]
const _hoisted_23 = ["id"]
const _hoisted_24 = ["data-init-value"]
const _hoisted_25 = { class: "figure sec sec-1" }
const _hoisted_26 = ["id"]
const _hoisted_27 = ["id"]
const _hoisted_28 = ["id"]
const _hoisted_29 = ["id"]
const _hoisted_30 = { class: "figure sec sec-2 mb-2" }
const _hoisted_31 = ["id"]
const _hoisted_32 = ["id"]
const _hoisted_33 = ["id"]
const _hoisted_34 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_shpt_normal_text = _resolveComponent("shpt-normal-text")!
  const _component_app_normal_text = _resolveComponent("app-normal-text")!

  return (_ctx.timerArray.length)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: "bloc-time hours",
          "data-init-value": _ctx.timer.hours
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("span", {
              class: "top",
              id: `${_ctx.uniqueId}-top-00`
            }, _toDisplayString(_ctx.timerArray[0][0]), 9, _hoisted_4),
            _createElementVNode("span", {
              class: "top-back",
              id: `${_ctx.uniqueId}-top-back-00`
            }, [
              _createElementVNode("span", null, _toDisplayString(_ctx.timerArray[0][0]), 1)
            ], 8, _hoisted_5),
            _createElementVNode("span", {
              class: "bottom",
              id: `${_ctx.uniqueId}-bottom-00`
            }, _toDisplayString(_ctx.timerArray[0][0]), 9, _hoisted_6),
            _createElementVNode("span", {
              class: "bottom-back",
              id: `${_ctx.uniqueId}-bottom-back-00`
            }, [
              _createElementVNode("span", null, _toDisplayString(_ctx.timerArray[0][0]), 1)
            ], 8, _hoisted_7)
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("span", {
              class: "top",
              id: `${_ctx.uniqueId}-top-01`
            }, _toDisplayString(_ctx.timerArray[0][1]), 9, _hoisted_9),
            _createElementVNode("span", {
              class: "top-back",
              id: `${_ctx.uniqueId}-top-back-01`
            }, [
              _createElementVNode("span", null, _toDisplayString(_ctx.timerArray[0][1]), 1)
            ], 8, _hoisted_10),
            _createElementVNode("span", {
              class: "bottom",
              id: `${_ctx.uniqueId}-bottom-01`
            }, _toDisplayString(_ctx.timerArray[0][1]), 9, _hoisted_11),
            _createElementVNode("span", {
              class: "bottom-back",
              id: `${_ctx.uniqueId}-bottom-back-01`
            }, [
              _createElementVNode("span", null, _toDisplayString(_ctx.timerArray[0][1]), 1)
            ], 8, _hoisted_12)
          ]),
          _createVNode(_component_shpt_normal_text, { customClass: `count-title !text-[11px]` }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode("Hours")
            ])),
            _: 1
          })
        ], 8, _hoisted_2),
        _createElementVNode("div", {
          class: "bloc-time min",
          "data-init-value": _ctx.timer.minutes
        }, [
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("span", {
              class: "top",
              id: `${_ctx.uniqueId}-top-10`
            }, _toDisplayString(_ctx.timerArray[1][0]), 9, _hoisted_15),
            _createElementVNode("span", {
              class: "top-back",
              id: `${_ctx.uniqueId}-top-back-10`
            }, [
              _createElementVNode("span", null, _toDisplayString(_ctx.timerArray[1][0]), 1)
            ], 8, _hoisted_16),
            _createElementVNode("span", {
              class: "bottom",
              id: `${_ctx.uniqueId}-bottom-10`
            }, _toDisplayString(_ctx.timerArray[1][0]), 9, _hoisted_17),
            _createElementVNode("span", {
              class: "bottom-back",
              id: `${_ctx.uniqueId}-bottom-back-10`
            }, [
              _createElementVNode("span", null, _toDisplayString(_ctx.timerArray[1][0]), 1)
            ], 8, _hoisted_18)
          ]),
          _createElementVNode("div", _hoisted_19, [
            _createElementVNode("span", {
              class: "top",
              id: `${_ctx.uniqueId}-top-11`
            }, _toDisplayString(_ctx.timerArray[1][1]), 9, _hoisted_20),
            _createElementVNode("span", {
              class: "top-back",
              id: `${_ctx.uniqueId}-top-back-11`
            }, [
              _createElementVNode("span", null, _toDisplayString(_ctx.timerArray[1][1]), 1)
            ], 8, _hoisted_21),
            _createElementVNode("span", {
              class: "bottom",
              id: `${_ctx.uniqueId}-bottom-11`
            }, _toDisplayString(_ctx.timerArray[1][1]), 9, _hoisted_22),
            _createElementVNode("span", {
              class: "bottom-back",
              id: `${_ctx.uniqueId}-bottom-back-11`
            }, [
              _createElementVNode("span", null, _toDisplayString(_ctx.timerArray[1][1]), 1)
            ], 8, _hoisted_23)
          ]),
          _createVNode(_component_shpt_normal_text, { customClass: `count-title !text-[11px]` }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode("Minutes")
            ])),
            _: 1
          })
        ], 8, _hoisted_13),
        _createElementVNode("div", {
          class: "bloc-time sec",
          "data-init-value": _ctx.timer.seconds
        }, [
          _createElementVNode("div", _hoisted_25, [
            _createElementVNode("span", {
              class: "top",
              id: `${_ctx.uniqueId}-top-20`
            }, _toDisplayString(_ctx.timerArray[2][0]), 9, _hoisted_26),
            _createElementVNode("span", {
              class: "top-back",
              id: `${_ctx.uniqueId}-top-back-20`
            }, [
              _createElementVNode("span", null, _toDisplayString(_ctx.timerArray[2][0]), 1)
            ], 8, _hoisted_27),
            _createElementVNode("span", {
              class: "bottom",
              id: `${_ctx.uniqueId}-bottom-20`
            }, _toDisplayString(_ctx.timerArray[2][0]), 9, _hoisted_28),
            _createElementVNode("span", {
              class: "bottom-back",
              id: `${_ctx.uniqueId}-bottom-back-20`
            }, [
              _createElementVNode("span", null, _toDisplayString(_ctx.timerArray[2][0]), 1)
            ], 8, _hoisted_29)
          ]),
          _createElementVNode("div", _hoisted_30, [
            _createElementVNode("span", {
              class: "top",
              id: `${_ctx.uniqueId}-top-21`
            }, _toDisplayString(_ctx.timerArray[2][1]), 9, _hoisted_31),
            _createElementVNode("span", {
              class: "top-back",
              id: `${_ctx.uniqueId}-top-back-21`
            }, [
              _createElementVNode("span", null, _toDisplayString(_ctx.timerArray[2][1]), 1)
            ], 8, _hoisted_32),
            _createElementVNode("span", {
              class: "bottom",
              id: `${_ctx.uniqueId}-bottom-21`
            }, _toDisplayString(_ctx.timerArray[2][1]), 9, _hoisted_33),
            _createElementVNode("span", {
              class: "bottom-back",
              id: `${_ctx.uniqueId}-bottom-back-21`
            }, [
              _createElementVNode("span", null, _toDisplayString(_ctx.timerArray[2][1]), 1)
            ], 8, _hoisted_34)
          ]),
          _createVNode(_component_app_normal_text, { customClass: `count-title !text-[11px]` }, {
            default: _withCtx(() => _cache[2] || (_cache[2] = [
              _createTextVNode("Seconds")
            ])),
            _: 1
          })
        ], 8, _hoisted_24)
      ]))
    : _createCommentVNode("", true)
}