import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col space-y-2" }
const _hoisted_2 = { class: "w-full flex flex-col space-y-2" }
const _hoisted_3 = { class: "w-full flex flex-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_text_field = _resolveComponent("app-text-field")!
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_checkbox = _resolveComponent("app-checkbox")!
  const _component_app_button = _resolveComponent("app-button")!
  const _component_app_form_wrapper = _resolveComponent("app-form-wrapper")!

  return (_openBlock(), _createBlock(_component_app_form_wrapper, {
    ref: "formComp",
    parentRefs: _ctx.parentRefs,
    class: "w-full flex flex-col space-y-4 pt-3 pb-4 md:!pb-0 mdlg:!pb-0"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_app_text_field, {
        placeholder: 'Enter email',
        rules: [_ctx.FormValidations.RequiredRule, _ctx.FormValidations.EmailRule],
        modelValue: _ctx.formData.email,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData.email) = $event)),
        hasTitle: true,
        type: "email",
        padding: 'py-3 px-4',
        name: "Email Address",
        ref: "friend_email"
      }, {
        title: _withCtx(() => _cache[1] || (_cache[1] = [
          _createTextVNode(" Email Address ")
        ])),
        _: 1
      }, 8, ["rules", "modelValue"]),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_app_normal_text, { class: "w-full text-left !font-semibold" }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createTextVNode(" Permissions ")
          ])),
          _: 1
        }),
        _createElementVNode("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formData.permissions, (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "w-full flex flex-col border-b-[1px] border-grey-50 pb-2"
            }, [
              _createVNode(_component_app_checkbox, {
                standard: true,
                modelValue: _ctx.formData.permissions[index],
                "onUpdate:modelValue": ($event: any) => ((_ctx.formData.permissions[index]) = $event),
                class: "w-full"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_app_normal_text, { class: "text-left" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(index == "manage_all_orders" ? "Manage all orders" : "") + " " + _toDisplayString(index == "manage_assigned_branch_orders"
                  ? "Manage only assigned orders"
                  : "") + " " + _toDisplayString(index == "manage_offline_payments"
                  ? "Manage offline payment"
                  : "") + " " + _toDisplayString(index == "manage_products" ? "Manage store products" : "") + " " + _toDisplayString(index == "manage_profile" ? "Manage store profile" : "") + " " + _toDisplayString(index == "manage_shoplists" ? "Manage shoplists" : "") + " " + _toDisplayString(index == "manage_promotions" ? "Manage promotions" : "") + " " + _toDisplayString(index == "manage_store_branches" ? "Manage store branches" : "") + " " + _toDisplayString(index == "manage_settlement_accounts"
                  ? "Manage settlement accounts"
                  : ""), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1032, ["modelValue", "onUpdate:modelValue"])
            ]))
          }), 128))
        ])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_app_button, {
          class: "w-full py-3",
          onClick: _withModifiers(_ctx.validateForm, ["prevent"])
        }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [
            _createTextVNode(" Send ")
          ])),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    _: 1
  }, 8, ["parentRefs"]))
}