<template>
  <app-swiper
    :free-mode="false"
    :show-pagination="false"
    :space-between="0"
    :slide-per-view="1"
    :currentSlidePosition="currentSlidePosition"
    custom-class="!h-full "
    :swiperClass="''"
    v-model="slidePosition"
    :auto-play="
      Logic.Common.currentBuildType() == 'web'
        ? {
            delay: 7000,
          }
        : false
    "
    id="swiperContainer"
  >
    <!-- Slide 1 -->
    <swiper-slide
      class="!h-screen !flex !flex-col items-start relative justify-center bg-[#470087]"
    >
      <div
        class="w-full flex flex-col items-center justify-start h-full relative space-y-2"
        style="padding-top: calc(env(safe-area-inset-top) + 16px)"
      >
        <!-- Top sections -->
        <div
          class="w-full flex flex-col items-center justify-center px-4 mdlg:!pt-2 md:!pt-2 pb-2"
        >
          <app-header-text
            class="!text-white font-bold text-center !text-2xl mdlg:!text-3xl xs:!text-base font-figtree"
          >
            Reward Loyalty
          </app-header-text>
          <app-normal-text
            class="!text-white text-center !font-regular !text-xl xs:!text-sm font-figtree"
          >
            Give your customers rewards <br />
            every time they buy from you.
          </app-normal-text>
        </div>

        <div class="w-full flex flex-col justify-center items-center h-[70%] z-40">
          <app-image-loader
            :photoUrl="'/images/splash/splash-1.png'"
            class="w-[90%] h-full rounded-[10px]"
          />
        </div>

        <div
          class="w-full flex flex-col absolute bottom-0 left-0 right-0 h-[50%]"
          style="
            background: url('/images/splash/bottom-white-bg.png') no-repeat top center;
            background-size: cover;
          "
        ></div>

        <!-- Bottom section -->
        <div
          class="w-full flex flex-row items-center justify-between space-x-3 px-4 absolute bottom-0 left-0 right-0 z-50"
          style="padding-bottom: calc(env(safe-area-inset-bottom) + 16px)"
        >
          <app-button
            :padding="'px-9 py-3 xs:!px-6 xs:!py-2'"
            :customClass="`!bg-[#EBDDF4] !text-[#470087]  !font-semibold  !uppercase !xs:text-[10px] font-figtree  ${
              Logic.Common.currentBuildType() == 'web' ? 'invisible' : ''
            }`"
            :border="''"
            @click="currentSlidePosition = 4"
          >
            Skip
          </app-button>

          <app-button
            :padding="'px-9 py-3 xs:!px-6 xs:!py-2'"
            :custom-class="'!bg-[#9411EF] !text-white  !font-semibold  !uppercase !xs:text-[10px] font-figtree'"
            @click="currentSlidePosition++"
            :border="''"
          >
            Next
          </app-button>
        </div>
      </div>
    </swiper-slide>

    <!-- Slide 2 -->
    <swiper-slide
      class="!h-screen !flex !flex-col items-start relative justify-start bg-[#470087]"
    >
      <div class="w-full flex flex-col items-center justify-start h-full relative">
        <!-- Top spacer -->
        <div style="height: calc(env(safe-area-inset-top) + 16px)" class="w-full"></div>

        <div
          class="w-full flex flex-col absolute top-0 left-0 right-0 h-[50%]"
          style="
            background: url('/images/splash/top-white-bg.png') no-repeat bottom center;
            background-size: cover;
          "
        ></div>

        <!-- Top sections -->
        <div
          class="w-full flex flex-col items-center px-4 z-30 max-h-[90%] mdlg:!pt-6 md:!pt-6 pb-4"
        >
          <app-header-text
            class="!text-black font-bold text-center !text-2xl mdlg:!text-3xl xs:!text-base font-figtree"
          >
            Boost Retail Sales
          </app-header-text>
          <app-normal-text
            class="!text-black text-center !font-regular !text-xl xs:!text-sm font-figtree"
          >
            Drive more sales to your shop <br />
            with exciting cashback offers.
          </app-normal-text>
        </div>

        <div class="w-full flex flex-col justify-center items-center h-[70%] z-40">
          <app-image-loader
            :photoUrl="'/images/splash/splash-2.png'"
            class="w-[90%] h-full rounded-[10px]"
          />
        </div>

        <!-- Bottom section -->
        <div
          class="w-full flex flex-row items-center justify-between space-x-3 px-4 absolute bottom-0 left-0 right-0 z-50"
          style="padding-bottom: calc(env(safe-area-inset-bottom) + 16px)"
        >
          <app-button
            :padding="'px-9 py-3 xs:!px-6 xs:!py-2'"
            :customClass="`!bg-[#EBDDF4] !text-[#470087]  !font-semibold  !uppercase !xs:text-[10px] font-figtree  ${
              Logic.Common.currentBuildType() == 'web' ? 'invisible' : ''
            }`"
            :border="''"
            @click="currentSlidePosition = 4"
          >
            Skip
          </app-button>

          <app-button
            :padding="'px-9 py-3 xs:!px-6 xs:!py-2'"
            :custom-class="'!bg-[#9411EF] !text-white  !font-semibold  !uppercase !xs:text-[10px] font-figtree'"
            @click="currentSlidePosition++"
            :border="''"
          >
            Next
          </app-button>
        </div>
      </div>
    </swiper-slide>

    <!-- Slide 3 -->
    <swiper-slide
      class="!h-screen !flex !flex-col items-start relative justify-start bg-[#470087]"
    >
      <div
        class="w-full flex flex-col items-center justify-start h-full relative"
        style="padding-top: calc(env(safe-area-inset-top) + 16px)"
      >
        <!-- Top sections -->
        <div
          class="w-full flex flex-col items-center px-4 z-30 max-h-[90%] mdlg:!pt-6 md:!pt-6 pb-4"
        >
          <app-header-text
            class="!text-white font-bold text-center !text-2xl mdlg:!text-3xl xs:!text-base font-figtree"
          >
            Engage Customers
          </app-header-text>
          <app-normal-text
            class="!text-white text-center !font-regular !text-xl xs:!text-sm font-figtree"
          >
            Easily create special deals <br />
            that match your customers' needs.
          </app-normal-text>
        </div>

        <div class="w-full flex flex-col justify-center items-center h-[70%] z-40">
          <app-image-loader
            :photoUrl="'/images/splash/splash-3.png'"
            class="w-[90%] h-full rounded-[10px]"
          />
        </div>

        <div
          class="w-full flex flex-col absolute bottom-0 left-0 right-0 h-[50%]"
          style="
            background: url('/images/splash/bottom-white-bg.png') no-repeat top center;
            background-size: cover;
          "
        ></div>

        <!-- Bottom section -->
        <div
          class="w-full flex flex-row items-center justify-between space-x-3 px-4 absolute bottom-0 left-0 right-0 z-50"
          style="padding-bottom: calc(env(safe-area-inset-bottom) + 16px)"
        >
          <app-button
            :padding="'px-9 py-3 xs:!px-6 xs:!py-2'"
            :customClass="`!bg-[#EBDDF4] !text-[#470087]  !font-semibold  !uppercase !xs:text-[10px] font-figtree  ${
              Logic.Common.currentBuildType() == 'web' ? 'invisible' : ''
            }`"
            :border="''"
            @click="currentSlidePosition = 4"
          >
            Skip
          </app-button>

          <app-button
            :padding="'px-9 py-3 xs:!px-6 xs:!py-2'"
            :custom-class="'!bg-[#9411EF] !text-white  !font-semibold  !uppercase !xs:text-[10px] font-figtree'"
            @click="currentSlidePosition++"
            :border="''"
          >
            Next
          </app-button>
        </div>
      </div>
    </swiper-slide>

    <!-- Slide 4 -->
    <swiper-slide
      class="!h-screen !flex !flex-col items-start relative justify-start bg-[#470087]"
    >
      <div class="w-full flex flex-col items-center justify-start h-full relative">
        <!-- Top spacer -->
        <div style="height: calc(env(safe-area-inset-top) + 16px)" class="w-full"></div>

        <div
          class="w-full flex flex-col absolute top-0 left-0 right-0 h-[50%]"
          style="
            background: url('/images/splash/top-white-bg.png') no-repeat bottom center;
            background-size: cover;
          "
        ></div>

        <!-- Top sections -->
        <div
          class="w-full flex flex-col items-center px-4 z-30 max-h-[90%] mdlg:!pt-6 md:!pt-6 pb-4"
        >
          <app-header-text
            class="!text-black font-bold text-center !text-2xl mdlg:!text-3xl xs:!text-base font-figtree"
          >
            Monetise Engagement
          </app-header-text>
          <app-normal-text
            class="!text-black text-center !font-regular !text-xl xs:!text-sm font-figtree"
          >
            Link products and <br />
            monetise your social media videos.
          </app-normal-text>
        </div>

        <div class="w-full flex flex-col justify-center items-center h-[70%] z-40">
          <app-image-loader
            :photoUrl="'/images/splash/splash-4.png'"
            class="w-[90%] h-full rounded-[10px]"
          />
        </div>

        <!-- Bottom section -->
        <div
          class="w-full flex flex-row items-center justify-between space-x-3 px-4 absolute bottom-0 left-0 right-0 z-50"
          style="padding-bottom: calc(env(safe-area-inset-bottom) + 16px)"
        >
          <app-button
            :padding="'px-9 py-3 xs:!px-6 xs:!py-2'"
            :customClass="`!bg-[#EBDDF4] !text-[#470087]  !font-semibold  !uppercase !xs:text-[10px] font-figtree  ${
              Logic.Common.currentBuildType() == 'web' ? 'invisible' : ''
            }`"
            :border="''"
            @click="currentSlidePosition = 4"
          >
            Skip
          </app-button>

          <app-button
            :padding="'px-9 py-3 xs:!px-6 xs:!py-2'"
            :custom-class="'!bg-[#9411EF] !text-white  !font-semibold  !uppercase !xs:text-[10px] font-figtree'"
            @click="currentSlidePosition++"
            :border="''"
          >
            Next
          </app-button>
        </div>
      </div>
    </swiper-slide>

    <!-- Slide 5 -->
    <swiper-slide
      class="!h-screen !flex !flex-col items-start relative justify-start bg-[#470087]"
    >
      <div
        class="w-full flex flex-col items-center justify-start h-full relative"
        style="padding-top: calc(env(safe-area-inset-top) + 16px)"
      >
        <!-- Top sections -->

        <div class="w-full flex flex-col justify-center items-center h-[55%] z-10">
          <app-icon name="base-logo" class="h-[35px]" />
        </div>

        <!-- Bottom section -->
        <div
          v-if="Logic.Common.currentBuildType() == 'mobile'"
          class="w-full flex flex-col space-y-4 items-center justify-between space-x-3 px-4 pt-4 absolute bottom-0 left-0 right-0 h-auto z-30 bg-white rounded-t-[10px]"
          style="padding-bottom: calc(env(safe-area-inset-bottom) + 16px)"
        >
          <!-- Top section -->
          <div class="w-full flex flex-row items-center justify-between">
            <app-header-text
              class="!text-black font-bold text-center !text-sm xs:!text-xs"
            >
              Get Started
            </app-header-text>
            <app-button
              @click="Logic.Common.GoToRoute('/auth/login')"
              :custom-class="'rounded-[40px] font-500 text-[14px]'"
              :bg-color="'bg-primary-50'"
              :text-color="'text-primary-500'"
              :padding="'px-3 py-[2px]'"
            >
              Login
            </app-button>
          </div>

          <div class="w-full flex flex-col space-y-3 justify-center items-center">
            <div class="w-full flex flex-col">
              <app-button
                @click.prevent="Logic.Common.GoToRoute('/auth/signup')"
                :padding="'py-3'"
                :bg-color="'bg-primary-main'"
                :text-color="'text-white'"
                class="w-full"
              >
                Sign up with Email
              </app-button>
            </div>

            <!-- SSO -->
            <SSO :fromAction="'signUp'" :isRelative="true" />
          </div>
        </div>
      </div>
    </swiper-slide>
  </app-swiper>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { Logic } from "../../index";
import {
  AppSwiper,
  AppButton,
  AppNormalText,
  AppHeaderText,
  AppImageLoader,
  AppIcon,
} from "../../components";
import { SwiperSlide } from "swiper/vue";
import SSO from "./SSO.vue";

export default defineComponent({
  components: {
    AppSwiper,
    AppButton,
    AppNormalText,
    AppHeaderText,
    AppImageLoader,
    AppIcon,
    SwiperSlide,
    SSO,
  },
  setup() {
    const router = useRouter();

    const slidePosition = ref(0);

    const currentSlidePosition = ref(0);

    watch(slidePosition, () => {
      currentSlidePosition.value = slidePosition.value;
    });

    localStorage.setItem("user_onboarded", "true");

    const goToRoute = (route: string) => {
      router.push(route);
    };

    return {
      slidePosition,
      currentSlidePosition,
      Logic,
      goToRoute,
    };
  },
});
</script>
