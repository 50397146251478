<template>
  <div
    class="w-full flex flex-col space-y-3 pt-2 items-center justify-center relative"
  >
    <!-- Search and add -->
    <div class="w-full flex flex-row space-x-2 items-center">
      <AppTextField :placeholder="`Search for shoplist`" v-model="searchQuery">
        <template v-slot:inner-prefix>
          <AppIcon :custom-class="''" :name="'search'"></AppIcon>
        </template>
      </AppTextField>
    </div>

    <!-- Loading -->
    <div
      class="w-full flex flex-col items-center justify-center h-[500px]"
      v-if="isLoading"
    >
      <svg
        :class="`animate-spin mr-3 h-5 w-5 text-primary-400`"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          class="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          stroke-width="4"
        ></circle>
        <path
          class="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    </div>

    <!-- Shoplists -->
    <div class="w-full grid grid-cols-2 gap-3">
      <template v-if="shoplists.length > 0">
        <div
          v-for="(item, index) in shoplists"
          :key="index"
          class="col-span-1 flex flex-col"
        >
          <app-shoplist :item="item" @click="selectShoplist(item)" />
        </div>
      </template>

      <div
        v-else
        class="w-full col-span-2 flex flex-col h-[300px] justify-center items-center"
      >
        <app-empty-state
          :title="'No Shoplist Here'"
          :subTitle="'All available shoplists will show up here'"
        >
        </app-empty-state>
      </div>
    </div>

    <!-- Spacer -->
    <div class="h-[50px]"></div>
  </div>
</template>
<script lang="ts">
import { Logic } from "../../composable";
import { defineComponent, onMounted, reactive, ref, toRef, watch } from "vue";
import AppIcon from "../AppIcon";
import { AppTextField } from "../AppForm";
import { CollectionPaginator } from "../../gql/graphql";
import AppEmptyState from "../AppEmptyState";
import { MediaBox } from "../../types";
import { AppShoplist } from "../AppShoplist";

export default defineComponent({
  components: {
    AppIcon,
    AppTextField,
    AppShoplist,
    AppEmptyState,
  },
  props: {
    data: {
      type: Object as () => {
        selected: string;
        getMediaBox: Function;
      },
    },
    action: {
      type: Function,
      default: () => {},
    },
  },
  setup(props) {
    const searchQuery = ref("");

    const ManyShoplists = ref<CollectionPaginator>();

    const isLoading = ref(false);

    const selectedShoplist = ref("");

    const shoplists = reactive<MediaBox[]>([]);

    const setShoplists = () => {
      shoplists.length = 0;

      ManyShoplists.value?.data?.forEach((item) => {
        const mediaData: any = props.data?.getMediaBox(item);

        shoplists.push(mediaData);
      });
    };

    const getShoplists = () => {
      const searchString = searchQuery.value
        ? `AND: {
        column: NAME
        operator: LIKE
        value: "%${searchQuery.value}%"
      }`
        : "";

      isLoading.value = true;
      Logic.Shoplist.GetShoplistCollections(
        1,
        15,
        "CREATED_AT",
        "DESC",
        `{
                        column: BUSINESS_PROFILE_ID
                        operator: EQ
                        value: ${Logic.Auth.GetCurrentBusiness()?.id}
                        ${searchString}
                      }`,
        true
      ).then((data: any) => {
        isLoading.value = false;

        if (data) {
          ManyShoplists.value = data;
          setShoplists();
        }
      });
    };

    const selectShoplist = (data: any) => {
      if (props.action) {
        props.action(data);
      }
    };

    watch(searchQuery, () => {
      Logic.Common.debounce(() => {
        getShoplists();
      }, 400);
    });

    onMounted(() => {
      selectedShoplist.value = props.data?.selected || "";
      getShoplists();
    });

    return {
      searchQuery,
      isLoading,
      ManyShoplists,
      selectedShoplist,
      shoplists,
      selectShoplist,
    };
  },
});
</script>
