import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-[165px] flex flex-col space-y-2 !cursor-pointer"
}
const _hoisted_2 = { class: "w-full flex flex-col" }
const _hoisted_3 = { class: "flex flex-row justify-between items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_normal_text = _resolveComponent("app-normal-text")!
  const _component_app_media_box = _resolveComponent("app-media-box")!

  return (_ctx.item)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_app_normal_text, { class: "w-full !text-left !font-light !text-[#313131] dark:!text-grey-200 !text-[12px]" }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.item.data.main_category), 1)
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_app_media_box, {
            data: _ctx.item.data,
            isVideo: !_ctx.item.data.is_grid,
            class: "!h-[160px] !rounded-t-[7.9px] relative"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", {
                class: "absolute bottom-0 left-0 h-[10px] w-full bg-gray-900",
                style: _normalizeStyle(`background-color: ${_ctx.item.colors.bg} !important;`)
              }, null, 4)
            ]),
            _: 1
          }, 8, ["data", "isVideo"]),
          _createElementVNode("div", {
            class: _normalizeClass(`w-full flex flex-col px-3 py-3 !pt-0 left-0 space-y-2 bg-gray-900 rounded-b-[7.9px]`),
            style: _normalizeStyle(`background-color: ${_ctx.item.colors.bg} !important;`)
          }, [
            _createVNode(_component_app_normal_text, { class: "!text-left !text-[11px] mdlg:!text-[12px] !line-clamp-1 w-full !text-white" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.item.data.title), 1)
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_app_normal_text, { class: "!text-[12px] !text-white" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.Logic.Common.convertToMoney(
                parseFloat(_ctx.item.data.price?.toFixed(2) || "0"),
                false,
                "ngn"
              )), 1)
                ]),
                _: 1
              })
            ])
          ], 4)
        ])
      ]))
    : _createCommentVNode("", true)
}