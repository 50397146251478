import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: /*@__PURE__*/_normalizeClass(`w-full  mdlg:!w-[70%] lg:!w-[65%]  grid grid-cols-12 `)
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`w-full flex flex-row items-center justify-center bottom-0 left-0  bg-white dark:!bg-black  ${
      _ctx.isRelative ? 'relative' : 'fixed'
    } `)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "hidden col-span-3 mdlg:!flex flex-col md:!flex md:!col-span-4 mdlg:!col-span-3" }, null, -1)),
      _createElementVNode("div", {
        class: _normalizeClass(`flex flex-col space-y-2 col-span-12 px-4 ${_ctx.customClass} mdlg:!col-span-6 md:!col-span-8 items-center justify-center mdlg:!border-l-[1px] mdlg:!border-r-[1px] mdlg:!border-grey-100 md:!border-l-[1px]   md:!border-grey-100`),
        style: {"padding-bottom":"calc(env(safe-area-inset-bottom) + 16px) !important"}
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 2),
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "hidden col-span-3 mdlg:!flex flex-col" }, null, -1))
    ])
  ], 2))
}