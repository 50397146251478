import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id", "loop", "autoplay", "poster"]
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("video", {
    id: `player${_ctx.tabIndex}`,
    class: _normalizeClass(`op-player__media  cursor-pointer ${
      _ctx.isFullScreen ? 'video-fullscreen' : 'h-full object-cover'
    }`),
    controls: false,
    loop: _ctx.loopVideo,
    autoplay: _ctx.autoplay,
    playsInline: "",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.togglePlayPause && _ctx.togglePlayPause(...args))),
    poster: _ctx.coverImage,
    muted: true
  }, [
    _createElementVNode("source", {
      src: 
        _ctx.videoUrl.replaceAll(
          'https://shpt.blob.core.windows.net/',
          'https://shptstorage-grazapdjemh4bzct.z02.azurefd.net/'
        )
      
    }, null, 8, _hoisted_2)
  ], 10, _hoisted_1))
}