import { reactive } from 'vue'
import { Logic } from '..'
import {
  CommercialBank,
  DynamicFundingAccount,
  EnrolledCardTransactionPaginator,
  ExchangeRate,
  MutationAddUserBankArgs,
  MutationCheckTransferStatusArgs,
  MutationCreatePaymentRequestArgs,
  MutationEnrolledUserCardArgs,
  MutationFundWalletArgs,
  MutationGenerateWalletAccountArgs,
  MutationInitiateTransferToBankArgs,
  MutationInitiateTransferToShoppointUserArgs,
  MutationResolveBankAccountArgs,
  MutationSavePaymentTransactionArgs,
  MutationUpdatePaymentRequestArgs,
  PaginatorInfo,
  PaymentBeneficiary,
  PaymentRequest,
  PointEarned,
  PointEarnedPaginator,
  PointTransaction,
  PointTransactionPaginator,
  Transaction,
  UserBank,
  UserCard,
  Wallet as WalletModel,
} from '../../gql/graphql'
import { $api } from '../../services'
import { SelectOption } from '../types/common'
import Common from './Common'
import { CombinedError } from 'urql'
import currency from 'currency.js'
import { TransactionPaginator } from '../../../dist/esm/gql/graphql'

export default class Wallet extends Common {
  constructor() {
    super()
  }

  // Default variables
  public ChargeAccessCode: string | undefined = undefined
  public SingleDynamicFundingAccount: DynamicFundingAccount | undefined
  public CommercialBanks: CommercialBank[] | undefined
  public CommercialBanksOptions: SelectOption[] = []
  public UserWallet: WalletModel | undefined
  public ManyTransactions: Transaction[] | undefined
  public SingleTransaction: Transaction | undefined
  public ManyPointEarned: PointEarnedPaginator | undefined
  public SinglePointEarned: PointEarned | undefined
  public ManyPointTransaction: PointTransactionPaginator | undefined
  public SinglePointTransaction: PointTransaction | undefined
  public ManyPaymentRequests: PaymentRequest[] | undefined
  public SinglPaymentRequest: PaymentRequest | undefined
  public ManyUserCards: UserCard[] | undefined
  public SingleCard: UserCard | undefined
  public ManyUserBanks: UserBank | undefined
  public UserExchangeRates: ExchangeRate[] | undefined
  public SingleBank: UserBank | undefined
  public ManyPaymentBeneficiary: PaymentBeneficiary[] | undefined
  public SinglePaymentBeneficiary: PaymentBeneficiary | undefined
  public BusinessWallet: WalletModel | undefined
  public AdWallet: WalletModel | undefined
  public BusinessWalletTransactions: TransactionPaginator | undefined
  public AdWalletTransactions: TransactionPaginator | undefined
  public ClientWalletTransactions: TransactionPaginator | undefined
  public PaymentMethods = reactive<SelectOption[]>([])
  public CheckStatusState = reactive({
    active: false,
  })
  public PointGainSubscriptionActive = false
  public NewPointGain:
    | {
        id: string
        uuid: string
        points: number
        category?: string
        extra_id?: string
      }
    | undefined
  public NewPointEarned: PointEarned | undefined
  public MastercardAuthToken: String | undefined
  public EnrolledCardTransations: EnrolledCardTransactionPaginator | undefined
  public CashbackRewards: {
    scan: PointEarned[] | undefined
    shop: PointEarned[] | undefined
    pay: PointEarned[] | undefined
    bonus: PointEarned[] | undefined
    paginatorInfo: PaginatorInfo | undefined
  } = {
    scan: undefined,
    shop: undefined,
    pay: undefined,
    bonus: undefined,
    paginatorInfo: undefined,
  }

  // Mutation input variable
  public AddUserBankForm: MutationAddUserBankArgs | undefined
  public CreatePaymentRequestForm: MutationCreatePaymentRequestArgs | undefined
  public UpdatePaymentRequestForm: MutationUpdatePaymentRequestArgs | undefined
  public InitiateTransferToBankForm:
    | MutationInitiateTransferToBankArgs
    | undefined
  public InitiateTransferToShoppointUserForm:
    | MutationInitiateTransferToShoppointUserArgs
    | undefined
  public FundWalletForm: MutationFundWalletArgs | undefined
  public ResolveBankAccountForm: MutationResolveBankAccountArgs | undefined
  public GenerateWalletAccountForm:
    | MutationGenerateWalletAccountArgs
    | undefined
  public CheckTransferStatusForm: MutationCheckTransferStatusArgs | undefined
  public CardInfoData = {
    cardNumber: '',
    expiryMonth: '',
    expiryYear: '',
    cvv: '',
  }
  public EnrollCardForm: MutationEnrolledUserCardArgs | undefined
  public SavePaymentTransactionForm:
    | MutationSavePaymentTransactionArgs
    | undefined

  // Helper methods
  public getCardType = (type: string, usedColored = false) => {
    if (type.toLocaleLowerCase().includes('mastercard')) {
      return 'mastercard'
    }

    if (type.toLocaleLowerCase().includes('visa')) {
      return usedColored ? 'visa-colored' : 'visa'
    }

    if (type.toLocaleLowerCase().includes('verve')) {
      return 'verve'
    }
  }

  // Helpers
  public getUserAmount = (amount: string, item_currency: string) => {
    const userBaseCurrency = Logic.Auth.AuthUser?.profile?.region.currency

    let finalAmount = amount

    if (item_currency != userBaseCurrency) {
      const latestExchangeRate = this.UserExchangeRates?.filter(
        (item) => item.from == item_currency,
      )

      if (latestExchangeRate?.length) {
        finalAmount = (
          parseFloat(finalAmount) * latestExchangeRate[0].unit_cost
        ).toString()
      }
    }

    return {
      amount: finalAmount,
      formated: currency(finalAmount, {
        separator: ',',
        symbol: userBaseCurrency,
      }).format(),
    }
  }

  public getUserPoints = (amount: string, category_slug: string) => {
    const pointCategory = Logic.Auth.AuthUser?.point_categories.filter(
      (category) => category.product_category.slug == category_slug,
    )

    let points = 0

    if (pointCategory?.length) {
      points = parseFloat(amount) / pointCategory[0].cash_per_point

      if (points > pointCategory[0].cap_amount) {
        points = pointCategory[0].cap_amount
      }
    }

    return new Intl.NumberFormat().format(parseFloat(points.toFixed(2)))
  }

  // Query actions
  public GetChargeAccessCode = () => {
    return $api.wallet
      .ChargeAccessCode()
      .then((response) => {
        this.ChargeAccessCode = response.data?.ChargeAccessCode
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        throw error
      })
  }

  public GetUserWallet = () => {
    return $api.wallet
      .GetUserWallet('client')
      .then((response) => {
        if (Logic.Auth.AuthUser) {
          Logic.Auth.AuthUser.wallet = response.data?.GetUserWallet
        }
        this.UserWallet = response.data?.GetUserWallet
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        throw error
      })
  }

  public GetBusinessWallet = () => {
    return $api.wallet
      .GetUserWallet('business')
      .then((response) => {
        this.BusinessWallet = response.data?.GetUserWallet
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        throw error
      })
  }

  public GetAdWallet = () => {
    return $api.wallet
      .GetUserWallet('advert')
      .then((response) => {
        this.AdWallet = response.data?.GetUserWallet
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        throw error
      })
  }

  public GetTransactionsByWalletId = (
    wallet_type: 'client' | 'business' | 'advert',
    wallet_id: number,
    page: number,
    count: number,
    orderType = 'CREATED_AT',
    order: 'ASC' | 'DESC',
    isUpdate = false,
  ) => {
    return $api.wallet
      .GetTransactionsByWalletId(wallet_id, page, count, orderType, order)
      .then((response) => {
        if (isUpdate) {
          return response.data?.GetTransactionsByWalletId
        }
        if (wallet_type == 'client') {
          this.ClientWalletTransactions =
            response.data?.GetTransactionsByWalletId
        } else if (wallet_type == 'business') {
          this.BusinessWalletTransactions =
            response.data?.GetTransactionsByWalletId
        } else if (wallet_type == 'advert') {
          this.AdWalletTransactions = response.data?.GetTransactionsByWalletId
        }
        return response.data?.GetTransactionsByWalletId
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        throw error
      })
  }

  public GetManyPointTransaction = (
    wallet_id: number,
    page: number,
    count: number,
    orderType = 'CREATED_AT',
    order: 'ASC' | 'DESC',
    isUpdate = false,
  ) => {
    return $api.wallet
      .GetManyPointTransaction(wallet_id, page, count, orderType, order)
      .then((response) => {
        if (isUpdate) {
          return response.data?.GetPointTransactionsByWalletId
        }
        this.ManyPointTransaction =
          response.data?.GetPointTransactionsByWalletId
        return response.data?.GetPointTransactionsByWalletId
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        throw error
      })
  }

  public GetUserEnrolledCards = () => {
    return $api.wallet
      .GetUserEnrolledCards(parseInt(Logic.Auth.AuthUser?.id || '0') || 0)
      .then((response) => {
        if (Logic.Auth.AuthUser) {
          Logic.Auth.AuthUser.enrolled_cards =
            response.data?.GetUserEnrolledCards
        }
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        throw error
      })
  }

  public GetEnrolledCardTransations = (
    enrolled_card_id: number,
    page: number,
    count: number,
  ) => {
    return $api.wallet
      .GetEnrolledCardTransations(enrolled_card_id, page, count)
      .then((response) => {
        this.EnrolledCardTransations = response.data?.GetEnrolledCardTransations
        return response.data?.GetEnrolledCardTransations
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        throw error
      })
  }

  public GetTransaction = (uuid: string) => {
    return $api.wallet
      .GetTransaction(uuid)
      .then((response) => {
        this.SingleTransaction = response.data?.Transaction
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        throw error
      })
  }

  public GetPointTransaction = (uuid: string) => {
    return $api.wallet
      .GetPointTransaction(uuid)
      .then((response) => {
        this.SinglePointTransaction = response.data?.PointTransaction
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        throw error
      })
  }

  public GetPaymentBeneficairies = () => {
    return $api.wallet
      .GetPaymentBeneficiary()
      .then((response) => {
        this.ManyPaymentBeneficiary = response.data?.GetPaymentBeneficiaries
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        throw error
      })
  }

  public GetUserPaymentRequests = () => {
    return $api.wallet
      .GetUserPaymentRequests()
      .then((response) => {
        this.ManyPaymentRequests = response.data?.GetUserPaymentRequests
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        throw error
      })
  }

  public GetMastercardAuthToken = () => {
    return $api.wallet
      .GetMastercardAuthToken()
      .then((response) => {
        this.MastercardAuthToken = response.data?.GetMastercardAuthToken

        return response.data?.GetMastercardAuthToken
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        throw error
      })
  }

  public GetPointEarned = (
    page: number,
    count: number,
    orderType: string,
    order: 'ASC' | 'DESC',
    whereQuery?: string,
  ) => {
    return $api.wallet
      .GetPointEarned(
        page,
        count,
        orderType,
        order,
        whereQuery || '',
        `
      {
        column: UUID,
        operator: EQ,
        value: "${Logic.Auth.AuthUser?.uuid}"
      }
      `,
      )
      .then((response) => {
        this.ManyPointEarned = response.data?.GetPointEarned
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        throw error
      })
  }

  public GetCashbackRewards = (
    group: 'shop' | 'scan' | 'pay' | 'bonus' = 'scan',
    page: number = 1,
    isUpdate = false,
  ) => {
    let whereQuery = ''

    if (group == 'shop') {
      whereQuery = `
      {
        column: EVENT_SLUG,
        operator: IN,
        value: [
          "AIRTIME_PURCHASE",
          "DATA_PURCHASE",
          "CABLE_TV_PURCHASE",
          "ELECTRICITY_PURCHASE",
          "SHOPLIST_PURCHASED",
        ]
      }
      `
    } else if (group == 'pay') {
      whereQuery = `
      {
        column: EVENT_SLUG,
        operator: IN,
        value: ["EXTERNAL_BANK_TRANSFER", "SHPT_TO_SHPT_TRANSFER"]
      }
      `
    } else if (group == 'scan') {
      whereQuery = `
      {
        column: EVENT_SLUG,
        operator: IN,
        value: ["SCANNED_RECEIPT"]
      }
      `
    } else {
      whereQuery = `
      {
        column: EVENT_SLUG,
        operator: NOT_IN,
        value: [
          "AIRTIME_PURCHASE",
          "DATA_PURCHASE",
          "CABLE_TV_PURCHASE",
          "ELECTRICITY_PURCHASE",
          "EXTERNAL_BANK_TRANSFER",
          "SHPT_TO_SHPT_TRANSFER",
          "SCANNED_RECEIPT",
        ]
      }
      `
    }
    return $api.wallet
      .GetPointEarned(
        page,
        10,
        'UPDATED_AT',
        'DESC',
        whereQuery,
        `
    {
      column: UUID,
      operator: EQ,
      value: "${Logic.Auth.AuthUser?.uuid}"
    }
    `,
      )
      .then((response) => {
        if (isUpdate) {
          return response.data?.GetPointEarned
        }
        this.CashbackRewards[group] = response.data?.GetPointEarned.data
        this.CashbackRewards.paginatorInfo =
          response.data?.GetPointEarned.paginatorInfo
        return response.data?.GetPointEarned
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        throw error
      })
  }

  public GetSinglePointEarned = (uuid: string) => {
    return $api.wallet
      .GetSinglePointEarned(uuid)
      .then((response) => {
        this.SinglePointEarned = response.data?.PointEarned
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        throw error
      })
  }

  public GetUserExchangeRates = () => {
    return $api.wallet
      .GetLatestExchangeRates(
        Logic.Auth.AuthUser?.profile?.region.currency || '',
      )
      .then((response) => {
        this.UserExchangeRates = response.data?.GetLatestExchangeRates
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
      })
  }

  public GetCommercialBanks = () => {
    return $api.wallet
      .CommercialBanks()
      .then((response) => {
        this.CommercialBanks = response.data?.CommercialBanks
        this.CommercialBanksOptions = []
        this.CommercialBanks?.forEach((bank) => {
          this.CommercialBanksOptions.push({
            key: bank.code,
            value: bank.name,
            hasIcon: false,
            isImage: false,
          })
        })
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        throw error
      })
  }

  // Mutations
  public AddCard = async (formIsValid: boolean) => {
    if (formIsValid) {
      Logic.Common.showLoader({
        loading: true,
      })

      // setup paystack
    }
  }

  public SetDefaultBank = (bank_uuid: string) => {
    return $api.wallet
      .SetDefaultBank(bank_uuid)
      .then((response) => {
        return response.data
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
      })
  }

  public AddBank = (formIsValid: boolean, silent = false) => {
    if (formIsValid && this.AddUserBankForm) {
      Logic.Common.showLoader({
        loading: true,
      })
      $api.wallet
        .AddUserBank(this.AddUserBankForm)
        .then((response) => {
          this.SingleBank = response.data?.AddUserBank
          Logic.Auth.GetAuthUser()

          Logic.Common.hideLoader()

          if (!silent) {
            Logic.Common.showSuccess('Your bank account has been saved', () => {
              Logic.Common.GoToRoute('/wallet')
            })
          }

          Logic.User.SaveUserActivity('Add a bank account', 'action')
        })
        .catch((error: CombinedError) => {
          Logic.Common.showError(error, 'Invalid bank account!')
          Logic.User.SaveUserActivity(
            `Failed - Add a bank account`,
            'failed_action',
            {
              reason: error.graphQLErrors[0].message,
            },
          )
        })
    }
  }

  public CreatePaymentRequest = () => {
    if (this.CreatePaymentRequestForm) {
      Logic.Common.showLoader({
        loading: true,
      })
      return $api.wallet
        .CreatePaymentRequest(this.CreatePaymentRequestForm)
        .then((response) => {
          Logic.Common.hideLoader()
          this.SinglPaymentRequest = response.data?.CreatePaymentRequest
          if (response.data?.CreatePaymentRequest) {
            this.ManyPaymentRequests?.push(response.data?.CreatePaymentRequest)
          }
          Logic.Common.showSuccess(
            'Your payment request has been sent',
            () => {
              Logic.Common.showModal({ show: false })
              Logic.Common.GoToRoute('/wallet/collect-payment/request-history')
            },
            'Continue',
          )

          Logic.User.SaveUserActivity('Create Payment Request', 'action')
          return response.data
        })
        .catch((error: CombinedError) => {
          Logic.Common.showError(error, 'Oops!')
          Logic.User.SaveUserActivity(
            `Failed - Create Payment Request`,
            'failed_action',
            {
              reason: error.graphQLErrors[0].message,
            },
          )
        })
    }
  }

  public UpdatePaymentRequest = (showFeedback = false) => {
    if (this.UpdatePaymentRequestForm) {
      Logic.Common.showLoader({
        loading: true,
        isInteractive: !showFeedback,
      })
      return $api.wallet
        .UpdatePaymentRequest(this.UpdatePaymentRequestForm)
        .then((response) => {
          if (this.UpdatePaymentRequestForm?.status != 'approved') {
            if (showFeedback) {
              Logic.Common.hideLoader()
              Logic.Common.showSuccess(
                `Payment request has been ${this.UpdatePaymentRequestForm?.status}`,
              )
              this.GetUserPaymentRequests()
            }
          } else {
            if (response.data?.UpdatePaymentRequest) {
              this.ReactToPointEarned(response.data.UpdatePaymentRequest)
            }
          }

          Logic.User.SaveUserActivity('Update Payment Request', 'action')

          return response.data
        })
        .catch((error: CombinedError) => {
          Logic.Common.showError(error, 'Oops!')
          Logic.User.SaveUserActivity(
            `Failed - Update Payment Request`,
            'failed_action',
            {
              reason: error.graphQLErrors[0].message,
            },
          )
        })
    }
  }

  public InitiateTransferToBank = () => {
    if (this.InitiateTransferToBankForm) {
      Logic.Common.showLoader({
        loading: true,
        isInteractive: true,
      })
      return $api.wallet
        .InitiateTransferToBank(this.InitiateTransferToBankForm)
        .then((response) => {
          if (response.data?.InitiateTransferToBank) {
            this.ReactToPointEarned(response.data.InitiateTransferToBank)
          }
          this.GetPaymentBeneficairies()
          Logic.User.SaveUserActivity('Make Bank Transfer', 'action')
          return response.data
        })
        .catch((error: CombinedError) => {
          Logic.Common.showError(error, 'Oops!')
          Logic.User.SaveUserActivity(
            `Failed - Make Bank Transfer`,
            'failed_action',
            {
              reason: error.graphQLErrors[0].message,
            },
          )
        })
    }
  }

  public InitiateTransferToShoppointUser = () => {
    if (this.InitiateTransferToShoppointUserForm) {
      Logic.Common.showLoader({
        loading: true,
        isInteractive: false,
      })
      return $api.wallet
        .InitiateTransferToShoppointUser(
          this.InitiateTransferToShoppointUserForm,
        )
        .then((response) => {
          if (response.data?.InitiateTransferToShoppointUser) {
            this.ReactToPointEarned(
              response.data.InitiateTransferToShoppointUser,
            )
          }
          this.GetPaymentBeneficairies()
          Logic.User.SaveUserActivity('Make Shoppoint Transfer', 'action')
          return response.data
        })
        .catch((error: CombinedError) => {
          Logic.Common.showError(error, 'Oops!')
          Logic.User.SaveUserActivity(
            `Failed - Make Shoppoint Transfer`,
            'failed_action',
            {
              reason: error.graphQLErrors[0].message,
            },
          )
        })
    }
  }

  public FundWallet = () => {
    if (this.FundWalletForm) {
      Logic.Common.showLoader({
        loading: true,
      })
      return $api.wallet
        .FundWallet(this.FundWalletForm)
        .then((response) => {
          this.UserWallet = this.updatedData(
            this.UserWallet,
            response.data?.FundWallet,
          )
          Logic.Auth.GetAuthUser()
          Logic.Common.hideLoader()

          return response.data
        })
        .catch((error: CombinedError) => {
          Logic.Common.showError(error, 'Oops!')
        })
    }
  }

  public EnrollCard = () => {
    if (this.EnrollCardForm) {
      Logic.Common.showLoader({
        loading: true,
      })
      return $api.wallet
        .EnrolledUserCard(this.EnrollCardForm)
        .then((response) => {
          return response.data
        })
        .catch((error: CombinedError) => {
          Logic.Common.showError(error, 'Oops!')
        })
    }
  }

  public GenerateFundingAccount = (amount: string) => {
    Logic.Common.showLoader({
      loading: true,
    })
    return $api.wallet
      .GenerateFundingAccount(amount)
      .then((response) => {
        this.SingleDynamicFundingAccount = response.data?.GenerateFundingAccount
        Logic.Common.hideLoader()

        return response.data
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
      })
  }

  public ResolveFundingAccount = (dynamic_funding_account_uuid: string) => {
    this.CheckStatusState.active = true
    $api.wallet
      .ResolveFundingAccount(dynamic_funding_account_uuid)
      .then((response) => {
        if (response.data?.ResolveFundingAccount) {
          this.CheckStatusState.active = false
          Logic.Auth.GetAuthUser()

          Logic.Common.showModal({
            show: true,
            title: '',
            type: 'request_feedback',
            preventClose: true,
            closeAction: () => {
              Logic.Common.goBack()
            },
            action: () => {
              //
            },
            extraData: {
              icon: 'success-thumb',
              title: 'Your cashback balance has been topup successfully!',
              large_title: 'Top-up Successful',
              buttons: [
                {
                  label: 'Continue',
                  action: () => {
                    Logic.Common.goBack()
                  },
                },
              ],
            },
          })

          Logic.User.SaveUserActivity(`Fund Cashback Balance`, 'action')
        } else {
          if (this.CheckStatusState.active) {
            this.ResolveFundingAccount(dynamic_funding_account_uuid)
          }
        }
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        Logic.User.SaveUserActivity(
          `Failed - Fund Cashback Balance`,
          'failed_action',
          {
            reason: error.graphQLErrors[0].message,
          },
        )
      })
  }

  public ResolveBankAccount = (formIsValid: boolean) => {
    if (formIsValid && this.ResolveBankAccountForm) {
      return $api.wallet
        .ResolveBankAccount(this.ResolveBankAccountForm)
        .catch(() => {
          // Logic.Common.showError(error, 'Oops!')
        })
    }
  }

  public GenerateWalletAccount = () => {
    if (this.GenerateWalletAccountForm) {
      Logic.Common.showLoader({
        loading: true,
      })
      return $api.wallet
        .GenerateWalletAccount(this.GenerateWalletAccountForm)
        .then((response) => {
          Logic.Auth.GetAuthUser()
          Logic.Common.showSuccess(
            'Your wallet account has been generated.',
            () => {
              Logic.Common.showModal({ show: false })
              Logic.Common.goBack()
            },
          )
          Logic.Common.hideLoader()
          return response.data
        })
        .catch((error: CombinedError) => {
          Logic.Common.showError(error, 'Oops!')
        })
    }
  }

  public SavePaymentTransaction = (isSilent = false) => {
    if (this.SavePaymentTransactionForm) {
      Logic.Common.showLoader({
        loading: true,
      })

      return $api.wallet
        .SavePaymentTransaction(this.SavePaymentTransactionForm)
        .then((response) => {
          Logic.Auth.GetAuthUser()
          if (!isSilent) {
            Logic.Common.showSuccess('Topup Successful', () => {
              Logic.Common.showModal({ show: false })
              Logic.Common.goBack()
            })
          }
          Logic.Common.hideLoader()
          return response.data
        })
        .catch((error: CombinedError) => {
          Logic.Common.showError(error, 'Oops!')
        })
    }
  }

  public CheckTransferStatus = (
    formIsValid: boolean,
    navigateToWallet = true,
  ) => {
    if (formIsValid) {
      this.CheckStatusState.active = true

      $api.wallet
        .CheckTransferStatus({
          existing_transactions: this.UserWallet?.transactions.length || 0,
          account_type: 'client',
        })
        .then((response) => {
          if (response.data?.CheckTransferStatus) {
            this.CheckStatusState.active = false
            Logic.Auth.GetAuthUser()
            Logic.Common.showLoader({
              show: true,
              loading: false,
              useModal: true,
              icon: 'success-thumb',
              title: 'Success',
              message: 'Your Wallet account setup was successful',
              ctaFunction: () => {
                if (navigateToWallet) {
                  Logic.Common.GoToRoute('/wallet')
                  return
                } else {
                  Logic.Common.hideLoader()
                }
              },
              ctaText: 'Got it!',
            })

            Logic.User.SaveUserActivity(`Fund Wallet`, 'action')
          } else {
            if (this.CheckStatusState.active) {
              this.CheckTransferStatus(true, navigateToWallet)
            }
          }
        })
        .catch((error: CombinedError) => {
          Logic.Common.showError(error, 'Oops!')
          Logic.User.SaveUserActivity(`Failed - Fund Wallet`, 'failed_action', {
            reason: error.graphQLErrors[0].message,
          })
        })
    }
  }

  public DeleteCard = (card_uuid: string) => {
    Logic.Common.showLoader({
      loading: true,
    })
    $api.wallet
      .DeleteUserCard(card_uuid)
      .then(() => {
        Logic.Auth.GetAuthUser()
        Logic.User.SaveUserActivity('Deleted debit card', 'action')
      })
      .catch((error: CombinedError) => {
        Logic.Common.showError(error, 'Oops!')
        Logic.User.SaveUserActivity(
          `Failed - Deleted debit card`,
          'failed_action',
          {
            reason: error.graphQLErrors[0].message,
          },
        )
      })
  }

  public ReactToPointEarned = (NewPointEarned: PointEarned) => {
    Logic.Common.hideLoader()
    Logic.Auth.GetAuthUser()
    Logic.Common.clearTimer()
    // @ts-ignore
    clearInterval(window.globalTimer)
    this.NewPointEarned = NewPointEarned
    Logic.Common.GoToRoute(
      `/others/new_point_earned?trigger_confetti=yes&point_earned_uuid=${NewPointEarned.uuid}`,
    )
  }

  // Subscription
  public SubscribeToNewPointGain = () => {
    $api.wallet.SubscribeToNewPointGain(
      (_result: {
        NewPointGain: {
          id: string
          uuid: string
          points: number
          category?: string
          extra_id?: string
        }
      }) => {
        // this.ReactToPointGain(result.NewPointGain)
      },
      () => {
        this.PointGainSubscriptionActive = true
      },
    )
  }
}
