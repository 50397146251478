import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, normalizeClass as _normalizeClass, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "w-full flex flex-row h-full items-center justify-center" }
const _hoisted_2 = ["id"]
const _hoisted_3 = { class: "flex items-center flex-row space-x-3" }
const _hoisted_4 = {
  key: 0,
  class: "flex flex-row items-center justify-center"
}
const _hoisted_5 = { class: "flex flex-row items-center space-x-3 z-50 px-4" }
const _hoisted_6 = {
  key: 1,
  class: "w-full flex flex-row items-end justify-end absolute bottom-0 left-0 z-50 px-4"
}
const _hoisted_7 = { class: "w-[80px]" }
const _hoisted_8 = { class: "flex flex-row space-x-3 items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_web_side_bar = _resolveComponent("app-web-side-bar")!
  const _component_app_icon = _resolveComponent("app-icon")!
  const _component_app_header_text = _resolveComponent("app-header-text")!
  const _component_app_image_loader = _resolveComponent("app-image-loader")!
  const _component_fixed_container = _resolveComponent("fixed-container")!
  const _component_app_board_side_bar = _resolveComponent("app-board-side-bar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(`w-full h-full mdlg:!w-[70%] lg:!w-[65%]  mdlg:!h-screen ${_ctx.parentContClass} lg:text-sm mdlg:text-[12px] text-xs grid grid-cols-12  bg-white dark:!bg-black  !font-inter  relative`),
      style: {"padding-bottom":"calc(env(safe-area-inset-bottom) + 16px) !important"},
      id: `parentContainer-${_ctx.uniqueKey}`
    }, [
      (_openBlock(), _createBlock(_Teleport, {
        to: "#app",
        disabled: _ctx.Logic.Common.currentBuildType() == 'web'
      }, [
        (
            _ctx.showWebSideBar ||
            _ctx.Logic.Common.currentMediaQuery() != 'md' ||
            (_ctx.Logic.Common.currentMediaQuery() == 'md' &&
              _ctx.Logic.Common.currentBuildType() == 'web')
          )
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "hidden col-span-3 mdlg:!flex md:!flex md:!col-span-4 mdlg:!col-span-3 flex-col fixed top-0 h-full z-[999999999] md:!pl-4 bg-white dark:bg-black",
              style: _normalizeStyle(`width: ${_ctx.sideBarConfig.left.width}px; left: ${_ctx.sideBarConfig.left.left}px;`),
              id: `sidbarContainerLeft`
            }, [
              _createVNode(_component_app_web_side_bar, { tabIsActive: _ctx.tabIsActive }, null, 8, ["tabIsActive"])
            ], 4))
          : _createCommentVNode("", true)
      ], 8, ["disabled"])),
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "hidden col-span-3 mdlg:!flex md:!flex md:!col-span-4 mdlg:!col-span-3 flex-col sticky top-0" }, null, -1)),
      _createElementVNode("div", {
        class: _normalizeClass(`col-span-12 mdlg:!col-span-6 md:!col-span-8 flex flex-col mdlg:!border-l-[1px] md:!border-l-[1px]  md:!min-h-screen mdlg:!border-r-[1px] mdlg:!border-grey-100 md:!border-grey-100  mdlg:dark:!border-grey-700 md:dark:!border-grey-700  relative h-full ${_ctx.mainSectionClass}  ${
          _ctx.Logic.Common.currentBuildType() == 'web' ? '' : 'overflow-y-auto'
        }`),
        id: "main-section"
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(` w-full flex-col flex  sticky top-0 z-[99999999]  bg-white dark:bg-black  ${_ctx.topBarStyle} `),
          style: _normalizeStyle(` ${
            _ctx.type == 'normal'
              ? 'padding-top: calc(env(safe-area-inset-top) + 16px) !important;'
              : ''
          }`)
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(`w-full flex flex-row items-center justify-between ${
              _ctx.type == 'normal' ? 'px-4 pb-4' : ''
            }  `)
          }, [
            (_ctx.type == 'normal')
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_app_icon, {
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (
                    _ctx.goBackHandler ? _ctx.goBackHandler() : _ctx.Logic.Common.goBack()
                  )),
                      "custom-class": 'h-[38px] cursor-pointer',
                      name: 
                    _ctx.theme == 'light'
                      ? `${
                          _ctx.Logic.Common.currentAppTheme() == 'dark'
                            ? 'goback-dark-white'
                            : 'back-arrow-grey'
                        }`
                      : _ctx.darkBackIcon
                  
                    }, null, 8, ["name"]),
                    (_ctx.title)
                      ? (_openBlock(), _createBlock(_component_app_header_text, {
                          key: 0,
                          color: 
                    _ctx.theme == 'light'
                      ? 'text-black dark:!text-white'
                      : 'text-white '
                  
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.title), 1)
                          ]),
                          _: 1
                        }, 8, ["color"]))
                      : _createCommentVNode("", true)
                  ]),
                  (_ctx.centerTitle)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        _createVNode(_component_app_header_text, {
                          color: 
                    _ctx.theme == 'light'
                      ? 'text-dark  dark:!text-white'
                      : 'text-white '
                  ,
                          class: "-ml-[3%]"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.centerTitle), 1)
                          ]),
                          _: 1
                        }, 8, ["color"])
                      ]))
                    : _createCommentVNode("", true)
                ], 64))
              : _createCommentVNode("", true),
            (_ctx.type == 'image_card')
              ? (_openBlock(), _createBlock(_component_app_image_loader, {
                  key: 1,
                  customClass: `w-full flex flex-col relative ${_ctx.imageCardStyle}`,
                  customStyle: "padding-top: calc(env(safe-area-inset-top) + 16px) !important; height: calc(env(safe-area-inset-top) + 140px) !important;",
                  photoUrl: _ctx.photoUrl
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_5, [
                      _createElementVNode("div", {
                        class: _normalizeClass(`w-[40px] h-[40px] xs:w-[35px] xs:h-[35px] rounded-full flex flex-row items-center justify-center   bg-[#E36089]  bg-opacity-90  cursor-pointer`),
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.Logic.Common.goBack()))
                      }, [
                        _createVNode(_component_app_icon, {
                          name: 'arrow-left-white',
                          customClass: 'h-[14px]'
                        })
                      ]),
                      (_ctx.title)
                        ? (_openBlock(), _createBlock(_component_app_header_text, {
                            key: 0,
                            color: 'text-white'
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.title), 1)
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _renderSlot(_ctx.$slots, "extra-imagetext"),
                    (_ctx.hasImageBackground)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          class: _normalizeClass(`absolute top-0 left-0 w-full h-full ${_ctx.imageCardStyle}`)
                        }, null, 2))
                      : _createCommentVNode("", true),
                    (_ctx.brandImage)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                          _createElementVNode("div", _hoisted_7, [
                            _createVNode(_component_app_image_loader, {
                              photoUrl: _ctx.brandImage,
                              customClass: "h-[80px] w-[80px] rounded-full bg-white -mb-[40px] border-[3px] border-white"
                            }, null, 8, ["photoUrl"])
                          ]),
                          _renderSlot(_ctx.$slots, "extra-topbar-special")
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 3
                }, 8, ["customClass", "photoUrl"]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_8, [
              (_ctx.hasNotify)
                ? (_openBlock(), _createBlock(_component_app_icon, {
                    key: 0,
                    name: 'notify-circle',
                    customClass: '!h-[38px]'
                  }))
                : _createCommentVNode("", true),
              (_ctx.hasSearch)
                ? (_openBlock(), _createBlock(_component_app_icon, {
                    key: 1,
                    name: 'search-circle',
                    customClass: '!h-[38px]'
                  }))
                : _createCommentVNode("", true),
              (_ctx.hasCart)
                ? (_openBlock(), _createBlock(_component_app_icon, {
                    key: 2,
                    name: 'cart-circle',
                    customClass: '!h-[38px]'
                  }))
                : _createCommentVNode("", true),
              _renderSlot(_ctx.$slots, "extra-topbar")
            ])
          ], 2),
          _renderSlot(_ctx.$slots, "extra-topbar-row")
        ], 6),
        _renderSlot(_ctx.$slots, "default"),
        (_ctx.showBottomPadding)
          ? (_openBlock(), _createBlock(_component_fixed_container, {
              key: 0,
              class: _normalizeClass(`w-full  !bottom-0 !left-0  z-[9999999] ${_ctx.bottomBlankStyle}`),
              style: {"height":"calc(env(safe-area-inset-bottom))"}
            }, null, 8, ["class"]))
          : _createCommentVNode("", true)
      ], 2),
      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "hidden col-span-3 mdlg:!flex flex-col sticky top-0" }, null, -1)),
      _createElementVNode("div", {
        class: "hidden col-span-3 mdlg:!flex flex-col fixed top-0 h-full",
        style: _normalizeStyle(`width: ${_ctx.sideBarConfig.left.width}px; right: ${_ctx.sideBarConfig.left.left}px;`),
        id: `sidbarContainerRight`
      }, [
        _createVNode(_component_app_board_side_bar)
      ], 4)
    ], 10, _hoisted_2)
  ]))
}