import { createApp } from 'vue/dist/vue.esm-bundler.js'
import App from './App.vue'
import { createRouter, createWebHistory } from '@ionic/vue-router'
import routes from './router/routes'
import { createMetaManager } from 'vue-meta'
import { getPlatforms, IonicVue } from '@ionic/vue'
import { App as CapacitorApp, URLOpenListenerEvent } from '@capacitor/app'

// import app layout components
import {
  AppAuthLayout,
  AppDashboardLayout,
  AppOnboardingLayout,
  AppPlainLayout,
  AppSubPageLayout,
} from '@shpt/ui-components'

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css'

// You can disable this if you dont want TailwindCss
import './assets/app.css'

// UI component css style
import '@shpt/ui-components/dist/library.min.css'

import { useRoute, useRouter } from 'vue-router'
import { onMounted, ref } from 'vue'

import { SetFrontendLogic } from '@shpt/ui-components'

// Frontend logic module
import { Logic } from '@shpt/logic'
import { User } from '@shpt/logic/src/gql/graphql'

const router = Promise.all(routes).then((routes) => {
  const router = createRouter({
    history: createWebHistory(),
    routes,
  })

  router.beforeEach((to, from, next) => {
    const toRouter: any = to
    const fromRouter: any = from
    return Logic.Common.preFetchRouteData(toRouter, next, fromRouter)
  })

  return router
})

const init = async () => {
  const app = createApp({
    components: {
      App,
    },
    setup() {
      const router: any = useRouter()
      const route: any = useRoute()

      // Set routers
      Logic.Common.SetRouter(router)
      Logic.Common.SetRoute(route)

      // Set UI frontend logic
      SetFrontendLogic(Logic)

      // Set app version
      localStorage.setItem('app_version', '1.32')

      // Set app url
      Logic.Common.SetApiUrl(
        process.env.VUE_APP_API_URL ? process.env.VUE_APP_API_URL : '',
      )

      const modalSetup = ref(Logic.Common.modalSetup)

      const autoSetup = () => {
        // Update app content
        Logic.User.GetClientAppContent()
        Logic.Common.preloadAudios()
        Logic.Common.setCurrentAccount('client')

        const AuthUser: User | undefined = Logic.Auth.AuthUser

        if (AuthUser == undefined) {
          const routeQuery = window.location.search
          if (!window.location.href.includes('/auth')) {
            if (Logic.Common.currentBuildType() == 'web') {
              Logic.Common.GoToRoute(`/auth/login${routeQuery}`)
            } else {
              Logic.Common.GoToRoute(`/start${routeQuery}`)
            }

            return
          }
        } else {
          Logic.Common.initiateWebSocket()
          Logic.Auth.GetAuthUser()

          if (!window.location.href.includes('/auth')) {
            if (!AuthUser?.profile?.gender) {
              router.push('/auth/personalize')
              return
            }

            if (!AuthUser?.phone_verified_at) {
              router.push('/auth/verify-phone')
              return
            }

            if (Logic.Auth.localSetupIsPending()) {
              router.push('/auth/setup')
              return
            }

            if (Logic.Common.currentAccountType() == 'merchant') {
              if (!window.location.pathname.includes('merchant')) {
                if (Logic.Auth.GetCurrentBusiness()?.phone_number) {
                  Logic.Common.GoToRoute('/merchant/home')
                } else {
                  Logic.Common.GoToRoute('/merchant/onboarding')
                }
              }
            } else {
              if (window.location.pathname.includes('merchant')) {
                Logic.Common.GoToRoute('/')
              }
            }
          }
        }
      }

      onMounted(() => {
        Logic.Common.watchProperty('modalSetup', modalSetup)
        // deep link config
        CapacitorApp.addListener('appUrlOpen', function (
          event: URLOpenListenerEvent,
        ) {
          // Example url: https://beerswift.app/tabs/tabs2
          // slug = /tabs/tabs2
          const domainType = '.com'
          const slug = event.url.split(domainType).pop()

          // We only push to the route if there is a slug present
          if (slug) {
            if (getPlatforms()[0] == 'android') {
              window.location.href = `https://localhost${slug}`
              return
            }
            Logic.Common.GoToRoute(slug)
            return
          }
        })

        autoSetup()
      })

      return {}
    },
  })
    .component('auth-layout', AppAuthLayout)
    .component('dashboard-layout', AppDashboardLayout)
    .component('onboarding-layout', AppOnboardingLayout)
    .component('plain-layout', AppPlainLayout)
    .component('subpage-layout', AppSubPageLayout)

  if (Logic.Common.currentBuildType() == 'mobile') {
    app.use(IonicVue, {
      rippleEffect: false,
      scrollPadding: false,
    })
  }

  app
    .use(await router)
    .use(createMetaManager())
    .mount('#app')
}

init()
