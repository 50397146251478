<template>
  <div
    class="w-full flex flex-col space-y-3 py-4 pt-2 relative md:!pb-0 mdlg:!pb-0"
  >
    <app-normal-text :customClass="'text-left'" class="!text-gray-700">{{
      data.info
    }}</app-normal-text>

    <div class="w-full flex flex-col pt-2">
      <app-button class="w-full py-3" @click="action()">
        {{ data.cta_copy }}
      </app-button>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { AppNormalText, AppHeaderText } from "../AppTypography";
import { Logic } from "../../composable";
import AppButton from "../AppButton";

export default defineComponent({
  components: {
    AppNormalText,
    AppHeaderText,
    AppButton,
  },
  props: {
    data: {
      type: Object as () => {
        info: string;
        cta_copy: string;
      },
    },
    action: {
      type: Function,
    },
  },
  name: "AppAskForPermission",
  setup() {
    return {
      Logic,
    };
  },
});
</script>
