<template>
  <div
    class="w-full flex flex-col space-y-3 pt-2 items-center justify-center relative"
  >
    <!-- Search and add -->
    <div class="w-full flex flex-row space-x-2 items-center">
      <AppTextField :placeholder="`Search products`" v-model="searchQuery">
        <template v-slot:inner-prefix>
          <AppIcon :custom-class="''" :name="'search'"></AppIcon>
        </template>
        <!-- <template v-slot:inner-suffix>
          <AppIcon :custom-class="''" :name="'filter-icon'"></AppIcon>
        </template> -->
      </AppTextField>
    </div>

    <!-- Loading -->
    <div
      class="w-full flex flex-col items-center justify-center h-[300px]"
      v-if="isLoading"
    >
      <svg
        :class="`animate-spin mr-3 h-5 w-5 text-primary-400`"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          class="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          stroke-width="4"
        ></circle>
        <path
          class="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    </div>

    <!-- Products -->
    <div class="w-full grid grid-cols-2 gap-3" v-else>
      <template v-if="products.length > 0">
        <div
          v-for="(product, index) in products"
          :key="index"
          class="col-span-1 flex flex-col"
        >
          <app-product
            @click="toggleAddedProducts(product)"
            :data="product"
            :show-commission="selectedSwitch == 'affiliate_products'"
            :class="` px-3 py-2 rounded-[6px] ${
              selectedProducts.filter((eachItem) => eachItem.id == product.id)
                .length > 0
                ? '!border-primary-300 !border-[2px] '
                : '!border-grey-100 !border-[1px] '
            }`"
          />
        </div>
      </template>

      <div
        v-else
        class="w-full col-span-2 flex flex-col h-[300px] justify-center items-center"
      >
        <app-empty-state
          :title="'No Products Here'"
          :subTitle="'All available products will show up here'"
        >
        </app-empty-state>
      </div>
    </div>

    <!-- Spacer -->
    <div class="h-[50px]"></div>

    <!-- Bottom button -->
    <div
      class="w-full flex flex-row py-4 bg-white dark:bg-black sticky bottom-0 mdlg:!pb-0 md:!pb-0"
    >
      <app-button class="w-full !py-3" @click.prevent="selectProducts()">
        Add Product{{ selectedProducts.length > 1 ? "s" : "" }} ({{
          selectedProducts.length
        }})
      </app-button>
    </div>
  </div>
</template>
<script lang="ts">
import { Logic } from "../../composable";
import { defineComponent, onMounted, reactive, ref, toRef, watch } from "vue";
import AppIcon from "../AppIcon";
import AppSwitch from "../AppSwitch";
import { AppNormalText, AppHeaderText } from "../AppTypography";
import { AppTextField } from "../AppForm";
import AppProduct from "../AppProduct";
import { ShoplistProductPaginator } from "../../gql/graphql";
import AppEmptyState from "../AppEmptyState";
import AppButton from "../AppButton";

interface BaseProduct {
  base_image_url: string;
  title: string;
  price: number;
  resale_commission: string;
  cashback: string;
  available_stock: number;
  merchant: {
    name: string;
    profile_image_url: string;
  };
  id: string;
}

export default defineComponent({
  components: {
    AppIcon,
    AppNormalText,
    AppHeaderText,
    AppSwitch,
    AppTextField,
    AppProduct,
    AppEmptyState,
    AppButton,
  },
  props: {
    data: {
      type: Object as () => {
        selected: {
          id: string;
          image_url: string;
          title: string;
          price: number;
        }[];
      },
    },
    action: {
      type: Function,
      default: () => {},
    },
  },
  setup(props) {
    const searchQuery = ref("");

    const ManyShoplistProducts = ref<ShoplistProductPaginator>();

    const isLoading = ref(false);

    const selectedProducts = ref<
      {
        id: string;
        image_url: string;
        title: string;
        price: number;
      }[]
    >([]);

    const selectedSwitch = ref("your_products");

    const productSwitch = reactive([
      {
        name: "Your Products",
        key: "your_products",
      },
      {
        name: "Affiliate Products",
        key: "affiliate_products",
      },
    ]);

    const products = reactive<BaseProduct[]>([]);

    const setProducts = () => {
      products.length = 0;

      ManyShoplistProducts.value?.data?.forEach((product) => {
        const generalReward = product.rewards.filter(
          (item) => item.quantity == "-1"
        );
        products.push({
          base_image_url: product.primary_image_url,
          title: product.name,
          price: parseFloat(product.regular_price),
          available_stock: product.stock_quantity,
          cashback: generalReward[0]?.percentage,
          merchant: {
            name: product.business?.business_name || "",
            profile_image_url: product.business?.photo_url || "",
          },
          resale_commission: product.affiliate_commission || "",
          id: product.id,
        });
      });
    };

    const getProducts = () => {
      selectedSwitch.value =
        Logic.Auth.GetCurrentBusiness()?.account_type == "individual"
          ? "affiliate_products"
          : "your_products";

      const searchString = searchQuery.value
        ? `AND: {
      column:NAME
      operator: LIKE
      value: "%${searchQuery.value}%"
    }`
        : "";
      if (selectedSwitch.value == "your_products") {
        isLoading.value = true;
        Logic.Shoplist.GetShoplistProducts(
          1,
          15,
          "CREATED_AT",
          "DESC",
          `{
                      column: BUSINESS_PROFILE_ID
                      operator: EQ
                      value: ${Logic.Auth.AuthUser?.profile?.business?.id}
                      ${searchString}
                    }`,
          true
        ).then((data: any) => {
          isLoading.value = false;

          if (data) {
            ManyShoplistProducts.value = data;
            setProducts();
          }
        });
      } else {
        isLoading.value = true;
        Logic.Shoplist.GetShoplistProducts(
          1,
          15,
          "CREATED_AT",
          "DESC",
          `{
                      column: BUSINESS_PROFILE_ID
                      operator: NEQ
                      value: ${Logic.Auth.AuthUser?.profile?.business?.id}
                       ${searchString}
                    }`,
          true,
          "true"
        ).then((data: any) => {
          isLoading.value = false;

          if (data) {
            ManyShoplistProducts.value = data;
            setProducts();
          }
        });
      }
    };

    const toggleAddedProducts = (data: BaseProduct) => {
      if (
        selectedProducts.value.filter((item) => item.id == data.id).length > 0
      ) {
        selectedProducts.value = selectedProducts.value.filter(
          (eachItem) => eachItem.id != data.id
        );
      } else {
        selectedProducts.value.push({
          id: data.id,
          image_url: data.base_image_url,
          title: data.title,
          price: data.price,
        });
      }
    };

    const selectProducts = () => {
      if (props.action) {
        props.action(selectedProducts.value);
      }
    };

    watch(searchQuery, () => {
      Logic.Common.debounce(() => {
        getProducts();
      }, 400);
    });

    watch(selectedSwitch, () => {
      getProducts();
    });

    onMounted(() => {
      selectedProducts.value = props.data?.selected || [];
      getProducts();
    });

    return {
      searchQuery,
      ManyShoplistProducts,
      selectedSwitch,
      productSwitch,
      products,
      isLoading,
      selectedProducts,
      toggleAddedProducts,
      selectProducts,
    };
  },
});
</script>
