<template>
  <Teleport to="#modal">
    <div
      class="w-full h-screen flex flex-col justify-start items-start fixed lg:text-sm mdlg:text-[12px] text-xs bg-black !bg-opacity-30 dark:!bg-opacity-50 !font-inter z-[9999999999999]"
      @click="closeBar()"
    >
      <Transition name="sidebar" @enter="onEnter" @leave="onLeave">
        <div
          class="h-full bg-white flex flex-col w-[80%] xs:!w-[85%] justify-between space-y-3 overflow-y-auto relative dark:bg-black"
          style="
            padding-top: calc(env(safe-area-inset-top) + 16px) !important;
            padding-bottom: calc(env(safe-area-inset-bottom) + 16px) !important;
          "
          v-if="showSideBar"
          @click.stop="null"
        >
          <div class="w-full flex flex-col space-y-3 px-4">
            <!-- Profile section -->
            <div
              class="bg-grey-50 rounded-[10px] dark:!bg-gray-800 px-3 py-3 flex flex-row items-center justify-between sticky top-0"
            >
              <div class="flex flex-row space-x-2 items-center">
                <app-image-loader
                  :photoUrl="
                    Logic.Common.currentAccountType() == 'merchant'
                      ? Logic.Auth.GetCurrentBusiness()?.photo_url
                        ? Logic.Auth.GetCurrentBusiness()?.photo_url
                        : '/images/temp/vendor.svg'
                      : Logic.Auth.AuthUser?.profile?.photo_url
                      ? Logic.Auth.AuthUser?.profile?.photo_url
                      : '/images/avatar-1.png'
                  "
                  :customClass="'!h-[40px] !w-[40px] rounded-full border-[1px] border-grey-200'"
                  @click.stop="goToPath('/profile/user')"
                />

                <div
                  class="flex flex-col space-y-[1px] justify-center items-start"
                >
                  <app-header-text
                    class="!text-sm text-left !line-clamp-1"
                    v-if="Logic.Common.currentAccountType() == 'client'"
                  >
                    {{ Logic.Auth.AuthUser?.first_name }}
                    {{ Logic.Auth.AuthUser?.last_name }}
                  </app-header-text>
                  <app-header-text
                    class="!text-sm text-left !line-clamp-1"
                    v-else
                  >
                    {{ Logic.Auth.GetCurrentBusiness()?.business_name }}
                  </app-header-text>
                  <app-normal-text
                    class="!text-primary-400 dark:!text-primary-100 text-left !text-[11px]"
                    v-if="
                      Logic.Common.currentAccountType() == 'client' ||
                      Logic.Auth.BusinessUserCan('manage_profile')
                    "
                    @click.stop="
                      goToPath(
                        Logic.Common.currentAccountType() == 'merchant'
                          ? '/merchant/profile/edit'
                          : '/profile/user'
                      )
                    "
                  >
                    {{
                      Logic.Common.currentAccountType() == "merchant"
                        ? "Edit Profile"
                        : "View Profile"
                    }}
                  </app-normal-text>
                  <app-normal-text v-else class="!line-clamp-1">
                    {{ Logic.Auth.AuthUser?.full_name }}
                  </app-normal-text>
                </div>
              </div>

              <div
                class="w-[32px] h-[32px] rounded-full bg-white flex items-center justify-center"
                v-if="
                  Logic.Common.currentAccountType() == 'client' ||
                  Logic.Auth.BusinessUserCan('manage_profile')
                "
                @click="
                  goToPath(
                    Logic.Common.currentAccountType() == 'merchant'
                      ? '/merchant/profile/edit'
                      : '/profile/edit'
                  )
                "
              >
                <app-icon :name="'edit-black'" :customClass="'h-[14px]'" />
              </div>
            </div>

            <!-- Side bar navs -->
            <div class="w-full flex flex-col space-y-1">
              <div
                v-for="(item, index) in sideBarItems"
                :key="index"
                class="px-1 py-[8px] flex flex-row justify-between items-center"
                @click.stop="goToPath(item.route_path)"
              >
                <div class="flex flex-row space-x-2 items-center">
                  <div class="w-[24px]">
                    <app-icon
                      :name="`sidebar/${item.icon.name}`"
                      :customClass="item.icon.size"
                    />
                  </div>
                  <app-normal-text class="!text-left">
                    {{ item.name }}
                  </app-normal-text>
                </div>

                <div class="flex flex-row space-x-2 justify-end items-center">
                  <span
                    class="w-[30px] h-[24px] rounded-[30px] bg-secondary-50 dark:!bg-secondary-500 flex items-center justify-center"
                    v-if="item.counter > 0"
                  >
                    <app-normal-text class="!text-[10px] text-secondary-main">
                      {{ item.counter < 99 ? item.counter : "99+" }}
                    </app-normal-text>
                  </span>
                  <app-icon
                    :name="'chevron-right-grey'"
                    :customClass="'h-[12px]'"
                  />
                </div>
              </div>
            </div>

            <!-- Referral box -->
            <div
              class="w-full flex flex-col pt-1"
              v-if="Logic.Common.currentAccountType() != 'merchant'"
            >
              <div
                class="w-full flex flex-col bg-primary-400 rounded-[10px] px-3 py-3 relative"
                @click="goToPath('/others/referral')"
              >
                <img
                  src="/images/referral-coins.svg"
                  class="h-[80%] absolute right-[5%] bottom-0"
                />

                <div class="w-full flex flex-col space-y-[2px]">
                  <app-normal-text class="!text-left !text-white !text-[10px]">
                    Refer Friends, Earn more
                  </app-normal-text>
                  <app-normal-text
                    class="!font-semibold !text-white text-left !text-sm"
                  >
                    Get 10% Bonus
                  </app-normal-text>
                </div>

                <div class="w-full flex flex-row pt-3">
                  <div
                    class="px-3 py-[2px] bg-primary-800 rounded-[30px] flex items-center justify-center"
                  >
                    <app-normal-text class="!text-white !text-[11px]">
                      Refer & Earn
                    </app-normal-text>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            class="w-full flex flex-col space-y-2 bg-white dark:!bg-black px-4 sticky bottom-0 pt-3 justify-center items-center"
          >
            <!-- <div
              class="w-full flex flex-row items-center justify-center px-3 py-3 bg-primary-50 dark:!bg-primary-500 rounded-[999px] cursor-pointer"
              @click.stop="toggleUserAccount()"
             >
              <app-normal-text
                class="!text-primary-400 dark:!text-primary-100"
                v-if="Logic.Auth.GetCurrentBusiness()?.phone_number"
              >
                Switch to
                {{
                  Logic.Common.currentAccountType() == "client"
                    ? "Shop Account"
                    : "Personal Account"
                }}
              </app-normal-text>
              <app-normal-text class="!text-primary-400" v-else>
                <template v-if="Logic.Common.currentAccountType() == 'merchant'">
                  Switch to personal account
                </template>
                <template v-else> Create a shop account </template>
              </app-normal-text>
            </div> -->

            <app-normal-text
              class="!text-red-500 w-full text-center"
              @click.stop="Logic.Auth.SignOut()"
            >
              Logout
            </app-normal-text>
          </div>
        </div>
      </Transition>
    </div>
  </Teleport>
</template>
<script lang="ts">
import { onMounted, ref, defineComponent, watch } from "vue";
import AppIcon from "../AppIcon";
import { AppNormalText, AppHeaderText } from "../AppTypography";
import AppImageLoader from "../AppImageLoader";
import { Logic } from "../../composable";

export default defineComponent({
  components: {
    AppIcon,
    AppNormalText,
    AppHeaderText,
    AppImageLoader,
  },
  props: {
    accountType: {
      type: String,
      default: "",
    },
    closeBar: {
      type: Function,
      default: () => {},
    },
  },
  name: "AppSideBar",
  setup(props) {
    const hoverTab = ref("");

    const UnreadNotification = ref(Logic.Notification.UnreadNotification);
    const UnClaimedPointEarnedCount = ref(Logic.User.UnClaimedPointEarnedCount);

    const sideBarItems = ref<
      {
        name: string;
        icon: {
          name: string;
          size: string;
        };
        counter: number;
        route_path: string;
      }[]
    >(Logic.Common.sideBarItems);

    const showSideBar = ref(false);

    const goToPath = (path: string) => {
      props.closeBar();
      Logic.Common.GoToRoute(path);
    };

    const onEnter = (el: any) => {
      el.style.transform = `translateX(-${window.innerWidth}px)`;
      el.offsetHeight; // Trigger a reflow
      el.style.transition = "transform 0.5s";
      el.style.transform = `translateX(0)`;
    };

    const onLeave = (el: any) => {
      el.style.transform = `translateX(0)`;
      el.offsetHeight; // Trigger a reflow
      el.style.transition = "transform 0.5s";
      el.style.transform = `translateX(-${window.innerWidth}px)`;
    };

    const toggleUserAccount = () => {
      if (Logic.Common.currentAccountType() == "client") {
        Logic.Common.setCurrentAccount("merchant");
      } else {
        Logic.Common.setCurrentAccount("client");
      }
      window.location.reload();
    };

    const setSideBarCount = () => {
      if (sideBarItems.value.length > 0) {
        sideBarItems.value.forEach((item) => {
          if (item.name == "Notifications") {
            item.counter = UnreadNotification.value || 0;
          }
          if (item.name == "Cashback History") {
            item.counter = UnClaimedPointEarnedCount.value || 0;
          }
        });

        if (Logic.Common.currentAccountType() == "merchant") {
          if (!Logic.Auth.AuthUser?.profile?.business) {
            sideBarItems.value = sideBarItems.value.filter((item) => {
              return item.icon.name != "merchant-admin";
            });
          }

          if (!Logic.Auth.BusinessUserCan("manage_settlement_accounts")) {
            sideBarItems.value = sideBarItems.value.filter((item) => {
              return item.icon.name != "expense-tracker";
            });
          }

          if (!Logic.Auth.BusinessUserCan("manage_store_branches")) {
            sideBarItems.value = sideBarItems.value.filter((item) => {
              return item.icon.name != "delivery-area-icon";
            });
          }

          if (!Logic.Auth.BusinessUserCan("manage_offline_payments")) {
            sideBarItems.value = sideBarItems.value.filter((item) => {
              return item.icon.name != "accept-payment";
            });
          }
        }
      }
    };

    watch([UnreadNotification, UnClaimedPointEarnedCount], () => {
      setSideBarCount();
    });

    onMounted(() => {
      Logic.Notification.watchProperty(
        "UnreadNotification",
        UnreadNotification
      );
      Logic.User.watchProperty(
        "UnClaimedPointEarnedCount",
        UnClaimedPointEarnedCount
      );
      showSideBar.value = true;
      setSideBarCount();
    });

    return {
      hoverTab,
      sideBarItems,
      Logic,
      showSideBar,
      UnreadNotification,
      goToPath,
      onEnter,
      onLeave,
      toggleUserAccount,
    };
  },
});
</script>
