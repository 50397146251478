<template>
  <div class="w-[165px] flex flex-col space-y-2 !cursor-pointer" v-if="item">
    <app-normal-text
      class="w-full !text-left !font-light !text-[#313131] dark:!text-grey-200 !text-[12px]"
    >
      {{ item.data.main_category }}
    </app-normal-text>
    <div class="w-full flex flex-col">
      <app-media-box
        :data="item.data"
        :isVideo="!item.data.is_grid"
        class="!h-[160px] !rounded-t-[7.9px] relative"
      >
        <div
          class="absolute bottom-0 left-0 h-[10px] w-full bg-gray-900"
          :style="`background-color: ${item.colors.bg} !important;`"
        ></div>
      </app-media-box>
      <div
        :class="`w-full flex flex-col px-3 py-3 !pt-0 left-0 space-y-2 bg-gray-900 rounded-b-[7.9px]`"
        :style="`background-color: ${item.colors.bg} !important;`"
      >
        <app-normal-text
          class="!text-left !text-[11px] mdlg:!text-[12px] !line-clamp-1 w-full !text-white"
        >
          {{ item.data.title }}
        </app-normal-text>
        <div class="flex flex-row justify-between items-center">
          <app-normal-text class="!text-[12px] !text-white">
            {{
              Logic.Common.convertToMoney(
                parseFloat(item.data.price?.toFixed(2) || "0"),
                false,
                "ngn"
              )
            }}
          </app-normal-text>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { MediaBox } from "../../types";
import { defineComponent } from "vue";
import { AppNormalText } from "../AppTypography";
import AppIcon from "../AppIcon";
import AppMediaBox from "../AppMediaBox";
import { Logic, numberToAbbrev } from "../../composable";

export default defineComponent({
  components: {
    AppNormalText,
    AppIcon,
    AppMediaBox,
  },
  props: {
    item: {
      type: Object as () => {
        data: MediaBox;
        id: string;
        colors: {
          bg: string;
          text: string;
        };
      },
    },
  },
  setup() {
    return {
      numberToAbbrev,
      Logic,
    };
  },
  name: "AppShoplistRecommended",
});
</script>
