import {
  BusinessPaginator,
  Category,
  Collection,
  CollectionPaginator,
  Favorite,
  InstagramMediaData,
  MutationAddRemoveCollectionProductArgs,
  MutationCheckIfMediaAccountIsConnectedArgs,
  MutationCheckIfProductIsAvailableInAreaArgs,
  MutationCreateShoplistCollectionArgs,
  MutationCreateUserDeliveryLocationArgs,
  MutationLikeUnlikeCollectionArgs,
  MutationLikeUnlikeProductArgs,
  MutationRequestShipmentRateArgs,
  MutationUpdateShoplistCollectionArgs,
  PackageDimension,
  ProductDeliveryLocationResponse,
  ShippingCategory,
  ShippingRateResponse,
  ShoplistCart,
} from './../gql/graphql'
import { OperationResult } from 'urql'
import { BaseApiService } from './common/BaseService'
import {
  CategoryPaginator,
  DeliveryLocationPaginator,
  MutationCreateBusinessDeliveryAreasArgs,
  MutationCreateShoplistProductArgs,
  MutationCreateShoplistProductRewardArgs,
  MutationUpdateDeliveryLocationArgs,
  MutationUpdateShoplistProductArgs,
  MutationUpdateShoplistProductRewardArgs,
  ShoplistProduct,
  ShoplistProductPaginator,
  ShoplistReward,
  SupportedArea,
} from '../gql/graphql'

export default class ShoplistApi extends BaseApiService {
  public GetShoplistProductCategories = (
    page: number,
    count: number,
    orderType = 'CREATED_AT',
    order: 'ASC' | 'DESC',
    whereQuery = '',
  ) => {
    const requestData = `
            query GetShoplistProductCategories($page: Int!, $count: Int!){
                GetShoplistProductCategories(first: $count, page: $page,
                    orderBy: {column: ${
                      orderType ? orderType : 'CREATED_AT'
                    }, order: ${order}}
                       ${whereQuery ? `where: ${whereQuery}` : ''} 
                       ){
                  paginatorInfo{
                    count
                    currentPage
                    firstItem
                    hasMorePages
                    lastItem
                    lastPage
                    total
                    lastPage
                  }
                  data{
                    id
                    uuid
                    name
                    parent_category_id
                  }
                }
              }
                    `

    const response: Promise<OperationResult<{
      GetShoplistProductCategories: CategoryPaginator
    }>> = this.query(requestData, {
      page,
      count,
    })

    return response
  }

  public GetCategoriesWithProducts = () => {
    const requestData = `
            query GetCategoriesWithProducts {
                GetCategoriesWithProducts {
                    id
                    uuid
                    name
                }
            }
            `

    const response: Promise<OperationResult<{
      GetCategoriesWithProducts: Category[]
    }>> = this.query(requestData, {})

    return response
  }

  public GetUserCart = () => {
    const requestData = `
            query GetCart{
                GetCart{
                    id
                    collection {
                      id
                      uuid
                      name
                      base_image_url
                      media_type
                      description
                      expires_at
                      status
                      media {
                        media_url
                        percentage 
                      }
                      products {
                        id
                        uuid
                        name
                        description
                        category {
                          parent_category_id
                          name
                        }
                        rewards {
                          id
                          type
                          percentage
                          quantity
                        }
                        business {
                          id
                          business_tag
                          business_name
                          uuid
                        }
                        regular_price
                        primary_image_url
                        stock_quantity
                        status
                      }
                      business {
                        id
                        uuid
                        business_name
                        business_tag
                        photo_url
                      }
                    }
                }
            }
                    `

    const response: Promise<OperationResult<{
      GetCart: ShoplistCart[]
    }>> = this.query(requestData, {})

    return response
  }

  public GetSupportedAreas = () => {
    const requestData = `
          query GetSupportedAreas {
          GetSupportedAreas {
            id
            uuid
            state_name
            area_name
          }
        }
            `

    const response: Promise<OperationResult<{
      GetSupportedAreas: SupportedArea[]
    }>> = this.query(requestData, {})

    return response
  }

  public GetPackageDimension = () => {
    const requestData = `
          query GetPackageDimension {
          GetPackageDimension {
            name
            description_image_url
            height
            width
            length
            max_weight
          }
        }
            `

    const response: Promise<OperationResult<{
      GetPackageDimension: PackageDimension[]
    }>> = this.query(requestData, {})

    return response
  }

  public GetShippingCategory = () => {
    const requestData = `
          query GetShippingCategory {
          GetShippingCategory {
            category_id
            category
          }
        }
            `

    const response: Promise<OperationResult<{
      GetShippingCategory: ShippingCategory[]
    }>> = this.query(requestData, {})

    return response
  }

  public GetInstagramAuthUrl = () => {
    const requestData = `
          query GetInstagramAuthUrl {
          GetInstagramAuthUrl
        }
            `
    const response: Promise<OperationResult<{
      GetInstagramAuthUrl: string
    }>> = this.query(requestData, {})

    return response
  }

  public GetUserInstagramMedia = (after: string, before: string) => {
    const requestData = `
          query GetUserInstagramMedia($after: String, $before: String) {
          GetUserInstagramMedia(after: $after, before: $before) {
            paging {
             cursors {
               before
               after
             }
             next
            }
            list {
              media_thumbnail
              media_id
              media_title
              media_url
              type
              isImported
            }
          }
        }
            `

    const response: Promise<OperationResult<{
      GetUserInstagramMedia: InstagramMediaData
    }>> = this.query(requestData, {
      after,
      before,
    })

    return response
  }

  public GetDeliveryLocations = (
    page: number,
    count: number,
    orderType = 'CREATED_AT',
    order: 'ASC' | 'DESC',
    whereQuery = '',
  ) => {
    const requestData = `
            query GetDeliveryLocation($page: Int!, $count: Int!){
                GetDeliveryLocation(first: $count, page: $page,
                    orderBy: {column: ${
                      orderType ? orderType : 'CREATED_AT'
                    }, order: ${order}}
                       ${whereQuery ? `where: ${whereQuery}` : ''} 
                       ){
                  paginatorInfo{
                    count
                    currentPage
                    firstItem
                    hasMorePages
                    lastItem
                    lastPage
                    total
                    lastPage
                  }
                  data{
                    id
                    uuid
                    supported_area {
                      id
                      state_name
                      area_name
                    }
                    business_admin {
                        user {
                          full_name
                        }
                    }
                    user_name
                    user_email
                    address_code
                    title
                    fixed_cost
                    address
                    phone
                    status
                    default
                    lng
                    lat
                  }
                }
              }
                    `

    const response: Promise<OperationResult<{
      GetDeliveryLocation: DeliveryLocationPaginator
    }>> = this.query(requestData, {
      page,
      count,
    })

    return response
  }

  public GetShoplistProducts = (
    page: number,
    count: number,
    orderType = 'CREATED_AT',
    order: 'ASC' | 'DESC',
    whereQuery = '',
    affiliateable = '',
    hasCategory = '',
  ) => {
    const requestData = `
            query GetShoplistProducts($page: Int!, $count: Int!){
                GetShoplistProducts(first: $count, page: $page,
                    ${affiliateable ? `affiliateable: ${true}` : ''}
                    orderBy: {column: ${
                      orderType ? orderType : 'CREATED_AT'
                    }, order: ${order}}
                       ${whereQuery ? `where: ${whereQuery}` : ''} 
                       ${hasCategory ? `hasCategory: ${hasCategory}` : ''}
                       ){
                  paginatorInfo{
                    count
                    currentPage
                    firstItem
                    hasMorePages
                    lastItem
                    lastPage
                    total
                    lastPage
                  }
                  data{
                    id
                    uuid
                    name
                    description
                    weight
                    category {
                      parent_category_id  
                      name
                      id
                    }
                    rewards {
                      id
                      uuid
                      type
                      percentage
                      quantity
                    }
                    business {
                      id
                      business_name
                      business_tag
                      photo_url
                    }
                    delivery_areas {
                      id
                      delivery_locations_id
                    }
                    regular_price
                    primary_image_url
                    stock_quantity
                    affiliateable
                    affiliate_commission
                    deliverable
                    status
                    created_at
                    updated_at
                  }
                }
              }
                    `

    const response: Promise<OperationResult<{
      GetShoplistProducts: ShoplistProductPaginator
    }>> = this.query(requestData, {
      page,
      count,
    })

    return response
  }

  public GetShoplistCollections = (
    page: number,
    count: number,
    orderType = 'CREATED_AT',
    order: 'ASC' | 'DESC',
    whereQuery = '',
    hasBusiness = '',
    hasProfile = '',
  ) => {
    const requestData = `
            query GetShoplistCollections($page: Int!, $count: Int!){
                GetShoplistCollections(first: $count, page: $page,
                    orderBy: {column: ${
                      orderType ? orderType : 'CREATED_AT'
                    }, order: ${order}}
                       ${whereQuery ? `where: ${whereQuery}` : ''} 
                       ${hasBusiness ? `hasBusiness: ${hasBusiness}` : ''}
                       ${hasProfile ? `hasProfile: ${hasProfile}` : ''}
                       ){
                  paginatorInfo{
                    count
                    currentPage
                    firstItem
                    hasMorePages
                    lastItem
                    lastPage
                    total
                    lastPage
                  }
                  data{
                      id
                      uuid
                      name
                      base_image_url
                      favorites_count
                      user_is_favorite
                      media_type
                      description
                      expires_at
                      shipping_category {
                        category_id
                        category
                      }
                      dimension_size {
                        name
                        description_image_url
                        height
                        width
                        length
                        max_weight
                      }
                      status
                      media {
                        media_url
                        percentage 
                      }
                      products {
                        id
                        uuid
                        name
                        description
                        category {
                          id
                          parent_category_id
                          name
                        }
                        rewards {
                          id
                          type
                          percentage
                          quantity
                        }
                        business {
                          id
                          business_tag
                          business_name
                          uuid
                        }
                        regular_price
                        primary_image_url
                        stock_quantity
                        status
                      }
                      business {
                        id
                        uuid
                        business_name
                        business_tag
                        photo_url
                      }
                   }
                 }
               }
                    `

    const response: Promise<OperationResult<{
      GetShoplistCollections: CollectionPaginator
    }>> = this.query(requestData, {
      page,
      count,
    })

    return response
  }

  public GetShoplistProduct = (uuid: string) => {
    const requestData = `
    query GetShoplistProduct($uuid: String!) {
      GetShoplistProduct(
        uuid: $uuid
      ) {
        id
        uuid
        name
        description
        weight
        category {
          parent_category_id
          name
          id
        }
        rewards {
          id
          uuid
          type
          percentage
          quantity
        }
        business {
          id
          business_name
          business_tag
          photo_url
        }
        delivery_areas {
          id
          delivery_locations_id
        }
        regular_price
        primary_image_url
        stock_quantity
        affiliateable
        affiliate_commission
        deliverable
        status
        created_at
        updated_at
      }
    }`

    const response: Promise<OperationResult<{
      GetShoplistProduct: ShoplistProduct
    }>> = this.query(requestData, {
      uuid,
    })

    return response
  }

  public GetSimilarProducts = (product_uuid: string) => {
    const requestData = `
    query GetSimilarProducts($product_uuid: String!) {
      GetSimilarProducts(
        product_uuid: $product_uuid
      ) {
       id
        uuid
        name
        description
        weight
        category {
          parent_category_id
          name
          id
        }
        rewards {
          id
          uuid
          type
          percentage
          quantity
        }
        business {
          id
          business_name
          business_tag
          photo_url
        }
        delivery_areas {
          id
          delivery_locations_id
        }
        regular_price
        primary_image_url
        stock_quantity
        affiliateable
        affiliate_commission
        deliverable
        status
        created_at
        updated_at
      }
    }`

    const response: Promise<OperationResult<{
      GetSimilarProducts: ShoplistProduct[]
    }>> = this.query(requestData, {
      product_uuid,
    })

    return response
  }

  public GetShoplistCollection = (uuid: string) => {
    const requestData = `
    query GetShoplistCollection($uuid: String!) {
      GetShoplistCollection(
        uuid: $uuid
      ) {
        id
        uuid
        name
        base_image_url
        user_is_favorite
        favorites_count
        media_type
        description
        expires_at
        status
        shipping_category {
             category_id
             category
        }
        dimension_size {
            name
            description_image_url
            height
            width
            length
            max_weight
          }
        media {
            media_url
            percentage 
          }
        products {
          id
          uuid
          name
          description
          category {
            id
            parent_category_id
            name
          }
          rewards {
            id
            type
            percentage
            quantity
          }
          business {
            id
            business_tag
            business_name
            uuid
          }
          regular_price
          primary_image_url
          stock_quantity
          status
        }
        business {
          id
          uuid
          business_name
          business_tag
          photo_url
        }
      }
    }`

    const response: Promise<OperationResult<{
      GetShoplistCollection: Collection
    }>> = this.query(requestData, {
      uuid,
    })

    return response
  }

  public CreateBusinessDeliveryAreas = (
    data: MutationCreateBusinessDeliveryAreasArgs,
  ) => {
    const requestData = `
    mutation CreateBusinessDeliveryAreas(
        $title: String!
        $address: String!
        $phone: String!
        $user_name: String!
        $user_email: String!
        $business_admin_id: Int!
        ) {
        CreateBusinessDeliveryAreas(title: $title, address: $address, phone: $phone,  user_name: $user_name, user_email: $user_email, business_admin_id: $business_admin_id)
      }
    `
    const response: Promise<OperationResult<{
      CreateBusinessDeliveryAreas: Boolean
    }>> = this.mutation(requestData, data)

    return response
  }

  public CheckIfMediaAccountIsConnected = (
    data: MutationCheckIfMediaAccountIsConnectedArgs,
  ) => {
    const requestData = `
    mutation CheckIfMediaAccountIsConnected($business_user_id: Int!, $account_type: String!) {
      CheckIfMediaAccountIsConnected(business_user_id: $business_user_id, account_type: $account_type)
    }
    `
    const response: Promise<OperationResult<{
      CheckIfMediaAccountIsConnected: boolean
    }>> = this.mutation(requestData, data)

    return response
  }

  public LikeUnlikeCollection = (data: MutationLikeUnlikeCollectionArgs) => {
    const requestData = `
    mutation LikeUnlikeCollection($content: FavoriteType, $collection_uuid: String) {
      LikeUnlikeCollection(content: $content, collection_uuid: $collection_uuid) {
        id
        uuid
      }
  }`

    const response: Promise<OperationResult<{
      LikeUnlikeCollection: Favorite
    }>> = this.mutation(requestData, data)

    return response
  }

  public RequestShipmentRate = (data: MutationRequestShipmentRateArgs) => {
    const requestData = `
   mutation RequestShipmentRate($input: ShippingRateInput!) {
        RequestShipmentRate(input: $input) {
          business_id
          shipping_rate {
            request_token
            couriers {
              courier_id
              courier_name
              courier_image
              service_code
              pickup_eta
              pickup_eta_time
              delivery_eta
              pickup_eta_time
              info
              currency
              ratings
              total
            }
            fastest_courier {
              courier_id
              courier_name
              courier_image
              service_code
              pickup_eta
              pickup_eta_time
              delivery_eta
              pickup_eta_time
              info
              currency
              ratings
              total
            }
            cheapest_courier {
              courier_id
              courier_name
              courier_image
              service_code
              pickup_eta
              pickup_eta_time
              delivery_eta
              pickup_eta_time
              info
              currency
              ratings
              total
            }
            checkout_data {
              pickup_time
              package_amount
              package_weight
            }
          }
        }
      }
    `
    const response: Promise<OperationResult<{
      RequestShipmentRate: ShippingRateResponse[]
    }>> = this.mutation(requestData, data)

    return response
  }

  public CreateUserDeliveryLocation = (
    data: MutationCreateUserDeliveryLocationArgs,
  ) => {
    const requestData = `
    mutation CreateUserDeliveryLocation(
          $title: String!
          $address: String!
          $phone: String!
          $user_name: String!
          $user_email: String!
          $default: Boolean!) {
        CreateUserDeliveryLocation(
        title: $title
        address: $address
        phone: $phone
        user_name: $user_name
        user_email: $user_email
        default: $default
        )
      }
    `
    const response: Promise<OperationResult<{
      CreateUserDeliveryLocation: Boolean
    }>> = this.mutation(requestData, data)

    return response
  }

  public CheckIfProductIsDeliverable = (
    data: MutationCheckIfProductIsAvailableInAreaArgs,
  ) => {
    const requestData = `
    mutation CheckIfProductIsAvailableInArea($product_ids: [Int!]!, $user_location: LocationInput!) {
        CheckIfProductIsAvailableInArea(product_ids: $product_ids, user_location: $user_location) {
          product_id
          delivery_location_id
          cost
        }
      }
    `
    const response: Promise<OperationResult<{
      CheckIfProductIsAvailableInArea: ProductDeliveryLocationResponse[]
    }>> = this.mutation(requestData, data)

    return response
  }

  public SearchCollections(query: string, page: number, count: number) {
    const requestData = `
    query SearchCollections($query: String!, $page: Int!, $count: Int!) {
      SearchCollections(query: $query, first: $count, page: $page) {
        paginatorInfo {
          count
          currentPage
          firstItem
          hasMorePages
          lastItem
          lastPage
          total
          lastPage
        }
        data {
          id
          uuid
          name
          base_image_url
          favorites_count
          user_is_favorite
          media_type
          description
          expires_at
          shipping_category {
            category_id
            category
          }
          dimension_size {
            name
            description_image_url
            height
            width
            length
            max_weight
          }
          status
          media {
            media_url
            percentage
          }
          products {
            id
            uuid
            name
            description
            category {
              id
              parent_category_id
              name
            }
            rewards {
              id
              type
              percentage
              quantity
            }
            business {
              id
              business_tag
              business_name
              uuid
            }
            regular_price
            primary_image_url
            stock_quantity
            status
          }
          business {
            id
            uuid
            business_name
            business_tag
            photo_url
          }
        }
      }
    }
    `

    const response: Promise<OperationResult<{
      SearchCollections: CollectionPaginator
    }>> = this.query(requestData, {
      page,
      count,
      query,
    })

    return response
  }

  public SearchProducts = (query: string, page: number, count: number) => {
    const requestData = `
    query SearchShoplistProducts($query: String!, $page: Int!, $count: Int!) {
      SearchShoplistProducts(query: $query, first: $count, page: $page) {
        paginatorInfo {
          count
          currentPage
          firstItem
          hasMorePages
          lastItem
          lastPage
          total
        }
        data {
          id
          uuid
          name
          description
          primary_image_url
          stock_quantity
          status
          category {
            id
            parent_category_id
            name
          }
          rewards {
            id
            uuid
            type
            percentage
            quantity  
          }
          business {
            id
            business_tag
            business_name
            photo_url 
            uuid
          }
          regular_price
        }
      }
    }
    `
    const response: Promise<OperationResult<{
      SearchShoplistProducts: ShoplistProductPaginator
    }>> = this.query(requestData, {
      page,
      count,
      query,
    })
    return response
  }

  public SearchBrands = (query: string, page: number, count: number) => {
    const requestData = `
    query SearchBrands($query: String!, $page: Int!, $count: Int!) {
      SearchBrands(query: $query, first: $count, page: $page) {
        paginatorInfo {
          count
          currentPage
          firstItem
          hasMorePages
          lastItem
          lastPage
          total
        }
        data {
          id
          uuid
          business_name
          business_tag
          photo_url
        }
      }
    }
    `
    const response: Promise<OperationResult<{
      SearchBrands: BusinessPaginator
    }>> = this.query(requestData, {
      page,
      count,
      query,
    })
    return response
  }

  public UpdateDeliveryLocation = (
    data: MutationUpdateDeliveryLocationArgs,
  ) => {
    const requestData = `
    mutation UpdateDeliveryLocation(
       $is_business: Boolean!
        $delivery_location_id: String!
        $cost: String
        $status: String
        $title: String
        $address: String
        $phone: String
        $lng: String
        $lat: String
        $default: Boolean
        $user_name: String
        $user_email: String
        $business_admin_id: Int
    ) {
        UpdateDeliveryLocation(
         is_business: $is_business
        delivery_location_id: $delivery_location_id
        cost: $cost
        status: $status
        title: $title
        address: $address
        phone: $phone
        lng: $lng
        lat: $lat
        default: $default
        user_name: $user_name
        user_email: $user_email
        business_admin_id: $business_admin_id
        )
      }
    `
    const response: Promise<OperationResult<{
      UpdateDeliveryLocation: Boolean
    }>> = this.mutation(requestData, data)

    return response
  }

  public DeleteDeliveryLocation = (delivery_location_id: string) => {
    const requestData = `
    mutation DeleteDeliveryLocation($delivery_location_id: String!) {
        DeleteDeliveryLocation(delivery_location_id: $delivery_location_id)
      }
    `
    const response: Promise<OperationResult<{
      DeleteDeliveryLocation: Boolean
    }>> = this.mutation(requestData, { delivery_location_id })

    return response
  }

  public CreateUserShoplistCart = (collection_id: string) => {
    const requestData = `
    mutation CreateUserShoplistCart($collection_id: String) {
        CreateUserShoplistCart(collection_id: $collection_id) {
                    id
                    collection {
                      id
                      uuid
                      name
                      base_image_url
                      media_type
                      description
                      expires_at
                      status
                      media {
                        media_url
                        percentage 
                      }
                      products {
                        id
                        uuid
                        name
                        description
                        category {
                          id
                          parent_category_id
                          name
                        }
                        rewards {
                          id
                          type
                          percentage
                          quantity
                        }
                        business {
                          id
                          business_tag
                          business_name
                          uuid
                        }
                        regular_price
                        primary_image_url
                        stock_quantity
                        status
                      }
                      business {
                        id
                        uuid
                        business_name
                        business_tag
                        photo_url
                      }
                    }
         }
      }
    `
    const response: Promise<OperationResult<{
      CreateUserShoplistCart: ShoplistCart
    }>> = this.mutation(requestData, { collection_id })

    return response
  }

  public DeleteUserShoplistCart = (cart_id: string) => {
    const requestData = `
    mutation DeleteUserShoplistCart($cart_id: String!) {
        DeleteUserShoplistCart(cart_id: $cart_id)
      }
    `
    const response: Promise<OperationResult<{
      DeleteUserShoplistCart: Boolean
    }>> = this.mutation(requestData, { cart_id })

    return response
  }

  public CreateShoplistProduct = (data: MutationCreateShoplistProductArgs) => {
    const requestData = `
   mutation CreateShoplistProduct($name: String!, $description: String, $category_id: Int!, $regular_price: String!, $primary_image: Upload!, $stock_quantity: Int!, $affiliateable: Boolean!, $affiliate_commission: String!, $deliverable: Boolean!, $reward_settings: [ProductRewardSettings!]!, $delivery_areas: [String!]!, $weight: String!) {
        CreateShoplistProduct(
          name: $name
          description: $description
          category_id: $category_id
          regular_price: $regular_price
          primary_image: $primary_image
          stock_quantity: $stock_quantity
          affiliateable: $affiliateable
          affiliate_commission: $affiliate_commission
          deliverable: $deliverable
          reward_settings: $reward_settings
          delivery_areas: $delivery_areas
          weight: $weight
        ) {
          id
          uuid
          name
          description
          weight
          category {
            parent_category_id
            name
            id
          }
          rewards {
            id
            uuid
            type
            percentage
            quantity
          }
          business {
            id
            business_name
            business_tag
            photo_url
          }
          regular_price
          primary_image_url
          stock_quantity
          affiliateable
          affiliate_commission
          deliverable
          status
          created_at
          updated_at
        }
      }
    `
    const response: Promise<OperationResult<{
      CreateShoplistProduct: ShoplistProduct
    }>> = this.mutation(requestData, data)

    return response
  }

  public UpdateShoplistProduct = (data: MutationUpdateShoplistProductArgs) => {
    const requestData = `
   mutation UpdateShoplistProduct($product_id: Int!, $name: String, $description: String, $category_id: Int, $regular_price: String, $primary_image: Upload, $stock_quantity: Int, $affiliateable: Boolean, $affiliate_commission: String, $deliverable: Boolean, $delivery_areas: [String!], $weight: String) {
        UpdateShoplistProduct(
          product_id: $product_id
          name: $name
          description: $description
          category_id: $category_id
          regular_price: $regular_price
          primary_image: $primary_image
          stock_quantity: $stock_quantity
          affiliateable: $affiliateable
          affiliate_commission: $affiliate_commission
          deliverable: $deliverable
          delivery_areas: $delivery_areas
          weight: $weight
        ) {
          id
          uuid
          name
          description
          weight
          category {
            parent_category_id
            name
            id
          }
          rewards {
            id
            uuid
            type
            percentage
            quantity
          }
          business {
            id
            business_name
            business_tag
            photo_url
          }
          regular_price
          primary_image_url
          stock_quantity
          affiliateable
          affiliate_commission
          deliverable
          status
          created_at
          updated_at
        }
      }
    `
    const response: Promise<OperationResult<{
      UpdateShoplistProduct: ShoplistProduct
    }>> = this.mutation(requestData, data)

    return response
  }

  public CreateShoplistProductReward = (
    data: MutationCreateShoplistProductRewardArgs,
  ) => {
    const requestData = `
    mutation CreateShoplistProductReward($product_id: Int!, $type: RewardType, $percentage: String, $quantity: String) {
      CreateShoplistProductReward(
        product_id: $product_id
        type: $type
        percentage: $percentage
        quantity: $quantity
      ) {
        id
        uuid
        type
        percentage
        quantity
      }
    }
    `
    const response: Promise<OperationResult<{
      CreateShoplistProductReward: ShoplistReward
    }>> = this.mutation(requestData, data)

    return response
  }

  public UpdateShoplistProductReward = (
    data: MutationUpdateShoplistProductRewardArgs,
  ) => {
    const requestData = `
    mutation UpdateShoplistProductReward($reward_id: Int!, $type: RewardType, $percentage: String, $quantity: String) {
      UpdateShoplistProductReward(
        reward_id: $reward_id
        type: $type
        percentage: $percentage
        quantity: $quantity
      ) {
        id
        uuid
        type
        percentage
        quantity
      }
    }
    `
    const response: Promise<OperationResult<{
      UpdateShoplistProductReward: ShoplistReward
    }>> = this.mutation(requestData, data)

    return response
  }

  public LikeUnlikeProduct = (data: MutationLikeUnlikeProductArgs) => {
    const requestData = `
    mutation LikeUnlikeProduct($content: FavoriteType!, $product_uuid: String!) {
      LikeUnlikeProduct(content: $content, product_uuid: $product_uuid) 
    }
    `
    const response: Promise<OperationResult<{
      LikeUnlikeProduct: Boolean
    }>> = this.mutation(requestData, data)

    return response
  }

  public CreateShoplistCollection = (
    data: MutationCreateShoplistCollectionArgs,
    progressCb: (progress: number) => void,
  ) => {
    const requestData = `
   mutation CreateShoplistCollection($name: String!, $collection_media: Upload, $expires_at: String, $products: [ID!]!, $base_image: Upload, $base_image_url: String, $base_video_url: String, $media_type: String, $description: String, $shipping_category: String, $dimension_size: String, $type: UpdateShoplistCollectionType!) {
        CreateShoplistCollection(
          name: $name
          collection_media: $collection_media
          expires_at: $expires_at
          products: $products
          base_image: $base_image
          base_image_url: $base_image_url
          base_video_url: $base_video_url
          media_type: $media_type
          description: $description
          shipping_category: $shipping_category
          dimension_size: $dimension_size
          type: $type
        ) {
          id
          uuid
          name
          base_image_url
          media_type
          favorites_count
          user_is_favorite
          description
          expires_at
          shipping_category {
             category_id
             category
          }
          dimension_size {
            name
            description_image_url
            height
            width
            length
            max_weight
          }
          status
          media {
            media_url
            percentage 
          }
          products {
            id
            uuid
            name
            description
            category {
              id
              parent_category_id
              name
            }
            rewards {
              id
              type
              percentage
              quantity
            }
            business {
              id
              business_tag
              business_name
              uuid
            }
            regular_price
            primary_image_url
            stock_quantity
            status
          }
          business {
            id
            uuid
            business_name
            business_tag
            photo_url
          }
        }
      }
    `
    const response: Promise<OperationResult<{
      CreateShoplistCollection: Collection
    }>> = this.mutationWithProgress(requestData, data, progressCb)

    return response
  }

  public UpdateShoplistCollection = (
    data: MutationUpdateShoplistCollectionArgs,
    progressCb: (progress: number) => void,
  ) => {
    const requestData = `
    mutation UpdateShoplistCollection(
        $collection_id: Int!
        $name: String
        $collection_media: Upload,
        $expires_at: String
        $products: [ID!]
        $base_image: Upload
        $base_image_url: String
        $base_video_url: String
        $media_type: String
        $description: String
        $shipping_category: String
        $dimension_size: String
        $type: UpdateShoplistCollectionType!
    ) {
      UpdateShoplistCollection(
          collection_id: $collection_id
          name: $name
          collection_media: $collection_media
          expires_at: $expires_at
          products: $products
          base_image: $base_image
          base_image_url: $base_image_url
          base_video_url: $base_video_url
          media_type: $media_type
          description: $description
          shipping_category: $shipping_category
          dimension_size: $dimension_size
          type: $type
      ) {
          id
          uuid
          name
          base_image_url
          media_type
          description
          favorites_count
          user_is_favorite
          expires_at
          status
          shipping_category {
             category_id
             category
          }
          dimension_size {
            name
            description_image_url
            height
            width
            length
            max_weight
          }
          media {
            media_url
            percentage 
          }
          products {
            id
            uuid
            name
            description
            category {
              id
              parent_category_id
              name
            }
            rewards {
              id
              type
              percentage
              quantity
            }
            business {
              id
              business_tag
              business_name
              uuid
            }
            regular_price
            primary_image_url
            stock_quantity
            status
          }
          business {
            id
            uuid
            business_name
            business_tag
            photo_url
          }
        }
      }
    `
    const response: Promise<OperationResult<{
      UpdateShoplistCollection: Collection
    }>> = this.mutationWithProgress(requestData, data, progressCb)

    return response
  }

  public AddRemoveCollectionProduct = (
    data: MutationAddRemoveCollectionProductArgs,
  ) => {
    const requestData = `
    mutation AddRemoveCollectionProduct($collection_id: Int!, $products: [Int!], $action: ManageCollectionAction!) {
      AddRemoveCollectionProduct(collection_id: $collection_id, products: $products, action: $action) {
        id
        uuid
      }
    }
    `
    const response: Promise<OperationResult<{
      AddRemoveCollectionProduct: Collection
    }>> = this.mutation(requestData, data)

    return response
  }

  public DeleteShoplistProductReward = (reward_id: number) => {
    const requestData = `
    mutation DeleteShoplistProductReward($reward_id: Int!) {
      DeleteShoplistProductReward(
        reward_id: $reward_id 
      )
    }
    `
    const response: Promise<OperationResult<{
      DeleteShoplistProductReward: Boolean
    }>> = this.mutation(requestData, {
      reward_id,
    })

    return response
  }

  public DeleteCollection = (collection_id: number) => {
    const requestData = `
    mutation DeleteCollection($collection_id: Int!) {
      DeleteCollection(
        collection_id: $collection_id 
      )
    }
    `
    const response: Promise<OperationResult<{
      DeleteCollection: Boolean
    }>> = this.mutation(requestData, {
      collection_id,
    })

    return response
  }
}
